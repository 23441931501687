export default {
  resources: 'Resources',
  aviNumber: 'Avi Number',
  color: 'Color',
  catalogId: 'Catalog Id',
  id: 'Vehicle Id ',
  activeRentalId: 'Active Rental Id',
  rentalStatus: 'Rental Status',
  status: 'Status',
  address: 'Address',
  bodyType: 'Body Type:',
  odometer: 'Odometer',
  canbusOdometer: 'Canbus Km.',
  chassisNumber: 'Chassis No',
  doorLockType: 'Door Lock Type',
  equipmentNumber: 'Equipment Number',
  fuelLevelLt: 'Fuel Level (Lt.)',
  fuelLevelBattery: 'Battery Level',
  fuelType: 'Fuel Type',
  hgsNumber: 'HGS No',
  imeiNumber: 'IMEI No',
  lastDirection: 'Direction',
  latestLocation: 'Location',
  licenseNumber: 'License No',
  make: 'Make',
  materialNumber: 'Material Number',
  model: 'Model',
  modelYear: 'Year',
  plate: 'Plate',
  transmissionType: 'Transmission Type',
  typeDetail: 'Type detail',
  firstAvailableDate: 'First Available Date',
  firstDeliveryDate: 'First Delivery Date',
  // DO NOT USE THIS, It will be deprecated, use rentals.key instead
  rentalStatuses: {
    PENDING_PICKUP: 'Pending Pickup',
    NOT_ACTIVATED: 'Not Activated',
    ACTIVATED: 'Activated',
    WORKING: 'Working',
    AVAILABLE: 'Available',
    ALL: 'All',
    IN_TRANSIT: 'In Transit',
  },
  damage: {
    description: 'Description',
    fileIds: 'Files',
    region: 'Region',
    id: 'Id',
  },
  operationalStatus: {
    IN_TRANSIT: 'In Transit',
    PENDING_PICKUP: 'Pending Pickup',
    WORKING: 'Working',
    WILL_TRANSFER_TO_CAR_LOT: 'Will transfer to car lot',
    TRANSFERING_TO_CAR_LOT: 'Transerring to car lot',
    NONE: '-',
    IN_REPLACEMENT: 'In Replacement',
  },
  vehicleStatus: {
    ACTIVATED: 'Passive',
    NOT_ACTIVATED: 'Not Active',
    OUT_OF_SERVICE: 'Out of Service',
    DELETED: 'Deleted',
    AVAILABLE: 'Available',
    STOLEN: 'Stolen',
    STOLEN_OUT_OF_FLEET: 'Stolen (out of fleet)',
    RETURN_PREPARATION: 'Return Preparation',
    HEAVILY_DAMAGED: 'Heavily Damaged',
    RETURNED: 'Returned',
    DEVICE_IS_BEING_INSTALLED: 'Device is being installed',
    PLATE_IS_BEING_UPDATED: 'Plate is being updated',
    NEW: 'New',
  },
  compoundOperationalStatus: {
    AVAILABLE: 'Available',
    NOT_AVAILABLE: 'Not Available',
    WORKING: 'Working',
  },
  assignJob: 'Assign Job',
  streetView: 'Street View',
  breakdown: 'Breakdown',
  vehicleDetails: 'Vehicle Details',
  inRental: 'Active Rental',
  lastAddress: 'Last Address',
  lastVisible: 'Last Visible',
  job: {
    title: 'Job Assignment',
  },
  parks: 'Parks',
  stations: 'Stations',
  valets: 'Valets',
  maintenance: 'Maintenance',
  maintenanceDamage: 'Maintenance / Damage',
  assetHistory: {
    DoorCommandCodeExecuted: 'Door command code executed',
    DoorCloseCommandSent: 'Door close command sent.',
    DoorOpenCommandSent: 'Door open command sent',
    IgnitionOn: 'Ignition on',
    IgnitionOff: 'Ignition off',
    DoorOpenEvent: 'Door opened',
    DoorCloseEvent: 'Door closed',
    RTCSetTimeCommandSent: 'RTC set time command sent',
    RTCDoorOnCommandSent: 'RTC door on command sent',
    RTCDoorOffCommandSent: 'RTC door off command sent',
    ResetCommandSent: 'Reset command sent',
    SoftwareStart: 'Software start',
    EchoResponse: 'Echo response',
    EchoCommandSent: 'Echo command sent',
    valets: 'Valets',
    parks: 'Parks',
    stations: 'Stations',
    maintenance: 'Maintenance',
    maintenanceDamage: 'Maintenance / Damage',
    DoorCloseCommandSendingFailed: 'Door close command sending failed',
    DeviceConnected: 'Device connected',
    ABSFailure: 'ABS Failure',
    ABSFailureReversed: 'ABS Failure Reversed',
    AirbagFailure: 'Airbag Failure',
    AirbagFailureReversed: 'Airbag Failure Reversed',
    ASRFailure: 'ASR Failure',
    OutputStateChanged: 'Output State Changed',
    VDCFailureReversed: 'VDC Failure Reversed',
    TrailerDisconnected: 'Trailer Disconnected',
    LeftHandFrontParkTailLightFailureReversed: 'Left Hand Front Park Tail Light Failure Reversed',
    ImmobilizerFailure: 'Immobilizer Failure',
    DeviceDisconnected: 'Device Disconnected',
  },
  taskColumns: {
    name: 'Task',
    note: 'Note',
    notes: 'Notes',
    passiveReason: 'Passive Reason',
    status: 'Status',
    driverName: 'Driver Name',
    startDate: 'Start Date',
    endDate: 'End Date',
    startDistrict: 'Start District',
    endDistrict: 'End District',
    createdBy: 'Created by',
    cancelReason: 'Cancel Reason',
    creationDate: 'Creation Date',
    creationTime: 'Creation Time',
    acceptanceTime: 'Acceptance Time',
    description: 'Description',
    comment: 'Comment',
    destination: 'Destination',
  },
  available24: 'Available (0-24 Hours in Idle)',
  available2448: 'Available İçi (24-48 Hours in Idle)',
  available48: 'Available İçi (48+ Hours in Idle)',
};
