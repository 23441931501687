export default {
  searchText: 'Plana, isim yada şasi no ile ara',
  addNewVehicle: 'Yeni Araç Ekle',
  nameOptional: 'İsim (Opsiyonel)',
  chasisNo: 'Şasi No',
  licenseSerialNo: 'Lisans Seri No',
  averageCostPerMile: 'Ortalama 1 mil ücreti',
  averageEmissionPerMile: 'Ortalama 1 mil emisyonu',
  depot: 'Depo',
  editVehicle: 'Araç Düzenle',
  BUTUN_DOKUMANLAR: 'Bütün Dökümanlar',
  ARAC_FOTOGRAFI: 'Araç Fotoğrafı',
  TRAFIK_SIGORTASI: 'Trafik Sigortası',
  YOL_BELGESI: 'Yol Belgesi',
  RUHSAT: 'Ruhsat',
  ISG: 'ISG',
  plateNumber: 'Plaka',
  deviceStatus: 'Cihaz Durumu',
  yearsOld: '({0} yaşında)',
  driverLicence: '{0} Sınıf',
  driversLicence: 'Ehliyet Sınıfı',
  EHLIYET: 'Ehliyet',
  SABIKA_KAYDI: 'Sabıka Kaydı',
  vehicleAdded: 'Araç Eklendi.',
  vehicleDeleted: 'Araç Silindi.',
  devicesStatus: {
    OK: 'Okey',
    CIHAZ_YOK: 'Cihaz Yok',
    BAGLANTI_YOK: 'Bağlantı Yok',
  },
  vehicleChangeReasons: {
    ACCIDENT: 'Kaza',
    VEHICLE_BREAKDOWN: 'Araç Arızası',
    MAINTENANCE: 'Bakım',
    OTHER: 'Diğer',
  },
  vehiclePlate: 'Araç Plakası',
};
