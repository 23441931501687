export default {
  alarmRules: 'Alarm Rules',
  generatedAlarms: 'Oluşan Alarmlar',
  creationTime: 'Creation Time',
  ruleId: 'Rule ID',
  ruleName: 'Rule Name',
  rentalName: 'Rental Name',
  categoryName: 'Category Name',
  channel: 'Channel',
  departments: 'Departments',
  rentalId: 'Rental ID',
  accountId: 'Account ID',
  nameSurname: 'Name Surname',
  completedRentals: 'Completed Rentals',
  instantLocation: 'Instant Location(province)',
  subject: 'Subject',
  toList: 'To List',
  active: 'Active ?',
  addDepartment: 'Add Department',
  addToList: 'Add to List',
  sendNotification: 'Send Notification',
  apiCall: 'Api Call',
  topic: 'Topic',
  additionalInfo: 'Additional Info',
};
