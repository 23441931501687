export default {
  new: 'New',
  updatedData: 'There are updated data',
  prices: 'Prices',
  packages: 'Packages',
  list: 'Price List',
  action: 'Save Price Changes',
  effect: 'This pricing affects {group} vehicle groups and {count} vehicle(s) inside those groups',
  newVal: 'New Value',
  oldVal: 'Old Value',
  change: 'Change',
  modified: '{amount} item(s) modified',
  hourlyCostBeforeDiscount: 'Hourly Cost Before Discount',
  hourlyCostWithDiscount: 'Discounted Hourly Price',
  kmExceededCost: 'KM Price',
  convertToDailyThreshold: 'Daily Rent Treshold',
  provisionCost: 'Provision',
  kmPerRentalLimit: 'Rental/KM Limit',
  nightlyCost: 'Fixed Nightly',
  pointRatio: 'Points earning rate - Individual',
  corporatePointRatio: 'Points earning rate - Corparate',
  typeOfRental: 'Type of Rental',
  hourlyCost: 'Hourly Cost',
  selectChangeList: 'Please select price you want to change from price list',
  updatePriceError: 'It has been occured an error when prices updating',
  updatedPrice: 'price lists has been updated',
  tpcServicePrice: 'TPC Service Price ( including vat)',
  selectHgsFile: 'Please select the file to upload hgs', // :LTODO
  entryLocation: 'Entry Location',
  exitLocation: 'Exit Location',
  rateNotUpdated: 'Rate has not been updated',
  tpcServicePriceUpdated: 'Tpc Service Price has been updated',
  vatRateUpdate: 'New rate has been updated as {0} %',
  kmPackages: 'KM Packages',
  km: 'KM',
  selectType: 'Select Type',
  packageUpdated: 'Package has been updated',
  createNewPackage: 'Create New Package',
  newPackageAdded: 'New Package Added',
  packageWillSelect: 'Package will be selected named {0}. Are you Sure ?',
  packageDeleted: 'Package has been deleted',
  ACTIVE: 'Active',
  PASSIVE: 'Passive',
  refresh: 'Refresg',
  MONTHLY: 'Monthly',
  INSTANTLY: 'Instantly',
  hourlyPrice: 'Hourly Price',
  provisionBackingHours: 'Provision Backing Hours',
  pointRatioPercentage: 'Point Ratio Individual',
  corporatePointRatioPercentage: 'Point Ratio Corporate',
  pricePointRewardTime: 'Price Point Reward Time',
  dailyCost: 'Daily Cost',
  weekendCost: 'Weekend Cost',
  kmPerDayLimit: 'Km Per Day Limit',
  kmPackage: 'KM Package',
  durationPackage: 'Duration Package',
  catalogPrice: 'Catalog / Price',
  notSaved: 'Changes dont save until press {button} button.',
  pressButton: 'In order for the changed values ​​to be reflected in the system, all desired changes must be made and then the {button} button must be pressed.',
  addPackage: 'Adding package for {package}',
  voidPlates: 'Void Plates',
  ageGroups: 'Age Groups',
  priceCatalog: 'Price Catalog',
  addAgeGroup: 'Add Age Group',
  ageCatalogMatching: 'Age Group - Price Catalog Matching',
  insurancePackageRules: 'Insurance Package Rules',
  insurancePricingRules: 'Insurance Pricing Rules',
  pricesAndParameters: 'Prices - Parameters',
  addCatalog: 'Add Catalog',
  addInsurancePackage: 'Add Package',
  packageName: 'Package Name',
  addNewCatalog: 'Add New Catalog',
  editPackage: 'Edit Package',
  packageDetails: 'Package Details',
  addRule: 'Add Rule',
  catalog: 'Catalog',
  minDrivingLicenceAge: 'Minimum Driving Licence Age',
  maxDrivingLicenceAge: 'Maximum Driving Licence Age',
  minAge: 'Minimum Age',
  maxAge: 'Maximum Age',
  ruleCreated: 'Rule has been created.',
  insurancePackages: 'Insurance Packages',
  addNewPackage: 'Add New Package',
  insurancePackage: 'Insurance Package',
  insuranceCatalogs: 'Insurance Catalogs',
  corporateInsuranceCatalogs: 'Corporate Insurance Catalogs',
  insurancePackageCost: 'Insurance Package Cost',
  addedBalance: 'Added Balance',
  packageDescription: 'Package Description',
  availableDays: 'Days',
  bufferDurationInMinute: 'Buffer duration in minute',
  preDurationInMinute: 'Pre duration in minute',
  postDurationInMinute: 'Post duration in minute',
  freeWaitingDurationInMinute: 'Free waiting duration in minute',
  showCurrentImage: 'Show current image',
  showUploadedImage: 'Show uploaded image',
  packageImage: 'Package Image',
  showImage: 'Show Image',
};
