const BASEURL = process.env.NODE_ENV === 'development' ? '/api/' : '/';

const APP_PREFIX = () => {
  const app = localStorage.getItem('app');
  switch (app) {
    case 'garenta':
      return 'garenta2';
    case 'FLEXIGO':
    case 'FLEXIGO_ADMIN':
      return 'pool';
    default:
      return 'carshare';
  }
};

const UMNG = `${BASEURL}umng`;
const UMNG_REST = `${BASEURL}umng/rest`;
const PORTAL_PLAIN = `${BASEURL}${APP_PREFIX()}`;
const PORTAL = `${BASEURL}${APP_PREFIX()}/rest`;
const PORTAL_PUBLIC = `${BASEURL}${APP_PREFIX()}/public`;
const CORPORATE = `${BASEURL}${APP_PREFIX()}/rest/corporate`;
const UMNG_CORPORATE = `${UMNG}/rest/corporate`;
const REPORT = `${BASEURL}report/rest`;
const PLANNER = `${BASEURL}planner/rest`;
const PLANNER_CORPORATE = `${BASEURL}planner/rest/corporate`;
const TEST_SIM = `${BASEURL}${APP_PREFIX()}/public`;

const CARGO = `${BASEURL}cargo/rest`;
const OPTIMIZATION = `${BASEURL}optimization/v2/rest/opt/v1`;

export const corporate = {
  downloadTpcInvoice: `${PORTAL}/portal/system/downloadTpcInvoice`,
  downloadTpcTutanak: `${PORTAL}/portal/system/downloadTpcTutanak`,
  dashboard: `${CORPORATE}/dashboard/stats/rental`,
  additionalRentalFees: `${CORPORATE}/dashboard/stats/additionalRentalFees`,
  userAdditionalRentalFees: `${CORPORATE}/dashboard/stats/userAdditionalRentalFees`,
  monitor: `${CORPORATE}/monitor/stats/userCount`,
  users: `${CORPORATE}/members`,
  userRentals: `${CORPORATE}/members/:id/rentals`,
  rentals: `${CORPORATE}/rentals`,
  rental: `${CORPORATE}/rentals/:rentalId`,
  rentalAdvanced: `${CORPORATE}/rentals/advanced/:rentalId`,
  rentalFinishReasons: `${CORPORATE}/rentals/finishReasons`,
  balances: `${CORPORATE}/balances`,
  transactions: `${CORPORATE}/transactions`,
  upload: `${UMNG_CORPORATE}/members/upload`,
  add: `${UMNG_CORPORATE}/members/bulkAdd`,
  remove: `${UMNG_CORPORATE}/members/bulkRemove`,
  reactivate: `${UMNG_CORPORATE}/members/bulkReactivate`,
  paymentMethods: `${CORPORATE}/paymentMethods`,
  validate3d: `${CORPORATE}/paymentMethods/:id/validate3d`,
  buyOffer: `${CORPORATE}/buyOffer`,
  trips: `${CORPORATE}/rentals/:rentalId/trips`,
  rentalAssetHistory: `${CORPORATE}/rentals/:rentalId/route`,
  umngUsers: `${UMNG_CORPORATE}/users`,
  firm: `${CORPORATE}/firm`,
  vehicles: `${CORPORATE}/vehicles`,
  vehicle: {
    status: `${CORPORATE}/vehicles/:id/status`,
    outOfServiceCategories: `${CORPORATE}/vehicles/outOfServiceCategories`,
    updateOwner: `${CORPORATE}/vehicles/:vehicleId/update-owner`,
    doors: `${CORPORATE}/vehicles/:vehicleId/door`,
  },
  ownerList: `${CORPORATE}/vehicles/prm/owner-list`,
  parks: `${CORPORATE}/parks`,
  additionalFees: `${CORPORATE}/additionalFees`,
  userFines: `${CORPORATE}/trafficFines/:customerId`,
  assignPark: `${CORPORATE}/vehicles/:vehicleId/assign-park`,
};

export const files = {
  uuidUploadSingle: `${REPORT}/file/upload2`,
  uuidUploadMultiple: `${REPORT}/file/uploadMany2`,
  systemHgs: `${PORTAL}/portal/system/uploadHgsList`,
  vehicleCatalogImages: `${PORTAL}/portal/vehicles/images`,
  imageToCatalog: `${PORTAL}/portal/vehicles/catalogs/:vehicleCatalogId/image`,
  addressUpload: `${PLANNER_CORPORATE}/addresses/uploadList`,
  ordersUpload: `${PLANNER_CORPORATE}/orders/uploadOrders`,
  uploadIdBenefit: `${PORTAL}/portal/benefits/:id/uploadIdList`,
  batchUploadPushMessage: `${PORTAL}/portal/system/batchUploadPushMessage`,
  uploadDrivingLicenseBulkUpdate: `${PORTAL}/portal/customers/uploadDrivingLicenseBulkUpdate`,
  uploadSelfieBulkUpdate: `${PORTAL}/portal/customers/uploadSelfieBulkUpdate`,
  uploadGenderBulkUpdate: `${PORTAL}/portal/customers/uploadGenderBulkUpdate`,
  vehiclesCurrentStatus: `${PORTAL}/portal/vehicles/reports/vehiclesCurrentStatus`,
  bulkStatusUpdate: `${PORTAL}/portal/vehicles/upload/statusChange`,
  bulkStatusUpdateTemplate: `${PORTAL}/portal/vehicles/template/statusChange/AracTopluDurumDegistirme.xlsx`,
  zoneRulesExcel: `${PORTAL}/portal/zone/rules/:id/accountIdBoundList`,
  reservationExcel: `${PORTAL}/portal/daily-reservation/price-import`,
  instantOfferExcel: `${PORTAL}/portal/instant-offer/definition-import`,
  insurancePackageOfferExcel: `${PORTAL}/portal/insurance-package-offer/price-import`,
  routePackageExcel: `${PORTAL}/portal/route-package-offer/price-import`,
  dailyReservationStation: `${PORTAL}/portal/daily-reservation/station-import`,
  selfServicePriceExcel: `${PORTAL}/portal/prices/self-service/price-import`,
  dailyPriceExcel: `${PORTAL}/portal/prices/daily/price-import`,
  kmPackagePriceExcel: `${PORTAL}/portal/offer/packages/km-package/price-import`,
  durationPackagePriceExcel: `${PORTAL}/portal/offer/packages/duration-package/price-import`,
  slotBasedPriceExcel: `${PORTAL}/portal/offer/packages/slot-based/price-import`,
};

export const reservation = {
  parkings: `${CORPORATE}/calendars/parks`,
  vehicles: `${CORPORATE}/calendars/:calendarId/vehicles`,
  resources: `${UMNG_REST}/calendar/resources`,
  events: `${UMNG_REST}/calendar/:id/events`,
  requests: `${CORPORATE}/flexiride/requests`,
  assign: `${CORPORATE}/flexiride/requests/:requestId/assign`,
  assignableVehicles: `${CORPORATE}/flexiride/vehicles`,
  simulation: `${CORPORATE}/flexiride/moia/:simulationId`,
  updateVehicle: `${CORPORATE}/flexiride/requests/:requestId/updateVehicle`,
  priceModels: `${CORPORATE}/flexiride/available-poolcar-price-models`,
  requestStats: `${CORPORATE}/flexiride/stats`,
  // assignableVehicles: see planner/drivers
};

export const prm = {
  vehicles: `${PORTAL}/portal/vehicles/prm/plates`,
  vehicleCatalogs: `${PORTAL}/portal/vehicles/prm/catalogs`,
};

export const planner = {
  corporate: {
    dashboard: `${PLANNER_CORPORATE}/dashboard`,
    addresses: {
      list: `${PLANNER_CORPORATE}/addresses`,
      save: `${PLANNER_CORPORATE}/addresses/saveList`,
      reverseGeocode: `${PLANNER_CORPORATE}/addresses/reverseGeocode`,
    },
    vehicles: `${PLANNER_CORPORATE}/vehicles`,
    drivers: `${PLANNER_CORPORATE}/drivers`,
    resources: `${PLANNER_CORPORATE}/calendar/resources`,
    approveDraft: `${PLANNER_CORPORATE}/calendar/resources/:resourceId/approveDraft`,
    events: `${PLANNER_CORPORATE}/calendar/events`,
    removeGaps: `${PLANNER_CORPORATE}/calendar/:routeId/removeGaps`,
    updateFastestSequence: `${PLANNER_CORPORATE}/calendar/:routeId/updateFastestSequence`,
    calculateTraffic: `${PLANNER_CORPORATE}/calendar/:routeId/calculateTraffic`,
    track: `${PLANNER_CORPORATE}/calendar/track`,
    depots: `${PLANNER_CORPORATE}/depots`,
    orders: {
      orders: `${PLANNER_CORPORATE}/orders`,
      update: `${PLANNER_CORPORATE}/calendar/resources/:resourceId/updateOrder`,
      confirmOrdersFile: `${PLANNER_CORPORATE}/orders/confirmOrders`,
      removeRoute: `${PLANNER_CORPORATE}/orders/:orderId/remove-route`,
    },
    optimizations: `${PLANNER_CORPORATE}/optimizations`,
    autoplan: `${PLANNER_CORPORATE}/optimizations/autoPlan`,
    autoPlanAsync: `${PLANNER_CORPORATE}/optimizations/auto-plan-async`,
    regions: `${PLANNER_CORPORATE}/regions`,
    settings: {
      locations: `${PLANNER_CORPORATE}/settings/locations`,
      loadDimensions: `${PLANNER_CORPORATE}/settings/loadDimensions`,
      vehicleFeatures: `${PLANNER_CORPORATE}/settings/vehicleFeatures`,
      driverSkills: `${PLANNER_CORPORATE}/settings/driverSkills`,
      application: `${PLANNER_CORPORATE}/settings/applications`,
    },
  },
  poolOrders: `${PLANNER}/portal/pool-orders`,
};

export const publicServices = {
  districts: `${BASEURL}${APP_PREFIX()}/public/districts`,
};

export const prices = {
  prices: `${PORTAL}/portal/prices`,
  pricesUpdate: `${PORTAL}/portal/prices/update`,
  catalogs: `${PORTAL}/portal/prices/catalogs`,
  vehicleCatalogPrices: `${PORTAL}/portal/prices/:catalogId/vehicleCatalogPrices`,
  kmPackagePrices: `${PORTAL}/portal/offer/packages/priceCatalog/:packageId`,
  packageType: `${PORTAL}/portal/offer/packages`,
  packageTypeWithId: `${PORTAL}/portal/offer/packages/:packageId`,
  extension: `${PORTAL}/portal/offer/packages/:packageId/extension`,
  hgsRates: `${PORTAL}/portal/hgsVatRates`,
  rentalTypes: `${PORTAL}/portal/prices/catalogs/rental-types`,
  tpcVatRate: `${UMNG}/rest/portal/applicationParameter/payment/trafficFineServiceFee`,
  pricingRules: `${PORTAL}/portal/prices/pricing-rules`,
  priceCatalogRules: `${PORTAL}/portal/prices/price-catalog-rules`,
  reservationGroupPrices: `${PORTAL}/portal/prices/price-groups`,
  insuranceParameters: `${UMNG}/rest/portal/applicationParameter/additionalPackageOfferSettings`,
  catalogTypes: `${PORTAL}/portal/prices/catalog-types`,
  insuranceCatalogs: `${PORTAL}/portal/prices/insurance/catalogs`,
  exportInsurancePackage: `${PORTAL}/portal/insurance-package-offer`,
  insurancePackage: `${PORTAL}/portal/insurance-package-offer`,
  insuranceRules: `${PORTAL}/portal/prices/insurance/pricing-rules`,
  insurancePriceCatalogRules: `${PORTAL}/portal/prices/insurance/price-catalog-rules`,
  routeParameters: `${UMNG}/rest/portal/applicationParameter/routePackageOfferSettings`,
  exportRoutePackage: `${PORTAL}/portal/route-package-offer`,
  routeRuleList: `${PORTAL}/portal/prices/rental/pricing-distance-rules`,
  routePriceCatalogs: `${PORTAL}/portal/prices/rental/price-catalog-distance-rules`,
  exportDailyPriceExcel: `${PORTAL}/portal/prices/daily`,
  exportSelfServicePriceExcel: `${PORTAL}/portal/prices/self-service`,
  exportKmPackagePriceExcel: `${PORTAL}/portal/offer/packages/km-package`,
  exportDurationPackagePriceExcel: `${PORTAL}/portal/offer/packages/duration-package`,
  exportSlotBasedPackage: `${PORTAL}/portal/offer/packages/slot-based`,
  slotBasedParameters: `${UMNG}/rest/portal/applicationParameter/slotBasedPackageOfferSettings`,
  slotBasedPackages: `${PORTAL}/portal/offer/packages/slot-based`,
  updateSlotBasedPackages: `${PORTAL}/portal/offer/packages/slot-based-packages`,
};

export const zones = {
  zones: `${PORTAL}/portal/zones`,
  serviceAreas: `${PORTAL}/portal/zones/service-areas`,
  zoneTypes: `${PORTAL}/portal/zones/types`,
  zoneType: `${UMNG}/rest/portal/applicationParameter/zoneType`,
  zoneTypeUpdate: `${UMNG}/rest/portal/applicationParameter`,
  priceTimeIntervals: `${PORTAL}/portal/zones/price-time-intervals`,
  zoneRules: `${PORTAL}/portal/zone/rules`,
  zoneRulesExcelCount: `${PORTAL}/portal/zone/rules/:id/accountIdCount`,
  zoneRulesExlcelFile: `${PORTAL}/portal/zone/rules/:id/excel/accountIdBoundList`,
  updatePriceTimeIntervals: `${PORTAL}/portal/zones/price-time-intervals/:id`,
  getPriceTimeIntervals: `${PORTAL}/portal/zones/:zoneId/price-time-intervals`,
  forbiddenAreas: `${PORTAL}/portal/zones/forbidden-areas`,
  vehicleRecommend: `${PORTAL}/portal/zone/rules/:id/recommend`,
};

export const subscription = {
  subscription: `${PORTAL}/portal/subscription`,
  definitions: `${UMNG}/rest/portal/applicationParameter/subscription`,
  customers: `${PORTAL}/portal/customers`,
  referral: `${UMNG}/rest/portal/applicationParameter/referral`,
};

export const arge = {
  forecasting: `${PORTAL}/portal/arge/forecasting`,
  timeWindows: `${PORTAL}/portal/arge/forecasting/time-windows`,
  discountRanges: `${PORTAL}/portal/arge/price-suggestion-discount-ranges`,
};

export const alarms = {
  alarm: `${PORTAL}/portal/alarm-rules`,
  generatedAlarms: `${PORTAL}/portal/generated-alarms`,
};

export const rewardTasks = {
  reward: `${PORTAL}/portal/vehicle/rewards`,
  downloadTemplate: `${PORTAL}/portal/vehicle/rewards/template/AracOdulYukleme.xlsx`,
  uploadRewardExcel: `${PORTAL}/portal/vehicle/rewards/import/vehicleRewards`,
};

export const intercampus = {
  createSimulation: `${TEST_SIM}/intercampus/simulations/create-new`,
  getSim: `${TEST_SIM}/intercampus/simulations`,
};

export const dashboard = {
  routeChartInfo: `${PORTAL}/portal/dashboard/stats`,
};

export const reservations = {
  reservation: `${PORTAL}/portal/daily-reservation`,
  kmPackage: `${PORTAL}/portal/offer/packages/daily-reservation-km-package`,
  customerReservations: `${PORTAL}/portal/customers/`,
  settings: `${UMNG}/rest/portal/applicationParameter/dailyReservationSettings`,
  rentalReservationDetail: `${PORTAL}/portal/rentals/:id/dailyReservation`,
  downloadExcel: `${PORTAL}/portal/daily-reservation/price-template`,
};

export const instantOffers = {
  downloadExcel: `${PORTAL}/portal/instant-offer/definition-template`,
  getCurrentExcel: `${PORTAL}/portal/instant-offer/definition-export`,
  list: `${PORTAL}/portal/instant-offer/definition-list`,
  customerInstantOffers: `${PORTAL}/portal/customers/`,
};

export const incidents = {
  incident: `${PORTAL}/portal/vehicle-incidents`,
  damageTypes: `${PORTAL}/portal/vehicle-incidents/damage-types`,
  damageParts: `${PORTAL}/portal/vehicle-incidents/damage-parts`,
  partChangeStatus: `${PORTAL}/portal/vehicle-incidents/part-change-status`,
  damageSizes: `${PORTAL}/portal/vehicle-incidents/damage-sizes`,
  reflectionInvoiceStatus: `${PORTAL}/portal/vehicle-incidents/reflection-invoice-status`,
  reflectionInvoiceNotAppliedReasons: `${PORTAL}/portal/vehicle-incidents/reflection-invoice-not-applied-reasons`,
  currencies: `${PORTAL}/portal/vehicle-incidents/currencies`,
  damages: `${PORTAL}/portal/vehicle-incidents/:incidentId/damages`,
  withdrawalEnums: `${PORTAL}/portal/vehicle-incidents/withdrawal`,
  scrapEnums: `${PORTAL}/portal/vehicle-incidents/vehicle-scrap`,
};

export const umng = {
  logout: `${UMNG}/logout`,
  flow: `${UMNG}/oauth/flow`,
  sendPasswordLink: `${UMNG}/forgotPassword/apply`,
  tokenInfo: `${UMNG}/oauth/tokenInfo`,
  resetTokenInfo: `${UMNG}/forgotPassword/tokenInfo`,
  updatePassword: `${UMNG}/forgotPassword/changePassword`,
  sendPassword: `${UMNG}/rest/portal/user/:id/newPassword`,
  changePassword: `${UMNG}/oauth/flow`,
  mainMenu: `${UMNG}/rest/menu/main`,
  search: `${UMNG}/rest/portal/user/search`,
  applications: `${UMNG}/rest/admin/applications`,
  roles: `${UMNG}/rest/admin/:application/roles`,
  permissions: `${UMNG}/rest/admin/:application/permissions`,
  accounts: `${UMNG}/rest/admin/:application/accounts`,
  accountRoles: `${UMNG}/rest/admin/:application/accounts/:accountId/roles`,
  roleCreateUpdate: `${UMNG}/rest/admin/:application/roles`,
  deleteRole: `${UMNG}/rest/admin/:application/roles/:roleId`,
  assignRole: `${UMNG}/rest/admin/:application/accounts/:accountId/assignRoles`,
  settings: `${UMNG}/rest/portal/applicationParameter`,
  menuNodes: {
    list: `${UMNG}/rest/menu/node/:application`,
    updateNode: `${UMNG}/rest/menu/node/:application/update`,
    createNode: `${UMNG}/rest/menu/node/:application`,
  },
  documents: {
    byReference: `${UMNG}/rest/portal/documents/reference`,
    addDoc: `${UMNG}/rest/documents/add-document`,
  },
  organizationAdmin: `${UMNG}/rest/portal/createOrganisationAdmin`, // incosistent naming
  updateOrganizationAdmin: `${UMNG}/rest/portal/updateOrganisationAdmin`, // incosistent naming
  note: `${UMNG}/rest/notes`,
  noteByRef: `${UMNG}/rest/notes/reference`,
  menuNodesById: `${UMNG}/rest/menu/flexigo/account/:id`,
};

export const notes = {
  notes: `${PORTAL}/portal/notes`,
  note: `${PORTAL}/portal/notes/:id`,
};

export const settings = {
  smsBackup: `${PORTAL}/portal/settings/smsBackup`,
  smsOtp: `${UMNG_REST}/settings/sms/channel`,
  autoSmsSwitch: `${PORTAL}/portal/settings/smsAutoSwitch`,
  mobileOcrSetting: `${UMNG}/rest/portal/applicationParameter/mobile`,
  mobileVersion: `${REPORT}/app/version/mobile`,
  scoringParameters: `${PORTAL}/portal/rentals/score`,
  updateVehicleDeviceConnection: `${PORTAL}/portal/settings/check-vehicle-device-connection`,
  registrationSettings: `${UMNG}/rest/portal/applicationParameter/registrationSettings`,
  rentalEndMaxDistanceSettings: `${UMNG}/rest/portal/applicationParameter/rentalEndMaxDistanceSettings`,
};

export default {
  planner,
  umng,
  workflow: {
    taskCount: `${PORTAL}/workflow/tasks/taskCounts`,
    myPoolTasks: `${PORTAL}/workflow/tasks/myPoolTasks`,
    myTasks: `${PORTAL}/workflow/tasks/myTasks`,
    poolTasks: `${PORTAL}/workflow/tasks/poolTasks`,
    additionalData: `${PORTAL}/workflow/tasks/:id/additionalData`,
    reasonList: `${PORTAL}/workflow/documentRejectReasons`,
    approve: `${PORTAL}/workflow/tasks/:taskId/approveTask`,
    reject: `${PORTAL}/workflow/tasks/:taskId/rejectTask`,
    assignToMe: `${PORTAL}/workflow/tasks/:taskId/assignToMe`,
    assignNextPoolTask: `${PORTAL}/workflow/tasks/assignNextPoolTask`,
    task: `${PORTAL}/workflow/tasks/:id`,
    tasksPerUser: `${PORTAL}/workflow/tasks/userCounts`,
    assignableUsers: `${PORTAL}/portal/users/workflow`,
    assign: `${PORTAL}/workflow/tasks/:taskId/assign`,
  },
  garenta: {
    taxOffices: `${PORTAL_PLAIN}/public/taxOffices`,
    sendRentalDocuments: `${PORTAL}/portal/rentals/:id/sendRentalDocuments`,
    customers: `${PORTAL}/portal/customers/:customerId`,
    rentals: `${PORTAL}/portal/rentals`,
    rentalUpdate: `${PORTAL}/portal/rentals/:id/updateRentalInfo`,
    rentalDiscountDelete: `${PORTAL}/portal/rentals/:rentalId/discounts/:id`,
    rentalTerminationReasons: `${PORTAL}/portal/rentals/finishReasons`,
    rentalScore: `${PORTAL}/portal/rentals/:id/tripScores`,
    collectCurrentBalance: `${PORTAL}/portal/rentals/:id/collectCurrentDebt`,
    alternativesAssignments: `${PORTAL}/portal/rentals/:id/alternativeAssignments`,
    vehicles: `${PORTAL}/portal/vehicles`,
    vehicleCatalog: `${PORTAL}/portal/vehicleCatalogs`,
    vehicleDamages: `${PORTAL}/portal/vehicles/:id/damages`,
    vehicleHistory: `${PORTAL}/portal/vehicles/:id/history`,
    synchVehicles: `${PORTAL}/portal/system/synchVehicles`,
    deleteCustomerImmediately: `${PORTAL}/portal/customers/delete-immediately/:customerId`,
    vehicle: {
      // TODO: all vehicle related messages should be move under this
      info: `${PORTAL}/portal/vehicles/:id`,
      smsReset: `${PORTAL}/portal/vehicles/:id/reset`,
      smsEcho: `${PORTAL}/portal/vehicles/:id/echo`,
      status: `${PORTAL}/portal/vehicles/:id/status`,
      statusEvents: `${PORTAL}/portal/vehicles/:id/status/events`,
      information: `${PORTAL}/portal/vehicles/:id/information`,
      rentals: `${PORTAL}/portal/vehicles/:id/rentals`,
      driverRoutes: `${PORTAL}/portal/vehicles/:id/driverRoutes`,
      outOfServiceCategories: `${PORTAL}/portal/vehicles/outOfServiceCategories`,
      notes: `${PORTAL}/portal/vehicles/:id/notes`,
      lastLocation: `${PORTAL}/portal/vehicles/:id/modifyLocation`,
      maintenance: `${PORTAL}/portal/vehicles/:id/maintenance/notes`,
      zone: `${PORTAL}/portal/vehicles/:id/zone`,
      incidents: `${PORTAL}/portal/vehicles/:id/incidents`,
      outOfServiceReasons: `${PORTAL}/portal/vehicles/outOfServiceReasons`,
      statusTypes: `${PORTAL}/portal/vehicles/vehicle-status`, // service should be renamed status-types
      untouched: `${PORTAL}/portal/vehicles/untouched/:hours`,
      uploadExcel: `${PORTAL}/portal/vehicles/upload/statusChange`,
      zoneByLocation: `${PORTAL}/portal/vehicles/:id/zoneByLocation`,
      tasks: {
        valet: `${PORTAL}/portal/vehicles/:vehicleId/valetTasks`,
        pool: `${PORTAL}/portal/vehicles/:vehicleId/poolTasks`,
        inService: `${PORTAL}/portal/vehicles/:vehicleId/inServiceTasks`,
      },
      deviceProblems: `${PORTAL}/portal/vehicles/device-problems`,
    },
    serviceAreas: `${PORTAL_PUBLIC}/serviceAreas`,
    serviceAreasWithForbidden: `${PORTAL}/portal/serviceAreaData`,
    changeJob: `${PORTAL}/portal/rentals/:id/changeDeliveryJob`,
    noteOptions: `${PORTAL}/portal/driverRoutes/noteOptions/v2`,
    cancelJob: `${PORTAL}/portal/driverRoutes/:id/cancel`,
    users: `${PORTAL}/portal/users`,
    adminUsers: `${PORTAL}/portal/users/admin`,
    userRoles: `${PORTAL}/portal/users/roles`,
    user: {
      update: `${PORTAL}/portal/users/:id/updateStatus`,
      updateDetails: `${PORTAL}/portal/users/:accountId/updateDetails`,
    },
    jobs: `${PORTAL}/portal/driverRoutes`,
    jobsAndNotes: `${PORTAL}/portal/driverRoutes/jobsAndNotes`,
    assignTask: `${PLANNER}/task/assign`,
    parks: `${PORTAL}/portal/parks`,
    valets: `${PORTAL}/portal/drivers`,
    drivers: {
      availabilityHistorySingle: `${PORTAL}/portal/drivers/:id/history`,
      availabilityHistory: `${PORTAL}/portal/drivers/availabilityHistory`,
    },
    doors: `${PORTAL}/portal/vehicles/:id/door`,
    markVehicleDamages: `${PORTAL}/portal/vehicles/:id/damages/markAllRepaired`,
    damages: `${PORTAL}/portal/damages`,
    markDamage: `${PORTAL}/portal/damages/:id/markRepaired`,
    customer: {
      info: `${PORTAL}/portal/customers/:id`,
      rentals: `${PORTAL}/portal/customers/:id/rentals`,
      individualRentals: `${PORTAL}/portal/customers/:id/userRentals`,
      devices: `${PORTAL}/portal/customers/:id/devices`,
      payments: `${PORTAL}/portal/customers/:id/payments`,
      refund: `${PORTAL}/portal/payments/:id/refund`,
      blacklist: `${PORTAL}/portal/customers/blacklist`,
      blacklistRemove: `${PORTAL}/portal/customers/blacklist/remove`,
      blacklistReasons: `${PORTAL}/portal/customers/blacklist/enums/blackListReasons`,
      refundReasons: `${PORTAL}/portal/payments/enums/refundReasons`,
      paymentMethods: `${PORTAL}/portal/customers/:id/paymentMethods`,
      updateStatus: `${PORTAL}/portal/customers/:id/updateStatus`,
      updateCustomerSignedAgreement: `${PORTAL}/portal/customers/:id/updateSignedAgreement`,
      updateCustomerDetails: `${PORTAL}/portal/customers/:id/updateDetails`,
      balance: `${PORTAL}/portal/customers/:customerId/balances`,
      transactions: `${PORTAL}/portal/customers/:customerId/transactions`,
      createTransaction: `${PORTAL}/portal/customers/:id/createTransaction`,
      rejectInadvertentlyApprovedDocuments: `${PORTAL}/portal/customers/:customerId/rejectInadvertentlyApprovedDocuments`,
      requestPayment: `${PORTAL}/portal/customers/:customerId/payment`,
      requestOtp: `${UMNG}/rest/otpSecured/accounts/:accountId/updateMobile`,
      verifyOtp: `${UMNG}/rest/otpSecured/process`,
      updateCustomerStatus: `${PORTAL}/portal/customers/:id/updateCustomerStatus`,
      updateCustomerFollowup: `${PORTAL}/portal/customers/:id/updateCustomerFollowup`,
      customerStatusLogs: `${PORTAL}/portal/customers/:id/customerStatusLogs`,
      updateAddress: `${PORTAL}/portal/customers/:id/updateAddress`,
      hgs: `${PORTAL}/portal/customers/:customerId/hgs`,
      documents: `${PORTAL}/portal/customers/documents`,
      campaignLogs: `${PORTAL}/portal/customers/:id/customerCampaignCodeLogs`,
      IYSLogs: `${UMNG}/rest/iys/:id/logs`,
      IYSChannelPermisson: `${PORTAL}/portal/customers/:customerId/channel/permission`,
      incidents: `${PORTAL}/portal/customers/:customerId/incidents`,
      zoneRules: `${PORTAL}/portal/zone/rules/accountIdBound/:id`,
      updateZoneRule: `${PORTAL}/portal/zone/rules/:id/accountId`,
      allDevices: `${PORTAL}/portal/customers/:id/devices`,
      approveDevice: `${PORTAL}/portal/customers/:id/approve-device`,
      revokeDevice: `${PORTAL}/portal/customers/:id/revoke-device`,
      ocrInfo: `${PORTAL}/portal/users/ocr-result/:accountId`,
      sendToApprove: `${PORTAL}/workflow/customerCreateWorkflowWithLatestDocuments`,
      incidentScoreStats: `${PORTAL}/portal/customers/incidentScoreStats`,
      hotDeviceWhitelist: `${PORTAL}/portal/system/hot-device-whitelist`,
      searchDeletedCustomer: `${UMNG_REST}/portal/deleted-user`,
      deletedCustomer: `${PORTAL}/portal/deleted-customers`,
      deviceBlacklistTemplate: `${PORTAL}/portal/device/blacklist/download`,
      uploadDeviceBlacklist: `${PORTAL}/portal/device/blacklist/upload`,
      deviceBlacklist: `${PORTAL}/portal/device/blacklist`,
    },
    stats: {
      dashboard: `${PORTAL}/portal/dashboard/stats`,
      monitor: `${PORTAL}/portal/monitor/stats`,
    },
    metrics: {
      geohash: `${PORTAL}/portal/forecasting/cluster/stats`,
    },
    searchMetrics: `${PORTAL}/portal/metric/list`,
    searchCarDetail: `${PORTAL}/portal/metric/search/download`,
    countryCodes: `${PORTAL_PUBLIC}/prm/countryCodes`,
    campaigns: {
      campaigns: `${PORTAL}/portal/campaigns`,
      timeIntervals: `${PORTAL}/portal/campaigns/:id/timeIntervals`,
      uploadExcel: `${PORTAL}/portal/system/uploadExcelBankAccount`,
      confirmExcelAccount: `${PORTAL}/portal/system/confirmExcelBankAccount`,
      codeGen: `${PORTAL}/portal/campaigns/:id/generateCodes`,
      updateProps: `${PORTAL}/portal/campaigns/:id/update`,
      uploadIdList: `${PORTAL}/portal/campaigns/:id/uploadIdList`,
      catalog: `${PORTAL}/portal/campaigns/catalogs`,
      campaignCatalogs: `${PORTAL}/portal/campaigns/:id/catalogs`,
      districts: `${PORTAL}/portal/campaigns/districts`,
      pois: `${PORTAL}/portal/campaigns/areas`,
      startDistricts: `${PORTAL}/portal/campaigns/:id/startDistricts`,
      endDistricts: `${PORTAL}/portal/campaigns/:id/endDistricts`,
      startPois: `${PORTAL}/portal/campaigns/:id/startPois`,
      endPois: `${PORTAL}/portal/campaigns/:id/endPois`,
      coupon: `${PORTAL}/portal/campaigns/code/search`,
      campaignAges: `${PORTAL}/portal/campaigns/:id/update/ageLimits`,
      benefitTypes: `${PORTAL}/portal/campaigns/benefitTypes`,
      idList: `${PORTAL}/portal/campaigns/:id/idList`,
      accountIdBound: `${PORTAL}/portal/campaigns/:id/update/accountIdBound`,
      accountIdList: `${PORTAL}/portal/campaigns/:id/accountIdBoundList`,
      accounIdCount: `${PORTAL}/portal/campaigns/:id/accountIdCount`,
      accountIdExcel: `${PORTAL}/portal/campaigns/:id/excel/accountIdBoundList`,
      excludedAccountIdBound: `${PORTAL}/portal/campaigns/:id/update/excludedAccountIdBound`,
      excludedAccountIdExcel: `${PORTAL}/portal/campaigns/:id/excel/excludedAccountIdBoundList`,
      excludedAccountIdList: `${PORTAL}/portal/campaigns/:id/excludedAccountIdBoundList`,
      excludedAccounIdCount: `${PORTAL}/portal/campaigns/:id/excludedAccountIdCount`,
      binList: `${PORTAL}/portal/campaigns/:id/cardBins`,
      rentalTypes: `${PORTAL}/portal/campaigns/:id/rentalType`,
      rentalTypeList: `${PORTAL}/portal/campaigns/rentalTypes`,
      rentalKm: `${PORTAL}/portal/campaigns/:id/rentalKm`,
      totalRentalKm: `${PORTAL}/portal/campaigns/:id/totalRentalKm`,
      rentalMinutes: `${PORTAL}/portal/campaigns/:id/rentalMinutes`,
      nthRental: `${PORTAL}/portal/campaigns/:id/nthRental/v2`,
      rentalAmount: `${PORTAL}/portal/campaigns/:id/rentalAmount`,
      sendNotificationToAccounts: `${PORTAL}/portal/campaigns/:id/sendNotificationToAccounts`,
      customerActivationDaysBound: `${PORTAL}/portal/campaigns/:id/update/customerActivationDaysBound`,
      customerActivationDays: `${PORTAL}/portal/campaigns/:id/customerActivationDays`,
      domains: `${PORTAL}/portal/email-domain`,
      emailDomain: `${PORTAL}/portal/campaigns/:id/update/email-domain-bound`,
      campaignScoreLimit: `${PORTAL}/portal/campaigns/:id/update/drivingScoreLimits`,
      instantOfferSettings: `${UMNG}/rest/portal/applicationParameter/instantOfferSettings`,
      minNumberOfRentalDays: `${PORTAL}/portal/campaigns/:id/minNumberOfRentalDays`,
    },
    eventLogs: `${PORTAL}/portal/events`,
    firms: {
      firms: `${PORTAL}/portal/org/firms`,
      markOfferAsPaid: `${PORTAL}/portal/org/firms/markAsPaid`,
      balance: `${PORTAL}/portal/org/firms/:firmId/balances`,
      transactions: `${PORTAL}/portal/org/firms/:firmId/transactions`,
      rentals: `${PORTAL}/portal/org/firms/:firmId/rentals`,
      users: `${PORTAL}/portal/org/firms/:firmId/users`,
      createTransaction: `${PORTAL}/portal/org/firms/:id/createTransaction`,
      updateProp: `${PORTAL}/portal/org/firms/:firmId/update`,
      discountRatio: `${PORTAL}/portal/org/firms/:firmId/update/discountRatio`,
      creditCardRequiredForRental: `${PORTAL}/portal/org/firms/:firmId/update/creditCardRequiredForRental`,
      bankAccountLimitForRental: `${PORTAL}/portal/org/firms/:firmId/update/bankAccountLimitForRental`,
      paymentMethods: `${PORTAL}/portal/org/firms/:firmId/paymentMethods`,
      requestPayment: `${PORTAL}/portal/org/firms/:firmId/payment`,
      members: `${PORTAL}/portal/org/firms/:firmId/members`,
      companyTypes: `${PORTAL}/portal/org/firms/companyTypes`,
      invoiceDeliveryRule: `${PORTAL}/portal/org/firms/:firmId/update/modifyInvoicingPeriod`,
      discountSettings: `${PORTAL}/portal/org/firms/:firmId/discount-settings`,
      payments: `${PORTAL}/portal/org/firms/:firmId/payments`,
    },
    prices,
    offers: `${PORTAL}/portal/offers`,
    // garentaSmsBackup: `${PORTAL}/portal/settings/garentaSmsBackup`,
    pois: `${PORTAL}/portal/pois`,
    debt: `${PORTAL}/portal/customers/inDebt`,
    enums: `${PORTAL}/portal/enums/:categoryName`,
    announcements: {
      benefits: `${PORTAL}/portal/benefits`,
      accountBind: `${PORTAL}/portal/benefits/:id/update/accountIdBound`,
      sendNotification: `${PORTAL}/portal/benefits/:id/sendNotificationToAccounts`,
      updateStatus: `${PORTAL}/portal/benefits/:id/update/status`,
      updateBound: `${PORTAL}/portal/benefits/:id/update/campaignIdBound`,
      relatedCampaign: `${PORTAL}/portal/benefits/:id/campaign`,
      status: `${PORTAL}/portal/benefits/:id/update/status`,
    },
  },
  corporate,
  vplanner: {
    report: {
      driverUsage: `${PLANNER}/report/driverUtilisation`,
    },
  },
  cargo: {
    depots: {
      list: `${CARGO}/portal/depots`, // depots/304142/shipments?planningDate=2019-12-19
      shipments: `${CARGO}/portal/depots/:depotId/shipments`, // depots/304142/shipments?planningDate=2019-12-19
    },
    vehicles: {
      list: `${CARGO}/portal/vehicles`,
    },
    drivers: {
      list: `${CARGO}/portal/drivers`,
      location: `${PLANNER}/portal/drivers/:driverId/latestLocation`,
    },
    stats: {
      dashboard: `${CARGO}/portal/dashboard/stats`,
      monitor: `${CARGO}/portal/monitor/stats`,
    },
    orders: {
      list: `${CARGO}/portal/orders`,
      itemList: `${CARGO}/portal/orders/items`,
      order: `${CARGO}/portal/orders/:id/items`,
      shipmentUpdate: `${CARGO}/portal/orders/:id/shipments/update`,
      shipmentDepotUpdate: `${CARGO}/portal/orders/:id/shipments/updateDepot`,
      depotOrderItem: `${CARGO}/portal/orders/depotItems`,
      approve: `${CARGO}/portal/orders/:orderId/state/approveOrder`,
      countsByStatus: `${CARGO}/portal/orders/countsByStatus`,
    },
    suppliers: {
      depots: `${CARGO}/portal/suppliers/:id/depots`,
    },
    shipments: {
      list: `${CARGO}/portal/orderShipments`,
      shipments: `${CARGO}/portal/shipments`,
      shipmentGate: `${CARGO}/portal/shipments/:id/gate`,
      depotShipments: `${CARGO}/portal/shipments/depots/:depotId`,
      depotPlan: `${CARGO}/portal/orderShipmentItem/updateDepotPlan`,
      updateVehicleAndDriver: `${CARGO}/portal/shipments/:shipmentId/updateVehicleAndDriver`,
      finishPlanning: `${CARGO}/portal/shipments/:shipmentId/state/finishPlanning`,
      countsByStatus: `${CARGO}/portal/shipments/countsByStatus`,
    },
    routes: {
      route: `${PLANNER}/portal/routes/:routeId`,
    },
  },
  rideshare: {
    simulation: `${PORTAL_PUBLIC}/vride/simulations`,
    newDemand: `${PORTAL_PUBLIC}/vride/simulations/:id/newDemand`,
    arrivedToNextStation: `${PORTAL_PUBLIC}/vride/simulations/:simulationId/vehicles/:vehicleId/arrivedToNextStation`,
    updateVehiclePoisiton: `${PORTAL_PUBLIC}/vride/simulations/:simulationId/vehicles/:vehicleId/location`,
    resetSim: `${PORTAL_PUBLIC}/vride/simulations/:simulationId/actions/reset`,
    recalculateSim: `${PORTAL_PUBLIC}/vride/simulations/:simulationId/actions/recalculate`,
  },
  optimization: {
    pois: `${OPTIMIZATION}/poi/list`,
    start: `${OPTIMIZATION}/job/start`,
    problem: `${OPTIMIZATION}/problem/get`,
    problemList: `${OPTIMIZATION}/problem/list`,
    uploadUrl: `${OPTIMIZATION}/importShipment`,
    // problemUpdate: `${OPTIMIZATION}/problem/update`,
    psUpdate: `${OPTIMIZATION}/vop/update`,
    solution: `${OPTIMIZATION}/job/latest`,
    missingStops: `${OPTIMIZATION}/problem/:problemId/assignMissingStops`,
  },
  localization: {
    langs: `${UMNG}/rest/i18n/:app/lang`,
    message: `${UMNG}/rest/i18n/:app/message`,
    messageByKey: `${UMNG}/rest/i18n/:app/messages`,
    categories: `${UMNG}/rest/i18n/:app/category`,
    messages: `${UMNG}/rest/i18n/:app/:category/messages`,
    shutdownMessage: `${UMNG}/rest/i18n/:app/messages/shutdownMessage`,
  },
  zones,
  arge,
  subscription,
  dashboard,
};
