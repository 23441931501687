export default {
  new: 'Yeni',
  updatedData: 'Güncellenen değerler var',
  prices: 'Fiyatlar',
  packages: 'Paketler',
  list: 'Ücret Tarifesi',
  action: 'Tarife değişikliğini kaydet',
  effect: 'Bu ücret tarifesi {group} araç grubu ve bu gruplarda bulunan {count} aracı etkiliyor',
  newVal: 'Yeni Değer',
  oldVal: 'Eski Değer',
  change: 'Değişim',
  modified: '{amount} değer değiştirildi',
  hourlyCostBeforeDiscount: 'İndirimsiz Saatlik Ücret (₺)',
  hourlyCostWithDiscount: 'İndirimli Saatlik Ücret (₺)',
  kmExceededCost: 'KM Ücreti (₺)',
  convertToDailyThreshold: 'Günlük Kiralama Eşiği (saat)',
  provisionCost: 'Provizyon (₺)',
  kmPerRentalLimit: 'Kiralama/KM Limit',
  nightlyCost: 'Gece Sabit',
  pointRatio: 'Puan kazanım oranı - Bireysel',
  corporatePointRatio: 'Puan kazanım oranı - Kurumsal',
  typeOfRental: 'Kiralama Türü',
  hourlyCost: 'Saatlik Ücret (₺)',
  selectChangeList: 'Ücret Tarife listesinden değiştirmek istenen tarifeyi seçin.',
  updatePriceError: 'Tarifeler güncellenirken bir hata oluştu',
  updatedPrice: 'adet tarife güncellendi',
  tpcServicePrice: 'TPC Hizmet Ücreti (KDV Dahil)',
  selectHgsFile: 'HGS Yüklenecek Dosyayı Seçin',
  entryLocation: 'Giriş Noktası',
  exitLocation: 'Çıkış Noktası',
  rateNotUpdated: 'Oran Güncellenemedi',
  tpcServicePriceUpdated: 'Tpc Hizmet Ücreti Güncellendi.',
  vatRateUpdate: 'Yeni oran {0} % olarak güncellendi',
  kmPackages: 'KM Paketleri',
  km: 'KM',
  selectType: 'Tip Seçiniz',
  packageUpdated: 'Paket Güncellendi',
  createNewPackage: 'Yeni Paket Yarat',
  newPackageAdded: 'Yeni Paket Eklendi',
  packageWillSelect: '{0} isimli paket seçilecek. Emin misiniz ?',
  packageDeleted: 'Paket Silindi',
  ACTIVE: 'Aktif',
  PASSIVE: 'Pasif',
  refresh: 'Yenile',
  MONTHLY: 'Aylık',
  INSTANTLY: 'Hemen',
  hourlyPrice: 'Saatlik Ücret',
  provisionBackingHours: 'Provizyon (₺)',
  pointRatioPercentage: 'Puan kazanım oranı (%) - Bireysel',
  corporatePointRatioPercentage: 'Puan kazanım oranı (%) - Kurumsal',
  pricePointRewardTime: 'Puanların Yüklenme Zamanı',
  dailyCost: 'Günlük Üst Limit',
  weekendCost: 'Hs. Sabit',
  kmPerDayLimit: 'KM Limit/Günlük',
  kmPackage: 'KM Paketleri',
  durationPackage: 'Süre Paketleri',
  catalogPrice: 'Katalog / Fiyat',
  notSaved: '{button} düğmesine basılana kadar değişiklikler kaydedilmez.',
  pressButton: 'Değiştirilen değerlerin sisteme yansıması için istenilen tüm değişikliklerin yapılması ve bundan sonra {button} düğmesine basılması gerekmektedir.',
  addPackage: '{package} için paket ekleme',
  voidPlates: 'Geçersiz Plakalar',
  ageGroups: 'Yaş Grupları',
  priceCatalog: 'Fiyat Kataloğu',
  addAgeGroup: 'Yaş Grubu Ekle',
  ageCatalogMatching: 'Yaş Grubu - Fiyat Kataloğu Eşleştirme',
  insurancePackageRules: 'Sigorta Paketi Kuralları',
  insurancePricingRules: 'Ücretlendirme Kuralları',
  pricesAndParameters: 'Fiyatlar - Parametreler',
  addCatalog: 'Katalog Ekle',
  addInsurancePackage: 'Paket Ekle',
  packageName: 'Paket İsmi',
  addNewCatalog: 'Yeni Katalog Ekle',
  editPackage: 'Paketi Düzenle',
  packageDetails: 'Paket Detayları',
  addRule: 'Kural Ekle',
  catalog: 'Katalog',
  minDrivingLicenceAge: 'Minimum Ehliyet Yaşı',
  maxDrivingLicenceAge: 'Maksimum Ehliyet Yaşı',
  minAge: 'Minimum Yaş',
  maxAge: 'Maksimum Yaş',
  ruleCreated: 'Kural Oluşturuldu.',
  insurancePackages: 'Sigorta Paketleri',
  addNewPackage: 'Yeni Paket Ekle',
  insurancePackage: 'Sigorta Paketi',
  insuranceCatalogs: 'Sigorta Paketi Kataloğu',
  corporateInsuranceCatalogs: 'Kurumsal Sigorta Paketleri',
  insurancePackageCost: 'Sigorta Paket Tutarı',
  addedBalance: 'Yüklenen Bakiye',
  packageDescription: 'Paket Açıklaması',
  availableDays: 'Geçerli Günler',
  bufferDurationInMinute: 'Paket En Geç Başlama Süresi (Dk)',
  preDurationInMinute: 'Paket En Erken Başlama Süresi (Dk)',
  postDurationInMinute: 'Paketi En Geç Bitirme Süresi (Dk)',
  freeWaitingDurationInMinute: 'Ücretsiz Bekleme Süresi (Dk)',
  showCurrentImage: 'Mevcut Fotoğrafı Görüntüle',
  showUploadedImage: 'Yüklenen Fotoğrafı Görüntüle',
  packageImage: 'Paket Görseli',
  showImage: 'Fotoğrafı Görüntüle',
};
