export default {
  FUEL: 'Fuel',
  TRANSFER: 'Transfer',
  AMOUNT: 'Amount',
  PERCENTAGE: 'Percentage',
  AFTER_END: 'After Rental',
  AT_START: 'After Start',
  startTime: 'Start Time',
  endTime: 'End Time',
  rewardType: 'Reward Type',
  selectRewardType: 'Select Reward Type',
  rewardTime: 'Reward Time',
  selectRewardTime: 'Select Reward Time',
  reward: 'Reward',
  stationTypes: 'Station Types',
  vehicleGroups: 'Vehicle Groups',
  rewardAutoMinFuelLimit: 'Reward Auto Min Fuel Limit',
  rewardAutoTransferHourLimit: 'Reward Auto Transfer Hour Limit (hour)',
  benefitType: 'Benefit Type',
  benefitValue: 'Benefit Value',
  awardTime: 'Award Time',
  maxValue: 'Max Value',
  priority: 'Priority',
  minKm: 'Min Km',
  updatedRewardRule: 'Reward Rule has been updated.',
  createdRewardRule: 'Reward Rule has been created.',
  addVehicleGroup: 'Add Vehicle Group',
  addStationType: 'Add Station Type',
  selectBenefitType: 'Select Benefit Type',
  benefit: 'Benefit',
  beforeRental: 'Before Rental',
  afterRental: 'After Rental',
  tasks: {
    FUEL: 'Fuel Task',
    TRANSFER: 'Transfer Task',
  },
  assignRewardTask: 'Assign Reward Task',
  createdBy: 'Created By',
  creationTime: 'Creation Time',
  lastModificationTime: 'Last Modification Time',
  rewardTaskDeleted: 'Reward task has been deleted.',
  rewardRuleDeleted: 'Reward rule has been deleted.',
  rewardTaskWillDelete: 'Reward task will be deleted. Are you sure ?',
  rewardRuleWillDelete: 'Reward rule will be deleted. Are you sure ?',
  changeRewardRules: 'Change Reward Tasks',
  rewardTasks: 'Reward Tasks',
  rewardTaskRules: 'Reward Task Rules',
  uploadRewardTask: 'Upload Reward Task',
  maxReward: 'Max Reward',
  rewardValidationLimit: 'Reward Validation Limit',
  updatedRewardTask: 'Reward Task has been updated.',
  createdRewardTask: 'Reward Task has been created.',
  addNewRule: 'Add New Reward Rule',
  rewardInformation: 'Reward Information',
  downloadVehicleHasReward: 'Download all vehicles which has reward',
  vehicleId: 'Vehicle ID',
  availablePeriod: 'Available Period',
  rentalId: 'Rental Id',
};
