export default {
  rider: 'Personel',
  date: 'Tarih',
  type: 'Tip',
  assignedTo: 'Üstlenen',
  status: 'Durum',
  department: 'Departman',
  shuttleProvider: 'Servis Şirketi',
  route: 'Rota',
  driver: 'Sürücü',
  plate: 'Plaka',
  TO_SERVICE_COMPANY: 'Servis Firmasına',
  TO_PERSONNEL: 'Personele',
  TO_DRIVER: 'Sürücüye',
  TO_ADMIN: 'Admine',
  TO_ADMIN_FROM_PERSONNEL: "Personelden Admin'e",
  allCampuses: 'Kampüsler',
  allTypes: 'Tipler',
  filter: 'Filtrele',
  hideInfo: 'Bilgileri Gizle',
  showInfo: 'Bilgileri Göster',
  closeTicket: 'Talebi Kapat',
  closeReason: 'Talebi kapatmak için bir sebep girin',
  ticketDetail: 'Talep Detayları',
  placeholder: {
    reply: 'Cevabınızı yazın…',
  },
  types: {
    LATE_ARRIVAL: 'Geç Varış',
    DIFFERENT_DRIVER_OR_VEHICLE: 'Farklı Şoför veya Araç Gelmesi',
    VIOLATION_TRAFFIC: 'Trafik Kuralı İhlali',
    EARLY_LEAVE: 'Erken Kalkış',
    VEHICLE_DIRTY: 'Araç Kirliliği',
    VEHICLE_MISSING_NUMBER: 'Eksik Araç Plakası',
    VEHICLE_MISSING_EQUIPMENT: 'Eksik veya Çalışmayan Ekipman',
    MISSING_POI: 'Eksik POI',
    SERVICE_NOT_ARRIVED: 'Servis Gelmedi',
    SERVICE_NOT_FINISHED: 'Servis Bitmemesi',
    PROPOSAL: 'Teklif',
    OTHER: 'Diğer',
    DEMAND: 'İstek',
    DRIVER_BEHAVIOR: 'Şoförün Tutum ve Davranışları',
    ROUTE_CHANGE: 'Rota Değişikliği',
    WALKING_DISTANCE: 'Yürüme Mesafesi',
    ABOUT_VEHICLE: 'Araç',
    FLEXIRIDE: 'flexiRide',
    FLEXICAR: 'flexiCar',
    FLEXIMILEAGE: 'flexiMileage',
    missingEquipment: 'Eksik Ekipman',
    walkingDistance: 'Yürüme Mesafesi',
    arrivedLate: 'Geç Geldi',
    leftEarly: 'Erken Ayrıldı',
    noShow: 'Gelmedi',
    driverBehavior: 'Sürücü Davranışı',
    vehicleCleanliness: 'Araç Temizliği',
    checkinScan: 'Giriş Taraması',
    other: 'Diğer',
    // HAKEDIS_REMOVE: 'Hakediş Ceza/Ödeme',
    // HAKEDIS_PAYMENT: 'Hakediş Ödeme',
    // HAKEDIS_ADD: 'Ek Gün',
    // HAKEDIS_NOTE: 'Hakediş Notu',
  },
  categories: {
    FLEXI_RIDE: 'flexiRide',
    FLEXI_CAR: 'flexiCar',
    SHUTTLE: 'Shuttle',
    VANPOOL: 'Vanpool',
    FLEXI_CARPOOL: 'flexiCarpool',
    SHARED_RIDE: 'flexiRide Shared',
    OTHER: 'Diğer',
  },
  statuses: {
    OPEN: 'Açık',
    CLOSED: 'Kapalı',
    PENDING: 'Beklemede',
    INPROGRESS: 'İşlemde',
    RESOLVED: 'Sonuçlandı',
    REOPENED: 'Tekrar Açıldı',
  },
  columns: {
    messageDate: 'Mesaj Tarihi',
    incidentDate: 'Olay Tarihi',
    rider: 'Personel',
    type: 'Tip',
    message: 'Mesaj',
    route: 'Rota',
    driver: 'Sürücü',
    assignedTo: 'Atanan Admin',
  },
  tickets: 'Talepler',
  filterByKeyword: 'Filtrele...',
  assignToMe: 'Bana Ata',
  companyAdmin: 'Yönetici',
  recipient: 'Alıcı',
  includeAttachments: 'Ekleri de gönder',
};
