export default {
  distanceToCampus: 'Distance to Campus',
  invitationLastSentOn: 'Invitation Last Sent On',
  flexiShuttleUsers: 'flexiShuttle Users',
  flexiShuttleFlexiVanpoolUsers: 'flexiShuttle/flexiVanpool  Users',
  flexiCarUsers: 'flexiCar Users',
  mobilityChallenged: 'Assistance Required',
  userAgreementDate: 'User Agreement Date',
  pregnant: 'Pregnant',
  registrationDate: 'Registration Date',
  shift: 'Shift',
  eligibleFor: 'Eligible For',
  drivingLicence: 'Driving Licence',
  currentModeCommute: 'Current Mode of Commute',
  walkingDistance: 'Walking Distance',
  travelDistance: 'Travel Distance*',
  travelTime: 'Travel Time*',
  totalEmission: 'Total Emission*',
  line: 'Line',
  basicInfo: 'Basic Info',
  workSystemInfo: 'Work & System Info',
  commuteInfo: 'Commute Info',
  supportRequests: 'Support Requests',
  cardReads: 'Card Reads',
  showCardReads: 'Show Card Reads',
  addLevel: 'Add Level',
  level: 'Level',
  pleaseEnterEmail: 'Please enter email',
  received: 'Received',
  solved: 'Solved',
  assignAdmin: 'Assign Admin',
  personnelDeleted: 'Rider Deleted',
  welcomeEmail: 'Welcome Email',
  welcomeEmailWillBeSent: '{1} will be sent to {0} user(s). Do you confirm?',
  welcomeEmailWillBeSentNamed: '{1} will be sent to {0}. Do you confirm?',
  welcomeEmailSent: 'Welcome Email Sent',
  usingService: 'Uses Shuttle',
  noSupportRequests: 'No support requests received.',
  createdPersonnel: 'Personnel has been created',
  updatedPersonnel: 'Personnel has been updated',
  addEmployee: 'Add Rider',
  downloadExcel: 'Please download the template, fill it with your employee data and upload it here.',
  confirmDeletePersonnel: 'Are you sure to delete rider named {0}?',
  confirmDeletePersonnels: 'Are you sure to delete {0} rider(s)?',
  passwordValidationInfo:
    'The password must be at least 8 characters long and must contain at least three of the following: one uppercase letter, one lowercase letter, one number, and one special character.',
  userDeleted: 'The user was deleted !',
  flexiRideUsers: 'flexiRide Users',
  assistantAssigned: 'Assistant has been assigned',
  assignAssistantToSelected: 'Assign Assistant to Selected',
  selectAssistant: 'Select Assistant',
  assistantRole: 'Assistant Role',
  ridersAssistant: 'Riders Assisted',
  assistedby: 'Assisted By',
  assistantAdminEmail: 'Assistant Admin Email',
  cantAssignThisUser: 'You can not assign this user as assistant to the selected user',
  assistant: 'Assistant',
  assistants: 'Assistants',
  addNewRider: 'Add New Rider',
  addNewAssistant: 'Add New Assistant',
  ridersAssistedBy: 'Riders Assisted by {0}',
  assistantsTo: 'Assistants to',
  searchPersonnel: 'Search Personnel',
  requiredNoteToEnterNote: 'Please note if the user has specific preferences about the mobility services.',
  issueDate: 'Issue Date',
  validThrough: 'Valid Through',
  closePanel: 'Are you sure you want to close the panel? Any unsaved changes will be lost',
  flexiRideAssistant: 'flexiRide Assistant',
  flexiRideAssistant: 'flexiRide Assistant',
  assitanceDuePermanently: 'Permanently',
  assistanceDueUntil: 'Until',
  assistanceCategories: 'Assistance Categories',
  assistanceCategory: {
    WHEELCHAIR: 'Wheelchair',
    CRUTCHES: 'Crutches',
    VISUAL_IMPAIREMENT: 'Visual Impairment',
    HEARING: 'Hearing',
  },
};
