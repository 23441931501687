export default {
  addUser: 'Kullanıcı Ekle',
  newUser: 'Yeni Kullanıcı',
  mobileVerification: 'Mobil Doğrulama',
  lastLoginTime: 'Son Giriş Tarihi',
  newPassword: 'Yeni Şifre',
  updateUser: 'Kullanıcıyı Güncelle',
  userType: 'Kullanıcı Tipi',
  putInactive: 'Pasife Al',
  putActive: 'Aktife Al',
  sendSms: 'SMS ile şifre gönder',
  userManagement: 'Kullanıcı Yönetimi',
  application: 'UYGULAMA',
  roles: 'Roller',
  selectApplication: 'Uygulama Seç',
  addRole: 'Rol Ekle',
  noDescription: 'Açıklama yok.',
  noRole: 'Gösterilecek rol bulunamadı.',
  permissions: 'İzinler',
  filterRoles: 'Roller filtrele...',
  filterPermissions: 'İzinleri filtrele...',
  pleaseSelectRole: 'Lütfen Rol Seçiniz',
  noPermissions: 'Gösterilecek İzinleri Bulunamadı.',
  roleDeleted: 'Rol Silindi',
  users: 'Kullanıcılar',
  menu: 'Menü',
  newPermission: 'Yeni İzin',
  rolePermissionsUpdated: 'Role İzinleri Güncellendi',
  updatePermissions: 'İzinleri güncelle',
  permissionWillBeUpdated: '{0} izinleri güncellenecektir. Onaylıyor musun?',
  roleWillBeDeleted: 'Rol {0} silinecektir. Onaylıyor musunuz ?',
  roleAdded: 'Rol Eklendi',
  permissionCreated: '{0} izni yaratıldı',
  permissionNotCreated: 'İzin yaratılamadı',
  noUsers: 'Gösterilecek Kullanıcılar Bulunamadı.',
  selectUser: 'Lütfen Kullanıcı Seçiniz',
  addNode: 'Node Ekle',
  filter: 'Filtrele...',
  route: 'Rota',
  noNodes: 'Gösterilecek Node bulunamadı',
  pleaseSelectNode: 'Lütfen node\'u seçiniz',
  parentNode: 'Parent Node\'u',
  selectParentNode: 'Parent Node\'u Seçin',
  selectPermission: 'İzin Seç',
  selectStatus: 'Statü Seç',
  nodeSaved: 'Node kaydedildi',
  filterUser: 'Kullanıcı Filtrele...',
  person: {
    VALE: 'Vale',
    VALE_SEFI: 'Vale Şefi',
    GARENTA_MOTOVALE_ADMIN: 'MotoVale Yöneticisi',
    GARENTA_DAY_BRANCH: 'Garenta Day',
    YIKAMACI: 'Yıkamacı',
    CORPORATE_ADMIN: 'Kurumsal Yönetici',
    TIKTAK_SUPER_ADMIN: 'Tiktak Yöneticisi',
    SUPER_VALE: 'Süper Saha Görevlisi',
  },
  user: {
    status: {
      WAITING_FOR_ACTIVATION: 'Aktivasyon Bekliyor',
      ACTIVE: 'Aktif',
      PASSIVE: 'Pasif',
    },
    newUser: 'Yeni Kullanıcı',
    updateUser: 'Kullanıcıyı Güncelle',
  },
  registrationStatus: 'Kayıt durumu {0} olarak değiştirildi',
};
