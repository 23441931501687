export const appNameMapper = {
  GARENTA_ADMIN: 'cs',
  CARGO: 'cargo',
  KAMIL_KOC: 'kamil-koc',
  TIKTAK_ADMIN: 'cs',
  OPTIMIZATION: 'opt',
  FLEXIGO_ADMIN: 'cs',
  VPLANNER: 'planner',
};

export const appNames = {
  GARENTA_ADMIN: 'Moov',
  TIKTAK_ADMIN: 'Tiktak',
  CARGO: 'VKargo',
  FLEXIGO_ADMIN: 'Flexigo',
  OPTIMIZATION: 'Optimization',
  VPLANNER: 'Planner',
};

export const defaults = {
  date: 'yyyy-MM-dd',
  dateTime: 'yyyy-MM-dd HH:mm:ss',
  time: 'HH:mm:ss',
  humanDate: 'dd-MM-yyyy',
  textFirstMonthDate: 'MMMM dd, yyyy',
  textMiddleMonthDate: 'dd MMMM yyyy',
};

export const dateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export const defaultColDefs = {
  sortable: true,
  filter: true,
  suppressToolPanel: true,
  resizable: true,
};

export const mapConfig = {
  center: {
    lat: 41.015137,
    lng: 28.97953,
  },
  zoom: 7,
  mapTypeId: {
    styles: [{
      // featureType: 'road',
      elementType: 'all',
      stylers: [
        { saturation: -100 }, // <-- THIS
      ],
    }],
  },
  options: {},
};

// TODO: these should be either in constants file or in a separate file
export const currency = {
  TRY: '₺',
  USD: '$',
};

export const currencyString = {
  TRY: 'TL',
  USD: 'USD',
};

export const length = {
  METRIC: 'km',
  IMPERIAL: 'mi',
};

export const lengthSmaller = {
  METRIC: 'mt',
  IMPERIAL: 'feet',
};

export const weight = {
  METRIC: 'KG',
  IMPERIAL: 'lb',
};

export const weightSmaller = {
  METRIC: 'mt',
  IMPERIAL: 'feet',
};

// will be deprecated use convert instead
export const conversionRates = {
  mtToFeet: 3.28,
  kgToPound: 2.20,
  kmToMile: 0.62,
};

// will be deprecated use convert instead
export const kmConversion = {
  METRIC: 1,
  IMPERIAL: 0.62,
};

export default defaults;
