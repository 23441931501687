import monitor from './monitor.en';
import common from './common.en';
import dashboard from './dashboard.en';
import rentals from './rentals.en';
import vehicle from './vehicle.en';
import success from './success.en';
import error from './error.en';
import users from './users.en';
import jobs from './jobs.en';
import customer from './customer.en';
import settings from './settings.en';
import campaigns from './campaigns.en';
import announcements from './announcements.en';
import tasks from './tasks.en';
import pricing from './pricing.en';
import alarms from './alarms.en';
import geohash from './geohash.en';
import incidents from './incidents.en';
import zones from './zones.en';
import firms from './firms.en';
import asset from './asset.en';
import rewards from './rewards.en';
import subscription from './subscription.en';
import reservation from './reservation.en';

export default {
  monitor,
  common,
  dashboard,
  rentals,
  vehicle,
  success,
  error,
  users,
  jobs,
  customer,
  settings,
  campaigns,
  announcements,
  tasks,
  pricing,
  alarms,
  geohash,
  incidents,
  zones,
  firms,
  asset,
  rewards,
  subscription,
  reservation,
};
