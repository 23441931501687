export default {
  withoutDepartment: 'Departmanı Olmayan',
  allDepartments: 'Bütün Bölümler',
  selectDepartment: 'Bölüm Seçiniz',
  allGroups: 'Bütün Gruplar',
  selectGroup: 'Grup Seçiniz',
  uploadPersonnelWithExcel: 'Excel ile Yükle',
  personalFormInfo: 'Gruba personel eklemek için bilgileri Excel ile yükleyebilir, personel listesini filtreleyebilir veya kişileri ad soyad ile arayabilirsiniz.',
  or: 'veya',
  filterPersonnelList: 'Personel listesini filtrelemek için lütfen seçin',
  campus: 'Yerleşkeler',
  departments: 'Bölümler',
  group: 'Grup',
  searchPersonnel: 'Ad soyad arayarak kişileri ekleyin.',
  personnel: 'Kişiler',
  addAll: 'Tümünü Ekle',
  showList: 'Listeyi Göster',
  addLater: 'Daha sonra ekle',
};
