export default {
  title: 'Başlık',
  notifications: 'Bildirimler',
  noResults: 'Sonuç Yok',
  notificationDetail: 'Bildirim Detayı',
  newNotification: 'Yeni Bildirim',
  date: 'Tarih',
  message: 'Mesaj',
  route: 'Rota',
  recipients: 'Alıcılar',
  file: 'Dosya',
  users: 'kullanıcı',
  personnel: 'Personeller',
  allRoutes: 'Tüm rotalar',
  searchInResults: 'Sonuçlarda Ara',
  allCategories: 'Tüm Kategoriler',
  administrative: 'İdari',
  operation: 'Operasyon',
  tickets: 'Biletler',
  ridersOnARoute: 'Bir rotadaki yolcular',
  ridersOnACampus: 'Bir kampüsteki yolcular',
  ridersRelatedToAShuttleProvider: 'Bir servis sağlayıcıyla ilişkili yolcular',
  includePassiveUsers: 'Pasif kullanıcıları dahil et',
  selectRoute: 'Rota Seç',
  campus: 'Kampüs',
  selectCampus: 'Kampüs Seç',
  shuttleProvider: 'Servis Şirketleri',
  selectShuttleProvider: 'Servis Şirketi Seçin',
  typeMessageTitle: 'Mesaj başlığı yazın',
  typeMessage: 'Mesaj Yazın',
  attachments: 'Ekler (İsteğe Bağlı)',
  send: 'Gönder',
  cancel: 'İptal',
  notificationSent: 'Bildirim gönderildi ✓',
  files: 'Dosyalar',
  none: 'Hiçbiri',
  recipient: 'Alıcı',
  searchRecipientName: 'Alıcı ismini ara',
  filterByCampus: 'Kampüse göre filtrele',
  filterByServiceUsers: 'Hizmete göre filtrele',
  allServices: 'Tüm hizmetler',
  specificProviders: 'Belirli Servis Şirketleri',
  specificRoutes: 'Belirli Rotalar',
  areYouSure: 'Emin misiniz?',
  content: 'İçerik',
  'SSE-NEW-FLEXIRIDE-REQUEST': 'Yeni Rezervasyon Talebi',
  'SSE-NEW-FLEXIRIDE-CHANGE-REQUEST': 'Yeni Değişiklik Talebi',
  'SSE-NEW-FLEXIRIDE-DRAFT-REQUEST': 'Yeni Taslak',
  'SSE-UPDATED-FLEXIRIDE-REQUEST': 'Flexiride İsteği Güncellendi',
  'SSE-CANCELED-FLEXIRIDE-REQUEST-BY-EA': 'Flexiride isteği EA tarafından iptal edildi',
  'SSE-CANCELED-FLEXIRIDE-REQUEST-BY-RIDER': 'Flexiride isteği kullanıcı tarafından iptal edildi',
  'SSE-SKIPPED-FLEXIRIDE-REQUEST-BY-DRIVER': 'Flexiride isteği sürücü tarafından atlandı',
  sendTheNotification: 'Bildirim gönder',
  onScheduledTime: 'Planlanan zamanda',
  scheduledNotificationMessage: 'Bildirim, {0} yolcuya, {1} tarihinde, {2} saatinde gönderilecek',
  confirmScheduledNotificationMessage:
    'Lütfen bildirimin {0} yolcuya, {1} tarihinde, {2} saatinde gönderilmesini onaylayın',
  notificationSentMessage: 'Bildirim {0} yolcuya gönderildi',
  confirmSendNotificationMessage: 'Lütfen bildirimi seçili yolculara göndermek isteyip istemediğinizi onaylayın',
  sendTheNotificationToAllRidersOn: 'Seçilen kullanıcılara bildirim göndermek istiyor musunuz?',
  ridersLastTwoMonths: 'Hizmeti son 2 ayda kullanmış olan kullanıcılara bildirim gönderilecektir.',
};
