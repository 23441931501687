export default {
  taskList: 'List of Tasks',
  pendingTask: 'There is no pending tasks',
  takeTask: 'Take next task to yourself',
  noTask: 'There is no task',
  notSelectedTask: 'The task has not been selected',
  task: 'Task',
  firstClaimTime: 'First Claim Time',
  lastClaimTime: 'Last Claim Time',
  documents: 'Documents',
  andFinish: 'and Finish',
  moveNextTask: 'and move next task',
  pleaseSelectDocument: 'Please Select Document',
  taskNotFound: 'Task Not Found',
  documentUpdated: 'Document has been updated',
  takeJob: 'Take Job',
  messageNotSend: 'Message could not send',
  messageSent: 'Message has been sent to {0} {1}',
  jobCancelReason: 'Job Cancel Reason',
  occuredError: 'Error while canceling',
  cancelAssignedJob: 'Job which assigned to valet has been canceled',
  cancelSuccessful: 'Cancel is successful',
  assignDriverToJob: 'Assign Driver to Job',
  workflow: {
    documentTypes: {
      DRIVING_LICENSE: 'Driving License',
      IDENTITY: 'Identity',
      PASSPORT: 'Passport',
      DIGITAL_AGREEMENT: 'Digital Agreement',
      SIGNED_AGREEMENT: 'Signed Agreement',
      SELFIE: 'Selfie',
    },
    documentStatus: {
      ACTIVE: 'Active',
      REJECTED: 'Rejected',
      PENDING_APPROVAL: 'Pending Approval',
      REPLACED: 'Replaced',
    },
    poolTypes: {
      myTasks: 'My Tasks',
      customerDocumentApprove: 'Customer Document Approve',
      vehicleCleaningControl: 'Vehicle Cleaning Control',
    },
    ocrFields: {
      IDENTITY_NUMBER: 'Identity Number',
      NAME: 'Name',
      SURNAME: 'Surname',
      DRIVING_LICENSE_NO: 'Driving License No',
      DRIVING_LICENSE_ISSUE_DATE: 'Driving License Issue Date',
      DRIVING_LICENSE_VALID_UNTIL: 'Driving License Valid Until',
      DRIVING_LICENSE_CLASS: 'Driving License Class',
    },
  },
  isSmsSend: 'Will sms send to {0} ?',
  drivingLicenceScore: 'Driving Licence Score',
  selfieScore: 'Selfie Score',
  drivingLicenceSelfieSelfieMatching: 'Driving Licence - Selfie Matching',
};
