export default {
  age: 'Age',
  yes: 'Yes',
  no: 'No',
  day: 'Day(s)',
  week: 'Week',
  date: 'Date',
  duration: 'Duration',
  durationMin: 'Duration(min)',
  create: 'Create',
  open: 'Open',
  close: 'Close',
  closed: 'Closed',
  quit: 'Quit',
  viewAll: 'View All',
  location: 'Location',
  user: 'Users',
  users: 'Users',
  personnel: 'Rider',
  riders: 'Riders',
  route: 'Route',
  routes: 'Routes',
  vehicles: 'Vehicles',
  requests: 'Requests',
  newRequestsforToday: 'New Requests <br> for Today',
  NewRequestsforFuture: 'New Requests <br> for Future',
  changeRequestsforToday: 'Change Requests <br> for Today',
  changeRequestsforFuture: 'Change Requests <br> for Future',
  rejectedRequests: 'Rejected <br> Requests',
  requestsPendingAssignment: 'Requests <br> Pending Assignment ',
  requestsPendingApproval: 'Requests <br> Pending Approval',
  totalAmount: 'Total Amount',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  weekdays: 'Weekdays',
  everyDayOfTheWeek: 'every day of the week',
  alldaysOfTheWeek: 'all days of the week',

  and: 'and',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  allDays: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  from: 'From',
  to: 'To',
  fromTime: 'From',
  toTime: 'To',
  start: 'Start',
  end: 'End',
  startDate: 'Start Date',
  endDate: 'End Date',
  startEndDate: 'Start - End Date',
  startEndTime: 'Start - End Time',
  weekend: 'Weekend',
  weekday: 'Weekdays',
  weekendSatSun: 'Weekends (Sat-Sun)',
  weekdayMonFri: 'Weekdays (Mon-Fri)',
  departure: 'Departure',
  arrival: 'Arrival',
  dep: 'Dep.',
  depFrom: 'Dep. from {0}',
  moreWithCount: '{0} more',
  arr: 'Arr.',
  total: 'Total',
  workdays: 'Workdays',
  days: 'Days',
  specificDays: 'Specific Days',
  description: 'Description',
  person: 'person',
  continue: 'Continue',
  goBack: 'Go Back',
  add: 'Add',
  update: 'Update',
  cancel: 'Cancel',
  edit: 'Edit',
  view: 'View',
  delete: 'Delete',
  upload: 'Upload',
  groupName: 'Group Name',
  finish: 'Finish',
  settings: 'Settings',
  current: 'Current',
  best: 'Best',
  considered: 'Considered',
  stop: 'Stop',
  locationName: 'Location Name',
  address: 'Address',
  next: 'Next',
  back: 'Back',
  contactUs: 'Contact Us',
  continueWithDemo: 'Continue with Demo',
  distance: 'Distance',
  employeeName: 'Rider Name',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  surname: 'Surname',
  email: 'E-mail',
  campus: 'Campus',
  department: 'Department',
  homeAddress: 'Home Address',
  saveAndAddNew: 'Save and Add New',
  saveAndClose: 'Save and Close',
  headquarters: 'Headquarters',
  personnelCount: 'Personnel Count',
  shuttle: 'Shuttle',
  shuttleAgreement: 'flexiShuttle Agreement',
  ride: 'Ride',
  carpool: 'Carpool',
  carpoolAgreement: 'flexiCarpool Agreement',
  vanpool: 'Vanpool',
  vanpoolAgreement: 'flexiVanpool Agreement',
  flexiSharedRide: 'flexiSharedRide',
  flexiRideAgreement: 'flexiRide Agreement',
  car: 'Car',
  status: 'Status',
  registryDate: 'Registry Date',
  emailLastSentOn: 'E-mail Last Sent on',
  emissionRating: 'Emission Rating',
  success: 'Successful',
  accept: {
    first: 'I accept',
    second: 'terms and conditions.',
  },
  emailSent: 'E-mail sent',
  error: 'Error',
  gotIt: 'Got it',
  DRIVING: 'Driving',
  WALKING: 'Walking',
  BIKING: 'Biking',
  TRANSIT: 'Transit',
  VANPOOL: 'Carpool & Vanpool',
  SHUTTLE: 'Shuttle',
  CARPOOL: 'Carpool',
  min: 'min',
  km: 'km',
  mi: 'mi',
  hello: 'Hello',
  pleaseSelect: 'Please select',
  totalDuration: 'Total Duration',
  showAll: 'Show All',
  hideAll: 'Hide All',
  save: 'Save',
  clear: 'Clear',
  vehicle: 'Vehicle',
  driver: 'Driver',
  drivers: 'Drivers',
  note: 'Note',
  direction: 'Direction',
  distanceType: 'mi',
  stationName: 'Station Name',
  selectAll: 'Select All',
  startTime: 'Start Time',
  endTime: 'End Time',
  transfer: 'Transfer',
  active: 'Active',
  passive: 'Passive',
  activate: 'Activate',
  makePassive: 'Make Passive',
  setActive: 'Set Active',
  setPassive: 'Set Passive',
  undo: 'Undo',
  selectRoute: 'Select Route',
  select: 'Select',
  plate: 'Plate',
  canbusOdometer: 'Canbus Odometer',
  eventTime: 'Event Time',
  speed: 'Speed',
  updatedSuccessfully: 'Updated successfully.',
  month: 'month',
  monthUppercase: 'Month',
  ok: 'OK',
  pressEsc: 'Press esc to exit the process.',
  approve: 'Approve',
  reject: 'Reject',
  register: 'Register',
  login: 'Login',
  general: 'General',
  nameSurname: 'Name Last Name',
  phone: 'Phone',
  phoneNumber: 'Phone Number',
  driverPhoneNumber: 'Driver Phone Number',
  idNumber: 'ID Number',
  uniqueID: 'Unique ID',
  employeeNumber: 'Employee Number',
  cardNumber: 'Card Number',
  badgeNumber: 'Badge ID',
  admin: 'Admin',
  region: 'Region',
  station: 'Station',
  activePassive: 'Active / Passive',
  drivingLicense: 'Driving License',
  commuteOptions: 'Commute Options',
  assign: 'Assign',
  send: 'Send',
  sendEmail: 'Send Email',
  search: 'Search',
  searchAddress: 'Search Address',
  homeLocation: 'Home Location',
  meter: 'm',
  dashboard: 'Dashboard',
  clickEnterToAdd: 'Click to add',
  clickEnterToRemove: 'Click to remove',
  pressEnterToAdd: 'Press Enter to Add',
  registered: 'Registered',
  oneWay: 'One way',
  passwordReset: 'Password Reset',
  undefined: 'Undefined',
  group: 'Group',
  filter: 'Filter',
  reset: 'Reset',
  expand: 'Expand',
  shrinkOrExpand: 'Shrink / Expand',
  filters: 'Filters',
  routeGroup: 'Route Group',
  searchLocation: 'Search location...',
  dontShowAgain: "Don't show again",
  type: 'Type',
  candidate: 'Candidate(s)',
  selectStatus: 'Select Status',
  saveAll: 'Save All',
  saveAndActivateAll: 'Save and Activate All',
  activateAll: 'Activate All',
  clickHere: 'you can click here.',
  selected: 'Selected',
  allWeekdays: 'All Weekdays',
  companies: 'Companies',
  searchCompanies: 'Search Companies',
  newCompany: 'New Company',
  allCompanies: 'All Companies',
  language: 'Language',
  tr: 'TR',
  en: 'EN',
  currency: 'Currency',
  tl: 'TL',
  usd: 'USD',
  unit: 'Unit',
  weightUnit: 'Weight Unit',
  lengthUnit: 'Length Unit',
  metric: 'Metric',
  imperial: 'Imperial',
  functionality: 'Functionality',
  mileage: 'Mileage',
  passwordUpdated: 'Password Updated.',
  manageConfirmations: 'Manage Confirmations',
  selectDate: 'Select Date',
  feedback: 'Feedback',
  finance: 'Finance',
  accessRights: 'Access Rights',
  confirm: 'Are you sure ? ',
  confirmText: 'Confirm',
  saved: 'Saved !',
  searchbynameorIDno: 'Search by name or ID no',
  newDriver: 'New Driver',
  birthdate: 'Birth Date',
  socialSecurityNo: 'Social Security No',
  class: 'Class',
  licenceNumber: 'Licence No.',
  licence: 'Licence',
  blackList: 'Black List',
  newVehicle: 'New Vehicle',
  make: 'Make',
  year: 'Year',
  walkingPathElevationChart: 'Walking Path Elevation Chart',
  totalWalkingDistance: 'Total Walking Distance',
  tickets: 'Tickets',
  document: 'Document',
  expirationDate: 'Expiration Date',
  time: 'Time',
  thereIsNoData: 'There is no data.',
  needed: 'Needed',
  notNeeded: 'Not Needed',
  notes: 'Notes',
  loginInfo: {
    hello: 'Hello!',
    emailAdress: 'E-mail address',
    enterEmail: 'Please enter your e-mail address.',
    next: 'Next',
    welcomeFlexigo: 'Welcome to flexigo!',
    corporateCampusMobilityPlatform: 'Corporate and Campus Mobility Platform',
    Password: 'Password',
    login: 'login',
    riders: 'Riders',
    Administrators: 'Administrators',
    imageText:
      '<b> Flexigo </b> provides dynamic responsive commuter shuttles, closed-circuit carsharing and ridepooling platforms, and advanced mobility optimization tools all integrated within a single app to ensure a seamless end-to-end experience for users and management alike.',
  },
  profileNotes: 'Profile Notes',
  addLocation: 'Add Location',
  title: 'Title',
  notFound: 'Not Found.',
  selectNone: 'Select None',
  showRegions: 'Show Regions',
  requestApprovals: {
    routeChange: 'Route Change',
    routeReservation: 'Route Reservation',
    nearbyStop: 'Nearby Stop',
    vehicleDriverChange: 'Vehicle/Driver Change',
    documents: 'Documents',
    payments: 'Payments',
    requestTime: 'Request Time',
    rider: 'Rider',
    currentRoute: 'Current Route',
    newRoute: 'New Route',
    provider: 'Provider',
    previourDriver: 'Previous Driver',
    previousVehicle: 'Previous Vehicle',
    routeCapacity: 'Route Capacity',
    vehicleCapacity: 'Vehicle capacity',
    timeUpload: 'Time of Upload',
    documentType: 'Document Type',
    paymentName: 'Payment Name',
    lastApprovedBy: 'Last Approved by',
    lastApprovedOn: 'Last Approved On',
    comments: 'Comments',
    approvalStep: 'Approval Step',
    approvalExpectedBy: 'Approval Expected by',
    requestsAndApprovals: 'Requests and Approvals',
    reasonRejection: 'Reason of Rejection',
    pleaseEnterValue: 'Please enter a value',
    pleaseStateRejectRequest: 'Please state why you reject the request.',
    pleaseStateRejectChange: 'Please state why you reject the change.',
    pleaseStateRejectDocument: 'Please state why you reject the document',
    pleaseStateRejectPayment: 'Please state why you reject the payment.',
    preferredShift: 'Preferred Shift',
    stats: {
      ON_HOLD: 'On Hold',
      PENDING: 'Pending',
      RECEIVED: 'Received',
    },
  },
  approver: 'Approver',
  actualOccupancyRate: 'Actual Occupancy Rate',
  aboveWithParam: 'Above {0}',
  passThrough: 'Pass through?',
  selectDeviceStatus: 'Select Device Status',
  trips: 'Trips',
  QRCardReads: 'QR Reads',
  seats: 'seats',
  hours: 'Hours',
  newPassword: 'New Password',
  newPasswordAgain: 'New Password Again',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  approvals: 'Approvals',
  notOnFile: 'Not on File',
  dateTime: 'Date/Time',
  totalRiders: 'Total Riders',
  startEndTimes: 'Start-End Times',
  thisRouteAcceptReservationsHaveRegularRiders: 'This route does not accept reservations or have regular riders.',
  eligible: 'Eligible',
  notEligible: 'Not Eligible',
  markAs: 'Mark as',
  signed: 'Signed',
  notSigned: 'Not Signed',
  liveTracking: 'Live Tracking',
  change: 'Change',
  untilSpecifedDate: 'Until Specified Date',
  permanently: ' Permanently',
  until: 'Until',
  selectDriver: 'Select Driver',
  selectVehicle: 'Select Vehicle',
  hour: 'Time',
  dispatch: 'Dispatch',
  editDriverVehicle: 'Edit Driver & Vehicle',
  driverName: 'Driver Name',
  notCalculated: 'Not Calculated',
  now: 'Now',
  liveTraffic: 'Live Traffic',
  makeNewAssigment: 'To make a new assignment, make selections below and click Save.',
  documentName: 'Document Name',
  hasExpirationDate: 'has an Expiration Date',
  noExpirationDate: 'no Expiration Date',
  newDocumentType: 'New Document Type',
  typeDocumentName: 'Type document name',
  updateProgressStatus: 'Update Payment Status',
  category: 'Category',
  topic: 'Topic',
  photo: 'Photo',
  chart: 'Chart',
  firstStop: 'First Stop',
  archive: 'Archive',
  showAvailableOptions: 'show available options',
  reservation: 'reservation',
  rider: 'Rider',
  all: 'All',
  permanent: 'Permanent',
  newAddress: 'New Address',
  editAddress: 'Edit Address',
  myAddress: 'My Addresses',
  pickupAddressSet: 'You have not set your preferred pick-up address yet',
  setNow: 'Set Now',
  columns: 'Columns',
  optional: 'Optional',
};
