export default {
  FUEL: 'Yakıt',
  TRANSFER: 'Transfer',
  AMOUNT: 'Miktar',
  PERCENTAGE: 'Yüzde',
  AFTER_END: 'Kiralamadan Sonra',
  AT_START: 'Kiralamadan Önce',
  startTime: 'Başlangıç Zamanı',
  endTime: 'Bitiş Zamanı',
  rewardType: 'Ödül Tipi',
  selectRewardType: 'Ödül Tipi Seçiniz',
  rewardTime: 'Fayda Zamanı',
  selectRewardTime: 'Ödül Zamanı Seçiniz',
  reward: 'Ödül',
  stationTypes: 'İstasyon Tipleri',
  vehicleGroups: 'Araç Grupları',
  rewardAutoMinFuelLimit: 'Yakıt Kontrol Yüzdesi',
  rewardAutoTransferHourLimit: 'İşlemsiz Süre Kontrolü (Saat)',
  benefitType: 'Fayda Türü',
  benefitValue: 'Fayda Değeri',
  awardTime: 'Fayda Zamanı',
  maxValue: 'Max Tutar',
  priority: 'Öncelik Değeri',
  minKm: 'Min Km',
  updatedRewardRule: 'Ödül Görev Kuralı Güncellendi.',
  createdRewardRule: 'Ödül Görev Kuralı Oluşturuldu.',
  updatedRewardTask: 'Ödül Görevi Güncellendi.',
  createdRewardTask: 'Ödül Görevi Oluşturuldu.',
  addVehicleGroup: 'Araç Grubu Ekle',
  addStationType: 'İstasyon Tipi Ekle',
  selectBenefitType: 'Fayda Türü Seçiniz',
  rewardTasks: 'Ödül Görevleri',
  rewardTaskRules: 'Ödül Görev Kuralları',
  uploadRewardTask: 'Toplu Ödül Görevi Yükle',
  addNewRule: 'Yeni Kural Ekle',
  benefit: 'Fayda',
  beforeRental: 'Kiralama Başlangıcında',
  afterRental: 'Kiralama Bittikten Sonra',
  tasks: {
    FUEL: 'Yakıt Görevi',
    TRANSFER: 'Transfer Görevi',
  },
  assignRewardTask: 'Ödül Görevi Ata',
  createdBy: 'Oluşturan',
  creationTime: 'Oluşturulma Zamanı',
  lastModificationTime: 'Son Düzenlenme Tarihi',
  rewardTaskDeleted: 'Görev Ödülü Silindi.',
  rewardRuleDeleted: 'Ödül Görevi Silindi.',
  rewardTaskWillDelete: 'Görev Ödülü Silinecek. Emin misiniz ? ',
  rewardRuleWillDelete: 'Ödül Görevi Silinecek. Emin misiniz ? ',
  changeRewardRules: 'Topluca Ödül Görevi Değiştir',
  maxReward: 'Maksimum Ödül',
  rewardValidationLimit: 'Ödül Kazanma Eşik Değeri',
  rewardInformation: 'Ödül Bilgisi',
  downloadVehicleHasReward: 'Bütün Ödüllü Araçları İndir',
  vehicleId: 'Araç ID',
  availablePeriod: 'Kural Geçerlilik Süresi',
  rentalId: 'Kiralama ID',
};
