export default {
  rider: 'Rider',
  date: 'Date',
  type: 'Type',
  assignedTo: 'Assigned To',
  status: 'Status',
  department: 'Department',
  shuttleProvider: 'Shuttle Provider',
  route: 'Route',
  driver: 'Driver',
  plate: 'Plate',
  TO_SERVICE_COMPANY: 'To Shuttle Provider',
  TO_PERSONNEL: 'To Rider',
  TO_DRIVER: 'To Driver',
  TO_ADMIN: 'To Admin',
  TO_ADMIN_FROM_PERS: 'To Admin from Rider',
  allCampuses: 'All Campuses',
  allTypes: 'All Types',
  filter: 'Filter',
  hideInfo: 'Hide Info',
  showInfo: 'Show Info',
  closeTicket: 'Close Ticket',
  closeReason: 'Enter a reason to close the ticket',
  ticketDetail: 'Ticket Details',
  placeholder: {
    reply: 'Type your message…',
  },
  types: {
    LATE_ARRIVAL: 'Late Arrival',
    DIFFERENT_DRIVER_OR_VEHICLE: 'Different Driver or Vehicle',
    VIOLATION_TRAFFIC: 'Traffic Violation',
    EARLY_LEAVE: 'Early Leave',
    VEHICLE_DIRTY: 'Dirty Vehicle',
    VEHICLE_MISSING_NUMBER: 'Missing Vehicle Number',
    VEHICLE_MISSING_EQUIPMENT: 'Missing Vehicle Equipment',
    MISSING_POI: 'Missing POI',
    SERVICE_NOT_ARRIVED: 'Shuttle Not Arrived',
    SERVICE_NOT_FINISHED: 'Service Not Finished',
    PROPOSAL: 'Proposal',
    OTHER: 'Other',
    DEMAND: 'Request',
    DRIVER_BEHAVIOR: 'Driver Behavior',
    ROUTE_CHANGE: 'Route Change',
    WALKING_DISTANCE: 'Walking Distance',
    ABOUT_VEHICLE: 'Vehicle',
    FLEXIRIDE: 'flexiRide',
    FLEXICAR: 'flexiCar',
    FLEXIMILEAGE: 'flexiMileage',
    missingEquipment: 'Missing Equipment',
    walkingDistance: 'Walking Distance',
    arrivedLate: 'Arrived Late',
    leftEarly: 'Left Early',
    noShow: 'No Show',
    driverBehavior: 'Driver Behavior',
    vehicleCleanliness: 'Vehicle Cleanliness',
    checkinScan: 'Check-In Scan',
    other: 'Other',
  },
  categories: {
    FLEXI_RIDE: 'flexiRide',
    FLEXI_CAR: 'flexiCar',
    SHUTTLE: 'flexiShuttle',
    VANPOOL: 'flexiVanpool',
    FLEXI_CARPOOL: 'flexiCarpool',
    SHARED_RIDE: 'flexiRide Shared',
    OTHER: 'Other',
  },
  statuses: {
    OPEN: 'Open',
    CLOSED: 'Closed',
    PENDING: 'Pending',
    INPROGRESS: 'In Progress',
    RESOLVED: 'Resolved',
    REOPENED: 'Reopened',
  },
  columns: {
    messageDate: 'Message Date',
    incidentDate: 'Incident Date',
    rider: 'Rider',
    type: 'Type',
    message: 'Message',
    route: 'Route',
    driver: 'Driver',
    assignedTo: 'Assigned To',
  },
  tickets: 'Tickets',
  filterByKeyword: 'filter by keyword...',
  assignToMe: 'Assign to me',
  companyAdmin: 'Company Admin',
  recipient: 'Recipient',
  includeAttachments: 'Include attachments',
};
