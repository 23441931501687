export default {
  subscription: 'Abonelik',
  packageName: 'Paket Adı',
  details: 'Detaylar',
  labelPrice: 'Normal Tutar',
  price: 'Paket Tutarı',
  labelDiscountPercentage: 'Yüzde Fayda',
  dailyFreeMinutes: 'Günlük Ücretsiz Dk Hakkı',
  selfServiceRentalDiscountRatio: 'Dk lık İnd. Yüzdesi',
  dailyRentalDiscountRatio: 'Günlük İndirim Yüzdesi',
  autoCreatedRentalDiscountRatio: 'Aşım Sonrası Dk lık İnd. Yüzdesi',
  subscriptionPackages: 'Abonelik Paketleri',
  packages: 'Mevcut Paketler',
  updatePackages: 'Paketleri Güncelle',
  generalSubscriptionDefinitions: 'Genel Abonelik Tanımları',
  contentManagement: 'İçerik Yönetimi',
  districts: 'Geçerli İlçeler',
  subscriptionEnabled: 'Abonelik Açık / Kapalı',
  periodCount: 'Fiyat Sabitleme Süresi (ay)',
  freeUpgradeDays: 'Fiyat Değiştirmeden Upgrade Yapılma Süresi (ay)',
  activeness: 'Aktiflik',
  addNewLine: 'Yeni Satır Ekle',
  dailyMin: 'Günde {0} dk ücretsiz',
  noSubscription: 'Abonelik Yok',
  subscriptionInfo: 'Abonelik Bilgileri',
  NOT_SUBSCRIBER: 'Abone Değil',
  OLD_SUBSCRIBER: 'Eski Abone',
  SUBSCRIBER: 'Abone',
  WAITING_CANCELLATION: 'İptal Bekliyor',
  WAITING_DOWNGRADE: 'Abonelik Düşürme',
  subscriptionStatus: 'Abonelik Durumu',
  isEligible: 'Abonelik Satın Alabilme Durumu',
  firstSubscriptionDate: 'İlk abone olduğu tarih',
  TODAY: 'Bugün',
  TOMORROW: 'Yarın',
  addMinutes: 'Dakika Ekle',
  ACTIVE: 'Aktif',
  CANCELLED: 'İptal Edildi',
  PENDING: 'Beklemede',
  CREATED: 'Oluşturuldu',
  WAITING_PAYMENT: 'Ödeme Bekleniyor',
  INACTIVE: 'Pasif',
  COMPLETED: 'Tamamlandı',
  DELETED: 'Silindi',
  packageInfo: 'Paket Bilgileri',
  cancelReason: 'İptal Sebebi',
  changePackagePrice: 'Paket Fiyatını Değiştir',
  usageInformation: 'Kullanım Bilgileri',
  day: 'Gün',
  remainingMinute: 'Hak',
  usedMinute: 'Kullanım',
  historyInformation: 'Tarih Bilgileri',
  packageActivationDate: 'Paket Başlangıcı',
  month: 'ay',
  pleaseSelectPrice: 'Lütfen Fiyat Seçiniz',
  paymentNotReceived: 'Ödeme Alınamadı',
  transactionResult: 'İşlem Sonucu',
  errorReason: 'Hata Sebebi',
  paymentInfo: 'Ödeme Bilgileri',
  transactionHistory: 'İşlem Geçmişi',
  dailyFreeMinuteError: 'Girilen dakika hakkı, paket hakkından büyük olamaz.',
  recurringCreditCard: 'Kredi Kartı Bilgisi',
  comparePrices: 'Normal tutar, paket tutarından düşük olamaz.',
  cancellationReasons: {
    PAYMENT_PROBLEM: 'Ödeme Sorunu',
    CUSTOMER_REQUEST: 'Kullanıcı Talebi',
    UPGRADE: 'Abonelik Yükseltildi',
    DOWNGRADE: 'Abonelik Düşürüldü',
    CUSTOMER_DELETED: 'Müşteri Silindi',
    CUSTOMER_PASSIVATED: 'Müşteri Pasife Alındı',
    CUSTOMER_DEBT: 'Müşteri Borcu',
    PORTAL_REQUEST: 'Portal Kullanıcı',
    PACKAGE_PASSIVATED: 'Paket Pasife Alındı',
  },
  dailyKm: 'Günlük Ücretsiz Km Hakkı',
  usedKm: 'Kullanılan Km Hakkı',
  deletionTime: 'Silme Zamanı',
  completionDate: 'Bitiş Kazanım',
  activationDate: 'Başlangıç Kazanım',
  lastValid: 'Geçerlilik',
  creationTime: 'Gönderim Zamanı',
  isReferralEnable: 'Referans Menüsü Açık / Kapalı',
  referralLimit: 'Referans Gönderme Limiti (Kişi)',
  referrerRentalThreshold: 'Referans Gönderme - Kiralama Adedi',
  refereeRentalThreshold: 'İndirim Kazanımı - Kiralama Adedi (Davet Edilen)',
  referralValidDay: 'Referans Kodunun Expire Olma Süresi (Gün)',
  referralDiscountDuration: 'Referans İndiriminin Geçerli Olacağı Süre (Ay)',
  refereeDiscountAmount: 'İndirim Tutarı - Davet Edilen (Tek Seferlik)',
  referrerDiscountAmount: 'İndirim Tutarı - Davet Eden Kullanıcı',
  referralRemindDays: 'Hatırlat Butonu expire olmaya son kaç gün kala gösterilmeye başlansın (Gün)',
  referralRemindPeriodHours: 'Hatırlat Butonu Tekrar Aktifleştirme Süresi (Saat)',
  referralRemindDaysBySystem: 'Sistem Hatırlatması expire olmaya son kaç gün kala gönderilsin (Gün)',
  referralUsableLimit: 'İndirim kazanılacak max kişi sayısı',
  invitees: 'Davet Edilenler',
  referralCode: 'Referans Kodu',
  referrer: 'Referans Veren',
  referralCodes: 'Kodlar',
  referralParameters: 'Referral Parametreleri',
  discountAmount: 'Kazanılan İndirim',
  codes: 'Kod',
  cancelSubscription: 'Aboneliği İptal Et',
  confirmCancelSubscription: 'Aboneliği iptal etmek istediğine emin misin ?',
  subscriptionCancelled: 'Müşterinin aboneliği iptal edilmiştir.',
};
