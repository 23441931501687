export const infoWindowFields = [
  'activeRentalId',
  'canbusOdometer',
  'doorLockType',
  'fuelLevelLt',
  'imeiNumber',
  'make',
  'model',
  'modelYear',
  'plate',
  'status',
  'typeDetail',
];

export const defaultPollInterval = 15000;

export const customChartColors = [
  '#418bca', // blue
  '#d9534f', // danger
  '#f0ad4e', // warning
  '#003f5c',
  '#0E785C', // garenta M
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#616f77', // default
  '#042123',
  '#A40778', // drank
  '#1693A5', // dutch
  '#3f4e62', // slategray
  '#16a085', // greensea
  '#22beef', // #22beef cyan
  '#cd97eb', // amethyst
  '#5cb85c', // success
  '#F06', // hotpink
  '#ffc100', // orange
  '#a2d200', // green
  '#ff4a43', // red
  '#428bca', // primary
  '#3f4e62', // darkgray
  '#5bc0de', // info
  '#009966', // moov green
  '#039E7A', // garenta O,
];

export const distinctColors = [
  '#000000',
  '#e6194b',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#51DCA9',
  '#000075',
  '#808080',
  '#800000',
  '#808000',
  '#8C5761',
  '#65B2F5',
  '#F19D38',
  '#032E52',
  '#DB3D3A',
  '#9548EB',
  '#69542F',
  '#E996B1',
  '#aaffc3',
  '#bcf60c',
  '#ffd8b1',
  '#22BEEF',
  '#ff4a43',
  '#16a085',
  '#1693A5',
  '#F06',
  '#A40778',
  '#418bca',
];

export const teardrop = 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z';

export const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
export const weekendDays = ['saturday', 'sunday'];
export const sortDays = {
  monday: 'mon',
  tuesday: 'tue',
  wednesday: 'wed',
  thursday: 'thu',
  friday: 'fri',
  saturday: 'sat',
  sunday: 'sun',
};

export const weekdaysUs = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const serviceAreaTypes = [
  { name: 'CITY', label: 'City' },
  { name: 'FREE_FLOAT', label: 'Free Float' },
  { name: 'CAMPUS', label: 'Campus' },
  { name: 'HOME', label: 'Home' },
  { name: 'OFFICE', label: 'Office' },
  { name: 'MALL', label: 'Mall' },
];

export const contactUsEn = 'https://www.flexigo.com/en/contact';
export const contactUsTr = 'https://www.flexigo.com/tr/iletisim';

export const locales = {
  en: 'en-US',
  tr: 'tr-TR',
  fr: 'fr-FR',
};

export const languages = [
  'tr', 'en', 'en-US', 'tr-TR', 'en-GB',
];
