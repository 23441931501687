export default {
  stop: 'Durak',
  distanceToStop: 'Durağa Olan Uzaklık',
  timeOnShuttle: 'Araçta Geçen Süre',
  timeOnVanpool: 'Araçta Geçen Süre',
  min: 'dk',
  routeName: 'Rota Adı',
  capacity: 'Kapasite',
  totalCost: 'Toplam Maliyet',
  totalDistance: 'Toplam Mesafe',
  totalDuration: 'Toplam Süre',
  status: 'Durum',
  plate: 'Plaka',
  driver: 'Sürücü',
  reservation: 'Rezervasyon',
  transferToDifferentRoute: 'Farklı Rotaya Aktar',
  searchRoutePlateDriver: 'Rota, Plaka, Sürücü Ara...',
  personnelAddresses: 'Personel Adresleri',
  addNewRoute: 'Yeni Rota',
  editSelectedRoutes: 'Seçili Rotaları Düzenle',
  stations: 'Duraklar',
  campus: 'Yerleşke',
  responsableCampus: 'Sorumlu Yerleşke',
  workgroup: 'Rota Grubu',
  routeType: 'Rota Tipi',
  vehicle: 'Araç',
  serviceCompany: 'Servis Şirketi',
  editRoute: 'Rotayı Düzenle',
  activate: 'Aktive Et',
  activeRoutes: 'Aktif Rotalar',
  passiveRoutes: 'Pasif Rotalar',
  draftRoutes: 'Taslak Rotalar',
  deleteGroup: 'Grubu Sil',
  shortRouteName: 'Rota Kısa İsmi',
  accountingCenter: 'Muhasebe Merkezi',
  editInfo: 'Bilgileri Düzenle',
  personnelList: 'Personel Listesi',
  journeys: 'Seferler',
  cardIssuances: 'Kart Basımları',
  journeyList: 'Sefer Listesi',
  requests: 'Talepler',
  confirmations: 'Onaylar',
  type: 'Türü',
  walkingDistance: 'Yürüme Mesafesi',
  journeyName: 'Sefer İsmi',
  instantOccupancy: 'Anlık Doluluk',
  transportDuration: 'Ulaşım Süresi',
  showGroupInfo: 'Grup Bilgilerini Göster',
  hideGroupInfo: 'Grup Bilgilerini Gizle',
  optimizeWaypoints: 'Aradaki durakları optimize et',
  recalculateAll: 'Değişenleri Tekrar Hesapla',
  saveAndActive: 'Kaydet ve Rotayı Aktive Et',
  saveAndActiveMultiple: 'Kaydet ve Rotaları Aktive Et',
  showHistory: 'Geçmişi Göster',
  addNewStation: 'Yeni Durak Ekle',
  selectRoute: 'Durağın Bağlı Olduğu Rota',
  stopByRoute: 'Gidiş yönünde bu durağa hangi sırada uğranacağını seçin',
  transferRoute: 'Geçmişten Rota Aktar',
  reset: 'Başa dön',
  calculate: 'Hesapla',
  recalculate: 'Yeniden Hesapla',
  newSettings: 'Durak sırasını koru.',
  makeRouting: 'Optimizasyon Çalıştır',
  routeInfo: 'Rota Bilgisi',
  accountingInfo: 'Muhasebe Bilgisi',
  bridgeCrossing: 'Köprü Geçişi',
  departure: 'Kalkış',
  arrival: 'Varış',
  OUTGOING: 'Giden',
  INCOMING: 'Gelen',
  routeInstanceState: {
    ARRIVED_ON_TIME: 'Zamanında Tamamlandı',
    ARRIVED_LATE: 'Gecikmeyle Tamamlandı',
    NOT_ARRIVED: 'Ulaşmadı',
    LEAVE_EARLY: 'Erken Ayrıldı',
    LEAVE_ON_TIME: 'Zamanında Ayrıldı',
    READY_TO_LEAVE: 'Ayrılmaya Hazır',
    PERIOD_STARTED: 'Sefer Başladı',
    DEVICE_NOT_OK: 'Telamatik Verisi Yok',
    CREATED: 'Başlatılmadı',
    LATE_ARRIVAL: 'Geç Varış',
    ON_THE_WAY: 'Yolda',
    ON_THE_WAY_WITH_DELAY: 'Gecikmeli Olarak Yolda',
    STARTING_TIME_DELAYED: 'Gecikmiş Kalkış',
    PENDING_ACTIONS: 'Bekleyen İşlemler',
    CANCELLED: 'Admin Tarafından İptal Edildi',
    CANCELLED_NO_RESERVATION: 'Rezervasyon yok',
  },
  ONE_WAY: 'Tek Yön',
  ROUND_TRIP: 'Gidiş - Geliş',
  RING: 'Ring',
  workingTypes: {
    FIXED: 'Sabit',
    REGULAR: 'Düzenli',
    DYNAMIC: 'Dinamik',
  },
  fixedPriceItem: 'Sabit Ücret Kalemi',
  kmUnitPriceItem: 'KM Birim Fiyat Kalemi',
  miUnitPriceItem: 'Mi Birim Fiyat Kalemi',
  accountCenter: 'Hakediş Merkezi',
  passingBridge: 'Köprü Geçişi',
  bridgeTollItem: 'Köprü Ücret Kalemi',
  revisionItem: 'Revizyon Kalemi',
  priceDaily: '1 Günlük Fiyat',
  noPersonnel: 'Bu durağa atanan personel yok',
  assignDriverAndVehicle: 'Sürücü ve Araç Ata',
  vehicleDriverChangeReason: {
    TEMPORARY_VEHICLE: 'Araç geçici olarak değişti',
    TEMPORARY_DRIVER: 'Sürücü geçici olarak değişti',
    TEMPORARY_VEHICLE_AND_DRIVER: 'Araç ve sürücü geçici olarak değişti',
  },
  routeTransferred: 'Rota aktarıldı',
  addNewStationRightClick: 'Yeni durak eklemek istediğiniz yere sağ tıklayın.',
  assignedToStop: 'Durağı atananlar',
  outsideOfWalkingDistance: 'Yürüme mesafesi dışında kalanlar',
  notAssignedStop: 'Durak ataması yapılmayanlar',
  notAssignedRoute: 'Rota ataması yapılmayanlar',
  serviceLine: 'Servis Hattı',
  shuttleLine: 'Servis Hattı',
  station: 'Durak',
  stops: 'Duraklar',
  destinationTravelTime: 'Seyahat Süresi',
  travelTime: 'Seyahat Süresi',
  route: 'Rota',
  timeCoefficient: 'Konfor Skoru',
  assignStation: 'Durak Ata',
  unassignFromCurrentStop: 'Atanmış olan duraktan çıkart',
  personnel: 'Personel',
  assignToRoute: 'Rotaya Ata',
  editStation: 'Durağı Düzenle',
  selectStation: '{0} adlı durağı atamak istediğiniz rotayı seçin',
  deleteStation: 'Seçilen durağı silmek istediğine emin misin ?',
  changingOnRoute: 'Rota üzerinde değişiklik yapıldı.',
  updatedSuccessfully: 'Rota güncellendi.',
  createdRoute: 'Rota oluşturuldu.',
  addedStation: 'Durak eklendi',
  updatedStation: 'Durak güncellendi',
  deletedStation: 'Durak silindi',
  activatedRoute: 'Rota aktif edildi',
  sureToRouteDelete: 'Düzenlenen rotalar silinecek. Emin misin ?',
  cancel: 'İptal Et',
  continueEdit: 'Düzenlemeye Devam Et',
  cancelEdit: 'Düzenlemeyi İptal Et',
  activateEditedRoutes: 'Düzenlenmiş olduğunuz rotalar aktif edilecek. Emin misiniz ?',
  editedRoutes: 'Mevcut düzenlenmiş {0} rotanız bulunmakta.',
  createdEditedRoute: 'Rota oluşturuldu',
  savedEditRoutes: 'Rota düzenlendi',
  deletedEditedRoutes: 'Mevcut rota silindi',
  saveAndActivateRoute: 'Rota kaydedildi ve aktif edildi',
  selectStationPoint: 'Seçmek istediğiniz durağa tıklayın.',
  personnelToStop: '{0} adlı kişi {1} adlı durağa atanacaktır.',
  personnelToRemoveStop: '{0} adlı kişi {1} adlı duraktan çıkarılacaktır.',
  removePersonnelFromStationInfo:
    'Durakta yolcu kalmayacağı için durağı silebilir veya tutabilirsiniz. Silmeyi tercih ederseniz rotayı tekrar hesaplamanız gerekecektir.',
  removePersonnelFromStationConfirm: 'Kişiyi duraktan çıkarmak istediğinize emin misiniz?',
  detachRiderAndKeepTheStop: 'Yolcuyu Çıkart ve Durağı Koru',
  detachRiderAndDeleteTheStop: 'Yolcuyu Çıkart ve Durağı Sil',
  personnelAssignedToStop: 'Personel durağa başarıyla atandı.',
  personnelUnassignedToStop: 'Personel duraktan başarıyla çıkarıldı.',
  statusType: {
    0: 'OK',
    1: 'Cihaz Yok',
    2: 'Bağlantı Yok',
  },
  optimizations: 'Optimizasyon Çalışmaları',
  areYouSureToCancel: 'Yaptığınız değişiklikler kaydedilmedi, çıkmak istediğinize emin misiniz?',
  routeHistory: 'Rota Geçmişi',
  toStart: 'İlk Durak',
  toEnd: 'Son Durak',
  assignSuccessMessageWithApprovalRequired:
    'Sürücü ve/veya araç talebiniz alınmıştır. Onaylandıktan sonra güncelleme sağlanacaktır.',
  assignSuccessMessage: 'Sürücü ve/veya araç değişikliği başarılı bir şekilde tamamlandı.',
  legend: {
    showStations: 'Duraklar',
    assignedRoutes: 'Durağı atananlar',
    outOfWalkingDistance: 'Yürüme mesafesi dışında kalanlar',
    withoutRoute: 'Rotası olmayanlar',
    withoutStation: 'Durak ataması yapılmayanlar',
    drivers: 'Sürücüler',
    activeDriver: 'Aktif Sürücüler',
    driverCandidates: 'Sürücü Adayları',
    bestCommuteShuttle: 'En İyi Servis',
  },
  editMultipleRoutesHeader: 'Çoklu Rota Düzenleme',
  multipleDraftsExistNotification:
    'Çoklu düzenleme modunda rotalar bulunuyor. Düzenleme işlemini tamamlamadan yeni bir düzenleme başlatamazsınız.',
  sendToOptimization: 'Optimizasyonu Seçilenlerle Çalıştır',
  selectForOptimization: 'Optimizasyona aktarmak istediğiniz rota ve/veya personeli seçin.',
  runOptimization: 'Optimizasyon Çalıştır',
  exitOptimizationSelection: 'Optimizasyon Seçiminden Çık',
  needToAddMultipleStops: 'Birden fazla durak eklemeniz gerekiyor.',
  routeSelected: '{0} rota seçildi.',
  unassignedPersonnel: 'Atanmamış Personel',
  noRoutesYet: 'Bu grupta henüz rota bulunmuyor.',
  optimizeGroup: 'Optimizasyon saat {0}, {1} tarihinde çalıştırılacaktır.',
  createRoutesManually: 'Optimizasyonu şimdi çalıştırmak isterseniz',
  assignToNearestStop: 'En Yakın Durağa Ata',
  riders: 'Yolcular',
  vanpoolPreferenceInSurvey: 'Ankette Vanpool Tercihi',
  drivingPreferenceInSurvey: 'Ankette Sürüş Tercihi',
  lastSentConfirmation: 'Son Gönderilen Onay',
  dateSent: 'Gönderilen Tarih',
  riderConfirmation: 'Personel Onayı',
  driverConfirmation: 'Sürücü Onayı',
  sendConfirmation: 'Onay Gönder',
  confirmationManagement: 'Onay Yönetimi',
  confirmationsToBeSent: 'Gönderilecek Onaylar',
  approvalTypes: {
    NONE: 'Boş',
    VANPOOL_DRIVER: 'Sürücülük Onayı',
    VANPOOL_USER: 'Katılım Onayı',
    DRIVER_AND_USER: 'Sürücülük ve Katılım Onayı',
  },
  approvalTypeChangedSuccessfully: 'Onay tipi başarıyla değiştirildi.',
  responseStatuses: {
    NONE: '-',
    POSITIVE: 'Onaylandı',
    NEGATIVE: 'Reddedildi',
    PENDING: 'Bekliyor',
  },
  confirmAssignPersonnelDriver: '{0} sürücü olarak atanacaktır. Onaylıyor musunuz?',
  confirmAssignVanpoolAndPersonnelDriver:
    "Kullanıcı vanpool'a yolcu olarak katılmayı tercih ettiğini belirtti. Kullanıcıyı sürücü olarak atamak istiyor musunuz?",
  driverAssignedSuccessfully: 'Personel sürücü olarak başarıyla atanmıştır.',
  confirmpersonnelRemovedFromWorkgroup: '{0} çalışma grubundan çıkarılacaktır. Onaylıyor musunuz?',
  personnelRemovedFromWorkgroup: 'Personel çalışma grubundan çıkarıldı.',
  routeStatus: 'Rota Durumu',
  routeStatusTypes: {
    CREATED: 'Oluşturuldu',
    CONFIRMATION_SENT: 'Onay Gönderildi',
    MIN_OCCUPENCY_REACHED: 'Minimum Doluluğa Ulaşıldı',
    MIN_OCCUPENCY_EXPECTED: 'Minimum Doluluk Bekleniyor',
    READY: 'Hazır',
    ACTIVE: 'Aktif',
  },
  addPolygonSelection: 'Poligon Seçimi Ekle',
  addPolygon: 'Poligon Ekle',
  exitPolygonSelection: 'Poligon Seçiminden Çık',
  searchRoutes: 'Rota Ara...',
  searchRiders: 'Personel Ara...',
  departureLocation: 'Kalkış Yeri',
  departureTime: 'Kalkış Zamanı',
  arrivalLocation: 'Varış Yeri',
  arrivalTime: 'Varış Zamanı',
  assignment: 'Atama',
  boarding: 'Durum',
  anomaly: 'Anomali',
  stopProgress: 'Durak İlerlemesi',
  nextStop: 'Gelecek Durak',
  attendance: 'Katılım',
  occupancy: 'Doluluk',
  reservations: 'Yolculuklar',
  regularRiders: 'Düzenli Yolcular',
  optimizationSuccess:
    'Optimizasyon çalışıyor. Bu gruptaki binici sayısına göre birkaç dakika sürebilir. Bir çözüm geldiğinde, sonuçları bu sayfada görebilirsiniz.',
  saveAndActivateAllDrafts: 'Tüm taslak rotaları kaydedilecek ve etkinleştirilecektir. Emin misiniz?',
  totalKm: 'Toplam Km',
  totalMi: 'Toplam Mi',
  saveToRoute: 'Yapılan değişiklikleri kaydetmek istediğinize emin misiniz?',
  setRouteAsActive: 'Seçilen rotaları aktive etmek istediğinize emin misiniz?',
  setRouteAsPassive: 'Seçilen rotaları pasife almak istediğinize emin misiniz?',
  deleteSelectedroute: 'Seçilen rotaları silmek istediğine emin misiniz?',
  saveChangesConfirm: 'Yaptığınız değişiklikleri kaydetmek istediğinize emin misiniz?',
  stopsLocations: 'Duraklar/Konumlar',
  plannedStart: 'Planlanan Başlangıç',
  actualStart: 'Başlangıç',
  plannedArrival: 'Planlanan Varış',
  actualArrival: 'Varış',
  plannedDeparture: 'Planlanan Kalkış',
  actualDeparture: 'Gerçek Kalkış',
  routeGroupName: 'Rota Grubu',
  routeGroup: 'Rota Grubu',
  plannedOccupancy: 'Planlanan Doluluk',
  attendanceSoFar: 'Anlık Katılım',
  declaredByDriver: 'Anlık Katılım',
  plannedDepartureTime: 'Planlanan Kalkış Saati',
  plannedArrivalTime: 'Planlanan Varış Saati',
  tripTracking: 'Seferler',
  delayedJourneys: {
    ON_THE_WAY_WITH_DELAY: '{min} dakika gecikmeli yolda',
    ARRIVED_LATE: '{min} dakika gecikmeli tamamlandı',
    STARTING_TIME_DELAYED: '{min} dakika gecikmeli sefere başladı',
    NOT_ARRIVED: 'Gelmedi',
    ARRIVED_ON_TIME: 'Zamanında Ulaştı',
    LEAVE_EARLY: 'Erken Ayrıldı',
    LEAVE_ON_TIME: 'Zamanında Ayrıldı',
    READY_TO_LEAVE: 'Ayrılmaya Hazır',
    PERIOD_STARTED: 'Sefer Başladı',
    DEVICE_NOT_OK: 'Cihaz Sorunu',
    CREATED: 'Yaratıldı',
    LATE_ARRIVAL: 'Geç Varış',
    ON_THE_WAY: 'Yolda',
  },
  headingToStop: 'Sonraki Durak {0}/{1}',
  planned: 'Planlanan',
  actual: 'Gerçekleşen',
  arrivedLastStop: 'Son Durağa Ulaştı',
  progress: 'İlerleme',
  plannedRiders: 'Planlanan Katılım',
  actualRiders: 'Binen Yolcular',
  startLocation: 'Başlangıç Noktası',
  headingToDepot: 'Sonraki Durak: Depo',
  headingToCampus: 'Sonraki Durak: Yerleşke',
  arrivedAtDepot: 'Depoya Ulaştı',
  arrivedAtCampus: 'Yerleşkeye Ulaştı',
  noRoutesGroupYet:
    'Bu grup için henüz bir rota yok.<br><br> Grubu Optimize Et düğmesine tıklayarak rotaları otomatik olarak oluşturabilirsiniz.<br><br> Alternatif olarak, Yeni Rota Ekle düğmesine tıklayarak manuel olarak rotalar oluşturabilirsiniz.<br><br>',
  noRoutesGroup:
    'Bu grup için henüz rota yok.{br}{br} Bu grup için otomatik optimizasyon ayarlandı.{br}{br} Optimizasyon {time} saatinde çalıştırılacak ve rotalar oluşturulacak.{br}{br}',
  dontShowAgain: 'Tekrar gösterme',
  cardQrRead: 'Kart/Qr Okutma',
  awaiting: 'Bekliyor',
  ready: 'Hazır',
  planningStatus: 'Planlama Durumu',
  assignAsDriver: 'Sürücü olarak ata',
  showVehicleTracking: 'Araç Takibi',
  unregisteredRiders: 'Kayıtlı Olmayan Yolcular',
  unregistered: 'Kayıtlı Olmayan',
  selectRouteTransfer: 'Aktarılacak Rotayı Seçiniz',
  transferReservations: 'Rezervasyonları Aktar',
  notAssignedToStop: 'Durak ataması yapılmayanlar',
  selectRidersToTransferred: 'Transfer edilecek personelleri seçin',
  noRidersOnRoute: 'Seçilen tarih için bu rota üzerinde personel yok',
  transferredFrom: '{0} rotasından aktarıldı.',
  transferredTo: '{0} rotasına aktarıldı.',
  transferringFrom: '{0} rotasından, {1} rotasına aktarılacak.',
  accounting: {
    calculatedKM: 'Hesaplanan KM',
    actualKM: 'Gerçekleşen KM',
    contractKM: 'Sözleşme KM',
    revision: 'Revizyon',
    bridge: 'Köprü',
    fixedPrice: 'Sabit Ücret',
    kmPrice: 'KM Ücreti',
  },
  planningStatusOptions: {
    REGULAR: 'Düzenli',
    REQUESTED: 'Talep Edildi',
    RESERVED: 'Rezerve Edildi',
    FAILED: 'Karşılanmadı',
    CANCELLED: 'İptal Edildi',
    NOT_RESERVED: 'Rezervasyonsuz',
  },
  boardingOptions: {
    ARRIVED: 'Ulaştı',
    AWAITING: 'Beklemede',
    SKIPPED: 'Atlandı',
    BOARDED: 'Bindi',
    NO_SHOW: 'Gelmedi',
    REPEATING_NO_SHOW: 'Gelmedi (Tekrar eden)',
  },
  ridesUniqueRiders: 'Yolcular/Tekil Yolcu',
  over500: { ft: 'Durağa 500 ft’den Uzak', m: 'Durağa 500 m’den Uzak' },
  pleaseSelectRevision: 'Lütfen Revizyon Seçiniz',
  pleaseSelectBridge: 'Lütfen Köprü Seçiniz',
  pleaseSelectFixedPrice: 'Lütfen Sabit Ücret Seçiniz',
  pleaseSelectKMPrice: 'Lütfen KM Ücreti Seçiniz',
  totalStopsEmptyStops: 'Toplam/Boşta Durak Sayısı',
  emptyStops: 'Boş Durak',
  completeRouteDefinition: 'Rota Tanımlamasını Tamamla',
  editRouteMessage:
    'Bu grup için ring rotası otomatik yaratılmıştır. <br/> Rotayı aktive etmek için lütfen rota tanımlamasını tamamlayın ve her sefer için araç ve sürücü ataması yapın.',
  walkingDurationInMin: 'Yürüme Süresi',
  actualDepartureTime: 'Gerçekleşen Kalkış Saati',
  actualArrivalTime: 'Gerçekleşen Varış Saati',
  boardingStatus: 'Biniş Durumu',
  latestStatus: 'Son Durum',
  driverVehicleSameTrips: 'Tüm seferler için sürücü ve araç aynıdır.',
  driverVehicleDifferentTrips: 'Seferlere farklı sürücü ve araçlar ata.',
  selectEachTime: 'Sefer saatlerini ayrı ayrı seçerek her biri için sürücü ve araç seçimi yapın.',
  assignToMoreTrips: 'Daha Fazla Sefere Ata',
  assignActiveDriver: 'Aktif Sürücü Olarak Ata',
  assignSecondaryDriver: 'İkincil Sürücü Olarak Ata',
  secondaryDriver: 'İkincil Sürücü',
  activeDriver: 'Aktif Sürücü',
  deleteRiderFromThisRoute: 'Are you sure you want to delete the rider from this route?',
  ridersList: 'Personel Listesi',
  qrScans: 'QR/Kart Okutmalar',
  secondaryDrivers: 'İkincil Sürücü(ler)',
  direction: 'Yön',
  drivers: 'Sürücü(ler)',
  vehicles: 'Araç(lar)',
  vehicleName: 'Araç Adı',
  speedUnit: 'km/saat',
  ridersOnBoard: 'Araçtaki Yolcular',
  assignedStartingFrom: 'Atama Tarihi',
  assignedUntil: 'Atanan Tarih',
  assignmentReason: 'Atama Nedeni',
  assignedBy: 'Atayan',
  assignmentDate: 'Atama Tarihi',
  referenceNumber: 'Referans Numarası',
  days: 'GÜnler',
  campusArrivalTimesForInbound: 'Kampüse Varış Saatleri',
  campusDepartureTimesForOutbound: 'Kampüsten Çıkış Saatleri',
  firstStopOTP: ' İlk Durak Performans',
  delayedCheckin: 'Geç Giriş',
  lateArrivalToStop: 'Durağa Geç Varış',
  earlyDepartureFromStop: 'Duraktan Erken Kalkış',
  skipped: 'Geçti',
  pendingActions: 'Bekleyen İşlemler',
  driverCheckin: 'Sürücü Giriş',
  depotStart: 'Depo Başlangıcı',
  firstStopArrival: 'İlk Durağa Varış',
  earlyDeparture: 'Erken Kalkış',
  lateDeparture: 'Geç Kalkış',
  lateArrival: 'Geç Varış',
  ridersOn: 'Binen',
  ridersOff: 'İnen',
  showPOIs: "POI'leri Göster",
  viewStops: 'Durakları Göster',
  driverVehiclePermanentlyChange:
    'Bu rota için sürücüyü ve/veya aracı kalıcı olarak değiştirmek istediğinizden emin misiniz? Bu kalıcı değişiklik, rotaya ilişkin tüm geçici atamaları geçersiz kılacaktır.',
  changePermanently: 'Kalıcı olarak değiştir',
  cancelTrip: 'Seyahati İptal Et',
  revertCancellation: 'İptali Geri Al',
  confirm: 'Onayla',
  previousRiders: 'Önceki Yolcular',
  reservedRiders: 'Rezervasyonlu Yolcular',
  typeNotificationMessage: 'Bildirim mesajını yazın',
  selectRidersSendNotification: 'Bildirim gönderilecek yolcuları seçin',
  dispatchTripCancellation: {
    REVERT: {
      INCOMING: '{0} tarihinde {1} varışlı sefer gerçekleştirilecektir.',
      OUTGOING: '{0} tarihinde {1} kalkışlı sefer gerçekleştirilecektir.',
    },
    CANCELLED: {
      INCOMING: '{0} tarihinde {1} varışlı sefer iptal edilecektir.',
      OUTGOING: '{0} tarihinde {1} kalkışlı sefer iptal edilecektir.',
    },
  },
  customNotify: {
    CANCELLED: {
      INCOMING: '{0} rotasının {1} tarihindeki kampüs varışlı seferi yapılmayacaktır.',
      OUTGOING: '{0} rotası {1} tarihindeki seferi yapılmayacaktır.',
    },
    REVERT: {
      INCOMING:
        '{0} rotasının daha önce iptal edildiği bildirilen {1} tarihli kampüs varışlı seferi gerçekleştirilecektir.',
      OUTGOING:
        '{0} rotasının daha önce iptal edildiği bildirilen {2} tarihli kampüs çıkışlı seferi planlandığı gibi gerçekleştirilecektir.',
    },
  },
  multiCampus: {
    description: '{1} tarihindeki {0} saatli seferler iptal edilecektir.',
    defaultMessage: '{0} rotasının {1} saatlerinde ve {2} tarihindeki seferleri yapılmayacaktır. ',
  },
  cancelMessage: {
    CANCELLED: {
      INCOMING: '{0} rotasının {1} tarihindeki kampüse gidiş seferini iptal etmek istiyorsanız lütfen onaylayın.',
      OUTGOING: '{0}rotasının {1} tarihindeki kampüsten dönüş seferini iptal etmek istiyorsanız lütfen onaylayın.',
    },
    REVERT: {
      INCOMING:
        '{0} rotasının daha önce iptal edilen {1} tarihli kampüs varışlı seferinin planlandığı gibi gerçekleşeceğini onaylıyor musunuz?',
      OUTGOING:
        '{0} rotasının daha önce iptal edilen {1} tarihli kampüs çıkışlı seferinin planlandığı gibi gerçekleşeceğini onaylıyor musunuz?',
    },
  },
  tripCancellationNotification: 'Seyahat İptal Bildirimi',
  pleaseSelectRecipients: 'Lütfen alıcıları seçin.',
  tripAlertMessage: {
    CANCELLED: 'Sefer iptal edildi ancak yolculara bildirim gönderilmedi. Şimdi göndermek ister misiniz?',
    REVERT:
      'Sefer iptali geri alındı ancak yolculara bilgilendirme gönderilmedi. Şimdi bildirim göndermek ister misiniz?',
  },
  closePanel: 'Paneli Kapat',
  sendNotification: 'Bildirim Gönder',
  tripCancellationRevertedNotification: 'Sefer İptali Geri Alma Bildirimi',
  notifyRiders: 'Yolculara haber ver',
  showLess: 'Listeyi küçült',
  show: 'Göster',
  addForReverseDirection: {
    text: 'Bu durağı diğer yöne de eklemek istiyor musunuz?',
    confirmButtonText: 'İki Yöne de Ekle',
    cancelButtonText: 'Sadece Mevcut Yöne Ekle',
  },
  deleteForReverseDirection: {
    text: 'Durağı diğer yönden de silmek istiyor musunuz? Evetse iki durağı da yeniden hesaplayıp kaydetmeniz gerekli.',
    textRiderOnBoth:
      'Durak ters yönden de silinsin mi? Durağı her iki yönden de silerseniz, her iki güzergahı yeniden hesaplamanız ve kaydetmeniz gerekir. Lütfen her iki yönde de atanmış yolcuların olduğunu unutmayın.',
    textRiderOnReverse:
      'Durak ters yönden de silinsin mi? Durağı her iki yönden de silerseniz, her iki güzergahı yeniden hesaplamanız ve kaydetmeniz gerekir. Lütfen karşı yönde atanmış yolcuların olduğunu unutmayın..',
    confirmButtonText: 'İki yönden de sil',
    cancelButtonText: 'Sadece bu yönden sil',
  },
  assignReversedDirection: {
    text: 'Bu durak diğer yönde de var. Bu kullanıcıyı diğer yöndeki durağa da atamak ister misiniz?',
    confirmButtonText: 'İki yöne de ata',
    denyButtonText: 'Sadece bu durağa ata',
  },
  adjustAndCalculateMessage: 'Rotayı aktive etmeden önce diğer yöndeki rotayı ayarlayın ve hesaplayın',
  deleteRiderAndStopBothDirections: {
    text: 'Durağı ve yolcuyu iki yönden de kaldırmak istiyor musunuz?',
    confirmButtonText: 'İki yönden de kaldır',
    denyButtonText: 'Sadece bu yönden kaldır',
  },
  unassignRiderBothDirections: {
    text: 'Yolcuyu duraktan karşı yönden de kaldırmak istiyor musunuz?',
    confirmButtonText: 'İki yönden de kaldır',
    denyButtonText: 'Sadece bu duraktan kaldır',
  },
  selectAllActiveTrips: {
    select: 'Aktif rotaların tümünü seç',
    deSelect: 'Seçilenleri temizle',
  },
  cancelMultipleTrips:
    'Lütfen {0} rotasından {1} saatlerinde {2} tarihinde ayrılan seferleri iptal ettiğinizi onaylayın.',
  cancelled: 'İptal Edilmiş',
  cancelTrips: 'Seferleri İptal Et',
  selectDepartureTimes: 'İptal etmek istediğiniz seferlere ait ilk durak kalkış saatlerini seçin.',
  routesCopiedAlert: {
    message:
      'Duraklar geliş rotasından aktarılmıştır. Trafik yönünü dikkate alarak durak yerlerini düzenleyin ve dönüş rotasını hesaplayın.',
    dontShowAgain: 'Bir daha gösterme',
    noReservationsOnThisStop: 'Bu durakta rezervasyon bulunmamaktadır.',
  },
  routeSchedules: {
    routeSchedules: 'Rota Saatleri',
    Inbound: 'Kampüs Varışlılar',
    Outbound: 'Kampüs Çıkışlılar',
    originalSchedules: 'Orjinal Planlamalar',
    temporarySchedules: 'Geçici Planlamalar',
    changeArrivalTimes: 'Varış Saatlerini Değiştir',
    changeDepartureTimes: 'Kalkış Saatlerini Değiştir',
    departureLocation: 'Çıkış Konumu',
    arrivalTimeLastCampus: 'Son Kampüse Varış Saati',
    departureTimesFirstCampus: 'İlk Kampüsten Ayrılış Saatleri',
    arrivalTimeChangePrompt: '<b> son kampüse varış saatlerini nasıl değiştirmek istediğinizi seçin:',
    departureTimeChangePrompt: '<b> ilk kampüsten hareket saatlerini nasıl değiştirmek istediğinizi seçin:',
    setToASpecificTime: 'Seçilen saate çek',
    laterArrivalBy: 'Seçilen süre kadar ötele',
    earlierArrivalBy: 'Seçilen süre kadar öne al',
    laterDepartureBy: 'Daha Sonra Ayrıl',
    earlierDepartureBy: 'Daha Erken Ayrıl',
    datesForWhichThisChangeWillApply: 'Bu değişikliğin uygulanacağı tarihler',
    editStopTimesManually: 'Duraklama saatlerini manuel olarak düzenle',
    fromPlannedTime: 'planlanan zamandan itibaren',
    saveInstructions:
      'Kaydet butonuna bastığınızda tüm duraklar için saatler otomatik olarak hesaplanacaktır. Durak saatlerini kendiniz düzenlemek istiyorsanız aşağıdaki kutuyu işaretleyin.',
    originalArrivalTime: 'Orjinal Varış Saati',
    temporaryArrivalTime: 'Geçici Varış Saati',
    originalDepartureTime: 'Orjinal Çıkış Saati',
    temporaryDepartureTime: 'Geçici Çıkış Saati',
    validFrom: 'Başlangıç',
    validUntil: 'Bitiş',
    destination: 'Varış Noktası',
    arrive: 'Varış',
    depart: 'Çıkış',
    departureTime: 'Çıkış',
    arrivalTime: 'Varış',
    specificGroupName: '{0} Saatleri {1} Olarak Değiştirildi',
    earlyGroupName: '{0} {1} Öne Çekildi',
    arriveGroupName: '{0} {1} Ötelendi',
    hour: 'Saat',
    hours: 'Saat',
    minute: 'Dakika',
  },
  editRouteHeader: {
    route: 'Rota Bilgilerini Düzenle',
    accounting: 'Muhasebe Bilgilerini Düzenle',
  },

  temporaryScheduleChange: {
    title: 'Geçici Program Değişikliği',
    message: {
      SPECIFIC: {
        single:
          'Seçilen güzergâhların {2} saatlerini {0} tarihinde {3} olarak değiştirmek istediğinizden emin misiniz??',
        multi:
          'Seçilen güzergahların {2} saatlerini {0}, {1} tarihleri arasında {3} olarak değiştirmek istediğinizden emin misiniz?',
      },
      LATER: {
        single:
          'Seçilen güzergâhların {2} saatlerini {0} tarihinde {3} olarak değiştirmek istediğinizden emin misiniz??',
        multi:
          'Seçilen güzergahların {2} saatlerini {0}, {1} tarihleri arasında {3} olarak değiştirmek istediğinizden emin misiniz?',
      },
      EARLY: {
        single:
          'Seçilen güzergâhların {2} saatlerini {0} tarihinde {3} olarak değiştirmek istediğinizden emin misiniz??',
        multi:
          'Seçilen güzergahların {2} saatlerini {0}, {1} tarihleri arasında {3} olarak değiştirmek istediğinizden emin misiniz?',
      },
    },
  },
  arriveOrDepartEarlyLate: {
    SPECIFIC: ['arrive', 'depart'],
    EARLY: ['arrival', 'departure'],
    LATER: ['arrival', 'departure'],
  },
  delayTime: {
    EARLY: '{0} {1} erken',
    LATE: '{0} {1} geç',
  },
  specificTime: '{0}',
  deleteTemporarySchedule: {
    title: 'Geçici Programı Sil ',
    message:
      'Seçilen güzergahlar için geçici programı iptal etmek istediğinizden emin misiniz? Sefer saatleri orijinal programa dönecektir.',
  },
  conflictWithExistingSchedules: {
    title: 'Mevcut Programlarla Çakışma',
    message:
      'Belirttiğiniz tarihlerde, aşağıdaki güzergahlarda zaten başka geçici programlar bulunmaktadır.<br><br>{0}',
  },
  tripsHasStarted: 'Seçilen güzergahlardan bazılarına ait seferler zaten başlamış durumda.',
  noReservationsOnThisStop: 'Bu durakta rezervasyon bulunmamaktadır.',
  notifyRidersPanel: {
    panelTitle: 'Geçici Sefer Zamanı Güncelleme Bildirimi',
    message: {
      SPECIFIC: 'Seçili seferler için varış zamanı {1} - {2} tarihleri arasında {0} olarak değiştirilecektir.',
      LATER: 'Seçili rotalar için sefer saatleri {1} - {2} tarihleri arasında {0} gecikecektir.',
      EARLY: 'Seçili rotalar için {3} saatleri {1} - {2} tarihleri arasında {0} olacaktır.',
    },
    title: '{0} rota seçildi',
  },
  arriveOrDepart: ['varış', 'kalkış'],
  arrivalOrDeparture: ['varış', 'kalkış'],
  allRoutes: 'Tüm rotalar',
  allRouteGroups: 'Tüm rota grupları',
  vehiclePlate: 'Plaka',
  vehicleCapacity: 'Araç Kapasitesi',
  vehicleId: 'Araç Adı',
  departureTimePlannedActual: 'Kalkış Saati Planlanan - Gerçekleşen',
  arrivalTimePlannedActual: 'Varış Saati Planlanan - Gerçekleşen',
  notReservedRiders: 'Rezervasyonsuz Yolcular',
  actualOccupancy: 'Gerçekleşen Doluluk',
  qrCardReads: 'QR/Kart Okutmaları',
  totalMilesPlannedActual: 'Toplam  Miles Planlanan - Gerçekleşen',
  inbound: 'Kampüs Varışlılar',
  outbound: 'Kampüs Çıkışlılar',
  tripStatusFilter: {
    NO_SIGNAL: 'Sinyal Yok',
    NOT_ARRIVED: 'Ulaşmadı',
    DEPARTURE_DELAYED: 'Kalkış Gecikti',
    LATE_ARRIVAL: 'Geç Varış',
    CANCELLED_BY_ADMIN: 'Admin Tarafından İptal Edildi',
    NO_RESERVATIONS: 'Rezervasyon Yok',
  },
  plannedActualRiders: 'Planlanan / Gerçekleşen Doluluk',
  plannedActualStart: 'Planlanan / Gerçekleşen Başlangıç',
  plannedActualDeparture: 'Planlanan / Gerçekleşen Kalkış',
  plannedActualArrival: 'Planlanan / Gerçekleşen Varış',
  plannedActualDistance: 'Planlanan / Gerçekleşen {0}',
  totalPlannedActualDistance: 'Toplam {0} Planlanan - Gerçekleşen',
  addedByDriver: '+{0} kişi sürücü tarafından eklendi',
  addedByDriverNoNumber: 'Sürücü Tarafından Eklendi',
  createTrips: 'Seferleri Oluştur',
  createTripsHelper: 'Bu butona tıklayarak bir nedenle kaydedilmemiş seferleri otomatik oluşturabilirsiniz.',
  noShows: 'Gelmeyenler',
  missingTripsAreCreated: 'Kaydedilmemiş seferler oluşturuldu.',
  miles: 'Mil',
  km: 'km',
  routeSearch: 'Rota Arama',
  selectRouteGroup: 'Rota Grubu Seçin',
  selectRider: 'Personel Seçin',
  filterBy: 'Filtreye göre',
  schedule: 'Planlanmış',
  showNearbyStops: 'Yakın Durakları Göster',
  selectDays: 'Günleri Seçin',
  selecTimes: 'Saatleri Seçin',
  riderAddedtoRoute: '{0} adlı yolcu {1} rotasına eklendi',
  riderAddedConfirm: '{0} adlı kişiyi {1} rotasına eklemek istiyor musunuz?',
  allRouteGroup: 'Tüm Rota Grupları',
  periodStartTime: 'Rota periyot başlangıç saati',
  timeErrorMessage: 'Seçilen zaman şu anki zamandan önce olamaz. Lütfen geçerli bir gelecekteki zaman seçin.',
  closeNotifyPanel: 'Çıkmak istediğinizden emin misiniz? Henüz sürücülere herhangi bir bildirim göndermediniz.',
  timesUpdatedSuccessfully: 'Seçilen rotaların saatleri, seçilen tarihler için güncellendi.',
  ok: 'Tamam',
  paymentNote: 'Hakediş Notu',
  ridersCheckedIn: 'Check-in Yapanlar',
  filter: {
    checkedIn: 'Check-in Yapanlar',
    notCheckedIn: 'Check-in Yapmayanlar',
    reserved: 'Rezervasyonlu',
    notReserved: 'Rezervasyonsuz',
    awaiting: 'Bekliyor',
    noShowSkipped: 'Gelmedi / Atlandı',
  },
  selectRouteGroupToListRoutes: 'Rotaları listelemek için rota grubunu seçin.',
  noRoutesFound: 'Rota bulunamadı',
  noRouteGroupsFound: 'Rota grubu bulunamadı',
  transferRidersOnRoutename:
    '{0} katılacak sürücülerin <b>{1}</b> tarihinde aşağıdaki güzergaha taşınması gerekmektedir:',
  reservationInfoText: 'Her durak için, diğer rotada rezervasyonları aktarmak istediğiniz ilgili durağı seçin.',
  selectStop: 'Select stop',
  selectCampus: 'Select campus',
  reserve: 'reservations',
  past: 'Past',
  hide: 'Gizle',
  riderSelectedStopTransferredSelectedRoute: 'Yolcular başarılı bir şekilde diğer rotaya aktarıldı.',
  PICKUP: 'Biniş',
  DROPOFF: 'İniş',
  transferInfoMessage:
    'Lütfen <b>{1}</b> tarihinde <b>{0}</b> güzergahının seçili duraklarındaki yolcuları <b>{2}</b> güzergahındaki seçili duraklara aktarmak isteyip istemediğinizi onaylayın.',
  reservationTransferNotification: 'Rezervasyon Transfer Bildirimi',
  transferSubtitle:
    '<b>{0}</b> seçilen duraklardaki yolcular, <b>{2}</b> tarihindeki yolculuk için <b>{1}</b> seçilen duraklara aktarılmıştır.',
  transferMessageBox:
    '{0} rotasındaki rezervasyonunuz {1} tarihinde {2} rotasına aktarıldı. Ayrıntılar için lütfen flexiShuttle Yolculuklarım ekranını kontrol edin.',
  NON_PERSONALIZED_CARD: 'Kayıtlı Olmayan Kart',
  youMustSelectArrivalDepartureTimes: 'Başlangıç ve bitiş zamanı seçmelisiniz.',
  transferRiders: 'Yolcuları Aktar',
  onlyVisibleAdministrator: 'Sadece yönetici tarafından atanan yolcular görebilir.',
};
