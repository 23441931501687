export default {
  rider: 'Rider',
  typicalMode: 'Typical Mode',
  secondMode: 'Second Mode',
  reasonsToDriveAlone: 'Reasons to Drive Alone',
  futureModes: 'Considerable Future Modes',
  drivingCarpool: 'Driving a Carpool',
  drivingVanpool: 'Driving a Vanpool',
  reasonsToAvoidSharedOptions: 'Reasons to Avoid Shared Options',
  surveyResponses: 'Anket Cevapları'
};
