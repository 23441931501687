export const tr = {
  resources: 'Kaynaklar',
  aviNumber: 'X bir numara',
  color: 'Renk',
  catalogId: 'Katolog Numarası',
  id: 'Araç Numarası',
  activeRentalId: 'Aktif Kiralama Numarasi',
  rentalStatus: 'Kiralama Durumu',
  status: 'Araç Durumu',
  address: 'Adres',
  bodyType: 'Kasa Tipi',
  odometer: 'Odometre',
  canbusOdometer: 'Canbus Km.',
  chassisNumber: 'Şasi Numarası',
  doorLockType: 'Kapı Kilit Tipi',
  equipmentNumber: 'Ekipman Numarası',
  fuelLevelLt: 'Yakıt Seviyesi(Lt.)',
  fuelLevelBattery: 'Batarya Seviyesi',
  fuelType: 'Yakıt Tipi',
  hgsNumber: 'HGS Numarası',
  imeiNumber: 'IMEI Numarası',
  lastDirection: 'Yön',
  latestLocation: 'Konum',
  licenseNumber: 'Lisans Numarası',
  make: 'Marka',
  materialNumber: 'Materyal Numarası',
  model: 'Model',
  modelYear: 'Model Yılı',
  plate: 'Plaka',
  transmissionType: 'Şanzıman Tipi',
  typeDetail: 'Model Detayı',
  firstAvailableDate: 'İlk Aktif Tarihi',
  firstDeliveryDate: 'İlk Teslim Tarihi',
  // DO NOT USE THIS, It will be deprecated, use rentals.key instead
  rentalStatuses: {
    PENDING_PICKUP: 'Vale Bekliyor',
    NOT_ACTIVATED: 'Aktif Değil',
    ACTIVATED: 'Aktif',
    WORKING: 'Kirada',
    AVAILABLE: 'Uygun',
    ALL: 'Hepsi',
    IN_TRANSIT: 'Hareket Halinde',
  },
  damage: {
    description: 'Hasar Tanımı',
    fileIds: 'Hasar Dosyaları',
    region: 'Hasar Bölgesi',
    id: 'Hasar Numarası',
  },
  operationalStatus: {
    IN_TRANSIT: 'Hareket Halinde',
    PENDING_PICKUP: 'Vale Bekliyor',
    WORKING: 'Çalışıyor',
    WILL_TRANSFER_TO_CAR_LOT: 'Otoparka Çekilecek',
    TRANSFERING_TO_CAR_LOT: 'Otoparka Çekiliyor',
    NONE: '-',
  },
  vehicleStatus: {
    ACTIVATED: 'Pasif',
    NOT_ACTIVATED: 'Aktif Değil',
    OUT_OF_SERVICE: 'Hizmet Dışı',
    DELETED: 'Silinmiş',
    AVAILABLE: 'Hizmet İçi',
    WORKING: 'Aktif',
    IN_TRANSIT: 'Görevde/Meşgul',
    STOLEN: 'Çalıntı',
    STOLEN_OUT_OF_FLEET: 'Çalıntı (out of fleet)',
    RETURN_PREPARATION: 'İade Hazırlık',
    HEAVILY_DAMAGED: 'Ağır Hasarlı',
    RETURNED: 'İade Edildi',
    DEVICE_IS_BEING_INSTALLED: 'Cihaz Takılıyor',
    PLATE_IS_BEING_UPDATED: 'Plaka Güncelleniyor',
    NEW: 'Yeni',
  },
  compoundOperationalStatus: {
    AVAILABLE: 'Kiralamaya Uygun',
    NOT_AVAILABLE: 'Meşgul/Görevde',
    WORKING: 'Kirada',
  },
  assignJob: 'Görev Atama',
  streetView: 'Sokak Görünümü',
  breakdown: 'Arıza Bildirimi',
  vehicleDetails: 'Araç Detayı',
  inRental: 'Kiralama',
  lastAddress: 'Son Bulunduğu Adres',
  lastVisible: 'Son Görülme',
  job: {
    title: 'Görev Atama',
  },
};

export const en = {
  resources: 'Resources',
  aviNumber: 'Avi Number',
  color: 'Color',
  catalogId: 'Catalog Id',
  id: 'Vehicle Id ',
  activeRentalId: 'Active Rental Id',
  rentalStatus: 'Rental Status',
  status: 'Status',
  address: 'Address',
  bodyType: 'Body Type:',
  odometer: 'Odometer',
  canbusOdometer: 'Canbus Km.',
  chassisNumber: 'Chassis No',
  doorLockType: 'Door Lock Type',
  equipmentNumber: 'Equipment Number',
  fuelLevelLt: 'Fuel Level (Lt.)',
  fuelLevelBattery: 'Battery Level',
  fuelType: 'Fuel Type',
  hgsNumber: 'HGS No',
  imeiNumber: 'IMEI No',
  lastDirection: 'Direction',
  latestLocation: 'Location',
  licenseNumber: 'License No',
  make: 'Make',
  materialNumber: 'Material Number',
  model: 'Model',
  modelYear: 'Year',
  plate: 'Plate',
  transmissionType: 'Transmission Type',
  typeDetail: 'Type detail',
  firstAvailableDate: 'First Available Date',
  firstDeliveryDate: 'First Delivery Date',
  // DO NOT USE THIS, It will be deprecated, use rentals.key instead
  rentalStatuses: {
    PENDING_PICKUP: 'Vale Bekliyor',
    NOT_ACTIVATED: 'Aktif Değil',
    ACTIVATED: 'Aktif',
    WORKING: 'Kirada',
    AVAILABLE: 'Uygun',
    ALL: 'Hepsi',
    IN_TRANSIT: 'Hareket Halinde',
  },
  damage: {
    description: 'Description',
    fileIds: 'Files',
    region: 'Region',
    id: 'Id',
  },
  operationalStatus: {
    IN_TRANSIT: 'In Transit',
    PENDING_PICKUP: 'Pending Pickup',
    WORKING: 'Working',
    WILL_TRANSFER_TO_CAR_LOT: 'Will transfer to car lot',
    TRANSFERING_TO_CAR_LOT: 'Transerring to car lot',
    NONE: '-',
  },
  vehicleStatus: {
    ACTIVATED: 'Passive',
    NOT_ACTIVATED: 'Not Active',
    OUT_OF_SERVICE: 'Out of Service',
    DELETED: 'Deleted',
    IN_TRANSIT: 'On the job/Busy',
    AVAILABLE: 'Available',
    WORKING: 'Working',
    STOLEN: 'Stolen',
    STOLEN_OUT_OF_FLEET: 'Stolen (out of fleet)',
    RETURN_PREPARATION: 'Return Preparation',
    HEAVILY_DAMAGED: 'Heavily Damaged',
    RETURNED: 'Returned',
    DEVICE_IS_BEING_INSTALLED: 'Device is being installed',
    PLATE_IS_BEING_UPDATED: 'Plate is being updated',
    NEW: 'New',
  },
  compoundOperationalStatus: {
    AVAILABLE: 'Available',
    NOT_AVAILABLE: 'Not Available',
    WORKING: 'Working',
  },
  assignJob: 'Assign Job',
  streetView: 'Street View',
  breakdown: 'Breakdown',
  vehicleDetails: 'Vehicle Details',
  inRental: 'Active Rental',
  lastAddress: 'Last Address',
  lastVisible: 'Last Visible',
  job: {
    title: 'Job Assignment',
  },
};

export default tr;
