export default {
  basicInfo: 'Basic Info',
  departments: 'Departments',
  campus: 'Campus',
  selectCampus: 'Select Campus',
  selectProviderCompany: 'Select Provider Company',
  company: 'Company',
  status: 'Status',
  campuses: 'Campuses',
  providerCompany: 'Provider Company',
  routes: 'Routes',
  users: 'Users',
  registeredUsers: 'Registered Users',
  shuttleUsers: 'flexiShuttle Users ',
  poolcarUsers: 'flexiCar Users',
  rideUsers: 'flexiRide Users',
  parkUsers: 'flexiPark Users',
  region: 'Region',
  locations: 'Locations',
  locationName: 'Location Name',
  address: 'Address',
  serviceProvider: 'Service Provider',
  newServiceProvider: 'New Service Provider',
  addNewServiceProvider: 'Add New Service Provider',
  updateServiceProvider: 'Update Service Provider',
  admins: 'Admins',
  general: 'General',
  poolCarRide: 'flexiCar/flexiRide Private/ flexiRideShared',
  parking: 'flexiPark',
  allowance: 'Allowance',
  subCompanies: 'Sub Companies',
  pricing: 'Pricing',
  settings: 'Settings',
  noSubCompany: 'Subcompany not found.',
  newCampus: 'New Campus',
  newRegion: 'New Region',
  newLocation: 'New Location',
  newAdmin: 'New Admin',
  campusName: 'Campus Name',
  addNewCampus: 'Add New Campus',
  regionName: 'Region Name',
  addNewRegion: 'Add New Region',
  location: 'Location',
  editCampus: 'Edit Campus',
  editRegion: 'Edit Region',
  deleteCampus: 'Delete Campus',
  noLocation: 'No location founded.',
  deleteRegion: 'Delete Region',
  system: 'System',
  systemAdmin: 'System Admin',
  serviceProviderAdmin: 'Service Provider Admin',
  newDepartment: 'New Department',
  addNewDepartment: 'Add New Department',
  departmentName: 'Department Name',
  departmentNumber: 'Department Number',
  noDepartmentsAdded: 'No departments added yet',
  editDepartment: 'Edit Department',
  number: 'Number',
  phoneNumber: 'Phone Number',
  drawCampusLocation: 'Draw a polygon on the map to define the campus location.',
  editCampusArea: 'Drag polygon points to edit the campus area.',
  drawRegionLocation: 'Draw a polygon on the map to define the region location.',
  editRegionArea: 'Drag polygon points to edit the region area.',
  campusesDeleted: 'Campuses which have registered riders and/or active route groups on them cannot be deleted',
  industries: {
    IT: 'IT',
    Education: 'Education',
    'Sales&CRM': 'Sales&CRM',
    Finance: 'Finance',
    'Design and Creative': 'Design and Creative',
    Marketing: 'Marketing',
    Operations: 'Operations',
    Construction: 'Construction',
    'HR & Recruiting': 'HR & Recruiting',
    'Software Development': 'Software Development',
    Legal: 'Legal',
    Other: 'Other',
  },
  basicInfoSubProps: {
    type: 'Type',
    companyName: 'Company Name',
    taxNumber: 'Tax Number',
    industry: 'Industry',
    language: 'Language',
    currency: 'Currency',
    unit: 'Unit',
    functionality: 'Functionality',
  },
  functionalities: {
    usingPoolcar: 'flexiCar',
    usingRide: 'flexiRide',
    usingShuttle: 'flexiShuttle',
    usingTaxi: 'flexiMileage',
    usingVanPool: 'flexiVanpool',
    usingCarPool: 'flexiCarpool',
    seeCommuteOptions: 'Commute Options',
    usingSharedRide: 'flexiRide Shared',
    feedback: 'Feedback',
    usingFlexiPark: 'flexiPark',
  },
  feedback: {
    userFeedbackCategoriesAndTopics: 'User Feedback Categories and Topics',
    noTopicsAdded: 'No topics added',
    text1:
      'You can select or deselect from the basic topic list provided for each category and optionally add custom topics. We advise you not to provide more than 10 topics for ease of use and clarity for users on the mobile app.',
    newTopic: 'New Topic',
    walkingDistance: 'Walking Distance',
    arrivedLate: 'Arrived Late',
    leftEarly: 'Left Early',
    noShow: 'No Show',
    driverBehavior: 'Driver Behavior',
    vehicleCleanliness: 'Vehicle Cleanliness',
    missingEquipment: 'Missing Equipment',
    checkinScan: 'Check-In Scan',
    other: 'Other',
  },
  generalText: {
    newUser: 'Send welcome e-mail when a new user is added.',
    emails: 'Send users information e-mails.',
    sendUsersEmail: 'Send users introduction e-mails after registration.',
    displaySurvey: 'Display survey on user onboarding.',
    checkPersonel: 'Check Dashboard Items by Personnel',
    dashboardShuttle: 'Shuttle',
    dashboardPoolcar: 'Poolcar',
    dashboardVanpool: 'Vanpool',
    dashboardTaxi: 'Taxi',
    dashboardRide: 'Flexiride',
    dashboardCalender: 'Calendar',
    dashboardPastUses: 'Past Uses',
    dashboardCarpool: 'Carpool',
    dashboardComplaints: 'Complaints',
    mobileDashboardItems: 'Mobile Dashboard Items',
    request: 'Request',
    dashboardLoop: 'flexiLoop',
    firstLoginPickupReminder: 'Make it mandatory to save a preferred pick-up address during first login',
    changeHomeAddress: 'When the employees change their home addresses, email the administrators.',
  },
  adminsText: {
    setTwoFactor: 'Set two- factor authentication for admins.',
    adminsRequests: 'Admins to be informed about requests.',
    placeHolder: 'You can type multiple emails seperated by commas.',
  },
  poolCarRideText: {
    approvalFlexiCar: 'Approval required for flexiCar',
    shouldComplateDailyChecklist: 'Drivers should complate a daily checklist',
    reservationFlexiCar: 'Reservation required for flexiCar',
    approvalFlexiRide: 'Approval required for flexiRide',
    autoPlanning: 'Auto planning',
    sendConfirmationEmail: 'Send confirmation emails to EAs when the request is parsed from email',
    notifyRidersflexiRideRequest: 'Notify riders when driver and vehicle are assigned for their flexiRide request.',
    notifyRidersPickupPoint: 'Notify riders as the flexiRide vehicle arrives at the pick-up point',
    sendUsersSMSLink: 'Send users an SMS link to track the vehicle on trip times.',
    notifyDriverVehicleRequest: 'Notify assistants when driver and vehicle are assigned for their flexiRide request.',
    notifyflexiRideVehicleArrives: 'Notify assistants as the flexiRide vehicle arrives at the pick-up point',
    notifyflexiRideRequest: 'Notify assistants when the request is approved/rejected.',
    SendSMSLinkTrackVehicleTripTimes: 'Send assistants an SMS link to track the vehicle on trip times.',
    flexiRidePurposes: 'flexiRide Purposes',
    notifyApproachesPickupPoint:
      'Notify assistants {time} minutes before arrival as the flexiRide vehicle approaches the pick-up point.',
    notifyRidersApproachesPickupPoint:
      'Notify riders {time} minutes  before arrival as the flexiRide vehicle approaches the pick-up point.',
    purposeMustSelectedAdded: 'At least one purpose must be selected or added.',
    notifyRidersRequest: 'Notify riders when the request is approved/rejected.',
    notifyAdministratorsFlexiRideRequest: 'Notify administrators when a new flexiRide request is received.',
    riderForRequestAcknowledged: 'Notify riders when the flexiRide request is acknowledged by the administrator.',
    assistantForRequestAcknowledged:
      'Notify assistants when the flexiRide request is acknowledged by the administrator.',
    mobilityAssistance: 'Riders can request mobility assistance.',
    serviceTimeWithHoursMultiple: 'The service is available {0} on {1}.',
    requestsInAdvance: 'flexiRide Shared requests can be made at {mins} min intervals.',
    sharedServiceTime: 'Service time for each flexiRide Shared stop is {mins} min.',
    singleRequest: 'The max. number of riders that can be selected for a single request is {mins}',
    serviceTimeWithHours: 'The service is available {0} on {1}.',
    serviceTimeWithoutHours: 'The service is available all day on {0}.',
    theActiveTimesForFlexiRideSharedService: 'The active times for flexiRide Shared service',
    allDay: 'All day',
    duringSpecifiedHours: 'During specified hours',
    addPeriod: 'Add Period',
    phoneNumberRequiredFlexiRideSharedRequests: 'A phone number is required for all flexiRide Shared requests',
    theStartingTimeMustEarlierThanTheEndingTime: 'The starting time must be earlier than the ending time',
    from: 'from',
    to: 'to',
    weekdays: 'weekdays',
  },
  requestStartTime: {
    earliestTime: 'Requests can be made is',
    theSameDay: 'The same day',
    daysInAdvance: 'day(s) in advance',
    sameDay: 'same day',
    onTheDay: 'Requests can be made on the day of the trip',
    daysEarlier: {
      single: 'day in advance.',
      multiple: 'days in advance.',
    },
    requestsCanBeMade: 'Requests can be made is {0}',
  },
  flexiMileageText: {
    flexiMileageNotifications: 'flexiMileage Notifications',
    flexiMileagePurposes: 'flexiMileage Purposes',
    perMileCost: 'Per mile Cost',
    riderForComfirmed: 'Notify riders when their reported mileage is confirmed.',
    riderForRejected: 'Notify riders when their reported mileage is rejected.',
    riderForPaidBack: 'Notify riders when their reported mileage is paid back.',
  },
  shuttleText: {
    userControl: 'User Control',
    text1: 'Allow users to see shuttle routes to other campuses.',
    text2: 'Admin Notifications',
    text3: 'Report admins about vehicles not sending signal.',
    text4: 'Report admins about shuttle routes with late arrival or no arrival.',
    text5: 'Notify admins about card scan rates.',
    cardScans: 'Card/QR Scans',
    text6:
      'If the card/QR is not scanned for {scanDays} days, send users daily warning e-mails for {emailDays} days unless user restarts scanning.',
    text7: 'Set user passive for flexiShuttle if the card/QR is not scanned for  {flexiShuttleDays} days.',
    rules: 'Rules',
    text8: 'In the driver app, the routes should be created daily.',
    serviceProviders: 'Service Providers',
    text9: 'Make rider list visible to service providers.',
    text10: 'Make campus based notification sending control available for service providers.',
    text11: 'vehicle licence',
    text12: 'route name',
    text13: 'Set QR codes according to {QrCodes}.',
    text14: 'Maximum age of shuttle busses should be {maxVehicleAgeAllowed} years.',
    text15: 'Drivers can be called',
    text16: 'Approval required for vehicle and driver assignments made by the provider company.',
    text17: 'Approval required when riders want to change their regular route.',
    text18: 'When sending daily routes to the drivers, include stops that do not have assigned riders.',
    checkVehiclesCampusZone: 'Check if vehicles are in the Campus Zone between the instance time.',
    ridersCanReserveEvenThoughOverCapacity: 'Riders can reserve even though overcapacity,',
    shuttleBufferForArrivalTime:
      'The buffer for arrival times to be considered for on-time performance tracking is {shuttleBufferForArrivalTime}',
    shuttleBufferForDepartureTime:
      'The buffer for departure times to be considered for on-time performance tracking is {shuttleBufferForDepartureTime}',
    text19: 'Users can select number of riders while making dynamic route reqeusts',
    text20: 'On the dispatch screen, include the OTP of all stops for loop routes in the total dashboard calculations.',
    text21: 'Notify administrators if a driver does not check-in for a task within the planned time.',
    showVehicleInfoBy: {
      message: 'Show the vehicle information in the rider app according to',
      license: 'License Plate',
      vehicleId: 'Vehicle ID',
    },
  },

  vanpoolText: {
    driverAndRiderAgeRequirements: {
      text1: 'Minimum age required for vanpool <strong>riders</strong>.',
      text2: 'Riders must be over {age} years old.',
      text3: 'Minimum age required for vanpool <strong>drivers</strong>.',
      text4: 'Drivers must be over {age} years old.',
    },
    driverLicenceReqirements: {
      text1: 'Minimum period of driver licence holdership required for vanpool <strong>drivers</strong>.',
      text2: 'Vanpool drivers must have held a valid US driver license for at least {age} years.',
    },
    adminNotifications: {
      text2: 'Report admins when the number of riders on a vanpool trip is below {riderCount}',
      text3: 'Report admins when the non-commute mile of a vanpool route is over {distanceRatio}',
      text6: 'Notify admins when the telematics device is disassembled from a vehicle.',
    },
  },
  driverAndRiderAgeRequirements: 'Driver and Rider Age Requirements',
  driverLicenceReqirements: 'Driver Licence Reqirements',
  adminNotifications: 'Admin Notifications',

  carpoolText: {
    unitPricePerMile: 'Unit price per mile.',
  },
  adminsToBeNotified: 'Admins to be notified',
  clearSelection: 'Clear Selection',
  addNewCompany: 'Add New Company',
  addNewSubCompany: 'Add New Sub Company',
  mainCompany: 'Main Company',
  thirdParty: 'Third Party',
  companyName: 'Company Name',
  taxNumber: 'Tax Number',
  selectIndustry: 'Select Industry',
  selectCompany: 'Select Company',
  confirmMessage: 'Do you want to save the changes you made?',
  deleteMessageText: 'Are you sure you want to continue?',
  continueMessage: 'Are you sure you want to continue?',
  noServiceProviderMessage: 'No service providers added yet.',
  noAdminAddedYet: 'No admins added yet.',
  noCampusesAddedYet: 'No campuses added yet.',
  noRegionsAddedYet: 'No regions added yet.',
  noServiceProvidersAddedYet: 'No service providers added yet.',
  noSubCompaniesDefinedYet: 'No sub companies defined yet.',
  confirmContinueMessage: 'Are you sure you want to continue?',
  flexiRideNotifications: 'flexiRide Notifications',
  corporateRiders: 'Corporate Riders',
  flexiRideAssitants: 'flexiRide Assistants',
  pushNotification: 'Push Notification',
  Sms: 'SMS',
  business: 'Business',
  personal: 'Personal',
  purposeDescription: 'Purpose description',
  estimatedServiceTime: 'Estimated Service Time',
  newPurpose: 'New Purpose',
  flexiRideAssistants: 'flexiRide Assistants',
  serviceProviders: 'Service Providers',
  companyDomainAddress: 'Company domain address',
  timeZone: 'Time Zone',
  flexiShuttleDocuments: 'flexiShuttle Documents',
  typeTopicTitle: 'Type topic title',
  dismissMessage: 'Are you sure you want to close the panel? Any unsaved changes will be lost.',
  openingPage: 'Opening Page',
  selectOpeningPage: 'Select an opening page',
  cancelRequestMessage: 'Are you sure you want to delete your Safe Ride reservation at {0} on {1}?',
  TBD: 'TBD',
};
