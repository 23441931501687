export default {
  distanceToCampus: 'Yerleşkeye Uzaklık',
  invitationLastSentOn: 'Davetiyenin Son Gönderilme Tarihi',
  flexiShuttleUsers: 'flexiShuttle Kullanıcıları',
  flexiShuttleFlexiVanpoolUsers: 'flexiShuttle/flexiVanpool  Kullanıcıları',
  flexiCarUsers: 'flexiCar Kullanıcıları',
  mobilityChallenged: 'Engelli Desteği',
  userAgreementDate: 'KVKK Onay Tarihi',
  pregnant: 'Hamile',
  registrationDate: 'Kayıt Tarihi',
  shift: 'Vardiya',
  eligibleFor: 'Kullanım Hakları',
  drivingLicence: 'Ehliyet',
  currentModeCommute: 'Mevcut Mod',
  walkingDistance: 'Yürüme Mesafesi',
  travelDistance: 'Seyahat Mesafesi*',
  travelTime: 'Seyahat Süresi*',
  totalEmission: 'Toplam Emisyon*',
  line: 'Hat',
  basicInfo: 'Temel Bilgiler',
  workSystemInfo: 'İş & Sistem Bilgileri',
  commuteInfo: 'İşe Gidiş Geliş',
  supportRequests: 'Destek Talepleri',
  cardReads: 'Kart Okutma',
  showCardReads: 'Kart Okutmaları Göster',
  addLevel: 'Seviye Ekle',
  level: 'Seviye',
  pleaseEnterEmail: 'Lütfen email girişi yapınız',
  received: 'İşleme alındı',
  solved: 'Çözüldü',
  assignAdmin: 'Admin Ata',
  personnelDeleted: 'Personel Silindi',
  welcomeEmail: 'Hoşgeldin Emaili',
  welcomeEmailWillBeSent: '{0} kullanıcıya {1} gönderilecek. Onaylıyor musunuz?',
  welcomeEmailWillBeSentNamed: '{0} adlı kullanıcıya {1} gönderilecek. Onaylıyor musunuz?',
  welcomeEmailSent: 'Hoşgeldin Emaili Gönderildi',
  usingService: 'Servis Kullanıyor',
  noSupportRequests: 'Görüntülenecek Destek Talebi Yok.',
  createdPersonnel: 'Personel Oluşturuldu',
  updatedPersonnel: 'Personel Güncellendi',
  addEmployee: 'Personel Ekle',
  downloadExcel: 'Lütfen şablonu indirin, çalışan verilerinizle doldurun ve buraya yükleyin.',
  confirmDeletePersonnel: '{0} isimli personeli silmek istediğinize emin misiniz?',
  confirmDeletePersonnels: '{0} tane personeli silmek istediğinize emin misiniz?',
  passwordValidationInfo: 'Şifreniz en az 5 karakter,en az 1 büyük harf,1 küçük harf,1 rakam icermelidir.',
  userDeleted: 'Bu kullanıcı silindi !',
  flexiRideUsers: 'flexiRide Kullanıcıları',
  assistantAssigned: 'Asistan atandı.',
  assignAssistantToSelected: 'Asistan Ata',
  selectAssistant: 'Asistan Seçin',
  assistantRole: 'Asistan Rolü',
  ridersAssistant: 'Sorumlu Olduğu Personeller',
  assistedby: 'Asistanlar',
  assistantAdminEmail: 'Asistanlar',
  cantAssignThisUser: 'Bu asistan, seçilen personnelere atanamaz',
  assistant: 'Asistan',
  assistants: 'Asistan',
  addNewRider: 'Yeni Personel Ekle',
  addNewAssistant: 'Yeni Asistan Ekle',
  ridersAssistedBy: '{0} Sorumlu Olduğu Personneller',
  assistantsTo: '{0} Asistanları',
  searchPersonnel: 'Personel Ara',
  requiredNoteToEnterNote: 'Kullanıcının ulaşım hizmetleri ile ilgili özel bir tercihi varsa lütfen belirtin.',
  issueDate: 'Veriliş Tarihi',
  validThrough: 'Son Geçerlilik Tarihi',
  closePanel: 'Paneli kapatmak istediğinizden emin misiniz? Kaydedilmemiş tüm değişiklikler kaybolacaktır',
  flexiRideAssistant: 'flexiRide Assistant',
  assitanceDuePermanently: 'Kalıcı',
  assistanceDueUntil: 'Geçici',
  assistanceCategories: 'Engel Kategorileri',
  assistanceCategory: {
    WHEELCHAIR: 'Tekerlekli Sandalye',
    CRUTCHES: 'Kol Değnekleri',
    VISUAL_IMPAIREMENT: 'Görme Engelli',
    HEARING: 'İşitme Engelli',
  },
};
