const Geohash = {
  base32: '0123456789bcdefghjkmnpqrstuvwxyz',

  getBounds(hash) {
    if (hash.length === 0) throw new Error('Invalid geohash');

    const geohash = hash.toLowerCase();

    let evenBit = true;
    let latMin = -90;
    let latMax = 90;
    let lonMin = -180;
    let lonMax = 180;

    for (let i = 0; i < geohash.length; i++) {
      const char = geohash.charAt(i);
      const index = this.base32.indexOf(char);
      if (index === -1) throw new Error('Invalid geohash');

      for (let n = 4; n >= 0; n--) {
        /* eslint-disable-next-line */
        const bitN = (index >> n) & 1;
        if (evenBit) {
          // longitude
          const lonMid = (lonMin + lonMax) / 2;
          if (bitN === 1) {
            lonMin = lonMid;
          } else {
            lonMax = lonMid;
          }
        } else {
          // latitude
          const latMid = (latMin + latMax) / 2;
          if (bitN === 1) {
            latMin = latMid;
          } else {
            latMax = latMid;
          }
        }
        evenBit = !evenBit;
      }
    }

    return {
      sw: { lat: latMin, lng: lonMin },
      ne: { lat: latMax, lng: lonMax },
      nw: { lat: latMax, lng: lonMin },
      se: { lat: latMin, lng: lonMax },
    };
  },

  decode(geohash) {
    const bounds = this.getBounds(geohash); // <-- the hard work
    // now just determine the centre of the cell...

    const latMin = bounds.sw.lat;
    const lonMin = bounds.sw.lng;
    const latMax = bounds.ne.lat;
    const lonMax = bounds.ne.lng;

    // cell centre
    let lat = (latMin + latMax) / 2;
    let lon = (lonMin + lonMax) / 2;

    // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
    lat = lat.toFixed(Math.floor(2 - Math.log(latMax - latMin) / Math.LN10));
    lon = lon.toFixed(Math.floor(2 - Math.log(lonMax - lonMin) / Math.LN10));

    return { lat: Number(lat), lon: Number(lon) };
  },

  /* eslint-disable no-param-reassign */
  encode(latitude, longitude, precision) {
    // infer precision?
    if (typeof precision === 'undefined') {
      // refine geohash until it matches precision of supplied lat/lon
      for (let p = 1; p <= 12; p++) {
        const hash = this.encode(latitude, longitude, p);
        const position = this.decode(hash);
        if (position.lat === latitude && position.lon === longitude) return hash;
      }
      precision = 12; // set to maximum
    }

    const lat = Number(latitude);
    const lon = Number(longitude);
    precision = Number(precision);

    if (Number.isNaN(lat) || Number.isNaN(lon) || Number.isNaN(precision)) throw new Error('Invalid geohash');

    let idx = 0; // index into base32 map
    let bit = 0; // each char holds 5 bits
    let evenBit = true;
    let geohash = '';

    let latMin = -90;
    let latMax = 90;
    let lonMin = -180;
    let lonMax = 180;

    while (geohash.length < precision) {
      if (evenBit) {
        // bisect E-W longitude
        const lonMid = (lonMin + lonMax) / 2;
        if (lon >= lonMid) {
          idx = idx * 2 + 1;
          lonMin = lonMid;
        } else {
          idx *= 2;
          lonMax = lonMid;
        }
      } else {
        // bisect N-S latitude
        const latMid = (latMin + latMax) / 2;
        if (lat >= latMid) {
          idx = idx * 2 + 1;
          latMin = latMid;
        } else {
          idx *= 2;
          latMax = latMid;
        }
      }
      evenBit = !evenBit;

      // eslint-disable-next-line
      if (++bit === 5) {
        // 5 bits gives us a character: append it and start over
        geohash += this.base32.charAt(idx);
        bit = 0;
        idx = 0;
      }
    }

    return geohash;
  },
  /* eslint-enable no-param-reassign */

  adjacent(hash, dir) {
    // based on github.com/davetroy/geohash-js

    const geohash = hash.toLowerCase();
    const direction = dir.toLowerCase();

    if (geohash.length === 0) throw new Error('Invalid geohash');
    if ('nsew'.indexOf(direction) === -1) throw new Error('Invalid direction');

    const neighbour = {
      n: [
        'p0r21436x8zb9dcf5h7kjnmqesgutwvy',
        'bc01fg45238967deuvhjyznpkmstqrwx',
      ],
      s: [
        '14365h7k9dcfesgujnmqp0r2twvyx8zb',
        '238967debc01fg45kmstqrwxuvhjyznp',
      ],
      e: [
        'bc01fg45238967deuvhjyznpkmstqrwx',
        'p0r21436x8zb9dcf5h7kjnmqesgutwvy',
      ],
      w: [
        '238967debc01fg45kmstqrwxuvhjyznp',
        '14365h7k9dcfesgujnmqp0r2twvyx8zb',
      ],
    };

    const border = {
      n: ['prxz', 'bcfguvyz'],
      s: ['028b', '0145hjnp'],
      e: ['bcfguvyz', 'prxz'],
      w: ['0145hjnp', '028b'],
    };

    const lastCh = geohash.slice(-1); // last character of hash
    let parent = geohash.slice(0, -1); // hash without last character

    const type = geohash.length % 2;

    // check for edge-cases which don't share common prefix
    if (border[direction][type].indexOf(lastCh) !== -1 && parent !== '') {
      parent = Geohash.adjacent(parent, direction);
    }

    // append letter for direction to parent
    return (
      parent + Geohash.base32.charAt(neighbour[direction][type].indexOf(lastCh))
    );
  },

  neighbours(geohash) {
    const baseDirections = {
      n: this.adjacent(geohash, 'n'),
      e: this.adjacent(geohash, 'e'),
      s: this.adjacent(geohash, 's'),
      w: this.adjacent(geohash, 'w'),
    };

    const midDirections = {
      ne: this.adjacent(baseDirections.n, 'e'),
      se: this.adjacent(baseDirections.s, 'e'),
      sw: this.adjacent(baseDirections.s, 'w'),
      nw: this.adjacent(baseDirections.n, 'w'),
    };

    return { ...baseDirections, ...midDirections };
  },
};

export default Geohash;
