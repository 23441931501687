export default {
  newCampaign: 'Yeni Kampanya',
  showPassive: 'Pasifleri Göster',
  hidePassive: 'Pasifleri Gösterme',
  fixedCode: 'Sabit Kod',
  percent: 'Yüzde (%)',
  amount: 'Miktar',
  campaignBenefitType: 'Fayda Türü',
  pointsAwardedAt: 'Puanların Verilme Zamanı',
  pointsAwardedAt_AT_START: 'Kiralama Başlangıcında',
  pointsAwardedAt_AFTER_END: 'Kiralama Bittikten Sonra',
  benefitTypes: {
    DISCOUNT_BY_CODE: 'Kod ile İndirim',
    DISCOUNT_BY_AUTO_APPLY: 'Otomatik İndirim',
    POINTS_BY_CODE: 'Kod ile Puan',
    POINTS_BY_AUTO_APPLY: 'Otomatik Puan',
    DAY_BY_CODE: 'Kod İle Ücretsiz Gün',
    DAY_BY_AUTO_APPLY: 'Otomatik Ücretsiz Gün',
  },
  grid: {
    name: 'İsim',
    active: 'Aktif',
    type: 'Tür',
    discount: 'İndirim Tutarı',
    totalCode: 'Toplam Kod',
    usedCode: 'Kullanılan Kod',
    available: 'Uygun Kod',
    limitPerUser: 'Kişi Başı Limit',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    createdBy: 'Yaratan',
    firstUse: 'İlk Kullanım',
    lastUse: 'Son Kullanım',
  },
  modal: {
    name: 'Kampanya İsmi',
    desc: 'Kampanya Tanıtım Metni',
    type: 'İndirim Tipi',
    firstOnly: 'Bu kampanya sadece ilk kiralamalarda geçerli olsun',
    percentAmount: 'Yüzde İndirim (%)',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    startTime: 'Başlangıç Saati',
    endTime: 'Bitiş Saati',
    codeType: 'Kod Tipi',
    auto: 'Otomatik Kod',
    prefix: 'Prefix',
    limit: 'Toplam Limit',
    length: 'Kod Uzunluğu',
    amount: 'Miktar',
    percent: 'Yüzde',
    action: 'Kampanyayı Yarat',
    routeOnly: 'Bu kampanya sadece rota kiralamalarda geçerli olsun',
  },
  announcement: {
    type: {
      CAMPAIGN: 'Kampanya',
      ANNOUNCEMENT: 'Duyuru',
    },
  },
  bonus: 'Hediye Puan Kampanyası',
  downloadTemplate: 'Şablonu İndir',
  chooseFile: 'Dosya Seç',
  uploadFile: 'Dosyayı Yükle',
  errorLine: 'Hata Satırı',
  reasonOfError: 'Hata Nedeni',
  downloadExcelTemplate: 'Excel Şablonu İndir',
  campaignType: 'Kampanya Tipi',
  totalUsage: 'Toplam Kullanım',
  emptyCampaign: 'Kampanya için kısıtlama belirlenmiş olmasına rağmen, bu kısıtlama içerikleri boş.',
  invalidBounds: 'Kampanya aktif olmasına rağmen kuponlar geçersiz sayılıyor.',
  codeApprovedMessage: 'Kod Onay Mesajı',
  approvedCode: 'Kullanıcı kampanya kodu girdiğinde, kod onaylanırsa gösterilecek mesaj',
  limitedCodeMessage: 'Sınırlamaya Takılan Kod Mesajı',
  unconfirmedCodes:
    'Kampanyada en az bir kısıtlama olduğu için doğrulanamayan kodlarda kullanıcıya aşağıdaki hata mesajı gösterilecek',
  makePassiveCampaign: 'Kampanyayı Pasife Çek',
  activate: 'Aktive et',
  createCoupon: 'Kupon Yarat',
  timePeriods: 'Zaman Aralıkları',
  validTimeIntervals: 'Bu kampanya aşağıda belirtilen zaman aralıklarında geçerlidir',
  createTimePeriod: 'Zaman Aralığı Yarat',
  removeTimeBound: 'Zaman Sınırlamasını Kaldır',
  makeTimeBound: 'Zaman Sınırlı Yap',
  noTimeBound:
    'Bu kampanya belirli zaman aralıklarında geçerli olacak şekilde ayarlanmasına rağmen herhangi bir zaman aralığı belirtilmemiş. Kampanya aktif olsa bile kuponlar geçersiz olacaktır.',
  identityBound: 'Kimlik Sınırlaması',
  removeIdentity: 'Kimlikleri Sil',
  addNewFile: 'Yeni Dosya Ekle',
  campaignBounds: {
    removeIdentityBound: 'Kimlik Sınırlamasını Kaldır',
    makeIdentityBound: 'Kimlik Sınırlı Yap',
    makeStationBound: 'İstasyon Sınırlı Yap',
    makeDocumentBound: 'Döküman Sınırlı Yap',
    undefinedIdentity:
      'Bu kampanya belirli kimlik numaralarında geçerli olacak şekilde ayarlanmasına rağmen herhangi bir kimlik belirtilmemiş. Kampanya aktif olsa bile kuponlar geçersiz olacaktır.',
    undefinedIdentityForStation:
      ' Bu kampanya İstasyon Tipi Sınırlaması için ayarlanmasına rağmen herhangi bir kimlik belirtilmemiş. Kampanya aktif olsa bile kuponlar geçersiz olacaktır.',
    vehicleTypeBound: 'Araç Türü Sınırlaması',
    vehicleCampaignBound: 'Bu kampanya aşağıdaki araç tipleri için geçerlidir.',
    districtStartBound: 'Başlangıç İlçe Sınırlaması',
    districtBound: 'Bu kampanya aşağıda ki ilçeler için geçerlidir.',
    districtFinishBound: 'Bitiş İlçe Sınırlaması',
    poiStartBound: 'POI Başlangıç Sınırlaması',
    poiBound: 'Bu kampanya aşağıda ki POI listesi için geçerlidir.',
    poiFinishBound: 'POI Bitiş Sınırlaması',
    ageBound: 'Yaş Sınırlaması',
    ageCampaignBound: 'Bu kampanya aşağıdaki yaş aralığı için geçerlidir.',
    BIN: 'BIN',
    binBound: "Bu kampanya aşağıdaki BIN'ler ile başlayan kartlar için geçerlidir.",
    rentalTypeBound: 'Kiralama Türü Sınırlaması',
    rentalTypeCampaignBound: 'Bu kampanya aşağıdaki kiralama türlerinde geçerlidir.',
    rentalKmBound: 'Kiralama KM Sınırlaması',
    rentalKmCampaignBound: "Bu kampanya aşağıdaki KM'den sonra geçerlidir.",
    totalRentalKmBound: 'Toplam Kiralama Km Sınırlaması',
    totalRentalKmCampaignBound: "Bu kampanya aşağıdakit toplam KM'den sonra geçerlidir.",
    rentalMinutesBound: 'Kiralama Dakika Sınırlaması',
    rentalMinutesCampaignBound: 'Bu kampanya aşağıdaki minumum Kiralama dakikasından sonra geçerlidir.',
    rentalCountBound: 'Kiralama Sayı Sınırlaması',
    rentalCountCampaignBound: 'Bu kampanya sadece aşağıdaki kiralama sayısında geçerlidir.',
    scoreCampaignBound: 'Bu kampanya aşağıdaki skor aralığı için geçerlidir.',
    scoreBound: 'Skor Sınırlaması',
    vehiclePriceCatalogBound: 'Araç Grup Sınırlaması',
    vehiclePriceCatalogSubtitle: 'Bu kampanya aşağıda belirtilen araç grupları için geçerlidir',
    isMinNumberOfRentalDaysBound: 'Kiralama Gün Sınırlaması',
    rentalDaysBoundSubtitle: 'Bu kiralama aşağıdaki minimum Kiralama gün süresinden sonra geçerlidir',
  },
  transactionHistory: 'İşlem Geçmişi',
  createNewCampaignCode: 'Yeni Kampanya Kodu Yarat',
  codeCount: 'Üretilecek Kod Sayısı',
  vehicleSelection: 'Araç Seçimi',
  selectCity: 'Şehir Seçiniz',
  poiSelection: 'POI Seçimi',
  districtSelection: 'District Selection',
  savePoi: "{0} Poi'yi Kaydet",
  saveDistrict: '{0} İlçeyi Kaydet',
  saveVehicle: '{0} Aracı Kaydet',
  createCode: '{0} Adet Kod Yarat',
  ageSelection: 'Yaş Seçimi',
  minAge: 'Minimum Yaş',
  maxAge: 'Maksimum Yaş',
  selectionBin: 'BIN Seçimi',
  rentalTypeSelection: 'Kiralama Türü Seçimi',
  transferredRecord: '{0} kayıt içeriye aktarıldı.',
  fileTransferred: 'Dosya Aktarıldı',
  recordsDeleted: 'Kayıtlar Silindi',
  recordsCouldNotDeleted: 'Silinemedi',
  pushNotificationSent: 'Push bildirimleri gönderildi',
  totalPerson: 'Toplam Kişi',
  loadBalanceBonus: 'Puan/Bakiye Yükle',
  sendBatchPush: 'Toplu Push Gönder',
  totalBalanceLoad: 'Toplam Bakiye Yükleme',
  totalBalanceEditing: 'Toplam Bakiye Düzeltme',
  personTotalBalanceEditing: 'Toplam Bakiye Düzeltilen Kişi',
  personTotalBalanceLoad: 'Toplam Bakiye Yüklenen Kişi',
  cumulativeBalancePerson: 'Kümülatif Bakiye Kişi',
  cumulativeBalance: 'Kümülatif Bakiye',
  totalBonusLoad: 'Toplam Bonus Yükleme',
  totalBonusEditing: 'Toplam Bonus Düzeltme',
  personTotalBonusEditing: 'Toplam Bonus Düzeltilen Kişi',
  personTotalBonusLoad: 'Toplam Bonus Yüklenen Kişi',
  cumulativeBonusPerson: 'Kümülatif Bonus Kişi',
  cumulativeBonus: 'Kümülatif Bonus',
  sendingBatchPushNotification: 'Toplu Push Bildirim Gönderimi',
  requestsPendingApproval: 'Onay Bekleyen Talepler',
  documentReject: 'Dökümanlardan en az biri uygun olmadığı için talep reddedilecek.',
  fillComplete: 'Lütfen bütün dökümanları eksiksiz olarak işaretleyin.',
  searchİNGCampaignCode: 'Kampanya Kodu Arama',
  searchingCodes: 'Biraz önce aranan kodlar',
  campaignCodeDetail: 'Kampanya kodu {0} Kullanım Detayları',
  definedCoupon: 'Bu kupon {0} kere tanımlanmış',
  unusedCode: 'Bu kod henüz kullanılmamış.',
  campaignsWhichCanUses: 'Kullanabileceği kampanyalar',
  notFoundCode: 'Böyle bir kod bulunmuyor.',
  usedTime: 'Kullanıldığı Tarih',
  filterByKey: "Anahtar'a göre filtrele",
  price: 'Fiyatı',
  loaded: 'Yüklenen',
  externalSystemId: 'Dış Sistem Id',
  updated: 'Güncellenen',
  totalRow: 'Toplam Satır',
  incorrectRow: 'Hatalı Satır',
  row: 'Satır',
  uploadingDateBonus: 'Puanların Yükleneceği Zaman',
  selectDay: 'Gün Seçiniz',
  endOfMonth: 'Ay Sonu',
  validValueCampaign: 'Kampanyanın Geçerli Olduğu Değer',
  uploadingDateBonusChanged: 'Puanların Yükleneceği Zaman Değiştirildi',
  bonusUploadMonthly: 'Bu kampanya için puanlar, aşağıda seçilen günde aylık olarak yüklenecektir.',
  removeIdentities: 'Kimlikleri Sil',
  stationConstraints: 'İstasyon Kısıtlamaları',
  sendNotification: 'Girilmiş {0} hesaba bildirim gönder',
  uploadNewFile: 'Yeni Dosya Yükle',
  recordsTransferred: 'kayıt içeriye aktarıldı.',
  idConstraint: 'Id Sınırlaması',
  changeOptions: 'Seçenekleri Değiştir',
  remove: '{0} Kaldır',
  get: '{0} Getir',
  validCharacters: 'Geçerli Karakterler',
  parametersPointEarning: ' İlk Kiralamadan Puan Kazanım Kampanyası Parametreleri',
  firstRentalCampaign: 'İlk Kiralama Kampanyası',
  removeStationTypeBound: 'İstasyon Tipi sınırlamasını kaldır',
  removeDocumentTypeBound: 'Döküman Tipi sınırlamasını kaldır',
  removeIdentityTypeBound: 'Kimlik Tipi sınırlamasını kaldır',
  updatedSuccessfully: 'başarıyla güncellendi.',
  removeTitle: '{0} Kaldır',
  getTitle: '{0} Getir',
  availableCampaign: 'Kampanyanın geçerli olduğu değer',
  availableCampaigns: 'Kampanyanın geçerli olduğu değerler',
  noConstraint: 'Bu kampanya için bir sınırlama bulunmuyor. Kampanyayı sınırlandırmak için ayarları {change}',
  AMOUNT: 'Miktar',
  createdBy: '{creationTime} tarihinde {createdBy} tarafından yaratıldı.',
  change: 'değiştirin',
  changeTimePeriod:
    'Bu kampanya tüm gün ve saat aralıklarında geçerlidir.Kampanyayı belirli saat aralıklarında kullanabilmek için zaman ayarlarını {change}.',
  ageBoundCampaign:
    'Bu kampanya {all} yaş aralıkları için geçerlidir. Kampanyayı sınırlandırmak için ayarları {change}',
  all: 'tüm',
  availableAllType: 'Bu kampanya {all} tipler için geçerlidir. Kampanyayı sınırlandırmak için ayarları {change}',
  constraitCreated:
    'Bu kampanya için {length} farklı sınırlama oluşturulmuştur ve sadece bu sınırlamalar için geçerlidir.',
  noChoise:
    'Bu kampanya {0} için ayarlanmasına rağmen herhangi bir seçim belirtilmemiş. Kampanya aktif olsa bile kuponlar geçersiz olacaktır.',
  availableAllUsers:
    'Bu kampanya tüm kullanıcılar için geçerlidir. Kampanyayı belirli kimlik numarasına göre sınırlandırmak için ayarları {change}',
  matchedAccountNumbers:
    'Bu kampanya {accountIdCount} kişinin hesap numaraları ile eşleştirimiştir ve sadece bu kullanıcılar için geçerlidir.',
  rentalCountConstraint: 'Kiralama Sayı Sınırlaması',
  availableFollowingRentalNumbers:
    'Bu kampanya sadece aşağıdaki kiralama sayısında geçerlidir. ( Ör: 10 veya birden fazla değer için virgülle ayır: 1, 3, 5)',
  customerActivationDayConstrait: 'Müşteri aktivasyon Gün Sınırlaması',
  availableCustomerActivation:
    'Bu kampanya sadece aşağıdaki gün sayısından daha kısa süredir aktif olan müşterilerde geçerlidir.',
  binList: 'Virgül ile ayrılmış BIN listesi',
  PERCENT: 'Yüzde',
  availableAllStation:
    'Bu kampanya tüm istasyon tipleri için geçerlidir. Kampanyayı belirli istasyon tiplerine göre sınırlandırmak için ayarları {change}',
  availableAllDocuments:
    'Bu kampanya tüm döküman tipleri için geçerlidir. Kampanyayı belirli döküman tiplerine göre sınırlandırmak için ayarları {change}',
  updatedDocumentPermissionTypes: 'Döküman izin tipleri güncellendi.',
  updatedStationPermissionTypes: 'İstasyon izin tipleri güncellendi.',
  documentConstraints: 'Döküman Kısıtlamaları',
  pairedDocumentTypes:
    'Bu kampanya {types} döküman tipleri ile eşleştirilmiştir ve sadece bu döküman tiplerinde geçerlidir.',
  pairedStationTypes:
    'Bu kampanya {types} istasyon tipleri ile eşleştirilmiştir ve sadece bu istasyon tiplerinde geçerlidir.',
  documentTypeBound: 'Döküman Tipi Sınırlaması',
  stationTypeBound: 'İstasyon Tipi Sınırlaması',
  documentTypeSelection: 'Döküman Tipi Seçimi',
  stationTypeSelection: 'İstasyon Tipi Seçimi',
  desc: 'Kampanya Tanıtım Metni',
  maxBenefit: 'Maksimum Fayda',
  domainWillDelete: 'Seçilen {0} silinecek. Emin misiniz ?',
  groupDomain: 'grup domain',
  addNewDomain: 'Yeni Domain Ekle',
  domainCampaignWarning: 'Bu kampanya Domain Tanımı için ayarlanmasına rağmen herhangi bir seçim belirtilmemiş.',
  selectDomain: 'Domain Seç',
  domains: 'Domain Seçimi',
  addDomain: 'Domain Tanımı Ekle',
  domainGroup: 'Domain Grubu',
  domain: 'Domain',
  scoreBoundCampaign:
    'Bu kampanya {all} skor aralıkları için geçerlidir. Kampanyayı sınırlandırmak için ayarları {change}',
  minScore: 'Minimum Skor',
  maxScore: 'Maksimum Skor',
  scoreSelection: 'Skor Seçimi',
  vehiclePriceCatalog: 'Araç Grupları',
  addCatalog: 'Katalog Ekle',
  instantOffer: 'Özel Teklif',
  dayOfWeek: 'Haftanın Günü',
  offerStartHour: 'Başlama Zamanı',
  offerEndHour: 'Bitiş Zamanı',
  status: 'Durum',
  displayCount: 'Görüntülenme Adeti',
  offerCount: 'Faydalanma Adeti',
  offerPercentages: 'İndirim Oranları',
  displayFrequency: 'Gösterim Frekansı',
  expireDuration: 'Geçerlilik Süresi (Dk)',
  days: {
    MONDAY: 'Pazartesi',
    TUESDAY: 'Salı',
    WEDNESDAY: 'Çarşamba',
    THURSDAY: 'Perşembe',
    FRIDAY: 'Cuma',
    SATURDAY: 'Cumartesi',
    SUNDAY: 'Pazar',
  },
  availableCampaignDay: 'Kampanyanın geçerli olduğu gün',
  DAY: 'Gün',
  codeApprovelMessage: 'Kod Onaylandı Mesajı',
};
