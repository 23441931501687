<template>
  <div id="common-sidebar" class="sidebar">
    <aside class="menu">
      <p class="menu-label">
        {{$t('carshare.common.general')}}
      </p>
      <ul class="menu-list">
        <!-- TODO: At the moment this components lists only children of the root
        it should be modified to also display children of the children -->
        <li v-for="(item, index) in items.children" :key="index">
          <router-link
            :to="{name: item.route}"
            @click.native="routerChanged(item)">
            <template v-if="item.data && item.data.icon">
              <fa-icon :icon="item.data.icon"/> &nbsp;
            </template>
            {{item.text}}
          </router-link>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
import authService from '@/common/services/auth';

export default {
  name: 'common-sidebar',
  data() {
    return { items: [] };
  },
  async mounted() {
    const lang = this.$route.query.lang ? this.$route.query.lang : localStorage.getItem('lang');
    this.items = await authService.getMenu(lang);
  },
  methods: {
    routerChanged(item) {
      this.$store.commit('SET_SIDEBAR_STATUS', false);
      this.$emit('selectedMenu', item.text);
    },
  },
};
</script>
