export default {
  rentalJobId: 'Rental/Job ID',
  customerId: 'Customer ID',
  incidentId: 'Incident Id',
  externalSupplierIncidentId: 'External Supplier Incıdent Id',
  damageType: 'Damage Type',
  incidentDamageSize: 'Incıdent Damage Size',
  damagedParts: 'Damaged Parts',
  incidentDescription: 'Incident Description',
  accidentReport: 'Accident Report',
  thirdPersonUsage: 'Third Person Usage',
  extraFee: 'Extra Fee',
  extraFeeReason: 'Extra Fee Reason',
  extraFeeAmount: 'Extra Fee Amount',
  othersExtraFeeAmount: 'Others Extra Fee Amount',
  othersExtraFeeReason: 'Others Extra Fee Reason',
  creationTime: 'Creation Time',
  incidentRegistrationTime: 'Incident Registration Time',
  completionDate: 'Completion Date',
  damagedPart: 'Damaged Part',
  partChangeStatus: 'Part Change Status',
  incidentExplanation: 'Incident Explanation',
  incidentType: 'Incıdent Type',
  anonymous: 'Anonymous',
  rentalTaskId: 'Rental / Task Id',
  photosOfIncident: 'Photos of Incident',
  newPhotos: 'new photo(s)',
  photo: 'Photo',
  incidentLocation: 'Incident Location',
  incidentDetailExplanation: 'Incident Detail Explanation',
  weatherCondition: 'Weather Condition',
  incidentDateHours: 'Incident Date/Hours',
  incidentCompletionDate: 'Incident Completion Date/Hours',
  isVehicleDetained: 'Is Vehicle Detained ?',
  isDamageDocumentsSent: 'Is Damage Documents Sent',
  policeReport: 'Police Report',
  userDeclaration: 'User Declaration',
  isDriverDrunk: 'Is Driver Drunk ? ',
  alcoholReport: 'Alcohol Report',
  isThirdPersonUsageExists: 'Is third person usage exists ?',
  thirdPersonCitizenId: 'Third Person Citizen Id',
  currency: 'Currency',
  notExtraFee: 'Reason for not extra fee',
  isThereWithdrawing: 'Is there Withdrawing ?',
  isVehicleScrap: 'Is Vehicle Scrap ? ',
  scrapVehicleAmount: 'Scrap Vehicle Amount',
  scrapInformations: 'Scrap Information',
  salvageAmount: 'Salvage Amount',
  scrapVehicleTowingDocumentAmount: 'Scrap Vehicle Towing Document Amount',
  totalScrapVehicleExtraFee: 'Total Scrap Vehicle Extra Fee',
  automaticallyCalculated: 'It is automatically calculated.',
  part: 'Part',
  change: 'Change',
  vehicleDailyCost: 'Vehicle Daily Cost',
  pendingRepairDays: 'Pending Repair Days',
  pendingLossAmount: 'Pending Loss Amount',
  estimatedDamageAmount: 'Estimated Damage Amount',
  pendingReflectionAmount: 'Pending Reflection Amount',
  netRepairDays: 'Net Repair Days',
  netLossAmount: 'Net Loss Amount',
  valueLossAmount: 'Value Loss Amount',
  damageAmount: 'Damage Amount',
  totalReflectionAmount: 'Total Reflection Amount',
  netDamageCost: 'Net Damage Cost',
  otherReflectionReason: 'Other Reflection Reason',
  otherReflectionAmount: 'Other Reflection Amount',
  operationNote: 'Operation Note',
  incidentInformationUpdated: 'Incident Informations has been updated',
  incidentsWillRemove: 'All datas will be remove for this case and related',
  noRecords: 'It has not been found records for these criteria.',
  jobId: 'Job ID',
};
