export default {
  driverDeleted: 'Sürücü Silindi',
  category: 'Kategori',
  EHLIYET: 'Ehliyet',
  SABIKA_KAYDI: 'Sabıka Kaydı',
  SRC_BELGESI: 'SRC Belgesi',
  PSIKOTEKNIK: 'Psikoteknik',
  IKAMETGAH: 'İkametgah',
  ARAC_FOTOGRAFI: 'Araç Fotoğrafı',
  TRAFIK_SIGORTASI: 'Trafik Sigortası',
  YOL_BELGESI: 'Yol Belgesi',
  RUHSAT: 'Ruhsat',
  ISG: 'ISG',
  dragAndBrowse: 'Dosyayı bu alana sürükleyin veya <span class="text-primary">dosya</span> seçin',
  driversLicence: 'Sürücü Belgesi',
  uploadNewDocument: 'Yeni Belge Yükle',
  totalDistance: 'Toplam Mesafe ({0})',
  driverAdded: 'Sürücü Eklendi.',
  driverUpdated: 'Sürücü Güncellendi.',
  documentLoaded: 'Döküman Yüklendi.',
  documentNotFound: 'Döküman bulunamadı.',
  pendingApproval: 'Onay bekleniyor',
  addDocument: 'Döküman Ekle',
  showDocument: 'Dökümanı Göster',
  driversNotFound: 'Aranan sürücü bulunamadı.',
  shuttleProvider: 'Servis Şirketi',
  driverChangeReasons: {
    ON_LEAVE: 'İzinli',
    SICK: 'Hasta',
    OTHER: 'Diğer',
  },
};
