export default {
  confirmTransfer:
    'Please confirm if you would like to transfer the requests on <strong>{0}</strong> to other available vehicles. If you also choose to cancel the route for the date, any unresolved requests will be canceled.',
  keepTheRoute: 'Keep the Route and Transfer the Requests',
  cancelTheRoute: 'Cancel the Route and Transfer the Requests',
  requestsTransferred: 'Requests Transferred',
  requestTransferred: 'Request Transferred',
  requestsTransferredText: 'The requests have been successfully transferred to the available vehicles.',
  requestTransferredText: 'The request have been successfully transferred to the available vehicles.',
  transferSummary: 'Transfer Summary',
  unresolvedText:
    'The optimization has been able to transfer <strong>{0}</strong> of <strong>{1}</strong> requests to other vehicles. The following requests could not be accommodated. {2}',
  cancelUnresolved: 'Cancel the Unresolved Request',
  cancelRouteAlert: 'Please confirm if you would like to cancel<br/><strong>{0}</strong> for the <strong>{1}</strong>',
  cancelRouteAlertWithOptions:
    'Please confirm if you would like to cancel<br/><strong>{0}</strong> for the <strong>{1}</strong> after selecting an option for the requests on the route.',
  tryTransfering: 'Try transferring the requests to other available vehicles and cancel any unresolved ones',
  cancelAllRequests: 'Cancel all the requests',
  routeCancelled: 'Route Cancelled',
  routeCancelledText: 'The route and the requests on it have been canceled.',
  confirmAssignVehicle:
    'Please confirm if you want to assign vehicle <strong>{0}</strong> in place of <strong>{1}</strong>',
  changeDriver: 'Please confirm if you want to assign driver <strong>{0}</strong> in place of <strong>{1}</strong>.',
  noOrderOrRequests: {
    title: 'Cannot transfer the requests.',
    content: 'No orders or requests found.',
  },
  confirmTransferSingleRequest:
    'Please confirm if you would like to transfer the request by <strong>{riderName}</strong> on <strong>{date}</strong> with a pickup time at <strong>{pickupTime}</strong> from <strong>{routeName}</strong> to other available vehicles.',
  transferSingle: 'Transfer Request',
  cancelSingleRequest:
    'Do you want to cancel the request by <strong>{riderName}</strong> on <strong>{date}</strong> with a pickup time at <strong>{pickupTime}</strong> from <strong>{routeName}</strong>?',
  checkingRequest: 'We’re checking if the requests can be reassigned to other vehicles…',
  requestNotBeAccommodated: 'The request could not be accommodated.',
  reactivateRouteAlert: 'Please confirm if you would like to activate <strong> {0} </strong>',
  routeReactivated: 'Route Reactivated',
  routeReactivatedText: 'The route has been successfully reactivated.',
};
