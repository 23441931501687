export default {
  stationType: 'Station Type',
  createNew: 'Create New',
  regionPermissions: 'Region Permissions',
  serviceAreas: 'Service Areas',
  canSelectMultiple: 'You can select multiple areas',
  activeRegion: 'This region is active',
  coordinates: 'Coordinates',
  dailyPriceCatalog: 'Daily Price Catalog',
  selfPriceCatalog: 'Self Price Catalog',
  minute: 'Minute',
  eligibleNow: 'Eligible Now',
  minuteCatalog: 'Minute Catalog', // :LTODO
  whitelist: 'Whitelist',
  ageBound: 'Age Bound',
  downloadList: 'Download List',
  timeIntervalDeleted: 'Time Interval has been deleted',
  makeTimeSlotDefault: 'Make this time slot default',
  timeSlots: 'Time Slots',
  timeSlotCreated: 'Time Slot has been created',
  timeSlotUpdated: 'Time Slot has been updated',
  forbiddenAreas: 'Forbidden Areas',
  serviceArea: 'Service Area',
  mobileView: 'Mobile View:',
  assignZone: 'Assign zone for {assignZone}',
  selectServiceArea: 'Select Service Area',
  notInBorders: 'This vehicle is not in any borders of station, you can not assign',
};
