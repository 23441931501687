export default {
  userStatus: {
    heading: 'FlexiCarpool/Kullanıcı Durum',
    user: 'Kullanıcı',
    carpoolerSince: 'Carpool Katılım Tarihi',
    currentStatus: 'Durumu',
    address: 'Adres',
    ridersMatch: 'Eşleşilen Yolcular',
    ridersThumbsUp: 'Onaylanan Yolcular',
    ridersThumbsDown: 'Reddedilen Yolcular',
    driversMatch: 'Eşleşilen Sürücüler',
    driversThumbsUp: 'Onaylanan Sürücüler',
    driversThumbsDown: 'Reddedilen Sürücüler',
  },
  approvedMatches: {
    heading: 'flexiCarpool/Onaylanmış Eşleşmeler',
    driver: 'Sürücü',
    driverAddress: 'Sürücü Adresi',
    rider: 'Yolcu',
    riderAddress: 'Yolcu Adresi',
    startDate: 'Başlama Tarihi',
    endDate: 'Bitiş Tarihi',
    totalDistance: 'Toplam Mesafe',
    costPerTrip: 'Yolculuk Başına Maliyet',
    qrReads: 'QR/Kart Okutma',
    totalCost: 'Toplam Tutar',
    incentive: 'Incentive',
    campus: 'Kampüs',
  },
  flexiCarpoolTrips: 'flexiCarpool Seferleri',
  QRAddress: 'QR Adresi',
  QRTime: 'QR Zamanı',
  riderHomeAdress: 'Personel Ev Adresi',
  driverHomeAdress: 'Sürücü Ev Adresi',
  distanceWithRider: 'Personel ile Mesafe ({0})',
  newTrip: 'Yeni Sefer',
  inbound: 'Geliş',
  outbound: 'Gidiş',
};
