export default {
  selectPlate: 'Plaka Seç',
  enterCustomerName: 'Müşteri İsmi Giriniz',
  filter: 'Filtrele',
  removeFilter: 'Filtreleri kaldır',
  requestTime: 'Talep Zamanı',
  deviceIssue: 'Cihaz Sorunu. Lütfen aracı değiştiriniz',
  reservation: 'Rezervasyon',
  pricingStart: 'Ücretlendirme Başlangıç',
  pricingEnd: 'Ücretlendirme Bitiş',
  counterStart: 'Sayaç Başlama Zamanı',
  timePrice: 'Dakika başına süre ücreti',
  kmExceededCost: 'KM başına ek mesafe ücreti',
  vehicleGroup: 'Araç Grubu',
  vehicleDescription: 'Araç Tanımı',
  externalSystemId: 'Dış Sistem No',
  externalReference: 'Dış Sistem Referans',
  parkExit: 'Çıkış Otoparkı',
  finishReason: 'Bitirilme Nedeni',
  relatedRental: 'Bağlı Kiralama',
  sendDocuments: 'Dökümanları email ile gönder',
  addMailAddress: 'Mail adresi ekle',
  requestPoint: 'Talep Noktası',
  origin: 'Orijin',
  previousRental: 'Önceki Kiralama',
  nextRental: 'Sonraki Kiralama',
  firstRental: 'İlk Kiralama',
  vehicleOutOfService: 'Araç Servis Alanı Dışında',
  locatedCity: 'Bulunduğu Şehir',
  rejectReasons: 'İptal Nedenleri',
  drivingScore: 'Sürüş Skoru',
  gsm: 'Cep Telefonu',
  citizenId: 'TCKN',
  birthdate: 'Doğum Tarihi',
  paymentsInfo: 'Ödeme Bilgileri',
  finishRental: 'Kiralamayı Bitir',
  discounts: 'İndirimler',
  referenceInformation: 'Referans Bilgileri',
  photosEndRental: 'Kiralama Sonu Fotoğrafları',
  front: 'Ön',
  back: 'Arka',
  left: 'Sol',
  right: 'Sağ',
  innerFront: 'İç-Ön',
  innerBack: 'İç-Arka',
  directions: 'Yer Tarifi',
  rateOfDiscount: 'İndirim Miktarı',
  currentDiscount: 'Uygulanan indirim yok',
  finishingRental: 'Kiralamayı Bitirme',
  rentalInformation: 'Kiralama Bilgileri Güncellendi',
  requestReceived: 'Talep Alındı',
  rentalStarted: 'Kiralama Başladı',
  started: 'Başladı',
  finished: 'Bitti',
  paymentReceived: 'Ödeme Alındı',
  returned: 'İade yapıldı',
  collectBalance: 'Bakiyeyi tahsil etmek istediğinize emin misiniz ?',
  successfullyPayment: 'Tahsilat başarı ile yapıldı',
  paymentError: 'Tahsilat sırasında bir hata oluştu',
  approximate: 'Tahmini',
  extraCostsAmount: 'Ekstra Ücretler',
  payment: 'Tahsilat',
  selectReasonForChange: 'Değişiklik için bir neden seç!',
  valetCloseCar: 'Vale - Araca daha yakın vale var',
  valetHasDifferentJob: 'Vale - Valenin üzerinde farklı bir iş var',
  valetNotInShift: 'Vale - Mesaide Değil',
  valetApplicationError: 'Vale - Uygulama Hatası',
  vehicleDoorsNotOpen: 'Araç - Kapılar Açılmadı',
  dirty: 'Araç - Kirli',
  vehicleDamaged: 'Araç Arızalı / Hasarlı',
  vehicleParkProblem: 'Araç - OtoPark Sorunu',
  notAuthority: 'Bu işlem için yetkiniz yok',
  jobChanged: 'Görevi başarıyla değiştirildi',
  jobNotChanged: 'Görev değiştirilemedi',
  updateRental: 'Kiralama Güncelle',
  finish: 'Bitir',
  ending: 'Bitiş',
  finishWithNoCharge: 'Ücretsiz Bitir',
  noChargeFinishReason: 'Ücretsiz Bitirme Nedeni',
  NOT_STARTED: 'Başlamadı',
  PREPARING_VEHICLE: 'Araç hazırlanıyor',
  FINISHED: 'Tamamlandı',
  CANCELED: 'İptal',
  WAITING_RENTAL_START: 'Kiralamanın Başlaması Bekleniyor',
  VEHICLE_ON_DELIVERY: 'Araç Müşteriye Götürülüyor',
  RENTAL_IN_PROGRESS: 'Aktif Kiralama',
  WAITING_CUSTOMER_TO_VEHICLE: 'Müşteri Araca Bekleniyor',
  WAITING_PAYMENT: 'Ödeme Bekleniyor',
  score: {
    accScore: 'Hızlanma',
    decScore: 'Yavaşlama',
    idlingScore: 'Rölanti',
    speedScore: 'Sürat',
    corneringScore: 'Dönüş',
    overallScore: 'Ortalama',
  },
  cancel: 'İptal Et',
  reservationWillDelete: 'Bu rezervasyon silinecek.',
  reservationDeleted: 'Rezervasyon silindi',
  durationPackage: 'Süre Paketi',
  stationType: 'İstasyon Tipi',
  startZone: 'Giriş İstasyon',
  endZone: 'Çıkış İstasyon',
  rentalId: 'Kiralama ID',
  eventCategory: {
    DELIVERY: 'Bildirim',
    PAYMENT: 'Ödeme',
    RENTAL: 'Kiralama',
    REQUEST: 'Talep',
    SYSTEM: 'Sistem',
    REFUND: 'Geri ödeme',
    DRIVER: 'Sürücü',
  },
  removeCategoryFilter: 'Kategori Filtrelerini Kaldır',
  fromPastToPresent: 'Geçmişten Şimdiye',
  fromPresentToPast: 'Şimdiden Geçmişe',
  rentalFinished: 'Kiralama Bitti',
  durationPackages: 'Süre Paketleri',
  packageUpdated: 'Paket Güncellendi.',
  createdPackage: 'Paket Oluşturuldu',
  customerStatus: 'Müşteri Durumu',
  requiredCreditCard: '{required} için kredi kartı zorunluluğu',
  rentals: {
    NOT_STARTED: 'Başlamadı',
    PREPARING_VEHICLE: 'Araç hazırlanıyor',
    FINISHED: 'Tamamlandı',
    CANCELED: 'İptal',
    WAITING_RENTAL_START: 'Kiralamanın Başlaması Bekleniyor',
    VEHICLE_ON_DELIVERY: 'Araç Müşteriye Götürülüyor',
    RENTAL_IN_PROGRESS: 'Aktif Kiralama',
    WAITING_CUSTOMER_TO_VEHICLE: 'Müşteri Araca Bekleniyor',
    WAITING_PAYMENT: 'Ödeme Bekleniyor',
    WAITING_RENTAL_END: 'Kiralama Bitirme Bekleniyor',
    score: {
      accScore: 'Hızlanma',
      decScore: 'Yavaşlama',
      idlingScore: 'Rölanti',
      speedScore: 'Sürat',
      corneringScore: 'Dönüş',
      overallScore: 'Ortalama',
    },
  },
  kmLimit: 'Km/Limit',
  ratings: {
    OVERALL: 'Genel',
    CLEANLINESS: 'Temizlik',
  },
  limitOfKm: 'Km Limiti',
  code: 'Kodu',
  events: {
    assignVehicle: 'Araç Rezerve',
    provision_paid: 'Provizyon Ödeme',
    newRental: 'Kiralama Talebi',
    preparingVehicle: 'Araç Hazırlanıyor',
    deliveryRouteStarted: 'Teslimat Başladı',
    deliveredToCustomer: 'Teslimat',
    lateRentalStart: 'Kiralama Başladı',
    arrivedToCustomerLocation: 'Teslimat Noktasına Ulaşıldı',
    assignVehicleLog: 'Araç Sistem Kaydı',
    assignVehicleLogV2: 'Araç Sistem Kaydı',
    valetWasNotAvailable: 'Vale Görevdeyken Kiralama',
    endRentalDoorStatusLog: 'Araç Sistem Kaydı. Kapı Durumu',
    changeDeliveryJob: 'Sistem Kaydı: Vale ve/veya Araç değişti',
    cancelRentalByUser: 'Kullanıcı tarafından iptal edildi.',
    directPaymentSuccess: 'Para çekimi başarılı',
    directPaymentFailure: 'Para çekimi denemesi başarısız',
    provisionPaymentSuccess: 'Provizyondan ödeme alındı',
    provisionPaymentFailure: 'Provizyondan ödeme alma başarısız',
    refundSuccess: 'İade İşlemi Başarılı',
    refundFailure: 'İade İşlemi Yapılamadı',
    startRentalAddress: 'Kiralama Başlangıç Adresi',
    endRentalAddress: 'Kiralama Bitiş Adresi',
    valetCallNotification: 'Vale Arandı',
    valetAcceptedJob: 'Vale görevi kabul etti.',
    cancelContract: 'Sözleşme İptal edildi.',
    cancelContractFail: 'Sözleşme İptali başarısız',
    createContract: 'Sözleşme Oluşturuldu.',
    updateTotalCost: 'Toplam Ücret Güncellendi',
    updateOdometer: 'Odometre Güncellendi',
    updateStartDate: 'Başlangıç Tarihi Güncellendi',
    updateEndDate: 'Bitiş Tarihi Güncellendi',
    updateVehicleStatus: 'Araç Durumu Güncellendi',
    updateNoCharge: 'Ücretsiz duruma getirildi',
    rentFromStreet: 'Talep otopark dışından karşılandı',
    vehicleLeftServiceArea: 'Araç Servis Alanı Dışına çıktı.',
    vehicleEnteredServiceArea: 'Araç Servis Alanına girdi.',
    adressWhenVehicleLeftServiceArea: 'Servis alanından çıkılan adres',
    adressWhenVehicleEnteredServiceArea: 'Servis alanına girilen adres',
    hgsInvoice: 'HGS Fatura',
    newRentalSelfService: 'Kiralama Talebi (Self Servis)',
    newRentalStation: 'Kiralama Talebi (İstasyon\'dan Kiralama)',
    cancelSelfService: 'Sistem Self Servis İptal',
    devicePassiveStateDetected: 'Cihaz Pasif Durumda',
    sendResetForNotActive: 'Otomatik Cihaz Kontrol',
    sendKabisDataToCmc: 'Kabis verileri gönderildi',
    sendKabisDataToRobot: 'Kabis verileri gönderildi (Robot)',
    vehicleHasExistingTask: 'Aracın üstünde önceden görev var.',
    airportRentalStart: 'Havaalanında Kiralama Başlangıcı',
    airportRentalEnd: 'Havaalanında Kiralama Bitişi',
    finishedByAdmin: 'Admin tarafından sonlandırıldı',
    finishedByRentalChange: 'Kiralama değişikliği nedeni ile sonlandırıldı',
    startedByRentalChange: 'Kiralama değişikliği nedeni ile başlatıldı',
    provisionVoided: 'Bloke Kaldırıldı',
    rentalChangedToCorporate: 'Kurumsal Kiralamaya Geçiş',
    rentalChangedToPersonal: 'Bireysel Kiralamaya Geçiş',
    infoTiktakWeekendCostNotification: 'Hafta Sonu Fiyat Bilgilendirme',
    infoNigtlyCostNotification: 'Gece Fiyat Bilgilendirme',
    assignDiscount: 'İndirim Uygulandı',
    removeDiscount: 'İndirim İptal Edildi',
    unsuccessfulPayment: 'Ara ödeme başarısız',
    successfulPayment: 'Ara ödeme alındı',
    successfullSynchronized: 'Senkronizasyon başarı ile tamamlandı',
    rentalDistanceLimitExceedPush: 'Kiralama km\'si limit aşımı',
  },
  valetCompletedJob: 'Vale görevini tamamladı',
  traveledKm: 'Yapılan Km',
  extraFees: 'Ekstra Ücretler',
  pricingModel: 'Fiyatlandırma Modeli',
  earlyRentalEnd: 'Erken Kiralama Bitirme',
  noReasonStated: 'Sebep Belirtilmemiş.',
  rentalUsedMinute: 'Kullanılan Dakika Hakkı',
  baseDiscountRatio: 'Aşım olması durumunda ki normal dk\'lık ücrete uygulanacak indirim oranı',
  relatedRentals: 'Bağlı Kiralamalar',
  speedAnalysis: 'Sürüş Analizi',
  measuredSpeed: 'Hız',
  modelledSpeed: 'Hesaplanmış Hız',
  anomaly: 'Anomali',
  speedAnalysisChart: 'Sürüş Analiz Grafiği',
  doorOpenDate: 'Kapı Açılma Zamanı',
  analysisCount: 'Hız Verisi Sayısı',
  anomalyCount: 'Anomali Sayısı',
  anomalyCountInLast5Min: 'Son 5 dk\'da ki Anomali Sayısı',
  rentalInfoWillSend: 'Kiralama bilgileri {0} adresine gönderilecektir.',
  speedExceedScore: '130 Km/h Üstü Hız İhlali',
  corneringExceedScore: 'Ani Dönme',
  decelerationExceedScore: 'Ani Yavaşlama',
  accelerationExceedScore: 'Ani Hızlanma',
  overallScore: 'Ortalama Sürüş Puanı',
  saveScore: 'Skoru Kaydet',
  calculateScore: 'Skoru Hesapla',
  accidentRiskScore: 'Kaza İhtimali',
  scoringParamaterManagement: 'Skorlama Parametre Yönetimi',
  routeInfo: 'Rota Bilgileri',
  routePackageOffer: {
    routeMinute: 'Hesaplanan rota süresi',
    routeKm: 'Hesaplanan rota mesafesi',
    durationPrice: 'Hesaplanan rota tutarı',
    durationBufferInMinute: 'Rota için tanımlanan ek süre(buffer)',
    distanceBufferInKm: 'Rota için tanımlanan ek mesafe',
    basePrice: 'Hesaplanan taksi tutarı',
    discountRatio: 'Taksiye göre indirim oranı',
    routePackageOfferKm: 'Rota Mesafesi',
    routePackageOfferMinute: 'Rota Süresi',
    routePackageOfferTotalPrice: 'Rota Tutarı',
  },
  packageStartHour: 'Paket Başlatma Saati',
  packageEndHour: 'Paket Bitiş Saati',
  slotBasedRentalInfo: 'Paket Kiralama Bilgileri',
  slotBasedRentalPrice: 'Paket Kiralama Ücreti',
  packageFreeWaitingDurationInMinute: 'Ücretsiz Bekleme Süresi',
  packageBufferDurationInMinute: 'Paket En Geç Başlama Süresi',
  packagePostDurationInMinute: 'Paketi En Geç Bitirme Süresi',
  packagePreDurationInMinute: ' Paket En Erken Başlama Süresi',
};
