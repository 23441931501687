export default {
  invoiceDeliveryRule: 'Fatura Gönderim Kuralı',
  instantlyInvoice: 'Anlık Fatura Kesimi',
  monthlyInvoice: 'Aylık Fatura Kesimi',
  invoiceDeliveringType: 'Fatura gönderim türü',
  savedAs: 'olarak kaydedildi',
  successful: 'Başarılı',
  error: 'Hata',
  savedEmailBefore: 'Bu email ile önceden bir kullanıcı kaydedilmiş',
  creditCardForCompany: 'şirketi için kredi kartı',
  mandatory: 'zorunlu',
  notMandatory: 'zorunlu değil',
  updated: ' olarak güncellendi',
  companysMinLimit: 'şirketi minimum limiti',
  credit: 'alacak',
  debt: 'borç',
  package: 'paketi',
  beReflectedAs: 'olarak yansıtıldı',
  companysDiscountRate: 'şirketi indirim oranı',
  updatedInfos: 'bilgileri güncellendi',
  addedEnterpriceManaged: 'Kurumsal Yönetici Eklendi',
  updateCompany: 'Şirketi güncelle',
  minLimit: 'Minimum Limit',
  creditCardRequirement: 'Kredi Kart Mecburiyeti',
  financialTransaction: 'Finansal İşlem',
  updateDiscountRate: 'İndirim Oranı Güncelle',
  addPackage: 'Paket Ekle',
  addManager: 'Yönetici ekle',
  totalUser: 'Toplam Kullanıcı',
  systemUser: 'Sistem Kullanıcı',
  mobileUser: 'Mobil Kullanıcı',
  createdTime: 'Oluşturulma Zamanı',
  addedToCompany: 'şirketine eklendi',
  companyUpdated: 'Şirket Güncellendi',
  discountRate: 'İndirim Oranı (%)',
  newCompany: 'Yeni Şirket',
  MONTHLY: 'aylık',
  INSTANTLY: 'anlık',
  balance: 'Bakiye',
  activeRental: 'Aktif Kiralama',
  taxNumber: 'Vergi No',
  discountRatio: 'İndirim Oranı',
  last7Days: 'Son 7 Gün',
  normalBalance: 'Normal Bakiye',
  directPayment: '{0} şirketinden para çekimi.',
  maximumAmountWithdraw: 'Çekilebilecek Maksimum Miktar',
  createdBy: 'İşlemi Yapan',
  corparateMembership: 'Kurumsal Üyelik',
  applicationMembership: 'Uygulama Üyelik',
  refundSuccess: 'İade İşlemi Başarılı',
  refundAmount: 'Miktar(iade)',
  firmName: 'Firma Adı',
  firmType: 'Firma Tipi',
  taxOffice: 'Vergi Dairesi',
  accountPointCategory: 'Hesap/Puan Kategorisi',
  mainTransactionCategory: 'Ana İşlem Kategorisi',
  selectTransactionType: 'İşlem Tipi Seç',
  subTransactionCategory: 'Alt İşlem Kategorisi',
  vatRate: 'KDV Oranı',
  selectVatRate: 'Kdv Oranı Seçin',
  addDebtToCustomer: 'Müşteriye Borç Ekle',
  addToCustomerAccount: 'Müşteri Hesabına Ekle',
  invoiceWillCreate: 'Bu işlem için fatura oluşturulacak',
  firmOfficial: 'Firma Yetkilisi',
  lastLoginDate: 'Son Oturum Açma Tarihi',
  resetUserPassword: 'Kullanıcı Şifresini Sıfırla',
  updateInformations: 'Bilgileri Güncelle',
  inactivateUser: 'Kullanıcıyı Pasife Al',
  activateUser: 'Kullancıyı Aktife Al',
  userActivated: 'Kullanıcı Aktife Alındı',
  userInactivated: 'Kullanıcı Pasife Alındı',
  sentMessageToUser: '{0} {1} kullanıcısına SMS ile şifre gönderildi.',
  changePark: 'Otopark Değiştir',
  currentPark: 'Mevcut Otopark',
  parkChanged: 'Otopark Değiştirildi.',
  isSmsSent: '{0} kullanıcısına SMS ile şifre gönderilsin mi ?',
  moneyWithdrawn: 'Para Çekildi',
  discount: 'Düzeltme / İndirim',
  paymentTypes: {
    DIRECT_PAYMENT: 'Ödeme',
    PROVISION: 'Ön Provizyon',
    PROVISION_PAYMENT: 'Provizyondan Ödeme',
    REFUND: 'İade',
    THREE_D_PAYMENT: '3D Ödeme',
    VOID: 'Provizyon İade',
    PAYMENT_VOID: 'İptal',
    RECURRING_PAYMENT: 'Yinelenen Ödeme',
  },
  selectDistrict: 'Semt Seçiniz',
  selectCity: 'Şehir Seçiniz',
  lowerDiscountRatio: 'indirim oranı {ratio}',
  invoiceDelivery: 'Fatura Gönderim',
  hgsDelivery: 'Hgs Fatura Gönderim',
  filteredRentals: 'Seçilen başlangıç tarihinden itibaren maksimum 2 aylık kiralamalar getirilir.',
};
