export default {
  workgroup: 'Rota Çalışma Grubu',
  addPersonnel: 'Personel Ekle',
  makeRouting: 'Optimizasyon Çalıştır',
  downloadExcelTemplate: 'Excel Şablonunu İndir',
  dragFile: 'Dosyayı buraya sürükle veya',
  clickBrowse: 'göz atmak için tıklayınız',
  tableHeader: {
    nameSurname: 'Ad Soyad',
    email: 'E-posta',
    allSections: 'Tüm Bölümler',
    route: 'Rota',
    station: 'Durak',
    currentGroup: 'Mevcut Grup',
    fromWhere: 'Nereden',
    toWhere: 'Nereye',
    searchPersonnel: 'Personel Ara',
    campus: 'Kampüs',
    allCampus: 'Tüm Yerleşkeler',
    allGroups: 'Tüm Gruplar',
    departments: 'Bölümler',
    vanpoolChoice: 'Vanpool Tercihi',
    vanpoolDriverChoice: 'Sürücü Olma Tercihi',
    travelTime: 'Seyahat Süresi (dk)',
    timeCoefficient: 'Konfor Skoru',
  },
  serviceStops: 'Servis Durakları',
  creationDate: 'Oluşturulma Tarihi',
  creator: 'Oluşturan',
  accountingCenter: 'Sorumlu Merkez',
  startEnd: 'Başlangıç-Bitiş',
  openToDemand: 'Talebe açık',
  description: 'Açıklama',
  downloadExcelDescription:
    'Taslak excel dosyasını indirip gruba eklemek istediğiniz kişilerin bilgisini girerek geri yükleyebilirsiniz.',
  personnelSelected: 'personel seçildi',
  deleteSelected: 'Seçilenleri Sil',
  noPersonnel: 'Henüz gruba personel eklenmedi.',
  selectCampus: 'Yerleşke Seçiniz',
  addSelectedPersonnel: 'Seçilenleri Gruba Ekle',
  selectedPersonnel: 'Seçilen Personel',
  createPersonnelList: 'Aşağıdaki personel listesinden seçerek bir liste oluşturun',
  personnel: 'personel seçildi.',
  personnelNotFound: 'Personel bulunamadı',
  excludedDrivers: 'Tercih Edilmeyen Sürücüler',
  showNearbyShuttleStops: 'Yakındaki Servis Durağını Göster',
  assignRiderThisStop: 'Personeli Bu Durağa Atayın',
  availability: 'Doluluk',
  thereAreNoNearbyStations: 'Yakın durak bulunamadı',
  showingStopsFor: 'Durakları Göster',
  stopAssignmentSuccessful: 'Durak Ataması Başarılı',
  none: 'Bulunmuyor',
  riderTypicallyUsesThisStop: 'Kullanıcı bu durağı kullanmaktadır.',
};
