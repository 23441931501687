export default {
  invoiceDeliveryRule: 'Invoice Delivery Rule',
  instantlyInvoice: 'Instantly Invoice',
  monthlyInvoice: 'Monthly Invoice',
  invoiceDeliveringType: 'Invoice Delivering Type',
  savedAs: 'saved as',
  successful: 'successful',
  error: 'Error',
  savedEmailBefore: 'A user has already been registered with this email',
  creditCardForCompany: 'credit card for company',
  mandatory: 'mandatory',
  notMandatory: 'not mandatory',
  updated: 'updated',
  companysMinLimit: 'company Minumum Limit',
  credit: 'money owed to one',
  debt: 'debt',
  package: 'package',
  beReflectedAs: 'be reflected',
  companysDiscountRate: 'company discount rate',
  updatedInfos: 'updated',
  addedEnterpriceManaged: 'Added Enterprice Managed',
  updateCompany: 'Update Company',
  minLimit: 'Minimum Limit',
  creditCardRequirement: 'Credit Card Requirement',
  financialTransaction: 'Financial Transaction',
  updateDiscountRate: 'Update Discount Rate',
  addPackage: 'Add Package',
  addManager: 'Add Manager',
  totalUser: 'Total User',
  systemUser: 'System User',
  mobileUser: 'Mobile User',
  createdTime: 'Created Time',
  addedToCompany: 'added to company',
  companyUpdated: 'Company Updated',
  discountRate: 'Discount Rate (%)',
  newCompany: 'New Company',
  refresh: 'Refresh',
  MONTHLY: 'monthly',
  INSTANTLY: 'instantly',
  balance: 'Balance',
  activeRental: 'Active Rental',
  taxNumber: 'Tax Number',
  discountRatio: 'Discount Ratio',
  last7Days: 'Last 7 Days',
  normalBalance: 'Normal Balance',
  directPayment: 'Direct payment from {0} company.',
  maximumAmountWithdraw: 'Maximum Amount that can be withdrawn',
  createdBy: 'Created By',
  corparateMembership: 'Corparate Membership',
  applicationMembership: 'Application Membership',
  refundSuccess: 'Refund success',
  refundAmount: 'Amount(refund)',
  firmName: 'Firm Name',
  firmType: 'Firm Type',
  taxOffice: 'Tax Office',
  accountPointCategory: 'Account/Point Category',
  mainTransactionCategory: 'Main Transaction Category',
  selectTransactionType: 'Select Transaction Type',
  subTransactionCategory: 'Sub Transaction Category',
  vatRate: 'Vat Rate',
  selectVatRate: 'Select Vat Rate',
  addDebtToCustomer: 'Add Debt To Customer',
  addToCustomerAccount: 'Add To Customer Account',
  invoiceWillCreate: 'An invoice will be created for this transaction',
  firmOfficial: 'Firm Official',
  lastLoginDate: 'Last Login Date',
  resetUserPassword: 'Reset User Password',
  updateInformations: 'Update Informations',
  inactivateUser: 'Inactivate User',
  activateUser: 'Activate User',
  userActivated: 'User has been activated',
  userInactivated: 'User has been inactivated',
  sentMessageToUser: 'Message has been sent to {0} {1} with SMS.',
  changePark: 'Change Park',
  currentPark: 'Current Park',
  parkChanged: 'Park has been changed.',
  moneyWithdrawn: 'Money has been withdrawn',
  discount: 'Discount',
  paymentTypes: {
    DIRECT_PAYMENT: 'Direct Payment',
    PROVISION: 'Provision',
    PROVISION_PAYMENT: 'Provision Payment',
    REFUND: 'Refund',
    THREE_D_PAYMENT: '3D Payment',
    VOID: 'Void',
    PAYMENT_VOID: 'Payment Void',
    RECURRING_PAYMENT: 'Recurring Payments',
  },
  selectDistrict: 'Select District',
  selectCity: 'Select City',
  lowerDiscountRatio: 'Discount ratio for {ratio}',
  invoiceDelivery: 'Invoice Delivery',
  hgsDelivery: 'Hgs Invoice Delivery',
  filteredRentals: 'You can see only maximum 2 months rentals from the selected start date.',
};
