export default {
  users: 'Users',
  user: 'User',
  add: 'Add',
  send: 'Send',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  save: 'Save',
  customers: 'Customers',
  date: 'Date',
  message: 'Message',
  rentals: 'Rentals',
  hours: 'hour',
  minute: 'minute',
  Minute: 'Minute',
  total: 'Total',
  campaigns: 'Campaigns',
  vehicle: 'Vehicle',
  customer: 'Customer',
  status: 'Status',
  start: 'Start',
  end: 'End',
  startAddress: 'Start Address',
  endAddress: 'End Address',
  startTime: 'Start Time',
  endTime: 'End Time',
  type: 'Type',
  region: 'Region',
  company: 'Company',
  durationMin: 'Duration (min)',
  price: 'Price',
  balance: 'Balance',
  campaign: 'Campaign',
  city: 'City',
  general: 'General',
  valet: 'Valet',
  corporate: 'Corporate',
  duration: 'Duration',
  email: 'E-mail',
  rental: 'Rental',
  amount: 'Amount',
  time: 'Time',
  description: 'Description',
  apply: 'Apply',
  discount: 'Discount',
  name: 'Name',
  totalAmount: 'Total Amount',
  plate: 'Plate',
  color: 'Color',
  fuel: 'Fuel (l.)',
  brand: 'Brand',
  address: 'Address',
  continent: 'Continent',
  park: 'Park',
  info: 'Info',
  imeiNumber: 'IMEI No',
  hgsNumber: 'HGS No',
  download: 'Download',
  surname: 'Surname',
  mobil: 'Mobile',
  role: 'Role',
  select: 'Select',
  gsmNo: 'Gsm No',
  job: 'Job',
  distance: 'Distance',
  comment: 'Comment',
  destination: 'Destination',
  definition: 'Definition',
  note: 'Note',
  createdTime: 'Created Time',
  acceptanceDate: 'Acceptance Date',
  id: 'Id',
  state: 'State',
  idNo: 'Id No',
  foreign: 'Foreign',
  phone: 'Phone',
  nationality: 'Nationality',
  citizenId: 'Citizen Id',
  birthdate: 'Birtdate',
  gender: 'Gender',
  profession: 'Profession',
  device: 'Device',
  confirm: 'Are you sure ?',
  confirmCancel: 'Are you sure you want to cancel the membership?',
  update: 'Update',
  edit: 'Edit',
  back: 'Back',
  startDate: 'Start Date',
  endDate: 'End Date',
  pleaseSelect: 'Please Select',
  version: 'Version',
  bill: 'Bill',
  delete: 'Delete',
  incident: 'Incident',
  change: 'Change',
  percent: 'Percent',
  upload: 'Upload',
  announcement: 'Announcement',
  english: 'English',
  turkish: 'Turkish',
  passive: 'Passive',
  activate: 'Activate',
  model: 'Model',
  detail: 'Detail',
  gear: 'Gear',
  year: 'Year',
  preview: 'Preview',
  approve: 'Approve',
  reject: 'Reject',
  all: 'All',
  break: 'Break',
  onBreak: 'on break',
  available: 'Available',
  search: 'Search',
  piece: 'Piece',
  application: 'Application',
  category: 'Category',
  rate: 'Rate',
  provision: 'Provision',
  age: 'Age',
  open: 'Open',
  close: 'Close',
  customerStatus: 'Customer Status',
  followUp: 'Follow Up',
  verify: 'Verify',
  refresh: 'Refresh',
  card: 'Card',
  payments: 'Payments',
  admins: 'Admins',
  creditCards: 'Credit Cards',
  accountActivities: 'Account Activities',
  members: 'Members',
  addresses: 'Addresses',
  mobileNumber: 'Mobile Number',
  reason: 'Reason',
  days: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  required: 'Required',
  notRequired: 'Not Required',
  updating: 'Updating',
  district: 'District',
  firm: 'Firm',
  active: 'Active',
  catalog: 'Catalog',
  package: 'Package',
  individual: 'Individual',
  right: 'Right',
  file: 'File',
  default: 'Default',
  other: 'Other',
  homeAddress: 'Home Address',
  workAddress: 'Work Address',
  male: 'Male',
  woman: 'Woman',
  membership: 'Membership',
  document: 'Document',
  person: 'Person',
  new: 'New',
  sender: 'Sender',
  receipt: 'Receipt',
  notReceipt: 'Not Receipt',
  or: 'or',
  countryCode: 'Country Code',
  turkey: 'Turkey',
  rentalType: 'Rental Type',
  addNew: 'Add New',
  create: 'Create',
  low: 'Low',
  tooLow: 'Too Low',
  normal: 'Normal',
  percentage: 'Percentage',
  bankReceipt: 'Bank Receipt',
  excelTypes: {
    drivingLicense: 'Driving License',
    gender: 'Gender',
    selfie: 'Selfie',
  },
  addEmail: 'Add E-mail',
  addAsNewTag: 'Add this as new tag',
  weekday: 'Weekday',
  weekend: 'Weekend',
  reset: 'Reset',
  order: 'Order',
  activeness: 'Activeness',
  undo: 'Undo',
  changePassword: 'Change Password',
  logout: 'Logout',
  title: 'Title',
  loading: 'Loading...',
  permission: 'Permission',
  selectUploadFile: 'Select the file to upload',
  task: 'Task',
  day: 'day',
  min: 'min',
  lastLocation: 'Last Location',
  off: 'Off',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  km: 'Km',
  enterFilterCriteria: 'Enter Filter Criteria',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  enterOldPasswordAgain: 'Enter old password again',
  eligible: 'Eligible',
  notEligible: 'Not Eligible',
  passwordChangedSuccessfully: 'Password Changed Successfully',
  enterNewPassword: 'Enter new password',
  upperCase: '- Your password must contain at least 1 uppercase letter',
  requiredCharacters: '- Password does not contain required characters',
  specialCharacter: '- Your password must contain at least 1 special character (.,!@#?) letter',
  number: '- Your password must contain at least 1 number',
  lowerCase: '- Your password must contain at least 1 lowercase letter',
  minMaxLength: '- The password must contain min 8 and maximum 50 characters.',
  notMatchPassword: 'New password does not match with repeated password',
  oldPasswordWrong: 'Old password is wrong',
  selected: 'Selected',
  mobile: 'Mobile',
  lastCleaningDate: 'Last Cleaning Date',
  photo: 'Photo',
  rentalTypes: {
    VALET_SERVICE: 'Valet Service',
    SELF_SERVICE: 'Self Service',
    STATION_BASED: 'Station Based',
    POOL: 'Pool',
    DAILY: 'Daily',
    SUBSCRIPTION: 'Subscription',
    DAILY_RESERVATION: 'Daily Reservation',
    SLOT_BASED: 'Slot Based',
  },
  reasons3d: {
    START_OF_RENTAL: 'Start of Rental',
    DEBT_PAYMENT: 'Debt Payment',
    SUBSCRIPTION_PURCHASE: 'Subscription Purchase',
    CARD_VALIDATION: 'Card Validation',
    DEVICE_VALIDATION: 'Device Validation',
    KM_PACKAGE: 'KM Package',
    UNLOCK_DEVICE_LOGIN_PROBLEM: 'Unlock Device Login Problem',
    UNLOCK_DEVICE_RENTAL_PROBLEM: 'Unlock Device Rental Problem',
    UNLOCK_DEVICE_LOCATION_CHANGE_PROBLEM: 'Unlock Device Location Change Problem',
    PREPAID_OFFER_PURCHASE: 'Prepaid Offer Purchase',
    CREATE_OF_DAILY_RESERVATION: 'Create of Daily Reservation',
    EXTEND_DAILY_RENTAL: 'Extend Daily Rental',
  },
  remove: 'Remove',
  nameSurname: 'Name',
  value: 'Value',
  clickToChange: '{click} to change the settings',
  click: 'Click',
  identificationNumber: 'Identification Number',
  option: 'Option',
  key: 'Key',
  clickToSelectType: 'Click to select a type',
  pressEnterToAdd: 'Press Enter to Add',
  clickEnterToAdd: 'Click to add',
  clickEnterToRemove: 'Click to remove',
  downloadCurrentList: 'Download Current List',
  avgKm: 'Avg Km',
  details: 'Details',
  minDiscountRatio: 'Minimum Discount Ratio',
  maxDiscountRatio: 'Maximum Discount Ratio',
  dayOfWeek: 'Day of Week',
};
