import axios from 'axios';

// TODO: Refactor this to accept an optional token

function client(options = {}) {
  const token = localStorage.getItem('_token') || sessionStorage.getItem('_token');
  const headers = { 'vektor-token': token };
  let defaultOptions = {
    responseType: 'json',
    timeout: 60000,
  };

  defaultOptions = Object.assign(defaultOptions, options);
  if (token) {
    defaultOptions.headers = {};
    defaultOptions.headers = Object.assign(defaultOptions.headers, headers);
  }

  const instance = axios.create(defaultOptions);

  // TODO: this is messy and prone to errors
  // error redirection should be moved to http wrapper class in client.js
  instance.interceptors.response.use((response) => response, (error) => {
    // Do something with response error
    if (error.response && error.response.status === 403) {
      window.location.assign(`${window.location.origin}${process.env.BASE_URL}`);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  });

  return instance;
}

export default client;
