export default {
  today: 'Today',
  yesterday: 'Yesterday',
  thisYear: 'This Year',
  thisMonth: 'This Month',
  nextMonth: 'Next Month',
  nextWeek: 'Next Week',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastMonth: 'Last Month',
  thisWeek: 'This Week',
  customDate: 'Custom Date',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',

};
