export default {
  successfullSynchronized: 'Successfull synchoronized',
  userUpdated: 'User has been updated',
  userCreated: 'User has been created',
  successful: 'Successful',
  successfulRefund: 'Refund is successful',
  updated: 'It has been updated',
  dataUpdated: 'Data has been updated',
  fileUploaded: 'File has been uploaded',
  fileSendApprove: 'File has been send to approve',
  sendNotifications: 'File has been uploaded. Notifications will be send.',
  valuesUpdated: 'Values has been updated.',
  changingActivePassive: 'Changing active / passive has been made.',
};
