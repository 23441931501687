export default {
  today: 'Bugün',
  yesterday: 'Dün',
  thisYear: 'Bu Yıl',
  thisMonth: 'Bu Ay',
  nextMonth: 'Gelecek Ay',
  nextWeek: 'Gelecek Hafta',
  lastSevenDays: 'Son 7 Gün',
  lastThirtyDays: 'Son 30 Gün',
  lastMonth: 'Geçen Ay',
  thisWeek: 'Bu Hafta',
  customDate: 'Özel Tarih',
  daily: 'Günlük',
  weekly: 'Haftalık',
  monthly: 'Aylık',
  monday: 'Pzt',
  tuesday: 'Sal',
  wednesday: 'Çar',
  thursday: 'Per',
  friday: 'Cum',
  saturday: 'Cmt',
  sunday: 'Paz',
};
