export default {
  unknown: 'Beklenmeyen bir hata oluştu',
  errorOccured: 'Bir hata oluştu',
  recordNotChanged: 'Kayıt Değiştirilemedi',
  error: 'Hata',
  errorUploadingFiles: 'Dosya yüklemede hata',
  notUploadedFile: 'Dosya Yüklenemedi.',
  transferError: 'Hata. Aktarılamadı',
  recordCountError: '{0} kayıttan daha fazlası Excel olarak indirilemez. Lütfen Vektör ile iletişime geçin.',
  unknownError: 'Bilinmeyen hata',
  errorOccuredDuringUpdate: 'Güncelleme sırasında bir hata oluştu.',
};
