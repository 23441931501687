export const tr = {
  general: {
    save: 'Kaydet',
    update: 'Güncelle',
    cancel: 'İptal',
    create: 'Yarat',
    station: 'Durak',
    home: 'Ev',
    cost: 'Maliyet',
    arrival: 'Geliş',
    departure: 'Ayrılış',
    minute: 'dk.',
    minuteLong: 'Dakika',
    clickToFocus: 'Ayrıntılar için Tıkla',
    changes: 'Optimizaston tanımında değişklikler var. Devreye almak için kaydetmelisin',
    delete: 'Sil',
    showAdvanced: 'İleri Seçenekleri Göster',
    hideAdvanced: 'İleri Seçenekleri Gizle',
  },
  desc: {
    header: 'Optimizasyon Tanımı',
    workbookName: 'Çalışma İsmi',
    workbookDesc: 'Çalışma Tanımı',
    firstDeparture: 'İlk Kalkış',
    lastArrival: 'Son Varış',
    maxWalking: 'Maks. Yürüme Mesafesi',
    maxOccupancy: 'Araç Maks. Doluluk Oranı',
    walkDistanceCheck: 'Maks. Yürüme Mesafesi Kontrolü',
    tooltipEdit: 'Değiştir',
  },
  vehicles: {
    types: 'Araç Türleri',
    name: 'İsim',
    capacity: 'Kapasite',
    maxOccupancy: 'Maks. Doluluk',
    costKm: 'Maliyet(KM)',
    costMin: 'Maliyet(Dk)',
    costFixed: 'Sabit Maliyet',
    checkboxText: 'Optimizasyonu sınırlı araca göre çalıştır',
    numberOfVehiclesInput: 'Araç Sayısı',
  },
  tasks: {
    tasks: 'Görevler',
    name: 'Görev Adı',
    address: 'Ev Adresi',
    stationType: 'Durak Türü',
    tooltipOn: 'Görevleri Sakla',
    tooltipOff: 'Görevleri Göster',
  },
  actions: {
    saveDesc: 'Optimizasyon Tanımını Kaydet',
    refresh: 'Yenile',
    start: 'Çalıştır',
    stop: 'Durdur',
    assignMissing: 'Durakları olmayanları en yakın duraklara ata',
    assignToVirtualStops: 'Ana artere göre duraklama yap',
    assignToClosestStops: 'Personelin evine yakın adreslere duraklama yap',
  },
  summary: {
    iterationPercent: 'İşlem Durumu',
    status: 'Optimizasyon Durumu:',
    working: 'çalışıyor',
    notWorking: 'çalışmıyor',
    totalCost: 'Toplam Maliyet',
    found: 'Bulanan Çözüm Sayısı',
    runningTime: 'Optimizasyon Süresi',
    plannedTotal: 'Planlanan/Toplam Görev Sayısı',
    initialization: 'İlk Çalıştırma',
    routePickupSize: 'Rota Sayısı/ Personel Sayısı',
    totalKm: 'Toplam Km.',
    totalTime: 'Toplam Süre',
    minMaxKm: 'Min./Maks. Km',
    minMaxTime: 'Min./Maks. Süre',
  },
  solution: {
    mapFilters: 'Harita Filtreleri',
    filterHomes: 'Evler',
    filterStations: 'Duraklar',
    routeEditable: 'Rota Değiştirilebilir',
    routeNotEditable: 'Rota Değiştirilemez',
    routeOnly: 'Sadece Rota',
    selectRoute: 'Görüntülenecek Bir rota seç',
    stationNo: 'Durak No',
    selectedStaff: 'Seçili Personel',
    walkingDist: 'Durağa Yürüme Mesafesi',
    address: 'Adres',
    assignTo: 'Seçili Durağa Ata',
    currentDist: 'Anlık Mesafe',
    currentTime: 'Anlık Süre',
    finished: 'Optimization Bitti. Sonuçlar güncellendi.',
    deleteAllStations: 'Tüm boş durakları sil',
  },
  solverStep: {
    NOT_STARTED: 'Başlamadı',
    CHECKING_WALKING_DISTANCE: 'Yürüme mesafesi kontrol ediliyor',
    UPDATING_DISTANCE_MATRIX: 'Mesafe matrisi güncelleniyor',
    RUNNING_OPTIMIZATION: 'Optimizasyon çalışıyor',
    FINISHED: 'Bitti',
    WALKING_DISTANCE_NOT_VALID: 'Geçersiz Yürüme mesafesi',
    MERGE_WALKING_DISTANCES: 'Yürüme mesafesindeki işler birleştiriliyor.',
    INIT_CLUSTERS: 'Kümeler Hazırlanıyor.',
  },
  routeInfoBox: {
    prefixVehicle: 'Araç',
    totalCost: 'Toplam Maliyet',
    startStop: 'Başlangıç - Bitiş',
    totalKm: 'Toplam Km.',
    totalTime: 'Toplam Süre',
    totalStation: 'Toplam Durak',
    totalStaff: 'Toplam Personel',
    detailsShow: 'Detayları Göster',
    detailsHide: 'Detayları Sakla',
    activities: 'Aktiviteler',
  },
  toast: {
    problemSaved: 'Optimizasyon tanımı kaydedildi. Yeni sonuçlar için optimizasyonu tekrar başlat',
  },
  advanced: {
    boundingBox: 'BB',
    na: 'Yok',
    diagonal: 'Çapraz',
    box: 'Kare',
    maxPickupIntervalInMinutes: 'İlk ve Son Durak Arasındaki Maksimum Süre',
    mttm: 'Servis Süresi / Sürüş Süresi',
    maxExtraTimeInShuttle: 'Maks. (Servis Süresi - Sürüş Süresi)',
  },
};

export const en = {
  general: {
    save: 'Save',
    update: 'Update',
    cancel: 'Cancel',
    create: 'Create',
    station: 'Station',
    home: 'Home',
    cost: 'Cost',
    arrival: 'Arrival',
    departure: 'Departure',
    minute: 'm.',
    minuteLong: 'Minutes',
    clickToFocus: 'Click To Focus on This Route',
    changes: 'Optimization Description is updated. Save to see new effects.',
    delete: 'Delete',
    showAdvanced: 'Show Advanced Settings',
    hideAdvanced: 'Hide Advanced Settings',
  },
  desc: {
    header: 'Optimization Description',
    workbookName: 'Workbook Name',
    workbookDesc: 'Workbook Description',
    firstDeparture: 'First Departure',
    lastArrival: 'Last Arrival',
    maxWalking: 'Max. Walking Distance',
    maxOccupancy: 'Max. Vehicle Occupancy',
    walkDistanceCheck: 'Enforce Max. Walking Distance',
    tooltipEdit: 'Edit',
  },
  vehicles: {
    types: 'Vehicle Types',
    name: 'Name',
    capacity: 'Capacity',
    maxOccupancy: 'Maximum Occupancy',
    costKm: 'Cost per KM',
    costMin: 'Cost per Minute',
    costFixed: 'Daily Fixed Cost',
    checkboxText: 'Use limited fleet size',
    numberOfVehiclesInput: 'Fleet Size',
  },
  tasks: {
    tasks: 'Tasks',
    name: 'Task Name',
    address: 'Home Address',
    stationType: 'Station Type',
    tooltipOn: 'Show Tasks',
    tooltipOff: 'Hide Tasks',
  },
  actions: {
    saveDesc: 'Save Optimization Description',
    refresh: 'Refresh',
    start: 'Start',
    stop: 'Stop',
    assignMissing: 'Assign to closest station',
    assignToVirtualStops: 'Assign to virtual stops',
    assignToClosestStops: 'Assign to closest stops',
  },
  summary: {
    iterationPercent: 'Iteration Percent',
    status: 'Optimization Status:',
    working: 'working',
    notWorking: 'not working',
    totalCost: 'Total Cost',
    found: 'Number of Iterations',
    runningTime: 'Duration',
    plannedTotal: 'Planned/Total Task Count',
    initialization: 'Initialization',
    routePickupSize: 'Route/Personnel Count',
    totalKm: 'Total Km.',
    totalTime: 'Total Duration',
    minMaxKm: 'Min./Max. Km',
    minMaxTime: 'Min./Max. Duration',
  },
  solution: {
    mapFilters: 'Map Filters',
    filterHomes: 'Homes',
    filterStations: 'Stations',
    routeEditable: 'Route Editable',
    routeNotEditable: 'Route Not Editable',
    routeOnly: 'Only Route',
    selectRoute: 'Select a Route to Focus',
    stationNo: 'Station No',
    selectedStaff: 'Selected Personnel',
    walkingDist: 'Walking Distance to Station',
    address: 'Address',
    assignTo: 'Assign to Selected Station',
    currentDist: 'Current Distance',
    currentTime: 'Current Duration',
    finished: 'Optimization finished. Solution updated',
    deleteAllStations: 'Remove all empty stations',
  },
  solverStep: {
    NOT_STARTED: 'Not Started',
    CHECKING_WALKING_DISTANCE: 'Checking Walking Distance',
    UPDATING_DISTANCE_MATRIX: 'Updating Distance Matrix',
    RUNNING_OPTIMIZATION: 'Running Optimization',
    FINISHED: 'Finished',
    WALKING_DISTANCE_NOT_VALID: 'Walking Distance Not Valid',
    MERGE_WALKING_DISTANCES: 'Merge Walking Distasnces',
    INIT_CLUSTERS: 'Initializing Clusters',
  },
  routeInfoBox: {
    prefixVehicle: 'Vehicle',
    totalCost: 'Total Cost',
    startStop: 'Start - Stop',
    totalKm: 'Total Km.',
    totalTime: 'Total Duration',
    totalStation: 'Total Stations',
    totalStaff: 'Total Personnel',
    detailsShow: 'Show Details',
    detailsHide: 'Hide Details',
    activities: 'Activities',
  },
  toast: {
    problemSaved: 'Saved optimization description. To see new effects, rerun optimization',
  },
  advanced: {
    boundingBox: 'Bounding Box',
    na: 'N/A',
    diagonal: 'Diagonal',
    box: 'Box',
    maxPickupIntervalInMinutes: 'Max. minutes between pickups',
    mttm: 'Time in Shuttle / Driving Time',
    maxExtraTimeInShuttle: 'Max. (Time in Shuttle - Driving Time)',
  },
};

export default tr;
