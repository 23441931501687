export default {
  clickForPassive: 'Campaign is Active. Click for make passive',
  clickForActive: 'Campaign is Passive. Click for make active',
  selectCampaign: 'Select Campaign',
  selectFile: 'Select File',
  imgFile: 'İmage File',
  title: 'Title',
  order: 'Order',
  content: 'Content',
  announcementsMobile: 'Announcements in Mobile Application / Notifications / Rules about showing push',
  campaignImage: 'Campaign Image',
  deleteNotice: 'All data about this notice will be deleted',
  activeCampaign: 'Active Campaign',
  passiveCampaign: 'Passive Campaign',
  mobileAppRules: 'Mobile Application Text Display Rules',
  selectDocumentExamine: 'Select document for examine',
  documentApproved: 'Document has been approved',
  announcement: {
    type: {
      CAMPAIGN: 'Campaign',
      ANNOUNCEMENT: 'Announcement',
    },
  },
  accountIdBound: 'Account Id Bound',
  customerCount: 'Customer Count(s)',
  campaignBound: 'Campaign Bound',
  idDependencyChanged: 'Id dependency state has been changed.',
  campaignDependencyChanged: 'Campaign dependency state has been changed.',
};
