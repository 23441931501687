export default {
  registeredUser: 'Kayıtlı Kullanıcılar',
  totalRentals: 'Toplam Kiralama',
  rentalUsers: 'Kiralama Yapan Kullanıcı',
  corparateRental: 'Kurumsal Kiralama',
  registeredCompany: 'Kayıtlı Firma',
  todayRentals: 'Bugünkü Kiralamalar',
  dailyRent: 'Günlük Kiralama',
  customerStates: 'Müşteri Durumları',
  vehicles: 'Araçlar',
  rentalStatus: 'Kiralama Durumu',
  rentalTypes: 'Kiralama Türleri',
  instantRentalTypes: 'Anlık Kiralama Türleri',
  vehicleState: 'Araç Durumları',
  instantRentalState: 'Anlık Kiralama Durumu',
  radar: {
    status: {
      RADAR_RESPONSE: 'Başarılı',
      RADAR_REQUEST: 'Aktif',
      RADAR_EXPIRED: 'Başarısız',
      RADAR_CANCELED: 'İptal',
    },
  },
  addCodeToMessage: 'Kodu Mesaja ekle.',
  sendMessage: 'Mesajı Gönder',
  activeNeoUser: 'Aktif Neo Kullanıcısı',
  subcriptionFinalAmount: 'Neo Cirosu',
  rentalType: {
    RESERVED_DAILY: 'Rezervasyonlu Kiralama',
    'FREE_FLOAT-DAILY': 'Günlük Kiralama',
    'FREE_FLOAT-SELF_SERVICE': 'Dakikalık Kiralama',
    'FREE_FLOAT-SUBSCRIPTION': 'Abonelik Kiralaması',
    'FREE_FLOAT-SELF_SERVICE_ROUTE': 'Rota Kiralaması',
    'FREE_FLOAT-SLOT_BASED': 'Paketli Kiralama',
  },
};
