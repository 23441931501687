export default {
  newCampaign: 'New Campaign',
  showPassive: 'Show Passive',
  hidePassive: 'Hide Passive',
  fixedCode: 'Fixed Code',
  percent: 'Percent (%)',
  amount: 'Amount',
  campaignBenefitType: 'Benefit Type',
  pointsAwardedAt: 'When To Award',
  pointsAwardedAtStart: 'At Rental Start',
  pointsAwardedAfterEnd: 'After Rental Finish',
  benefitTypes: {
    DISCOUNT_BY_CODE: 'Discount by code',
    DISCOUNT_BY_AUTO_APPLY: 'Auto apply',
    POINTS_BY_CODE: 'Discount by points',
    POINTS_BY_AUTO_APPLY: 'Auto Points Apply',
    DAY_BY_CODE: 'Discount by day',
    DAY_BY_AUTO_APPLY: 'Discount by auto apply',
  },
  grid: {
    name: 'Name',
    active: 'Active',
    type: 'Type',
    discount: 'Discount Amount',
    totalCode: 'Total Code',
    usedCode: 'Used Code',
    totalUsage: 'Total Usage',
    available: 'Available Codes',
    limitPerUser: 'Limit Per User',
    startDate: 'Start Date',
    endDate: 'End Date',
    createdBy: 'Created By',
    firstUse: 'First Use',
    lastUse: 'Last Use',
  },
  modal: {
    name: 'Campaign Name',
    desc: 'Campagin Description',
    type: 'Discount Type',
    firstOnly: 'This campaign will be available only to new rentals.',
    amount: 'Discount Amount (TL)',
    percentAmount: 'Percent Discount (%)',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    codeType: 'Code Type',
    auto: 'Auto Code',
    prefix: 'Prefix',
    limit: 'Total Limit',
    length: 'Code Length',
    action: 'Create Campaign',
    routeOnly: 'This campaign will be available only to route rentals.',
  },
  bonus: 'Bonus',
  downloadTemplate: 'Download Template',
  chooseFile: 'Choose File',
  uploadFile: 'Upload File',
  errorLine: 'Error Line',
  reasonOfError: 'Reason of Error',
  downloadExcelTemplate: 'Download Excel Template',
  campaignType: 'Campaign Type',
  totalUsage: 'Total Usage',
  emptyCampaign: 'Although a bound has been set for the campaign, content of bounds are empty.',
  invalidBounds: 'Although campaign is active, coupons are invalid.',
  codeApprovelMessage: 'Code Approvel Message',
  approvedCode: 'When customer enter campaign code, the message which will be showing to customer if it has approved',
  limitedCodeMessage: '', // TO DO
  unconfirmedCodes:
    'It will show an error message for unconfirmed codes cause of there is at least one bound on the campaign',
  makePassiveCampaign: 'Passive',
  activate: 'Activate',
  createCoupon: 'Create Coupon',
  timePeriods: 'Time Periods',
  validTimeIntervals: 'This campaign is valid for the following time periods',
  createTimePeriod: 'Create Time Period',
  removeTimeBound: 'Remove Time Bound',
  makeTimeBound: 'Make Time Bound', //
  noTimeBound:
    'Although this campaign set in specific time periods, there is no specified time periods. Even campaigns are active, there will be invalid.',
  identityBound: 'Identity Bound',
  removeIdentity: 'Remove Identity',
  addNewFile: 'Upload New File',
  campaignBounds: {
    removeIdentityBound: 'Remove Identity Bound',
    makeIdentityBound: 'Make Identity Bound',
    makeStationBound: 'Make Station Bound',
    makeDocumentBound: 'Make Document Bound',
    undefinedIdentity:
      'Although this campaign set in specific time periods, there is no any identity. Even campaigns are active, there will be invalid.',
    undefinedIdentityForStation:
      'Although this campaing set for station type campaign there is no any identity. Even campaigns are active, there will be invalid.',
    vehicleTypeBound: 'Vehicle Type Bound',
    vehicleCampaignBound: 'This campaign is eligible for below vehicles type.',
    districtStartBound: 'District Start Bound',
    districtBound: 'This campaign is eligible for below districts',
    districtFinishBound: 'District Finish Bound',
    poiStartBound: 'POI Start Bound',
    poiBound: 'This campaign is eligible for below POI list',
    poiFinishBound: 'POI Finish Bound',
    ageBound: 'Age Bound',
    ageCampaignBound: 'This campaign is eligible for below age range',
    BIN: 'BIN',
    binBound: 'This campaign is eligible for below BIN', // LTODO:
    rentalTypeBound: 'Rental Type Bound',
    rentalTypeCampaignBound: 'This campaign is eligible for below rental types',
    rentalKmBound: 'Rental KM Bound',
    rentalKmCampaignBound: 'This campaign is eligible for below Km Limit',
    totalRentalKmBound: 'Total Rental Km Bound',
    totalRentalKmCampaignBound: 'This campaign is eligible for below Total Km Limit',
    rentalMinutesBound: 'Rental Minutes Bound',
    rentalMinutesCampaignBound: 'This campaign is eligible for after minimum Km Limit',
    rentalCountBound: 'Rental Count Bound',
    rentalCountCampaignBound: 'This campaign is eligible for below rental count',
    scoreBound: 'Score Bound',
    scoreCampaignBound: 'This campaign is eligible for below score range',
    vehiclePriceCatalogBound: 'Vehicle Price Catalog Bound',
    vehiclePriceCatalogSubtitle: 'This campaign is eligible for below Vehicle Price Catalogs',
    isMinNumberOfRentalDaysBound: 'Rental Days Bounds',
  },
  transactionHistory: 'Transaction History',
  createNewCampaignCode: 'Create a New Campaign Code',
  codeCount: 'Code Count',
  vehicleSelection: 'Vehicle Selection',
  selectCity: 'Select City',
  poiSelection: 'POI Selection',
  districtSelection: 'District Selection',
  savePoi: 'Save {0} Poi(s)',
  saveDistrict: 'Save {0} District(s)',
  saveVehicle: 'Save {0} Vehicle(s)',
  createCode: 'Create {0} number(s)',
  ageSelection: 'Age Selection',
  minAge: 'Minimum Age',
  maxAge: 'Maximum Age',
  selectionBin: 'Selection BIN',
  rentalTypeSelection: 'Rental Type Selection',
  transferredRecord: '{0} record(s) has been transferred',
  fileTransferred: 'File(s) Transferred',
  recordsDeleted: 'Records has been deleted',
  recordsCouldNotDeleted: 'Records could not deleted',
  pushNotificationSent: 'Push Notifications has been sent',
  totalPerson: 'Total Person',
  loadBalanceBonus: 'Bonus/Balance Load',
  sendBatchPush: 'Send Batch Push', // LTDO:
  totalBalanceLoad: '',
  totalBalanceEditing: '',
  personTotalBalanceEditing: '',
  personTotalBalanceLoad: '',
  cumulativeBalancePerson: '',
  cumulativeBalance: '',
  totalBonusLoad: '',
  totalBonusEditing: '',
  personTotalBonusEditing: '',
  personTotalBonusLoad: '',
  cumulativeBonusPerson: '',
  cumulativeBonus: '',
  sendingBatchPushNotification: '',
  requestsPendingApproval: 'Requests Pending Approval',
  documentReject: 'This request will be rejected as one document is not appropriate at least',
  fillComplete: 'Please fill all documents complete',
  usedTime: 'Used Time',
  searchingCampaignCode: 'Searching Campaign Code',
  searchedCodes: 'Searched codes a short while ago ',
  campaignCodeDetail: 'Campaign Code {0} Usage Detail',
  definedCoupon: 'This coupon has been defined {0} times',
  unusedCode: 'This coupon has not used yet.',
  campaignsWhichCanUses: 'Campaigns that can uses',
  notFoundCode: 'There has not found code.',
  filterByKey: 'Filter by key',
  price: 'Price',
  loaded: 'Loaded',
  externalSystemId: 'External System Id',
  uploadingDateBonus: 'Uploading Date of Bonus',
  selectDay: 'Select Day',
  endOfMonth: 'End of Month',
  validValueCampaign: 'Value which the campaign is valid',
  uploadingDateBonusChanged: 'Uploading Date Bonus has been changed',
  bonusUploadMonthly: 'For this campaign, bonus will in upload selected day as monthly',
  removeIdentities: 'Remove Identities',
  stationConstraints: 'Station Constraints',
  sendNotification: 'Send Notification to {0}',
  uploadNewFile: 'Upload New File',
  recordsTransferred: 'records has been transferred.',
  idConstraint: 'Id Constraint',
  changeOptions: 'Change Options',
  remove: 'Remove {0}',
  get: 'Get {0}',
  validCharacters: 'Geçerli Karakterler',
  parametersPointEarning: 'Parameters of points earning campaign from the first rental.',
  firstRentalCampaign: 'First Rental Campaign',
  removeStationTypeBound: 'Remove Station Type Bound',
  removeDocumentTypeBound: 'Remove Document Type Bound',
  removeIdentityTypeBound: 'Remove Identity Type Bound',
  updatedSuccessfully: 'has been updated successfully',
  removeTitle: 'Remove {0}',
  getTitle: 'Get {0}',
  availableCampaign: 'Value that campaign is available.',
  availableCampaigns: 'Values that campaign is available.',
  noConstraint: 'There is no bounds for this constraint. {change} the settings to constraint the campaign.',
  pointsAwardedAt_AT_START: 'At Start',
  pointsAwardedAt_AFTER_END: 'After End',
  AMOUNT: 'Amount',
  createdBy: 'It has been created by {createdBy} at {creationTime}',
  change: 'Change',
  changeTimePeriod:
    'This campaign is available for all day and hour periods. {change} the time settings to use the campaign at certain time intervals',
  ageBoundCampaign: 'This campaign is available for {all} age range. {change} the settings to constraint the campaign',
  all: 'all',
  availableAllType: 'This campaign is available for {all} types. {change} the settings to constraint the campaign',
  constraitCreated:
    '{length} different constrait have been created for this campaign and it is only available for this campaign',
  noChoise:
    'Although this campaign is set for {0}, no choise has been specified. Even campaigns are active, there will be invalid',
  availableAllUsers:
    'This campaign is available for all users. {change} the settings to constrait the campaign to specific ID number',
  matchedAccountNumbers:
    'This campaign has been matched with the account numbers of {accountIdCount} and is only valid for these users',
  rentalCountConstraint: 'Rental Count Constraint',
  availableFollowingRentalNumbers:
    'This campaign is available only for the following rental numbers. (E.g: Separate with commas for 10 or more values. 1, 3, 5)',
  customerActivationDayConstrait: 'Customer Activation Day Constrait',
  availableCustomerActivation:
    'This campaign is only available for customers who have been active for less than the following days',
  binList: 'A comma-separated list of BIN',
  PERCENT: 'Percent',
  availableAllStation:
    'This campaign is available for all station types. {change} the settings to constrait the campaign to specific station types',
  availableAllDocuments:
    'This campaign is available for all document types. {change} the settings to constrait the campaign to specific document types',
  updatedDocumentPermissionTypes: 'Document permission types has been updated',
  updatedStationPermissionTypes: 'Station permission types has been updated',
  documentConstraints: 'Document Constraints',
  pairedDocumentTypes: 'This campaign paired with {types} document types and it is valid only these document types.',
  pairedStationTypes: 'This campaign paired with {types} station types and it is valid only these document types.',
  documentTypeBound: 'Document Type Bound',
  stationTypeBound: 'Station Type Bound',
  documentTypeSelection: 'Document Type Selection',
  stationTypeSelection: 'Station Type Selection',
  desc: 'Description',
  maxBenefit: 'Max Benefit',
  domainWillDelete: 'Selected {0} will be removed. Are you sure ?',
  groupDomain: 'group domain',
  addNewDomain: 'Add New Domain',
  selectDomain: 'Select Domain',
  domains: 'Domains',
  addDomain: 'Add Domain',
  domain: 'Domain',
  scoreBoundCampaign:
    'This campaign is available for {all} score range. {change} the settings to constraint the campaign',
  minScore: 'Minimum Score',
  maxScore: 'Max Score',
  scoreSelection: 'Score Selection',
  vehiclePriceCatalog: 'Vehicle Price Catalog',
  addCatalog: 'Add Catalog',
  instantOffer: 'Instant Offer',
  dayOfWeek: 'Day of Week',
  offerStartHour: 'Offer Start Hour',
  offerEndHour: 'Offer End Hour',
  status: 'Status',
  displayCount: 'Display Count',
  offerCount: 'Offer Count',
  offerPercentages: 'Offer Percentages',
  displayFrequency: 'Display Frequency',
  expireDuration: 'Expire Duration (min)',
  days: {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
  },
  availableCampaignDay: 'Day that campaign is available',
  DAY: 'Gün',
};
