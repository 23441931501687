export default {
  rentalJobId: 'Kiralama/Görev ID',
  customerId: 'Müşteri ID',
  incidentId: 'Vaka Id',
  externalSupplierIncidentId: 'Dış Tedarikçi Vaka Id',
  damageType: 'Kaza Tipi',
  incidentDamageSize: 'Hasar Büyüklüğü',
  damagedParts: 'Hasarlı Parçalar',
  incidentDescription: 'Kaza/Hasar Detay Açıklama',
  accidentReport: 'Kaza Raporu',
  thirdPersonUsage: '3.Şahıs',
  extraFee: 'Yansıtma',
  extraFeeReason: 'Yansıtma Nedeni',
  extraFeeAmount: 'Yansıtma Miktarı',
  othersExtraFeeAmount: 'Diğer Yansıtma Miktarı',
  othersExtraFeeReason: 'Diğer Yansıtma Nedeni',
  creationTime: 'Yaratılış Tarihi',
  incidentRegistrationTime: 'Vaka Kayıt Tarihi',
  completionDate: 'Vaka Tamamlanma Tarihi',
  damagedPart: 'Hasarlı Parça',
  partChangeStatus: 'Parça Değişikliği Durumu',
  incidentExplanation: 'Hasar Açıklama',
  incidentType: 'Vaka Tipi',
  anonymous: 'Anonim',
  rentalTaskId: 'Kiralama / Görev Id',
  photosOfIncident: 'Kaza Fotoğrafları',
  newPhotos: 'yeni resim',
  photo: 'Foto',
  incidentLocation: 'Kaza Lokasyonu',
  incidentDetailExplanation: 'Kaza Detay Açıklama',
  weatherCondition: 'Hava Koşulları',
  incidentDateHours: 'Kaza Tarih/Saat',
  incidentCompletionDate: 'Vaka Tamamlama Tarih/Saat',
  isVehicleDetained: 'Araç bağlandı mı ?',
  isDamageDocumentsSent: 'Hasar dökümanları gönderildi mi ?',
  policeReport: 'Polis Raporu',
  userDeclaration: 'Kullanıcı Beyanı',
  isDriverDrunk: 'Sürücü Alkollü mü ?',
  alcoholReport: 'Alkol Raporu',
  isThirdPersonUsageExists: '3. Şahıs kullanımı var mı ?',
  thirdPersonCitizenId: '3.Şahıs TCKN',
  currency: 'Para Birimi',
  notExtraFee: 'Yansıtma Yapılmama Nedeni',
  isThereWithdrawing: 'Rucü Var mı ?',
  isVehicleScrap: 'Araç Pert mi ?',
  scrapVehicleAmount: 'Pert Araç Tutarı',
  scrapInformations: 'Pert Bilgileri',
  salvageAmount: 'Sovtaj Tutarı',
  scrapVehicleTowingDocumentAmount: 'Pert - Çekme Belgesi Tutarı',
  totalScrapVehicleExtraFee: 'Toplam Pert Araç Yansıtma Bedeli',
  automaticallyCalculated: 'Otomatik Olarak Hesaplanır.',
  part: 'Parça',
  change: 'Değişiklik',
  vehicleDailyCost: 'Günlük Kiralama Bedeli',
  pendingRepairDays: 'Muallak Onarım Süresi (Gün)',
  pendingLossAmount: 'Muallak Kazanç Kaybı Tutarı',
  estimatedDamageAmount: 'Muallak Hasar Tutarı',
  pendingReflectionAmount: 'Muallak Yansıtma Tutarı',
  netRepairDays: 'Net Onarım Süresi (Gün)',
  netLossAmount: 'Net Kazanç Kaybı Tutarı',
  valueLossAmount: 'Değer Kaybı Tutarı',
  damageAmount: 'Yansıtılan Hasar Tutarı (İskontosuz)',
  totalReflectionAmount: 'Toplam Yansıtma Tutarı',
  netDamageCost: 'Net Hasar Maliyeti',
  otherReflectionReason: 'Diğer Yansıtma Sebebi',
  otherReflectionAmount: 'Diğer Yansıtma Tutarı',
  operationNote: 'Operasyon Dipnot',
  incidentInformationUpdated: 'Kaza Bilgileri Güncellendi',
  incidentsWillRemove: 'Bu vaka ve ilişkili tüm veriler silinecek',
  noRecords: 'Bu kriterlerde kayıt bulunumadı.',
  jobId: 'Görev ID',
};
