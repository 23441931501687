export default {
  below: '{0} altında',
  belowWithPercent: '% {0} altında',
  over: '{0} üzerinde',
  overWithPercent: '% {0} üzerinde',
  driversVehicles: 'Araç/Sürücü Değişikliği',
  tasksDocuments: 'Evrak Onayı',
  addressMissing: 'Ev Adresi Yok',
  tickets: 'Talepler',
  systemStatus: 'Sistem Durumu',
  occupancyRatio: 'Doluluk Oranı',
  viewData: '{0}ı Görüntüle',
  viewUsers: 'Yolcuları Görüntüle',
  viewRoutes: 'Rotaları Görüntüle',
  avg: 'Ort.',
  timeCoefficient: 'Konfor Skoru',
  walkingDistance: 'Yürüme Mesafesi',
  totalCost: 'Toplam Maliyet',
  totalEmission: 'Toplam Emisyon (CO2)',
  perEmployee: 'Çalışan Başına',
  perEmployeeMile: 'Yolcu/Km',
  toCampus: 'Yerleşkeye',
  fromCampus: 'Yerleşkeden',
  onTimeArrival: 'Zamanında Ulaştı',
  noArrival: 'Gelmedi',
  lateArrival: 'Geç Ulaştı',
  deviceProblem: 'Cihaz Problemi',
  leaveOnTime: 'Zamanında Ayrıldı',
  readyToLeave: 'Ayrılmaya Hazır',
  leaveEarly: 'Erken Ayrıldı',
  devices: 'Cihaz Durumu',
  availableDevice: 'Cihaz Var',
  notAvailableDevice: 'Cihaz Yok',
  noSignal: 'Sinyal Yok',
  userRegistration: 'Personel Kayıt Durumları',
  completed: 'Tamamlanmış',
  undefinedRoute: 'Rota Tanımlanmamış',
  notUsingShuttle: 'Servis Kullanmıyor',
  documents: 'Belgeler',
  missing: 'Eksik',
  planned: 'Planlanmış',
  tasks: 'Görevler',
  pendingRequests: 'Bekleyen Onaylar',
  routeChange: 'Personel Rota Değişikliği',
  reservation: 'Rezervasyon Onay',
  progressPayment: 'Hakediş Onay',
  pendingAssignments: 'Bekleyen Atamalar',
  stops: 'Personel Durak Ataması',
  employeeLocationAndRouteDistribution: 'Rota&Personel Dağılımları',
  occupancyRatioAvg: 'Doluluk Oranı% Ort. (Min-Max)',
  numberOfUser: 'Kişi Sayısı',
  numberOfRoutes: 'Rota Sayısı',
  ratioToTotalUsers: 'Toplam Kullanıcılara Oranı',
  usageRate: 'Kullanım Oranı',
  fullfillmentRate: 'Yerine Getirme Oranı',
  usage: 'Kullanım',
  actual: 'Gerçekleşen',
  cardScans: 'Kart Taramaları',
  kgMonth: 'KG/Ay',
  city: 'Şehir',
  allCampuses: 'Tüm yerleşkeler & Rota grupları',
  allRoutes: 'Tüm rotalar',
  routeGroupSelected: '{0} rota grubu seçildi',
  searchCampus: 'Yerleşke & Rota grubu ara',
  searchRoutes: 'Rota ara',
  vehicles: 'Araçlar',
  drivers: 'Sürücüler',
  overTheAgeLimit: 'Yaş sınırının üzerinde',
  dynamicRoutes: 'Dinamik Rotalar',
  fixedRoutes: 'Sabit Rotalar',
  uniqueRidersRides: 'Tekil Yolcu/Yolculuk',
  tripsRoutes: 'Sefer/Rota',
  avgOccupancy: 'Ort. Doluluk',
  avgTimeCoefficient: 'Ort. Süre Katsayısı',
  avgDistanceToStop: 'Durağa Ort. Uzaklık',
  above: '{0} üzeri',
  perEmployeeTrip: 'Yolcu/Yolculuk Başına',
  totalEmmisions: 'Total Emisyon (CO₂)',
  telematicsDevices: 'Telematik Cihazları',
  noDevice: 'Cihaz yok',
  noConnection: 'Bağlantı yok',
  actionRequired: 'Bekleyen İşlemler',
  openTickets: 'Destek Talebi',
  nearbyStopRequests: 'Yakın Durak Talebi',
  changeStopRequests: 'Durak Değişim Talepleri',
  driversOverAgeLimit: 'Yaş Limiti Üzerinde Sürücü',
  driverVehicleApproval: 'Sürücü/Araç Onayı',
  documentApprovals: 'Döküman Onayı',
  paymentApprovals: 'Ödeme Onayı',
  rideRequests: 'Yolculuk Talepleri',
  reserved: 'Rezerve Edildi',
  failed: 'Karşılanamadı',
  canceled: 'İptal Edildi',
  numberOfRidersByDayOfTheWeek: 'Haftanın Günlerine Göre Yolcu Sayıları',
  ridersEmbarkedOnFixedRoutes: 'Sabit Rotaları Kullanan Yolcu Sayısı',
  ridersEmbarkedOnDynamicRoutes: 'Dinamik Rotaları Kullanan Yolcu Sayısı',
  occupancyRatiosOverTime: 'Doluluk Oranlarının Zamana Göre Değişimi',
  reportedByDriver: 'Sürücü Tarafından Bildirilen',
  riderCheckins: 'QR/Kart Okutmaları',
  onTimePerformanceOverTime: 'Zamanında Varış/Kalkış Performansının Zamana Göre Değişimi',
  lateArrivals: 'Geç Varış',
  noArrivals: 'Varmadı/Çıkmadı',
  noArrivalsDepartures: 'Varmadı/Çıkmadı',
  earlyDepartures: 'Erken Kalkış',
  onTimePerformance: 'Zamanında',
  nonCommuteMiles: 'İşe Gidiş-Geliş Dışı KM',
  avgRidersPerTrip: 'Sefer Başı Ort. Yolcu',
  compliance: "Kurallara Uygunluk (4 yolcu altında yolcusu olan seferler ve %20 üzerinde İşe Gidiş-Geliş Dışı KM'si olan Rotalar)",
  complianceGraphMarker: {
    first: 'Yolcu Sayısı <4 Olan Seferler',
    second: "İşe Gidiş-Geliş Dışı KM'si %20 Üzerinde Olan Araçlar",
  },
  vanpoolRoutesAvgRidership: 'Ort. Yolcu Sayıları ile <br/> Vanpool Rotaları',
  vanpoolRoutesAvgNonCommuteMiles: 'Ort. İş Dışı Kullanım Mesafeleri ile <br/> Vanpool Rotaları',
};
