export default {
  selectPlate: 'Select Plate',
  enterCustomerName: 'Enter Customer Name',
  filter: 'Filter',
  removeFilter: 'Remove Filter',
  requestTime: 'Request Time',
  deviceIssue: 'Device Issue. Please change vehicle.',
  reservation: 'Reservation',
  pricingStart: 'Pricing Start',
  pricingEnd: 'Pricing End',
  counterStart: 'Counter Start',
  timePrice: 'Time Price per minute',
  kmExceededCost: 'Km Exceeded Cost',
  vehicleGroup: 'Vehicle Group',
  vehicleDescription: 'Vehicle Descriptions',
  externalSystemId: 'External System Id',
  externalReference: 'External System Reference',
  parkExit: 'Park Exit',
  finishReason: 'Finish Reason',
  relatedRental: 'Related Rental',
  sendDocuments: 'Send documents with email',
  addMailAddress: 'Add Mail Address',
  requestPoint: 'Request Point',
  origin: 'Origin',
  relatedRentals: 'Related Rentals',
  previousRental: 'Previous Rental',
  nextRental: 'Next Rental',
  firstRental: 'First Rental',
  vehicleOutOfService: 'Vehicle Out of Service Area',
  locatedCity: 'The city which is it located',
  rejectReasons: 'Reject Reasons',
  drivingScore: 'Driving Score',
  gsm: 'GSM',
  citizenId: 'Citizen Id',
  paymentsInfo: 'Payments Info',
  finishRental: 'Finish Rental',
  discounts: 'Discounts',
  referenceInformation: 'Reference Information',
  photosEndRental: 'Photos that end of rental',
  front: 'Front',
  back: 'Back',
  left: 'Left',
  right: 'Right',
  innerFront: 'Inner-Front',
  innerBack: 'Inner-Back',
  directions: 'Directions',
  rateOfDiscount: 'Rate of Discount',
  currentDiscount: 'There is no current discount',
  finishingRental: 'Finishing Rental',
  rentalInformation: 'Rental Information Updated',
  requestReceived: 'Request Received',
  rentalStarted: 'Rental Started',
  started: 'Started',
  finished: 'Finished',
  paymentReceived: 'Payment Received',
  returned: 'Returned',
  collectBalance: 'Are you sure want to collect balance ?',
  successfullyPayment: 'The payment made successfully',
  paymentError: 'An error occurred in payment',
  approximate: 'Approximate',
  extraCostsAmount: 'Extra Costs Amount',
  payment: 'Payment',
  selectReasonForChange: 'Please Select a Reason for Change',
  valetCloseCar: 'Valet - There is a valet who closer to car',
  valetHasDifferentJob: 'Valet - Valet has a different job',
  valetNotInShift: 'Valet not in shift',
  valetApplicationError: 'Valet - Application Error',
  vehicleDoorsNotOpen: 'Vehicle - Doors did not open',
  dirty: 'Vehicle - Dirty',
  vehicleDamaged: 'Vehicle - Broken / Damaged',
  vehicleParkProblem: 'Vehicle - Park Problem',
  notAuthority: 'You don\'t have authority for this action ',
  jobChanged: 'Job has been changed successfully',
  jobNotChanged: 'Job could\'t changed',
  updateRental: 'Update Rental',
  finish: 'Finish',
  ending: 'Ending',
  finishWithNoCharge: 'Finish with no Charge',
  noChargeFinishReason: 'No Charge Finish Reason',
  NOT_STARTED: 'Not Started',
  PREPARING_VEHICLE: 'Preparing Vehicle',
  FINISHED: 'Finished',
  CANCELED: 'Cancelled',
  WAITING_RENTAL_START: 'Waiting Rental Start',
  VEHICLE_ON_DELIVERY: 'Vehicle on Delivery',
  RENTAL_IN_PROGRESS: 'Active Rental',
  WAITING_CUSTOMER_TO_VEHICLE: 'Waiting Customer',
  WAITING_PAYMENT: 'Waiting Payment',
  score: {
    accScore: 'Accelaration',
    decScore: 'Decelaration',
    idlingScore: 'Idling',
    speedScore: 'Speed',
    corneringScore: 'Cornering',
    overallScore: 'Overall',
  },
  cancel: 'Cancel',
  reservationWillDelete: 'This reservation will delete.',
  reservationDeleted: 'Reservation has been deleted',
  durationPackage: 'Duration Package',
  stationType: 'Station Type',
  startZone: 'Start Zone',
  endZone: 'End Zone',
  rentalId: 'Rental ID',
  eventCategory: {
    DELIVERY: 'Delivery',
    PAYMENT: 'Payment',
    RENTAL: 'Rental',
    REQUEST: 'Request',
    SYSTEM: 'System',
    REFUND: 'Refund',
    DRIVER: 'Driver',
  },
  removeCategoryFilter: 'Remove Category Filter',
  fromPastToPresent: 'From Past to Present',
  fromPresentToPast: 'From Present to Past',
  priceModel: 'Price Model',
  rentalFinished: 'Rental Finished',
  durationPackages: 'Duration Packages',
  packageUpdated: 'Package has been updated.',
  createdPackage: 'Package has been created',
  customerStatus: 'Customer Status',
  requiredCreditCard: 'Credit card requirement for {required}',
  rentals: {
    NOT_STARTED: 'Not Started',
    PREPARING_VEHICLE: 'Preparing Vehicle',
    FINISHED: 'Finished',
    CANCELED: 'Cancelled',
    WAITING_RENTAL_START: 'Waiting Rental Start',
    VEHICLE_ON_DELIVERY: 'Vehicle on Delivery',
    RENTAL_IN_PROGRESS: 'Active Rental',
    WAITING_CUSTOMER_TO_VEHICLE: 'Waiting Customer',
    WAITING_PAYMENT: 'Waiting Payment',
    WAITING_RENTAL_END: 'Waiting Rental End',
    score: {
      accScore: 'Accelaration',
      decScore: 'Decelaration',
      idlingScore: 'Idling',
      speedScore: 'Speed',
      corneringScore: 'Cornering',
      overallScore: 'Overall',
    },
  },
  kmLimit: 'Km/Limit',
  ratings: {
    OVERALL: 'Overall',
    CLEANLINESS: 'Cleanliness',
  },
  limitOfKm: 'Limit of km',
  code: 'Code',
  events: {
    assignVehicle: 'Assign vehicle',
    provision_paid: 'Provision paid',
    newRental: 'Rental request',
    preparingVehicle: 'Preparing Vehicle',
    deliveryRouteStarted: 'Delivery route started',
    deliveredToCustomer: 'Delivery',
    lateRentalStart: 'Rental started',
    arrivedToCustomerLocation: 'Arrived to customer Location',
    assignVehicleLog: 'Assign vehicle log',
    assignVehicleLogV2: 'Vehicle system registration',
    valetWasNotAvailable: 'Valet was not available',
    endRentalDoorStatusLog: 'Vehicle system log, Door status',
    changeDeliveryJob: 'Log status: Valet or Vehicle changed',
    cancelRentalByUser: 'Cancel rental by user.',
    directPaymentSuccess: 'Payment Success',
    directPaymentFailure: 'Payment failure',
    provisionPaymentSuccess: 'Provision payment success',
    provisionPaymentFailure: 'Povision payment failure',
    refundSuccess: 'Refund success',
    refundFailure: 'Refund failure',
    startRentalAddress: 'Start rental address',
    endRentalAddress: 'End rental address',
    valetCallNotification: 'Valet call notification',
    valetAcceptedJob: 'Valet accepted job',
    cancelContract: 'Contract canceled',
    cancelContractFail: 'Contract cancellation failed',
    createContract: 'Contract created',
    updateTotalCost: 'Total fee updated',
    updateOdometer: 'Odometer updated',
    updateStartDate: 'Start date updated',
    updateEndDate: 'End date updated',
    updateVehicleStatus: 'Vehicle status updated',
    updateNoCharge: 'Update no charge',
    rentFromStreet: 'Rent charged from outside of car park',
    vehicleLeftServiceArea: 'Vehicle left service area',
    vehicleEnteredServiceArea: 'Vehicle entered the service area',
    adressWhenVehicleLeftServiceArea: 'The address removed from service area',
    adressWhenVehicleEnteredServiceArea: 'The address entered in the service area',
    hgsInvoice: 'HGS Invoice',
    newRentalSelfService: 'Rental request (Self Service)',
    newRentalStation: 'Rental request (Rental from station)',
    cancelSelfService: 'System self service cancellation',
    devicePassiveStateDetected: 'Device in passive state',
    sendResetForNotActive: 'Automatic device control',
    sendKabisDataToCmc: 'Kabis data sent',
    sendKabisDataToRobot: 'Kabis saat sent (Robot)',
    vehicleHasExistingTask: 'There is a pretask on the vehicle',
    airportRentalStart: 'Airport rental start',
    airportRentalEnd: 'Airport rental end',
    finishedByAdmin: 'Finished by admin',
    finishedByRentalChange: 'Finished by rental change',
    startedByRentalChange: 'Started by rental change',
    provisionVoided: 'Unblocked provision',
    rentalChangedToCorporate: 'Rental changed to corporate',
    rentalChangedToPersonal: 'Rental changed to personal',
    infoTiktakWeekendCostNotification: 'Weekend cost information',
    infoNigtlyCostNotification: 'Night cost information',
    assignDiscount: 'Discount applied',
    removeDiscount: 'Cancelled discount',
    unsuccessfulPayment: 'Unsuccessful payment',
    successfulPayment: 'Successful payment',
    successfullSynchronized: 'Successfull synchoronized',
    rentalDistanceLimitExceedPush: 'Exceeding the rental km limit',
  },
  valetCompletedJob: 'Valet has been completed job',
  traveledKm: 'Traveled Km',
  extraFees: 'Extra Fees',
  pricingModel: 'Pricing Model',
  earlyRentalEnd: 'Early Rental End',
  noReasonStated: 'There is no reason stated.',
  rentalUsedMinute: 'Used Minute',
  baseDiscountRatio: 'Base Discount Ratio',
  speedAnalysis: 'Speed Analysis',
  measuredSpeed: 'Measured Speed',
  modelledSpeed: 'Modelled Speed',
  anomaly: 'Anomaly',
  speedAnalysisChart: 'Speed Analysis Chart',
  doorOpenDate: 'Door Open Date',
  analysisCount: 'Analysis Count',
  anomalyCount: 'Anomaly Count',
  anomalyCountInLast5Min: 'Anomaly Count in Last 5 min',
  rentalInfoWillSend: 'Rental info will be sent to the {0} email address.',
  speedExceedScore: 'Speed Exceed Score',
  corneringExceedScore: 'Cornering Exceed Score',
  decelerationExceedScore: 'Deceleration Exceed Score',
  accelerationExceedScore: 'Acceleration Exceed Score',
  overallScore: 'Overall Score',
  saveScore: 'Save Score',
  calculateScore: 'Calculate Score',
  accidentRiskScore: 'Accident Risk Score',
  scoringParamaterManagement: 'Scoring Parameter Management',
  routeInfo: 'Route Info',
  routePackageOffer: {
    routeMinute: 'Route minute',
    routeKm: 'Route km',
    durationPrice: 'Route duration price',
    durationBufferInMinute: 'Route duration buffer in minute',
    distanceBufferInKm: 'Route distance buffer in km',
    basePrice: 'Base price',
    discountRatio: 'Discount ratio',
    routePackageOfferKm: 'Route package offer km',
    routePackageOfferMinute: 'Route package offer minute',
    routePackageOfferTotalPrice: 'Route package offer total price',
    packageStartHour: 'Package Start Hour',
    packageEndHour: 'Package End Hour',
    slotBasedRentalInfo: 'Slot Based Rental Info',
    slotBasedRentalPrice: 'Slot Based Rental Price',
    packageFreeWaitingDurationInMinute: 'Package Free Waiting Duration in Minute',
    packageBufferDurationInMinute: 'Package buffer duration in minute',
    packagePostDurationInMinute: 'Package post duration in minute',
    packagePreDurationInMinute: 'Package pre duration in minute',
  },
};
