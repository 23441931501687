export default {
  ALL: 'All',
  PENDING_DEMAND: 'Gathering Demand',
  PENDING_PLANNING: 'Pending Planning',
  PENDING_OPTIMIZATION_RESULT: 'Pending Planning',
  PENDING_RECURRING_OPTIMIZATION: 'Pending Planning',
  ACTIVE: 'Active',
  INCOMPLETE: 'Incomplete',
  PENDING_ASSIGNMENT: 'Pending Assignment',
  CANCELLED: 'Cancelled',
  ARCHIVED: 'Archived',
  archive: 'Archive',
  locations: {
    CAMPUS: 'Office',
    POI: 'POI',
    PERSONNEL_SHUTTLE_STOP: 'Stops',
    PERSONNEL_HOME_ADDRESS: 'Rider Home Addresses',
    PERSONNEL_WORK_LOCATION: 'Rider Work Location',
  },
  header: 'Route Groups',
  createWorkgroup: 'New Route Group',
  timeOfArrival: 'Arrival Time at',
  departureTime: 'Departure Time',
  arrivalTime: 'Arrival Time',
  readyToDeparture: 'Ready for Departure',
  period: 'Period',
  periodicallyUpdatedGroup: 'Periodically Updated Group',
  periodicallyUpdatedGroupInfo:
    'Periodically updated groups collect demands from attendees for each period and the shuttle lines are re-planned accordingly.',
  selectPeriod: 'Select Period',
  roundTrip: 'Round Trip',
  oneWay: 'One Way',
  doubleDirection: 'Round Trip',
  fromWhere: 'From',
  toWhere: 'To',
  openSelectMenu: 'Select...',
  groupName: 'Group Name',
  enterDescription: 'You can enter a description',
  giveNameToGroup: 'Give a name to this group',
  selectLocation: 'Select a location',
  selectDeparturePoint: 'Select departure point for first trip',
  responsibleCampus: 'Responsible Campus',
  openToDemand: 'Open for Opt-In',
  limitQuota: 'Limit Quota',
  departmentRequest: 'Department(s) that can request',
  reportPlanningEnd: 'Report Planning End',
  startOfBookbuilding: 'Start of Opt-In Period',
  endOfBookbuilding: 'End of Opt-In Period',
  definition: 'Definition',
  location: 'Locations',
  timing: 'Timing',
  operationTimes: 'Operation Times',
  changeRouteOrStop: 'Change Route or Stop',
  removeFromGroup: 'Remove from Group',
  routePlanning: 'Route planning has a deadline',
  picnic: 'Picnic',
  overwork: 'Overwork',
  saturdayShift: 'Saturday Shift',
  customAddress: 'Custom Address',
  personnelLocations: 'Rider Locations',
  PERSONNEL_SHUTTLE_STOP: 'Stops',
  PERSONNEL_HOME_ADDRESS: 'Rider Home Adresses',
  PERSONNEL_WORK_LOCATION: 'Rider Work Locations',
  createRecurringGroups: 'Time when the group will be visible to riders',
  beforeStart: 'before start day.',
  makeDefault: 'Make Default',
  default: 'Default',
  searchGroup: 'Search Group',
  selectedPerson: 'person selected',
  archiveSearchGroup: 'Search archive groups...',
  deleteSelected: 'Delete Selected',
  sortBy: 'Sort By',
  sortByEndDate: 'Sort by end date',
  sortByStartDate: 'Sort by start date',
  sortByEmployeesCount: 'Sort by number of employees',
  indefinite: 'Indefinite',
  showMore: 'Show More',
  campus: 'Campus',
  selectEndDate: 'Select End Date',
  hoursBeforeTrip: '{hours} hours before the first trip',
  hoursBeforeFirstTrip: 'Planning ends {hours} hours before first trip.',
  changeStatus: 'Change Status',
  statusChanged: 'Status changed as {0}',
  invalidDepartureTime: 'Invalid Departure Time',
  invalidArrivalTime: 'Invalid Arrival Time',
  addNewCustomAddress: 'Add New Custom Address',
  descriptor: 'Descriptor',
  createdBy: 'Created by',
  makeDefaultRouteGroup: 'Make default route group for newly added staff.',
  makeDefaultEmployees: 'Make default for newly added employees.',
  personnelLabeledVanpoolOnly: 'Only employees with vanpool tag.',
  automation: 'Automation',
  optimization: 'Optimization',
  vehicleAssignment: 'Vehicle Assignment',
  activation: 'Activation',
  initiateAutomation: 'Initiate Automation',
  deleteGroupInstanceConfirm: "Are you sure you'd like to delete this group instance?",
  startsFromtheDepot: 'Starts from the depot',
  startingDepot: 'Starting Depot',
  selectDepot: 'Select a depot',
  endingDepot: 'Ending Depot',
  endsintheDepot: 'Ends in the depot',
  definedRegion: 'Defined region(s)',
  coverageArea: 'Coverage Area',
  noRestrictions: 'No restrictions',
  addCampus: 'Add Campus',
  multiCampus: 'Multi-Campus',
  selectRegion: 'Select a region',
  selectADeparturePoint: 'Select a departure point',
  enterThereadyToDepartureDepartureTimes:
    'Enter the desired "ready for departure" & "departure" times from first campus',
  enterArrivalTimeToLastDestination: 'Enter the desired arrival time to last destination',
  serviceTimeForEachStop: 'Service time for each stop',
  calculate: 'Calculate',
  vehicleTrackingStart: 'Vehicle Start Time',
  dynamic: 'Dynamic',
  regular: 'Regular',
  fixed: 'Fixed',
  DYNAMIC: 'Dynamic',
  REGULAR: 'Regular',
  FIXED: 'Fixed',
  readyForDepartureMustEarlierDepartureTime: 'Ready for departure time must be earlier than departure time.',
  acceptsReservations: 'Accepts Reservations',
  noReservations: 'No Reservations',
  ring: 'Ring',
  schedule: 'Schedule',
  startingEndingPoint: 'Starting/Ending Point',
  stops: 'Stops',
  workdays: 'Workdays (Mon-Fri)',
  singleTrip: 'Single Trip',
  multipleTrips: 'Multiple Trips',
  periodical: 'Periodical',
  atSpecificHours: 'At Specific Hours',
  departureTimeFromFirstPoint: 'Enter the desired departure time from the first point',
  tripsOnEvery: 'Trips on every',
  numberOfTrips: 'Number of Trips',
  min: 'min(s)',
  hour: 'hour(s)',
  totalDurationhours: 'The total duration of the trips exceeds 24 hours.',
  departureTimeCampusName: 'Departure Time from <b>{0}',
  calculateAccordingly: 'Calculate for all accordingly',
  addTrip: 'Add Trip',
  considerTimeBasedTrafficData: 'Consider time-based traffic data.',
  calculateForAll: 'Calculate for all',
  viewEditTimesOtherTrips: 'View & Edit Times for Other Trips',
  hideTimesOtherTrips: 'Hide Times for Other Trips',
  typeNameStop: 'Type a name for the stop',
  use: 'Use',
  addStop: 'Add Stop',
  deleteThisRouteGroup: 'Are you sure you want to delete this route group',
  acceptReservations: 'Accept Reservations',
  theSameDay: 'The same day',
  daysBeforeTripDate: 'day(s) before the trip date',
  singleSelectedMulticampusMessage:
    'You have selected a single campus for a multi-campus trip. Do you want to add more campuses?',
  reservationsCanBeMadeUntilCutOffTime: 'Reservations can be made until a cut-off time.',
  closeFilters: 'Close Filters',
  type: 'Type',
  creator: 'Creator',
  reservations: 'Reservations',
  required: 'Required',
  notRequired: 'Not Required',
};
