export default {
  home: {
    indicatePreferred: 'Indicate your preferred pick-up location',
    searchAddress: 'Search the address and/or move the pin to your preferred pick-up location',
    setLater: 'Set later',
  },
  kvkk: {
    approveDocument: 'I have read and agree',
  },
};
