export default {
  title: 'Title',
  notifications: 'Notifications',
  noResults: 'No Results',
  notificationDetail: 'Notification Detail',
  newNotification: 'New Notification',
  date: 'Date',
  message: 'Message',
  route: 'Route',
  recipients: 'Recipient(s)',
  file: 'File',
  users: 'users',
  personnel: 'Personeller',
  allRoutes: 'All routes',
  searchInResults: 'Search in results',
  allCategories: 'All Categories',
  administrative: 'Administrative',
  operation: 'Operation',
  tickets: 'Tickets',
  ridersOnARoute: 'Riders on a route',
  ridersOnACampus: 'Riders on a campus',
  ridersRelatedToAShuttleProvider: 'Riders related to a shuttle provider',
  includePassiveUsers: 'Include passive users',
  selectRoute: 'Select Route',
  campus: 'Campus',
  selectCampus: 'Select Campus',
  shuttleProvider: 'Shuttle Provider',
  selectShuttleProvider: 'Select Shuttle Provider',
  typeMessageTitle: 'Type message title',
  typeMessage: 'Type message...',
  attachments: 'Attachments (Optional)',
  send: 'Send',
  cancel: 'Cancel',
  notificationSent: 'Notification sent ✓',
  files: 'Files',
  none: 'None',
  recipient: 'Recipient',
  searchRecipientName: 'Search recipient name',
  filterByCampus: 'Filter by Campus',
  filterByServiceUsers: 'Filter by Service Users',
  allServices: 'All Services',
  specificProviders: 'Specific Providers',
  specificRoutes: 'Specific Routes',
  areYouSure: 'Are you sure?',
  content: 'Content',
  'SSE-NEW-FLEXIRIDE-REQUEST': 'New Flexiride Request',
  'SSE-NEW-FLEXIRIDE-CHANGE-REQUEST': 'New Flexiride Change Request',
  'SSE-NEW-FLEXIRIDE-DRAFT-REQUEST': 'New Flexiride Draft Request',
  'SSE-UPDATED-FLEXIRIDE-REQUEST': 'Updated Flexiride Request',
  'SSE-CANCELED-FLEXIRIDE-REQUEST-BY-EA': 'Canceled Flexiride Request by EA',
  'SSE-CANCELED-FLEXIRIDE-REQUEST-BY-RIDER': 'Canceled Flexiride Request by Rider',
  'SSE-SKIPPED-FLEXIRIDE-REQUEST-BY-DRIVER': 'Skipped Flexiride Request by Driver',
  sendTheNotification: 'Send the notification',
  onScheduledTime: 'On scheduled time',
  scheduledNotificationMessage: 'The notification will be sent to {0} riders on {1}, at {2}',
  confirmScheduledNotificationMessage:
    'Please confirm if you would like the notification to be sent to {0} riders on {1}, at {2}',
  notificationSentMessage: 'The notification has been sent to {0} riders',
  confirmSendNotificationMessage: 'Please confirm if you would like to send the notification to selected riders',
  sendTheNotificationToAllRidersOn: 'Please confirm if you would like to send the notification to the selected riders.',
  ridersLastTwoMonths: 'Riders who have used this service in the last 2 months will receive this notification.',
};
