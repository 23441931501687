export default {
  settings: 'Ayarlar',
  generalSettings: 'Genel Ayarlar',
  smsProvider: 'SMS Sağlayıcısı',
  otpProvider: 'OTP Sağlayıcısı',
  shutdownMessage: 'Kapama mesajı',
  showShutdownMessage: 'Kapama mesajı gösterilecek',
  dontShowShutdownMessage: 'Kapama mesajı gösterilmeyecek',
  showMessage: 'gösterilecek',
  dontShowMessage: 'gösterilmeyecek',
  selectEmailList: 'Email Listesi Seç',
  changedSmsProvider: 'SMS Sağlayıcısı Değiştirildi.',
  notChangedSmsProvider: 'SMS Sağlayıcısı Değiştirilemedi',
  changedOtpProvider: 'OTP SMS Sağlayıcısı Değiştirildi',
  notChangedOtpProvider: 'OTP SMS Sağlayıcısı Değiştirilemedi',
  areYouSureToChange: 'Değişiklik yapmak istediğinize emin misiniz ?',
  unsavedChanges: 'Kaydedilmemiş değişiklikler var!',
  rental: {
    isRentalSearchRequestEnabled: 'Arama İstekleri Açık',
    valetSearchForSecondRentalAllowed: 'Valeli Aramaları Sözleşmeli Müşteriler Kullanabilir',
    cancelSelfServiceRentalOnDeviceProblem: 'Cihaz probleminde self servisi iptal et',
    enableAutomaticDebtCollection: 'Otomatik bakiye çekimi',
    workingHours: 'Çalışma Saatleri',
    workingHoursWeekend: 'Hafta Sonu Çalışma Saatleri',
  },
  payment: {
    enableProvisionVoid: 'Otomatik olarak provizyonlari iptal et',
  },
  proximity: {
    useVehicleInTransferToPark: 'Otopark görevi atanan araçları kullan',
  },
  notification: {
    enableSendFirstRentalMail: 'İlk kiralama için email gönder.',
    inBlackList: 'Blacklist',
    dailyReport: 'Günlük Rapor',
    outOfServiceEmails: 'Araç Pasif Uyarısı',
    changedRentalDeliveryJob: 'changedRentalDeliveryJob',
    inconsistentVehicleStatus: 'changedRentalDeliveryJob',
    dailyDriverPerformanceReport: 'Günlük Sürücü Performans',
    collectionNotCreated: 'collectionNotCreated',
    contractNotCreated: 'Kontrat yaratılamadı',
    resetSmsWarningEmails: 'resetSmsWarningEmails',
    odometerJump: 'odometerJump',
    rating: 'Rating',
  },
  sap: {
    notificationEmails: 'SAP Notification Emails',
  },
  androidVersion: 'Android - Force Update Versiyon No',
  iosVersion: 'IOS - Force Update Versiyon No',
  latestAndroidVersion: 'Android - Son Versiyon No',
  latestIosVersion: 'IOS - Son Versiyon No',
  notificationMessage: 'Bilgilendirme Mesajı',
  forceUpdateText: 'Force Update Metni',
  filteredScores: 'Seçilen başlangıç tarihinden itibaren maksimum 1 aylık veri getirilir.',
  newProcessOpen: 'Yeni Süreç AÇIK',
  newProcessClosed: 'Yeni Süreç KAPALI',
  registrationFlowParameters: 'Kayıt Akışı Parametre Yönetimi',
};
