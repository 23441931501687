/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import settingsService from '../../services/settings';

export default {
  state: {
    location: {
      workingHours: undefined,
      checkinTimeInMin: 0,
      loadDimensions: {},
      // application: {},
    },
    skills: [],
    vehicleFeatures: [],
    routingType: undefined,
  },

  mutations: {
    SET_WORKING_HOURS(state, hours) {
      state.location.workingHours = hours;
    },
    SET_SETUP_TIME_IN_MIN(state, mins) {
      state.location.checkinTimeInMin = mins;
    },
    SET_DEFAULT_ROUTING_TYPE(state, type) {
      state.routingType = type;
    },
    SET_LOAD_DIMENSIONS(state, dimensions) {
      state.loadDimensions = dimensions;
    },
    SET_SKILLS(state, skills) {
      state.skills = skills;
    },
    SET_VEHICLE_FEATURES(state, features) {
      state.vehicleFeatures = features;
    },
    SET_APPLICATION_SETTINGS(state, settings) {
      state.application = settings;
    },
  },

  actions: {
    async LOCATION_SETTINGS({ commit, state }) {
      const r = await settingsService.getLocationSettings();
      commit('SET_WORKING_HOURS', r.workingHours);
      commit('SET_SETUP_TIME_IN_MIN', r.checkinTimeInMin);
      commit('SET_DEFAULT_ROUTING_TYPE', r.routingType);
    },
    async LOAD_DIMENSIONS({ commit }) {
      const r = await settingsService.getLoadDimensions();
      commit('SET_LOAD_DIMENSIONS', r);
    },
    async DRIVER_SKILLS({ commit }) {
      const r = await settingsService.getSkills();
      commit('SET_SKILLS', r);
    },
    async VEHICLE_FEATURES({ commit }) {
      const r = await settingsService.getVehicleFeatures();
      commit('SET_VEHICLE_FEATURES', r);
    },
    async APPLICATION_SETTINGS({ commit }) {
      const r = await settingsService.getApplicationSettings();
      commit('SET_APPLICATION_SETTINGS', r);
    },
  },

  getters: {
    workingHours(state) {
      // return state.location.workingHours;
      if (!state.location.workingHours) return [];
      const keys = Object.keys(state.location.workingHours);
      return keys.map((el) => {
        const times = state.location.workingHours[el][0].split('-');
        return {
          day: el,
          start: times[0],
          end: times[1],
        };
      });
    },
    checkinTimeInMin(state) {
      return state.location.checkinTimeInMin;
    },
    activeLoadDimensions(state) {
      const keys = Object.keys(state.loadDimensions);
      const dimensions = [];
      keys.forEach((el) => {
        // if (state.loadDimensions[el].isActive) dimensions.push(state.loadDimensions[el]);
        if (state.loadDimensions[el].name) {
          dimensions.push({
            key: el,
            ...state.loadDimensions[el],
          });
        }
      });
      return dimensions;
    },
    loadDimension(state) {
      return (id) => {
        if (!state.loadDimensions[`loadDimension${id}`]) return { name: `loadDimension${id}` };
        return state.loadDimensions[`loadDimension${id}`];
      };
    },
    applicationSettings(state) {
      return state.application;
    },
    vehicleFeatures(state) {
      return new Map(state.vehicleFeatures.map((el) => [el.id, el]));
    },
    driverSkills(state) {
      return new Map(state.skills.map((el) => [el.id, el]));
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
