export default {
  stationType: 'İstasyon Tipi',
  createNew: 'Yeni Yarat',
  regionPermissions: 'Bölge İzinleri',
  serviceAreas: 'Servis Alanları',
  canSelectMultiple: 'Birden fazla bölge seçilebilir.',
  activeRegion: 'Bu bölge aktif',
  coordinates: 'Koordinatlar',
  dailyPriceCatalog: 'Günlük Katalog',
  selfPriceCatalog: 'Self Servis Katalog',
  minute: 'Dakikalık',
  eligibleNow: 'Şu an geçerli',
  minuteCatalog: 'Dakikalık Katalog',
  whitelist: 'Beyaz Liste',
  ageBound: 'Yaş Kısıtlaması',
  downloadList: 'Listeyi İndir',
  timeIntervalDeleted: 'Zaman Aralığı Silindi',
  makeTimeSlotDefault: 'Bu zaman aralığını varsayılan olarak ayarla',
  timeSlots: 'Zaman Pencereleri',
  timeSlotCreated: 'Zaman Aralığı Oluşturuldu',
  timeSlotUpdated: 'Zaman Aralığı Güncellendi',
  forbiddenAreas: 'Yasaklı Alanlar',
  serviceArea: 'Servis Bölgesi',
  mobileView: 'Mobil Görünürlük:',
  assignZone: '{assignZone} plakalı araç için bölge ataması',
  selectServiceArea: 'Servis Bölgesi Seçiniz',
  notInBorders: 'Bu araç hiç bir istasyon sınırı içerisinde değildir, atama yapılamaz.',
};
