export default {
  reservations: 'Rezervasyonlar',
  status: {
    CREATED: 'Oluşturuldu',
    WAITING_PAYMENT: 'Ödeme Bekleniyor',
    WAITING_RENTAL: 'Kiralama Başlatma Bekleniyor',
    ACTIVE: 'Aktif',
    CANCELED: 'İptal Edildi',
    FINISHED: 'Tamamlandı',
  },
  parameters: 'Parametreler',
  serviceAreaOrder: 'Hizmet Bölgesi Sıralaması',
  rentalPeriod: 'Kiralama Süresi',
  rentalCost: 'Kiralama Tutarı',
  reservationId: 'Rez. No(id)',
  startStationAddress: 'Alış Yeri',
  startDate: 'Alış Tarihi ve Saati',
  endStationAddress: 'Bırakma Yeri',
  endDate: 'Bırakma Tarihi ve Saati',
  kmLimit: 'Km Paket',
  kmPackageCost: 'Km Paketi Ücreti',
  serviceArea: 'Hizmet Bölgeleri',
  order: 'Sıralama',
  parameter: 'Parametre',
  rentalDays: 'Kiralama Gün Sayısı',
  pickupTime: 'Aracı Teslim Alma Zamanı',
  dropoffTime: 'Aracı Bırakma Zamanı',
  rentalTimeCost: 'Kiralama Süre Ücreti',
  cancelReservation: 'Rezervasyonu İptal Et',
  reservationWillBeCancelled: 'Rezervasyon iptal edilecek. Emin misin ?',
  reservationInfo: 'Rezervasyon Bilgileri',
  startStationName: 'Alış Lokasyon',
  endStationName: 'Bırakış Lokasyon',
  taxNumber: 'Vergi Numarası',
  firmCity: 'Firma İl Bilgisi',
  firmDistrict: 'Firma İlçe Bilgisi',
  addressLine: 'Açık Adres',
  firmMobile: 'Telefon Numarası',
  firmEmail: 'Email Adresi',
  firmTaxOfficeName: 'Firma Vergi Dairesi Adı',
  kmExceededCost: 'Km Aşım Ücreti',
};
