export default {
  searchText: 'Search by plate no, name or chasis no',
  addNewVehicle: 'Add New Vehicle',
  nameOptional: 'Name (Optional)',
  chasisNo: 'Chasis No',
  licenseSerialNo: 'License Serial No',
  averageCostPerMile: 'Average Cost per Mile',
  averageEmissionPerMile: 'Average Emission per Mile',
  depot: 'Depot',
  editVehicle: 'Edit Vehicle',
  BUTUN_DOKUMANLAR: 'All Documents',
  ARAC_FOTOGRAFI: 'Vehicle Photo',
  TRAFIK_SIGORTASI: 'Traffic Insurance',
  YOL_BELGESI: 'Traffic Document',
  RUHSAT: 'License',
  ISG: 'ISG Document',
  plateNumber: 'Plate Number',
  deviceStatus: 'Device Status',
  yearsOld: '({0} years old)',
  driverLicence: '{0} Class',
  driversLicence: 'Driver’s Licence',
  vehicleAdded: 'Vehicle Added.',
  vehicleDeleted: 'Vehicle Deleted',
  EHLIYET: 'Driver Licence',
  SABIKA_KAYDI: 'Criminal Record',
  devicesStatus: {
    OK: 'Okey',
    CIHAZ_YOK: 'No Device',
    BAGLANTI_YOK: 'No Connect',
  },
  vehicleChangeReasons: {
    ACCIDENT: 'Accident',
    VEHICLE_BREAKDOWN: 'Vehicle Breakdown',
    MAINTENANCE: 'Maintenance',
    OTHER: 'Other',
  },
  vehiclePlate: 'License Plate',
};
