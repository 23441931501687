// This is a modified version of repository.js in common folder.
// At the time of writing carshare and flexigp use different authentication methods

import Axios from 'axios';

// this is temporary, will be removed when we have a proper authentication
function redirectUrl() {
  const [base] = window.location.hostname.split('.');
  const urls = {
    localhost: 'http://localhost:5431',
    secure: 'https://secure.flexigo.com?lang=tr',
    portal: 'https://portal.flexigo.com?lang=en',
    secure3: 'https://secure3.vektorteknoloji.com/flexigo/',
  };

  return urls[base];
}

export default class {
  constructor(options) {
    this.createInstance(options);
  }

  createInstance(options = {}) {
    const headers = {
      EHM: 'Exception',
    };
    let defaultOptions = {
      responseType: 'json',
      timeout: 60000,
      headers,
    };

    defaultOptions = Object.assign(defaultOptions, options);
    const instance = Axios.create(defaultOptions);

    instance.interceptors.response.use(
      (response) => {
        if (response?.data?.error) {
          console.error(response.data.error, response.data.message);
          return Promise.reject(response.data);
        }
        return response;
      },
      (error) => {
        // Because of this specific API server doesnt return right HTTTP codes.
        // So it shouldnt enter here most of the time.
        console.log(error);
        console.log(error.response);
        if (error.response) {
          if (error.response.status === 400 && error.request.responseURL.includes('oauth/web/session/verify')) {
            // const redirectUrl = error.response.headers.location;
            // console.log('Redirect to:', redirectUrl);
            window.location.href = redirectUrl();
          }
          if (error.response.status === 403) {
            console.log('Unauthorized');
            window.location.href = redirectUrl();
          }
        }
        return Promise.reject(error);
      },
    );

    this.instance = instance;
    return instance;
  }

  async get(url, params = null, rawData = false) {
    const response = await this.instance.get(url, { params });
    return rawData ? response : response.data;
  }

  async post(url, payload) {
    const response = await this.instance.post(url, payload);
    return response.data;
  }

  async put(url, payload) {
    const response = await this.instance.put(url, payload);
    return response.data;
  }

  async delete(url, payload = undefined) {
    // delete method in axios 0.20 is broken, this is a workaround
    const response = await this.instance.request({
      url,
      data: payload,
      method: 'delete',
    });
    return response.data;
  }
}
