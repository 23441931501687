import common from './common.en';
import dashboard from './dashboard.en';
import date from './date.en';
import workgroup from './workgroup.en';
import groupList from './groupList.en';
import personnelList from './personnelList.en';
import onboarding from './onboarding.en';
import login from './login.en';
import splash from './splash.en';
import employee from './employee.en';
import routeList from './routeList.en';
import employeeList from './employeeList.en';
import company from './company.en';
import carpool from './carpool.en';
import tickets from './tickets.en';
import survey from './survey.en';
import flexiride from './flexiride.en';
import driver from './driver.en';
import vehicle from './vehicle.en';
import notifications from './notifications.en';
import reports from './reports.en';
import rideShared from './rideShared.en';
import rider from './rider.en';
import modules from './modules.en';

export default {
  common,
  dashboard,
  date,
  workgroup,
  groupList,
  personnelList,
  onboarding,
  login,
  splash,
  employee,
  routeList,
  employeeList,
  company,
  carpool,
  tickets,
  survey,
  flexiride,
  driver,
  vehicle,
  notifications,
  reports,
  rideShared,
  rider,
  modules,
};
