export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isSuperAdmin() {
      return this.$store.getters.user.scopes.includes('GARENTA_V_ADMIN');
    },
  },
};
