export default {
  blackList: 'Kara Liste',
  updateDrivingLicence: 'Ehliyet Güncelle',
  updateGender: 'Cinsiyet Güncelle',
  updateSelfie: 'Selfi Güncelle',
  customerInBlackList: 'Müşteri Kara Listede',
  reason: 'Nedeni',
  balanceWithDownPayment: 'Ön Ödemeli Bakiye',
  bonusBalance: 'Bonus Bakiye',
  tiktakPersonnel: 'Tiktak Çalışanı',
  totalCrash: 'Toplam Kaza Adedi',
  customerNegativeBalance: 'Eksi Bakiyeli Müşteriler',
  debitBalance: 'Borç Bakiyesi (TL)',
  yourApproval: 'Senin onayın bekleniyor.',
  assignmentWaiting: 'Atama bekleniyor.',
  trackingStatus: 'İzleme Durumu',
  notInTracking: 'İzlemede değil',
  unfollow: 'Takipten Çıkar',
  followUp: 'Takibe Alma / Çıkarma',
  exitPermit: 'Power User',
  emailVerified: 'Email Doğrulanmış',
  emailNotVerified: 'Email Doğrulanmamış',
  corporateMembershipDate: 'Kurumsal Üyelik Tarihi',
  verifiedPhone: 'Doğrulanmış Telefon',
  notVerifiedPhone: 'Doğrulanmamış Telefon',
  mobileVerification: 'Mobil OTP Doğrulama',
  passportNo: 'Pasaport Numarası',
  dateOfEntry: 'Ülkeye Giriş Tarihi',
  isBirtday: 'Bugun bu müşterinin doğum günü!',
  linkedin: 'linkedin',
  softwareVersion: 'Yazılım Ver.',
  passiveReason: 'Pasif Nedeni',
  rental: 'kiralama',
  customerConcluded: 'Müşteri sözleşmeyi imzaladı',
  checkedDriverLicense: 'Ehliyet Kontrolünü yaptım.',
  documentNo: 'Belge No',
  placeOfIssue: 'Veriliş Yeri',
  issueDate: 'Veriliş Tarihi',
  dateOfValidity: 'Geçerlilik Süresi',
  registeredCards: 'Kayıtlı Kartlar',
  card: 'Kart',
  makePayment: 'Çekim yap',
  maxAmount: 'Çekilebilecek Maksimum Miktar',
  notWithdrawn: 'Para Çekilemedi',
  moneyWithdrawn: 'Para Çekildi',
  addMonetary: 'Parasal işlem ekle',
  lastPhotoVehicleFront: 'Aracın son fotoğrafı (ön)',
  lastPhotoVehicleBack: 'Aracın son fotoğrafı (arka)',
  lastPhotoVehicleLeft: 'Aracın son fotoğrafı (sol)',
  lastPhotoVehicleRight: 'Aracın son fotoğrafı (sağ)',
  citizenNo: 'TC Kimlik No',
  addReason: 'Ekleme Sebebi',
  addToBlackList: 'Kara Listeye Ekle',
  partialRefund: 'Kısmi İade',
  fullRefund: 'Tam İade',
  refundAmount: 'İade Tutarı',
  maxAmountRefund: 'İade Edilebilecek Maksimum Miktar',
  selectRefundReason: 'İade Nedenini Seçiniz',
  reverseRefund: 'Yapacağınız iade işleminin ters kaydı otomatik olarak sistem tarafından oluşturulsun mu ?',
  platform: 'Platform',
  createdBy: 'Yaratan',
  entryLocation: 'Giriş',
  entryDate: 'Giriş Tarihi',
  exitLocation: 'Çıkış',
  exitDate: 'Çıkış Tarihi',
  deleteSelectedCustomer: 'Seçilen müşteriyi kara listeden silmek istediğine emin misin ?',
  updatedDrivingLicence: 'Ehliyet bilgileri güncellendi.',
  deleteCustomer: 'Müşteriyi Sil',
  membershipCancelled: 'Üyelik iptal edilmiştir.',
  customerDeleted: 'Bu kullanıcı silinmiştir.',
  photograph: 'Fotoğraf',
  requestDate: 'Talep Tarihi',
  rentalReason: 'Kiralama Nedeni',
  overallScore: 'Puan (Genel)',
  cleanlinessScore: 'Puan (Temizlik)',
  avgScore: 'Puan (Ortalama)',
  rentalId: 'Kiralama Id',
  transaction: 'İşlem',
  cardName: 'Kart İsmi',
  refund: 'İade Et',
  amountRefund: 'Miktar(iade)',
  explanationIncıdent: 'Kaza Açıklama',
  dateHour: 'Tarih / Saat',
  legalFollowup: 'Yasal Takibe Al',
  notes: 'Notlar',
  noteWillDelete: 'Bu not silinecek',
  changingGsm: 'GSM Değişikliği ve Doğrulama',
  sendOtp: 'OTP Gönder',
  verifyPassword: 'Kullanıcıdan alınan tek kullanımlık şifreyi doğrulayın.',
  sendPasswordAgain: 'Tekrar Şifre Gönder',
  completed: 'tamamlanmış',
  activeRental: 'aktif kiralama',
  totalAfterDiscount: 'İndirimlerden sonradaki toplam',
  membershipStatus: 'Üyelik Durumu',
  delete: 'Sil',
  deleted: 'İptal Edildi',
  searchPlaceholder: 'Arama kriterlerini giriniz',
  repeatSearchPlaceholder: 'Tekrar arama metni yazıp enter\'a basın',
  searchMaxResults: 'En fazla 200 arama sonucu gösterilmektedir',
  searchAlternative: 'Aramanızı sınırlandırarak arama yapın',
  enableCustomer: 'Müşteriyi aktive etmek için tıkla',
  disableCustomer: 'Müşteriyi pasife çekmek için tıkla',
  enabled: 'Aktif',
  disabled: 'Pasif',
  confirm: 'Emin misin?',
  confirmEnable: 'Müşteriyi aktive etmek istediğine emin misin?',
  confirmDisable: 'Müşteriyi pasife çekmek istediğine emin misin?',
  confirmCancel: 'Üyeliği iptal etmek istediğine emin misin? Bu hareket geri alınamaz!',
  editCustomer: 'Müşteri Güncelle',
  active: 'Aktif',
  rejected: 'Reddedildi',
  passive: 'Pasif',
  pending: 'Onay Bekliyor',
  new: 'Başvuru Aşamasında',
  reevaluate: 'Yeniden Değerlendirilecek',
  tiktakEmployee: 'Bu kişi Tiktak Çalışanı',
  customerUpdated: 'Müşteri Güncellendi',
  pleaseSelectRecord: 'Lütfen bir kayıt seçin',
  off: 'Kapalı',
  activateReason: 'Aktive Etme Nedeni',
  membershipDocuments: 'Üyelik Dokümanları',
  change: 'Değişiklik',
  customerStatus: {
    legalFollowup: 'Yasal Takip',
    deleted: 'Silinmiş',
    active: 'Aktif',
    rejected: 'Reddedildi',
    passive: 'Pasif',
    pending: 'Onay Bekliyor',
    new: 'Başvuru Aşamasında',
    reevaluate: 'Yeniden Değerlendirilecek',
    creationDate: 'Oluşturulma Tarihi {0} {1} gün',
    registered: 'Kaydı Oluşturuldu',
  },
  receivePayment: 'Ödeme Al',
  rentals: 'Kiralamalar',
  payments: 'Ödemeler',
  documents: 'Dokümanlar',
  creditCards: 'Kredi Kartları',
  transactions: 'Hesap Hareketleri',
  transactionHistory: 'İşlem Geçmişi',
  hgs: 'HGS',
  codes: 'Kodlar',
  messageManagementSystem: 'IYS',
  incidents: 'Kazalar',
  stationPermissions: 'İstasyon İzinleri',
  devices: 'Cihazlar',
  addresses: 'Adresler',
  stationPermission: 'İstasyon İzni',
  brandModel: 'Marka - Model',
  mobileId: 'Mobil Id',
  loginDate: 'Login Tarihi',
  deviceApproval: 'Cihaz Onayı',
  addressNotUpdated: 'Adres Güncellenemedi',
  workflow: {
    documentTypes: {
      DRIVING_LICENSE: 'Ehliyet',
      IDENTITY: 'Kimlik',
      PASSPORT: 'Pasaport',
      DIGITAL_AGREEMENT: 'E-Sözleşme',
      SIGNED_AGREEMENT: 'Sözleşme',
      SELFIE: 'Selfi',
      ISPARK_INVOICE: 'İspark Fişi',
      SUBSCRIPTION_AGREEMENT: 'Abonelik Sözleşmesi',
      TERMS_OF_USE: 'Kullanım Koşulları',
    },
    documentStatus: {
      ACTIVE: 'Aktif',
      REJECTED: 'Reddedildi',
      PENDING_APPROVAL: 'Onay Bekliyor',
      REPLACED: 'Yenisiyle değiştirildi',
    },
    poolTypes: {
      myTasks: 'Görevlerim',
      customerDocumentApprove: 'Müşteri Doküman Onayı',
      vehicleCleaningControl: 'Araç Temizlik Kontrolü',
    },
    ocrFields: {
      IDENTITY_NUMBER: 'TCKN',
      NAME: 'Adı',
      SURNAME: 'Soyadı',
      DRIVING_LICENSE_NO: 'Ehliyet No',
      DRIVING_LICENSE_ISSUE_DATE: 'Ehliyet Veriliş',
      DRIVING_LICENSE_VALID_UNTIL: 'Ehliyet Geçerlilik',
      DRIVING_LICENSE_CLASS: 'Ehliyet Sınıfı',
    },
  },
  current: 'Güncel',
  onTask: 'Görev {task} üzerinde',
  createdDate: 'Oluşturulma Tarihi: {date}',
  activatedDate: 'Aktivasyon Tarihi: {date}',
  approvedBy: 'Bu doküman {documentType} {approvedDate} {date} {approvedBy} tarafından onaylandı.',
  onDate: 'tarihinde',
  userInBlacklist: 'Kullanıcı {passive} veya karalistede.Sözleşme imzalanabilmesi için aktif kullanıcı olmalıdır.',
  inBlacklist: 'Karalistede.',
  missing: 'eksik',
  missingDrivingLicence: 'Ehliyet bilgileri {missing} olduğu için onaylanamaz. Müşterinin ehliyet bilgilerini eksiksiz girdiğinden emin olun.',
  showNegativeBalanceCustomer: 'Eksi Bakiyeli Müşterileri Göster',
  stationPermissionChanged: 'İstasyon İzni Değiştirildi',
  ocrInformations: 'Ocr Bilgileri',
  undoApproval: 'Onayı geri al',
  tabs: {
    rentals: '#kiralamalar',
    payments: '#ödemeler',
    documents: '#dökümanlar',
    creditCards: '#kredi-kartları',
    transactions: '#hesap-hareketleri',
    transactionsHistory: '#işlem-geçmişi',
    hgs: '#hgs',
    codes: '#kodlar',
    iys: '#iys',
    incidents: '#kazalar',
    stationPermissions: '#istasyon-izinleri',
    devices: '#cihazlar',
    addresses: '#adresler',
    subscription: '#abonelik',
    instantOffer: '#özel-teklif',
  },
  sendToApprove: 'Onaya Gönder',
  deleteImmediately: 'Hemen Sil',
  confirmDeleteImmediately: 'Üyelik tamamen silinecek. Emin misin ? ',
  membershipWillHaveDeleted: 'Bu işlem ile üyelik tamamen silinecek. Bu aksiyonu almak istediğine emin misin ? Bu aksiyon geri alınamaz.',
  redirectToManuel: 'Üyelik Başvurusunu Manuel\'e Yönlendir',
  additionalDocuments: 'Ek Dokümanlar',
  sgkDocument: 'E-Devlet SGK Dokümanı',
  studentDocument: 'E-Devlet Öğrenci Belgesi',
  demographicScore: 'Demografik Kaza Risk Skoru',
  rentalBehaviorScore: 'Kiralama Davranışlı Kaza Risk Raporu',
  customerCount: 'Kiralamadaki Müşteri Sayısı',
  demographicScoreAvg: 'Demografik Risk Skor Ortalaması',
  damageCostScore: 'Maliyet Bazlı Müşteri Risk Skoru',
  rfmClass: 'RFM Sınıfı',
  rentalBehaviorCount: 'Kiralamadaki Riskli Müşteri Sayısı',
  rentalBehaviorScoreAvg: 'Kiralama Risk Skor Ortalaması',
  noRental: 'Kiralama Yapmamış',
  notLoggedIn: 'Uygulamayı Açmamış',
  securityMeasures: 'Güvenlik Önlemi',
  hasLockDueToLocationChange: 'Konum Zıplaması',
  removeLock: 'Kilitleri Kaldır',
  hasFuelBlockageSupport: 'Yakıt kesme montajı yapıldı mı ?',
  setFuelBlockage: 'Yakıt Kesme Gönder',
  unsetFuelBlockage: 'Yakıt Kesme Kaldır',
  confirmRemoveLock: 'Kilidi kaldırmak istediğinize emin misiniz ?',
  deletedCustomerTransactions: 'Silinen Müşteriye Yapılacak İşlemler',
  deviceCreationTime: 'Cihaz Değişikliği Tarihi',
  customerLogoutConfirm: 'Müşterinin sessionu\'nu sonlandırmak istediğine emin misin ?',
  qualificationMarkType: 'Skor İzleme Durumu',
  membershipHistory: 'Üyelik Kontrol Geçmişi',
  approved: 'Onaylandı',
  failedToVerify: 'Doğrulama Yapılamadı',
  approvalStatus: 'Onay Durumu',
  citizenIdVerified: 'TCKN Doğrulanmış',
  citizenIdNotVerified: 'TCKN Doğrulanmamış',
  registerFlow: 'Register Kanalı',
  dailyRentalExtend: 'Günlük Kiralama Uzatma',
  dailyKmPackage: 'Günlük Paket Alımı',
};
