export default {
  available: 'Available Vehicles',
  rentable: 'Rentable Vehicles',
  daily: 'Daily',
  user: 'Users',
  minute: 'Minute',
  general: {
    title: 'General',
    minDays: 'Minumum Days',
    maxDays: 'Maximum Days',
    dayCount: 'Day Count',
  },
  avgs: {
    title: 'Averages',
    price: 'Price',
    vehicleCount: 'Vehicle Count',
    waitTime: 'Avg. Waiting Time for a rental',
  },
  totals: {
    title: 'Totals',
    start: 'Rental Start',
    end: 'Rental End',
    users: 'Customers',
    income: 'Income',
  },
  selfService: {
    title: 'Rentals (Self Service)',
    searching: 'User Searches',
    success: 'Successful Searches',
    inRegion: 'User count in region',
    all: 'All Searches',
  },
  assignJob: 'Assign Job',
  streetView: 'Street View',
  vehicleFeatures: 'Vehicle Features',
  breakdown: 'Breakdown',
  searchCategories: {
    AVAILABLE_VEHICLE_NOT_FOUND: 'Available Vehicle Not Found',
    NOT_IN_WORKING_HOURS: 'Not in Working Hours',
    SUCCESS: 'Success',
    RENTAL: 'Rental',
    RENTAL_END: 'Rental End',
    RENTAL_SELFSERVICE: 'Rental Self Service',
    NO_VALET_SERVICE: 'There is no valet service',
    NOT_IN_SERVICE_AREA: 'Not in Service Area',
    SEARCH_MAX_REQUEST: 'Maximum Request',
    SUCCESS_SELFSERVICE: 'Success (Self Service)',
    FAIL_SELFSERVICE: 'Fail (Self Service)',
    RADAR_RESPONSE: 'Radar Completed',
    RADAR_REQUEST: 'Radar Active',
    RADAR_EXPIRED: 'Radar Expired',
    RADAR_CANCELED: 'Radar Canceled',
  },
  selectTimePeriod: 'Select Time Period',
  selectCriterion: 'Select Criterion',
  rentalCount: 'Rental Count',
  income: 'Income',
  incomeCount: 'Income Count',
  showJobs: 'Show Jobs',
  morning: 'Morning( 07 am- 10 am)',
  noon: 'Noon( 10 am - 13 pm )',
  afternoon: 'Afternoon(13 pm - 17 pm)',
  evening: 'Evening(17 pm - 23 pm)',
  night: 'Night(23 pm - 02 am)',
  midnight: 'Midnight(02 am - 07 am)',
  rentalPercentage: 'Rental Percentage',
  incomePercentage: 'Income Percentage',
};
