export default {
  status: 'Statü',
  pickupPoint: 'Pickup Point',
  dropoffPoint: 'Drop-off Point',
  plannedPickupTime: 'Planlanan Biniş Saati',
  plannedDropoffTime: 'Planlanan İniş Saati',
  actualPickupTime: 'Gerçekleşen Biniş Saati',
  actualArrivalTime: 'Gerçekleşen Varış Zamanı',
  actualDropoffTime: 'Gerçekleşen İniş Saati',
  driver: 'Sürücü',
  noAssignedDriver: 'Atanmış sürücü yok',
  vehicle: 'Araç',
  user: 'User',
  rider: 'Kullanıcı',
  editRoute: 'Edit Route',
  approveRoute: 'Approve Route',
  date: 'Tarih',
  mode: 'Mod',
  startingMileage: 'Başlangıç Kilometresi ({0})',
  endingMileage: 'Bitiş Kilometresi ({0})',
  startingPoint: 'Başlangıç ​Noktası',
  endingPoint: 'Bitiş ​​Noktası',
  endingTime: 'Bitiş Zamanı',
  startingTime: 'Başlama Zamanı',
  tripType: 'Gezi Türü',
  tripPurpose: 'Gezi Amacı',
  description: 'Açıklama',
  invoice: 'Fatura',
  totalMileage: 'Toplam Kilometre ({0})',
  declaredCost: 'Beyan Edilen Tutar ({0})',
  toBePaid: 'Ödenecek Tutar',
  confirmSelected: 'Seçileni Onayla',
  rejectSelected: 'Seçileni Reddet',
  markSelectedPaid: 'Seçileni Ödendi Olarak İşaretle',
  belowEmployeesStatusCouldntUpdated:
    'Aşağıdaki çalışanların durumu güncellenemedi. Durumlarını ücretli olarak güncellemek için durumlarını onaylandı olarak değiştirmelisiniz',
  addNote: 'Not Ekle',
  deleteLocation: 'Bu konumu silmek istediğinizden emin misiniz?',
  locationHasBeenDeleted: 'Konum silindi',
  tripExtendedforMinutes: 'Yolculuk {0} dakika uzatıldı.',
  activatedRequests: 'Aktif İstekler',
  file: 'Dosya',
  question: 'Soru',
  response: 'Cevap',
  eventStatus: {
    CANCELLED: 'İptal Edildi',
    REQUESTED: 'Talep Edildi',
    AWAITING_PLANNING: 'Planlama Bekleniyor',
    PLANNED: 'Planlandı',
    ASSIGNED: 'Atandı',
    NOT_STARTED: 'Başlamadı',
    ENROUTE_TO_PICKUP: 'Almaya Gidiyor',
    AWAITING_PICKUP: 'Almayı Bekliyor',
    ENROUTE_TO_DROPOFF: 'Bırakmaya Gidiyor',
    AWAITING_DROPOFF: 'Bırakmayı Bekliyor',
    COMPLETED: 'Tamamlandı',
    CHANGE_REQUEST: 'Değişiklik İsteği',
    ON_TIME: 'Zamanında',
    DELAYED: 'Gecikti',
    ACTIVE: 'Aktif',
    DRAFT: 'Taslak',
    CHANGE_REQUEST_CANCELLED: 'İptal Edildi',
    BY_ADMIN: 'by Admin',
    BY_EA: 'by EA',
    BY_RIDER: 'by Rider',
    BY_SELF: 'by Rider',
    AUTOMATICALLY: 'by Automatically ',
    BY_DRIVER: 'Skipped by Driver',
    BY_ASSISTANT: 'by Assistant',
    AT_PLANNING: 'Planlamada',
    NO_OFFER: 'Öneri Sunulamadı',
  },

  requestStatus: {
    PENDING: 'Beklemede',
    APPROVED: 'Onaylandı',
    NO_OFFER: 'Müsaitlik yok',
    PLANNED: 'Planlandı',
    REJECTED: 'Reddedildi',
    CANCELLED: 'İptal Edildi',
    FINISHED: 'Tamamlandı',
    IN_PROGRESS: 'Devam Ediyor',
    PENDING_CHANGE: 'Değişiklik Bekliyor',
    DRAFT: 'Taslak',
    CONFIRMED: 'Onaylandı',
    CANCELED: 'İptal Edildi',
    CREATED: 'Oluşturuldu',
    PAID: 'Ödendi',
  },

  pointStatus: {
    NOT_STARTED: 'Başlamadı',
    ON_TIME: 'Tam Zamanında',
    DELAYED: 'Gecikmeli',
    ARRIVED_ON_TIME: 'Tam Zamanında Geldi',
    ARRIVED_WITH_DELAY: 'Gecikmeli Geldi',
    PICKED_UP_ON_TIME: 'Tam Zamanında Alındı',
    PICKED_UP_WITH_PASSENGER_DELAY: 'Yolcu Gecikmesiyle Alındı',
    PICKED_UP_WITH_DRIVER_DELAY: 'Sürücü Gecikmesiyle Alındı',
    DELIVERED_ON_TIME: 'Tam Zamanında Teslim Edildi',
    DELIVERED_WITH_DELAY: 'Gecikmeli Teslim Edildi',
  },
  request: {
    emailHeader: 'The request has been drafted from the following e-mail sent by {0}  on {1}',
    pickupTime: 'Biniş Saati',
    dropOffTime: 'Drop-off Time',
    pickupDropoffTimes: 'Biniş Saati - <br> İniş Saati',
    submitRequest: 'Submit Request',
    saveDraft: 'Taslağı Kaydet',
    sourceEmail: ' Kaynak E-Posta',
    alReadyRequestMessage:
      'Zaten aynı saatler için bir yolculuk talebiniz var. Mevcut talebi silip yeni bir yolculuk talebi göndermek ister misiniz?',
    keeptheExistingRequest: 'Mevcut İsteği Koru',
    makeaNewRequest: 'Yeni Talep Oluştur',
    noNotesAdded: 'Not Eklenmedi',
    youCanDefaultNoteLocation: 'Konum için varsayılan not ekleyebilirsiniz',
    thisTripCannotBeExtended: 'Bu gezi uzatılamaz',
    rideData: 'Yolculuk Tarihi',
    rider: 'Personel',
    pickupAddress: 'Biniş Adresi',
    requestedDropoffTime: 'Planlanan İniş Zamanı',
    acknowledgement: 'Sürücü Teyidi',
    actualDropoffTime: 'Gerçekleşen İniş Saati',
    plannedPickupTime: 'Planlanan Biniş Saati',
    riderCount: 'Rider Sayısı',
    vehicleRating: 'Araç Değerlendirmesi',
    cleanlinessEating: 'Temizlik Değerlendirmesi',
    overallRating: 'Genel Değerlendirmesi',
    actualPickupTime: 'Gerçekleşen Biniş Saati',
    requestStatus: 'Talep Durumu',
    times: 'Saatler',
    plannedActualPickup: 'Planlanan / Gerçekleşen Biniş Zamanı',
    plannedActualDropoff: 'Planlanan / Gerçekleşen İniş Zamanı',
    vehicle: 'Araç',
    driver: 'Sürücü',
    assistance: 'Assistance',
    required: 'Required',
    notRequired: 'Not Required',
    requestType: 'Request Type',
    preBooked: 'Pre-Booked',
    onDemand: 'On Demand',
  },
  newRequest: {
    pickupStop: 'A - Alış Noktası',
    dropoffStop: 'B - Bırakış Noktası',
    selectStop: 'Durak Seç',
    request: 'Talep Et',
    receiveTextMessage: 'Sürücü takibini içeren mesaj linkini yolcuya göndermek istiyorsanız işaretleyin',
    noteToDriver: 'Sürücüye Not',
    saveNote: 'Notu Kaydet',
    editNotes: 'Notları Düzenle',
    max100Char: 'En fazla 100 karakter',
    pickupDate: 'Alış Tarihi',
    pickupTime: 'Alış Saati',
    phoneNumber: 'Telefon Numarası',
    enterPhone: 'Telefon numarasını girin',
  },
  riderNewRequest: {
    receiveTextMessage: 'Yolculuk takibi için kısa mesaj bağlantısı almak isteyip istemediğinizi kontrol edin',
  },
  alert: {
    weAreChecking: 'Talep için uygun araçları kontrol ediyoruz…',
    description: 'Talebinize en yakın müsait zaman:',
    pickup: 'ALINAN DURAK',
    dropoff: 'BIRAKILAN DURAK',
    seconds: 'saniye',
    weCannotAccommodate: 'Talebiniz karşılanamadı.',
    optimizationFailed: 'Kampüste araç tanımlı olmadığı için optimizasyon çalıştırılamamıştır.',
    youHaveAnotherReservation:
      'Aynı saat aralığı için başka bir rezervasyonunuz bulunmaktadır. Lütfen yeni bir rezervasyon talep etmeden önce mevcut rezervasyonunuzu iptal ediniz.',
    ok: 'Tamam',
    reservationSuccessful: 'FlexiRide Paylaşımlı rezervasyon başarıyla gerçekleştirildi',
    didntRespond: 'Belirtilen süre içinde yanıt vermediniz.',
    reSubmit: 'Talebi Tekrar Gönder',
    createNewRequest: 'Yeni Talep Oluştur',
    pleaseConfirm: 'Lütfen bunun sizin için uygun olup olmadığını teyit edin.',
  },
  modeStatus: {
    COMPANY_CAR: 'Şirket Arabası',
    PERSONAL_CAR: 'Kişisel Araba',
    TAXI: 'Taksi',
    RIDE_HAILING: 'Araç Çağırma',
  },
  type: {
    PICKUP: 'Teslim Alma',
    DELIVERY: 'Teslim Etme',
  },
  requestSaveResults: {
    ALL_OK: 'The ride request has been updated',
    REPLANNING_NEEDED:
      ' All the requested stops cannot be assigned to the current driver due to daily planning and need to be re-planned.',
    FIRST_OK_OTHERS_NEED_PLANNING:
      'The first ride has been updated as requested but the following rides can not be assigned to the current driver due to daily planning and need to be re-planned.',
    CANNOT_UPDATE: 'Your update request can not be executed. The driver tasks need to be updated.',
  },
  purposeType: {
    AIRPORT_PICKUP: 'Havaalanından Alma',
    AIRPORT_DROPOFF: 'Havaalanı Bırakma',
    MEETING: 'Toplantı',
    CUSTOMER_VISIT: 'Müşteri Ziyareti',
    OTHER: 'Diğer',
    AIRPORT_TRANSFER: 'Havaalanından Transferi',
    RIDE_HOME: 'Eve Gidiş',
    COMMUTE: 'Gidiş-geliş',
  },

  ridePurpose: {
    PERSONAL: 'Kişisel',
    BUSINESS: 'İş',
  },
  orderStatus: {
    NOT_STARTED: 'Not Started',
    CANCELLED: 'Cancelled',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
    DELETED: 'Deleted',
  },
  tracking: {
    estimated: 'Tahmin',
    planned: 'Planlanan',
    actual: 'Gerçekleşen',
    start: 'Hareket',
    arrival: 'Varış',
    departure: 'Bitiş',
    lastStopOnRoute: 'Bu rotadaki son durak.',
    allCampuses: 'Hepsi',
    select: 'Seçiniz',
    completedSteps: 'Tamamlanan Adım',
    min: 'dk',
    all: 'Hepsi',
    planning: 'Planlama',
    estimatedDuration: 'Tahmini Süre',
    distanceToNextStop: 'Bir sonraki hedefe uzaklık',
    minutes: 'dakika',
    early: 'erken',
    late: 'geç',
    routeStatus: {
      NOT_STARTED: 'Başlamadı',
      ACTIVE: 'Aktif',
      COMPLETED: 'Tamamlandı',
      DRAFT: 'Taslak',
      CANCELLED: 'İptal',
      NOT_CREATED: 'Oluşturulmadı',
    },
    plannedPickup: 'Planlanan Alış',
    actualPickup: 'Gerçek Alış',
    plannedDropoff: 'Planlanan Bırakma',
    actualDropoff: 'Gerçek Bırakma',
    progress: 'İlerleme',
    lastStatus: 'Son Durum',
    totalTasks: 'Toplam Görevler',
    cancellations: 'İptaller',
    delayedPickups: 'Gecikmiş Alımlar',
    delayedDropoffs: 'Gecikmiş Bırakmalar',
    pickupStop: 'Alım Noktası',
    dropoffStop: 'Bırakma Noktası',
    totalRequest: 'Toplam Talepler',
    skippedStops: 'Atlanan Duraklar',
    latePickups: 'Geç Alımlar',
    lateDropoffs: 'Geç Bırakmalar',
    requests: 'Talepler',
    totalPassenger: 'Yolcu sayısı',
  },
  confirmMessage: 'Bu ödemeyi onaylamak istediğinize emin misiniz?',
  rejectMessage: 'Bu ödemeyi reddetmek istediğinizden emin misiniz?',
  selectedConfirmMessage: 'Seçilen satır/satırları onaylamak istediğinizden emin misiniz?',
  selectedRejectMessage: 'Seçilen satır/satırları reddetmek istediğinizden emin misiniz?',
  markPaidMessage: 'Seçileni ödenmiş olarak işaretlemek istediğinizden emin misiniz?',
  selectedMarkPaid: `Seçili satır/satırları 'ödendi' olarak işaretlemek istediğinizden emin misiniz?`,
  pool: {
    status: {
      ASSIGNED: 'Rezerve',
      ENROUTE_TO_PICKUP: 'Enroute to Pickup',
      AWAITING_PICKUP: 'Awaiting Pickup',
      ENROUTE_TO_DROPOFF: 'Enroute to Dropoff',
      AWAITING_DROPOFF: 'Awaiting Dropoff',
      COMPLETED: 'Completed',
      NOT_STARTED: 'Not Started',
    },
  },
  riderCountLimitByCapacity: 'Yolcu sayısı araç kapasitesiyle sınırlıdır',
};
