export default {
  subscription: 'Subscription',
  packageName: 'Package Name',
  details: 'Details',
  labelPrice: 'Label Price',
  price: 'Price',
  labelDiscountPercentage: 'Label Discount Percentage',
  dailyFreeMinutes: 'Daily Free Minutes',
  selfServiceRentalDiscountRatio: 'Self Service Rental Discount Ratio',
  dailyRentalDiscountRatio: 'Daily Rental Discount Ratio',
  autoCreatedRentalDiscountRatio: 'Auto Created Rental Discount Ratio',
  subscriptionPackages: 'Subscription Packages',
  packages: 'Packages',
  updatePackages: 'Update Packages',
  generalSubscriptionDefinitions: 'General Subscription Definitions',
  contentManagement: 'Content Management',
  districts: 'Districts',
  subscriptionEnabled: 'Subscription Open / Close',
  periodCount: 'Period Count ( month )',
  freeUpgradeDays: 'Free Upgrade Days ( month )',
  activeness: 'Activeness',
  addNewLine: 'Add New Line',
  dailyMin: '{0} minutes free per day',
  noSubscription: 'There is no subscription',
  subscriptionInfo: 'Subscription Info',
  NOT_SUBSCRIBER: 'Not Subscriber',
  OLD_SUBSCRIBER: 'Old Subscriber',
  SUBSCRIBER: 'Subscriber',
  WAITING_CANCELLATION: 'Waiting Cancellation',
  WAITING_DOWNGRADE: 'Waiting Downgrade',
  subscriptionStatus: 'Subscription Status',
  isEligible: 'Eligible',
  firstSubscriptionDate: 'First Subscription Date',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  addMinutes: 'Add Minutes',
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  CREATED: 'Created',
  WAITING_PAYMENT: 'Waiting Payment',
  INACTIVE: 'Inactive',
  COMPLETED: 'Completed',
  DELETED: 'Deleted',
  packageInfo: 'Package Info',
  cancelReason: 'Cancel Reason',
  changePackagePrice: 'Change Package Price',
  usageInformation: 'Usage Information',
  day: 'Day',
  remainingMinute: 'Remaining Minute',
  usedMinute: 'Used Minute',
  historyInformation: 'History Information',
  packageActivationDate: 'Package Activation Date',
  month: 'month',
  pleaseSelectPrice: 'Please Select Price',
  paymentNotReceived: 'Payment not received',
  transactionResult: 'Transaction Result',
  errorReason: 'Error Reason',
  paymentInfo: 'Payment Info',
  transactionHistory: 'Transaction History',
  dailyFreeMinuteError: 'Entered minutes can not bigger than defined subscription minutes.',
  recurringCreditCard: 'Recurring Credit Card',
  comparePrices: 'Normal price can not be lower than package price.',
  cancellationReasons: {
    PAYMENT_PROBLEM: 'Payment Problem',
    CUSTOMER_REQUEST: 'Customer Request',
    UPGRADE: 'Upgrade',
    DOWNGRADE: 'Downgrade',
    CUSTOMER_DELETED: 'Customer Deleted',
    CUSTOMER_PASSIVATED: 'Customer Passivated',
    CUSTOMER_DEBT: 'Customer Debt',
    PORTAL_REQUEST: 'Portal User',
    PACKAGE_PASSIVATED: 'Package Passivated',
  },
  dailyKm: 'Daily Free Km',
  usedKm: 'Used Km',
  deletionTime: 'Deletion Time',
  completionDate: 'Completion Date',
  activationDate: 'Activation Date',
  lastValid: 'Last Valid',
  creationTime: 'Creation Time',
  isReferralEnable: 'Referral Menu On / Off',
  referralLimit: 'Referral Limit (person)',
  referrerRentalThreshold: 'Referrer Rental Threshold',
  refereeRentalThreshold: 'Referee Rental Threshold',
  referralValidDay: 'Referral Valid Day (day)',
  referralDiscountDuration: 'Referral Discount Duration (month)',
  refereeDiscountAmount: 'Referee Discount Amount (for once)',
  referrerDiscountAmount: 'Referrer Discount Amount',
  referralRemindDays: 'Referral Remind Days (day)',
  referralRemindPeriodHours: 'Referral Remind Period Hours (hours)',
  referralRemindDaysBySystem: 'Referral Remind Days by System',
  referralUsableLimit: 'Referral Usable Limit',
  invitees: 'Invitees',
  referralCode: 'Referral Code',
  referrer: 'Referrer',
  referralCodes: 'Codes',
  referralParameters: 'Referral Parameters',
  codes: 'code(s)',
  cancelSubscription: 'Cancel Subscription',
  confirmCancelSubscription: 'Are you sure to cancel subscription ?',
  subscriptionCancelled: 'Customer subscription has been cancelled'
};
