import { parseISO, format, toDate } from 'date-fns';
import { defaults } from './appDefaults';
// TODO: these filters should be refactored to accept custom formats
// and they should be merged
export function date(value, fmt = defaults.date) {
  if (!value) return '';

  let dt = null;
  if (typeof value === 'string') {
    dt = parseISO(value);
  } else {
    dt = toDate(value);
  }

  return format(dt, fmt);
}
export function time(value) {
  let dt = null;

  if (typeof value === 'string') {
    dt = parseISO(value);
  } else {
    dt = toDate(value);
  }

  return format(dt, defaults.time);
}
export function tl(value) {
  // if (value) return '';
  if (value === undefined) return '-';
  return value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
}
export function fulltime(minutes, showSeconds = true) {
  const value = parseInt(minutes, 10);
  if (Number.isNaN(value)) return null;

  const days = value / 1440;
  const rDays = Math.floor(days);
  const hours = value / 60;
  const rHours = Math.floor(hours - (rDays * 24));
  const response = [];
  const dayFloor = Math.floor(rDays);
  const hourFloor = Math.floor(rHours);
  const minuteFloor = minutes % 60;

  if (dayFloor > 0) {
    response.push(`${dayFloor}g.`);
  }
  if (hourFloor > 0) {
    response.push(`${hourFloor}sa.`);
  }
  if (minuteFloor > 0) {
    const fixed = showSeconds ? 2 : 0;
    response.push(`${minuteFloor.toFixed(fixed)}dk.`);
  }

  return response.join(' ');
}
export function getDiff(startDate, endDate) {
  const diff = endDate.getTime() - startDate.getTime();
  if (diff === 0) return 0;
  if (diff < 0) throw new Error('UnsupportedOperationException');
  return fulltime(diff / 60000);
}

// convert seconds to hhmmss format
// this will fail for seconds greater than 1 day
export function hhmmss(seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

// export function humanReadable(durationInMin) {
//   const hours = (durationInMin / 60);
//   const rhours = Math.floor(hours);
//   const minutes = (hours - rhours) * 60;
//   const rminutes = Math.round(minutes);

//   if (rminutes < 60) {
//     return `${rminutes} dk`;
//   }
//   if (rhours.toString().length === 1) {
//     return `0${rhours} sa ${rminutes} dk`;
//   }
//   return `${rhours} sa ${rminutes} dk`;
// }

export default date;
