export default {
  users: 'Kullanıcılar',
  user: 'Kullanıcı',
  add: 'Ekle',
  yes: 'Evet',
  no: 'Hayır',
  send: 'Gönder',
  cancel: 'İptal',
  save: 'Kaydet',
  customers: 'Müşteriler',
  minute: 'dakika',
  Minute: 'Dakika',
  date: 'Tarih',
  message: 'Mesaj',
  rentals: 'Kiralamalar',
  rental: 'Kiralama',
  hours: 'saat',
  total: 'Toplam',
  campaigns: 'Kampanyalar',
  vehicle: 'Araç',
  customer: 'Müşteri',
  status: 'Durum',
  start: 'Başlangıç',
  end: 'Bitiş',
  startAddress: 'Başlangıç Adresi',
  endAddress: 'Bitiş Adresi',
  startTime: 'Başlangıç Saati',
  endTime: 'Bitiş Saati',
  type: 'Tip',
  region: 'Bölge',
  company: 'Şirket',
  durationMin: 'Süre (dk)',
  price: 'Ücret',
  balance: 'Bakiye',
  campaing: 'Kampanya',
  city: 'Şehir',
  general: 'Genel',
  valet: 'Vale',
  corporate: 'Kurumsal',
  duration: 'Süre',
  email: 'Mail Adresi',
  amount: 'Miktar',
  time: 'Zaman',
  description: 'Açıklama',
  apply: 'Uygula',
  discount: 'İndirim',
  name: 'İsim',
  totalAmount: 'Tutar',
  plate: 'Plaka',
  color: 'Renk',
  fuel: 'Yakıt (l,)',
  brand: 'Marka',
  address: 'Adres',
  continent: 'Kıta',
  park: 'Otopark',
  info: 'Bilgi',
  imeiNumber: 'IMEI Numarası',
  hgsNumber: 'HGS',
  download: 'İndir',
  surname: 'Soyad',
  mobil: 'Mobil',
  role: 'Rol',
  select: 'Seç',
  gsmNo: 'Cep No',
  job: 'Görev',
  distance: 'Mesafe',
  comment: 'Yorum',
  definition: 'Tanım',
  destination: 'Hedef Tanım',
  note: 'Not',
  createdTime: 'Yaratılma Tarihi',
  acceptanceDate: 'Kabul Tarihi',
  id: 'Id',
  state: 'Durum',
  idNo: 'Kimlik No',
  foreign: 'Yabancı',
  phone: 'Telefon',
  nationality: 'Uyruk',
  citizenId: 'TCKN',
  birthdate: 'Doğum Tarihi',
  gender: 'Cinsiyet',
  profession: 'Meslek',
  device: 'Cihaz',
  confirm: 'Emin misiniz ?',
  confirmCancel: 'Üyeliği iptal etmek istediğine emin misin? Bu hareket geri alınamaz!',
  update: 'Güncelle',
  edit: 'Düzenle',
  back: 'Geri',
  startDate: 'Başlangıç Tarihi',
  endDate: 'Bitiş Tarihi',
  pleaseSelect: 'Lütfen Seçiniz',
  version: 'Sürüm',
  bill: 'Fatura',
  delete: 'Sil',
  incident: 'Kaza',
  change: 'Değiştir',
  percent: 'Yüzde',
  upload: 'Yükle',
  announcement: 'Duyuru',
  english: 'İngilizce',
  turkish: 'Türkçe',
  passive: 'Pasif',
  activate: 'Aktive Et',
  model: 'Model',
  detail: 'Detay',
  gear: 'Vites',
  yıl: 'Yıl',
  preview: 'Ön İzleme',
  approve: 'ONAYLA',
  reject: 'REDDET',
  campaign: 'Kampanya',
  all: 'Hepsi',
  break: 'Mola',
  onBreak: 'Molada',
  available: 'Uygun',
  search: 'Ara',
  piece: 'Adet',
  application: 'Uygulama',
  category: 'Kategori',
  rate: 'Oran',
  provision: 'Provizyon',
  age: 'Yaş',
  open: 'Açık',
  close: 'Kapalı',
  followUp: 'Takibe Al',
  verify: 'Doğrula',
  refresh: 'Yenile',
  card: 'Kart',
  payments: 'Ödemeler',
  admins: 'Yöneticiler',
  creditCards: 'Kredi Kartları',
  accountActivities: 'Hesap Hareketleri',
  members: 'Üyeler',
  addresses: 'Adresler',
  mobileNumber: 'Cep Telefonu',
  reason: 'Nedeni',
  days: {
    mon: 'Pazartesi',
    tue: 'Salı',
    wed: 'Çarşamba',
    thu: 'Perşembe',
    fri: 'Cuma',
    sat: 'Cumartesi',
    sun: 'Pazar',
  },
  required: 'Zorunlu',
  notRequired: 'Zorunlu Değil',
  updating: 'Güncelleme',
  district: 'Semt',
  firm: 'Firma',
  active: 'Aktif',
  catalog: 'Katalog',
  package: 'Paket',
  individual: 'Bireysel',
  right: 'Tamam',
  file: 'Dosya',
  default: 'Varsayılan',
  other: 'Diğer',
  homeAddress: 'Ev Adresi',
  workAddress: 'İş Adresi',
  male: 'Erkek',
  woman: 'Kadın',
  membership: 'Üyelik',
  document: 'Doküman',
  person: 'Kişi',
  new: 'Yeni',
  sender: 'Gönderen',
  receipt: 'Okundu',
  notReceipt: 'Okunmadı',
  or: 'Ya da',
  countryCode: 'Ülke Kodu',
  turkey: 'Türkiye',
  rentalType: 'Kiralama Tipi',
  addNew: 'Yeni Ekle',
  create: 'Oluştur',
  low: 'Düşük',
  tooLow: 'Çok Düşük',
  normal: 'Normal',
  percentage: 'Yüzde',
  bankReceipt: 'Dekont',
  excelTypes: {
    drivingLicense: 'Ehliyet',
    gender: 'Cinsiyet',
    selfie: 'Selfie',
  },
  addEmail: 'E-mail Ekle',
  addAsNewTag: 'Yeni tag olarak ekle',
  weekday: 'Hafta İçi',
  weekend: 'Hafta Sonu',
  reset: 'Sıfırla',
  order: 'Sıra',
  activeness: 'Aktiflik',
  undo: 'Geri Al',
  changePassword: 'Şifreyi Değiştir',
  logout: 'Çıkış',
  title: 'Başlık',
  loading: 'Yükleniyor...',
  permission: 'İzin',
  selectUploadFile: 'Yüklenecek Dosyayı Seçin',
  task: 'Görev',
  day: 'gün',
  min: 'dk',
  lastLocation: 'Son Konum',
  off: 'Kapalı',
  km: 'Km',
  enterFilterCriteria: 'Arama Kriterlerini Giriniz',
  oldPassword: 'Eski Şifre',
  newPassword: 'Yeni Şifre',
  enterOldPasswordAgain: 'Yeni Şifre Tekrar',
  eligible: 'Uygun',
  notEligible: 'Uygun Değil',
  passwordChangedSuccessfully: 'Şifre başarıyla değiştirildi.',
  enterNewPassword: 'Yeni Şifreyi giriniz',
  upperCase: '- Şifreniz en az 1 büyük harf içermelidir.',
  requiredCharacters: '- Şifre gerekli karakterleri içermiyor.',
  specialCharacter: '- Şifreniz en az 1 özel karakter (.,!@#?) içermelidir.',
  number: '- Şifreniz en az 1 rakam içermelidir.',
  lowerCase: '- Şifreniz en az 1 küçük harf içermelidir.',
  minMaxLength: '- En az 8, En fazla 50 karakter içermelidir.',
  notMatchPassword: 'Yeni şifre ile tekrarlanan şifre uyuşmuyor',
  oldPasswordWrong: 'Eski Şifre Alanı Hatalı',
  mobile: 'Mobil',
  lastCleaningDate: 'Son Temizlenme Zamanı',
  photo: 'Fotoğraf',
  rentalTypes: {
    VALET_SERVICE: 'Vale Servis',
    SELF_SERVICE: 'Dakikalık Kiralama',
    STATION_BASED: 'İstasyon',
    POOL: 'Havuz',
    DAILY: 'Günlük Kiralama',
    SUBSCRIPTION: 'Abonelik Kiralaması',
    DAILY_RESERVATION: 'Rezervasyonlu Kiralama',
    SLOT_BASED: 'Paketli Kiralama',
  },
  reasons3d: {
    START_OF_RENTAL: 'Kiralama',
    DEBT_PAYMENT: 'Borç Ödeme',
    SUBSCRIPTION_PURCHASE: 'Abonelik Ödeme',
    CARD_VALIDATION: 'Kart Doğrulama',
    DEVICE_VALIDATION: 'Cihaz Doğrulama',
    KM_PACKAGE: 'KM Paketi',
    UNLOCK_DEVICE_LOGIN_PROBLEM: 'Uzun süre uygulamaya girmeme kilini açma',
    UNLOCK_DEVICE_RENTAL_PROBLEM: 'Uzun süre kiralama yapmama kilidini açma',
    UNLOCK_DEVICE_LOCATION_CHANGE_PROBLEM: 'Konum zıplaması kilidi açma',
    PREPAID_OFFER_PURCHASE: 'Ön Ödemeli Bakiye Satın Alma',
    CREATE_OF_DAILY_RESERVATION: 'Günlük Rezervasyon Oluşturma',
    EXTEND_DAILY_RENTAL: 'Rezervasyonlu Günlük Kiralama Süresini Uzatma',
  },
  remove: 'Kaldır',
  nameSurname: 'Ad Soyad',
  value: 'Değer',
  clickToChange: 'Ayarları değiştirmek için {click}',
  click: 'tıklayınız',
  identificationNumber: 'Kimlik Numarası',
  option: 'Seçim',
  key: 'Değer',
  clickToSelectType: 'Tip seçmek için tıklayınız',
  pressEnterToAdd: "Eklemek için Enter'a basınız",
  clickEnterToAdd: 'Eklemek için tıklayınız',
  clickEnterToRemove: 'Kaldırmak için tıklayınız',
  downloadCurrentList: 'Mevcut Listeyi İndir',
  avgKm: 'Ortalama Km',
  monday: 'Pazartesi',
  tuesday: 'Salı',
  wednesday: 'Çarşamba',
  thursday: 'Perşembe',
  friday: 'Cuma',
  saturday: 'Cumartesi',
  sunday: 'Pazar',
  details: 'Detaylar',
  minDiscountRatio: 'Minimum İndirim Oranı',
  maxDiscountRatio: 'Maksimum İndirim Oranı',
  dayOfWeek: 'Gün',
};
