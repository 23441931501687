export default {
  allVehicles: 'All Vehicle',
  notUsedOneDay: 'Vehicle that hasn\'t not been used in the last 24 hours.',
  notUsedTwoDay: 'Vehicle that hasn\'t not been used in the last 48 hours.',
  downloadSearchData: 'Download Search Data',
  operation: 'Operation',
  battery: 'Battery',
  avgWaiting: 'Average Waiting',
  chassisNo: 'Chassis No',
  modal: 'Modal',
  transmissionType: 'Transmission Type',
  maintenanceStatus: 'Mainenance Status',
  serviceArea: 'Service Area',
  lastLocation: 'Last Location',
  lastRentingStart: 'Last Rental Start',
  lastRentingEnd: 'Last Rental End',
  latestStatusUpdate: 'Latest Status Update',
  lastAvailableDate: 'Last Available Date',
  lastOutOfServiceTime: 'Last out of Service Time',
  lastMaintenanceDate: 'Last Maintenance Date',
  nextMaintenanceDate: 'Next Maintenance Date',
  statusChangedBy: 'Status Changed By',
  lastValetName: 'Last Valet Name',
  lastValetRouteActualEnd: 'Last Valet Route Actual End',
  lastValetRouteNote: 'Last Valet Route Note',
  activePassive: 'Active / Passive',
  outOfServiceCategory: 'Out of Service Category',
  outOfServiceReason: 'Out of Service Reason',
  radius: 'Radius',
  maxDate: 'Maximum 1000 m can be selected',
  maxHours: 'Maximum 24 hours range can be selected',
  searchingAddress: 'Searching Address',
  mSoftware: 'M. Software',
  task: 'Task',
  firstLogin: 'First Login',
  lastLogout: 'Last Logout',
  current: 'Current',
  changeStatus: 'Change Status As a Whole',
  changeVehicleStatusAll: 'Change Vehicle As a Whole',
  requestedStatusChange: 'Vehicle numbers which status change requested',
  changedVehicleStatus: 'Vehicles which changed status with successful',
  notChangedVehicleStatus: 'Vehicles which does\'nt changed status',
  assignedZone: 'Assigned Zone',
  fuelType: 'Fuel Type',
  vehicleFrontPhoto: 'Last photo of vehicle (Front)',
  vehicleBackPhoto: 'Last photo of vehicle (Back)',
  vehicleLeftPhoto: 'Last photo of vehicle (Left)',
  vehicleRightPhoto: 'Last photo of vehicle (Right)',
  vehicleInnerFront: 'Last photo of vehicle (Inner-Front)',
  vehicleInnerBack: 'Last photo of vehicle (Inner-Back)',
  working: 'Working',
  notWorking: 'Not working',
  expectedToAcceptJob: 'Expected to accept the job',
  lastRentalsOfVehicle: 'The last {0} rentals of vehicle',
  photos: 'Photos',
  minute: 'Minute',
  customerDriver: 'Customer/Driver',
  incidentsOfVehicle: 'Incidents of Vehicles',
  closeDoors: 'Close Doors',
  openDoors: 'Open Doors',
  resetSms: 'Reset Sms',
  echoSms: 'Echo Sms',
  assignJob: 'Assign Job',
  lastUpdate: 'Last Update',
  lastJobsOfVehicle: 'Last Jobs of Vehicle',
  cancelReason: 'Cancel Reason',
  eventDate: 'Event Date',
  resetCommandSent: 'Reset command has been sent with SMS',
  commandNotSend: 'Command could not send',
  echoCommandSent: 'Echo command has been sent with SMS',
  repairedDamageApproval: 'All Damages will be mark as repaired. Are you approve ? ',
  markedAsRepaired: 'All damages belong to vehicle has been marked as repaired',
  vehicleDamageRepaired: 'Damage belong to vehicle has been marked as repaired',
  noteAdded: 'Note has been added',
  noteDeleted: 'Note has been deleted',
  definitionUpdated: 'Definition has been updated',
  definitionNotUpdated: 'Definition has not been updated',
  bodyType: 'Body Type',
  availableVehicle: 'Available Vehicle',
  outOfService: 'Out of Service',
  totalVehicle: 'Total Vehicle',
  catalogPicture: 'Catalog Picture',
  selectCatalogWantToChange: 'Please select you want to change price catalog from price catalog',
  hourlyCost: 'Hourly Cost',
  kmPrice: 'KM Price',
  dailyRentTreshold: 'Daily Rent Treshold',
  dailyCost: 'Daily Cost',
  clickChangeStatus: 'Please click to change for status',
  saveFiles: 'Save Files',
  filesCouldntUpdate: 'Files couldn\' update',
  packageThatAdded: 'Package that want to add',
  packageConfirm: '{1} will add to {0} company. Do you confirm ?',
  packageName: 'Package Name',
  imageFile: 'Image File',
  totalUploaded: 'Total to be Uploaded',
  rejectionReason: 'Rejection Reason',
  selectRejectionReason: 'Select Rejection Reason',
  selectReasonForRejection: 'Select Reason for Rejection',
  uploadingExcel: 'Uploading Excel',
  vehicleInformationUpdated: 'Vehicle information has been updated',
  vehicleStatusCouldntChange: 'Vehicle Status Could\'nt change.',
  changeVehicleStatus: 'Change Vehicle Status',
  selectWantToChange: 'Select Status that you want to change!',
  subReason: 'Sub Reason',
  vehicleStatusChange: 'Vehicle Status has been changed.',
  vehicleStatus: 'Vehicle Status',
  drivers: {
    availability: {
      null: 'Unknown',
      AVAILABLE: 'Available',
      WORKING: 'Working',
      NOT_WORKING: 'Not Working',
      ON_BREAK: 'On Break',
      PENDING_TASK_ACCEPTANCE: 'Pending Task Acceptance',
    },
  },
  commandHistory: 'Command History',
  command: 'Command',
  noDataDateRange: 'There is no data in this date range',
  firstAvailableDate: 'First Available Date',
  firstDeliveryDate: 'First Delivery Date',
  outOfServiceSubReason: 'Out of Service Sub Reason',
  outOfServiceDescription: 'Out of Service Description',
  updateVehiclesCollectively: 'Update Vehicle Status Collectively',
  idleTimeInHour: 'Idle Time in Hour',
  jobStep: 'Job Step',
  idleHours: 'Idle Hours',
  downtime: 'Downtime',
  downtime24_48: 'Downtime for 24-48 hours',
  downtime48: 'Downtime for +48 hours',
  charge: 'Charge',
  charging: 'Charging',
  numberOfAssignedTasks: 'Number of Assigned Tasks',
  damage: 'Damage',
  repaired: 'Repaired',
  markRepairedAllDamage: 'Mark all damages as repaired',
  markRepaired: 'Mark as repaired',
  photo: 'Photo',
  noDamage: 'There is no damage on vehicle',
  catalogPictureUpdated: 'catalog pictures has been updated',
  getDrivers: 'Get {0} ( driver(s) )',
  appAbr: 'App.',
  workingAbr: 'Working.',
  notWorkingAbr: 'NotWorking.',
  expectedToAcceptJobAbr: 'Waiting.',
  valuesUpdated: 'Values has been updated',
  activePassiveChanged: 'Active / Passive changing done',
  selectApplication: 'Select Application',
  selectCategory: 'Select Category',
  synchVehiclestoMiles: 'Sync with Miles',
  routeStatus: {
    NOT_STARTED: 'Not Started',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
    DRAFT: 'Draft',
    CANCELLED: 'Cancelled',
    NOT_CREATED: 'Not Created',
  },
  deliveryLocation: 'Delivery Location',
  finishTask: 'Finish Task',
  bookingWillDeleted: 'This booking will be deleted.',
  jobWillComplete: 'Job will be completed',
  bookingDeleted: 'Booking has been deleted',
  taskCompleted: 'Task Completed',
  withdrawMoneyFromCard: 'Withdrawing money from the {1} of a customer named {0}',
  vehiclesInService: 'Vehicles in Service',
  vehiclesInStation: 'Vehicles in Station',
  noSuitableConbination: 'It has not been found suitable valet vehicle combination',
  customer: 'CUSTOMER',
  valet: 'VALET',
  taskTypes: {
    valet: 'Valet',
    pool: 'Pool',
    inService: 'In Service',
  },
  logoCondition: 'Logo Condition',
  vehicleColor: 'Vehicle Color',
  showDamages: 'Show Damages',
  hideDamages: 'Hide Damages',
  outOfServiceDate: 'Out of Service Date',
  lastConnectionDate: 'Last Connection Date',
  lastDoorStatus: 'Last Door Status',
  totalCommandCount: 'Total Command Count',
  lastSpeedAndUpdateDate: 'Last Spped and Update Date of Vehicle',
  isControlInProgress: 'Is control in progress ?',
  echoCommandSentDates: 'Echo Command Sent Date',
  resetCommandSentDates: 'Reset Command Sent Date',
  doorCloseCommandSentDates: 'Door Close Command Sent Date',
  fuelBlockageCommandSentDate: 'Fuel Blockage Command Sent Date',
  hoodOpenedAlarmDates: 'Hood Opened Alarm Date',
  deviceAlarmDates: 'Device Alarm Date',
  securityAlarmDates: 'Security Alarm Date',
  crashAlarmDates: 'Crash Alarm Date',
  securityAlarmCount: 'Security Alarm Count',
  alarmType: 'Alarm Type',
  secondDeviceCommandSentDate: 'Second Device Command Sent Date',
  gsmAccountProvider: 'Gsm Account Provider',
  gsmAccountIdentity: 'Gsm Account Identity',
  hardwareType: 'Hardware Type',
  deviceIdentity: 'Device Identity',
  telematicServer: 'Telematic Server',
  showDeletedVehicles: 'Show Deleted Vehicles',
  dontShowDeletedVehicles: 'Do not Show Deleted Vehicles',
};
