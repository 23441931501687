export default {
  reservations: 'Reservations',
  status: {
    CREATED: 'Created',
    WAITING_PAYMENT: 'Waiting Payment',
    WAITING_RENTAL: 'Waiting Rental',
    ACTIVE: 'Active',
    CANCELED: 'Cancel',
    FINISHED: 'Finished',
  },
  parameters: 'Parameters',
  serviceAreaOrder: 'Service Area Order',
  rentalPeriod: 'Rental Period',
  rentalCost: 'Rental Cost',
  reservationId: 'Reservation Id',
  startStationAddress: 'Start Station Address',
  startDate: 'Start Date',
  endStationAddress: 'End Station Address',
  endDate: 'End Date',
  kmLimit: 'Km Limit',
  kmPackageCost: 'Km Package Cost',
  order: 'Order',
  parameter: 'Parameter',
  rentalDays: 'Rental Days',
  pickupTime: 'Pick-up Time',
  dropoffTime: 'Drop-off Time',
  rentalTimeCost: 'Rental Time Cost',
  cancelReservation: 'Cancel Reservation',
  reservationWillBeCancelled: 'The reservation will be canceled. Are you sure ?',
  startStationName: 'Start Station Name',
  endStationName: 'End Station Name',
  taxNumber: 'Tax Number',
  firmCity: 'City',
  firmDistrict: 'District',
  addressLine: 'Address',
  firmMobile: 'Mobile',
  firmEmail: 'Email',
  firmTaxOfficeName: 'Tax Office',
  kmExceededCost: 'Km Exceeded Cost',
};
