export default {
  basicInfo: 'Temel Bilgiler',
  departments: 'Departmanlar',
  campus: 'Kampüs',
  providerCompany: 'Servis Şirketi',
  selectCampus: 'Kampüs Seçiniz',
  selectProviderCompany: 'Servis Şirketi Seçiniz',
  company: 'Şirket',
  status: 'Durum',
  campuses: 'Kampüsler',
  routes: 'Rotalar',
  users: 'Kullanıcılar',
  registeredUsers: 'Kayıtlı Kullanıcılar',
  shuttleUsers: 'flexiShuttle Kullanıcıları',
  poolcarUsers: 'flexiCar Kullanıcıları',
  rideUsers: ' flexiRide Kullanıcıları',
  parkUsers: 'flexiPark Kullanıcıları',
  region: 'Bölge',
  locations: 'Kayıtlı Adresler',
  locationName: 'Adres adı',
  address: 'Adres',
  serviceProvider: 'Servis Şirketi',
  newServiceProvider: 'Yeni Servis Şirketi',
  addNewServiceProvider: 'Yeni Servis Şirketi Ekle',
  updateServiceProvider: 'Yeni Servis Şirketi Güncelle',
  admins: 'Yöneticiler',
  general: 'Genel',
  poolCarRide: 'flexiCar/flexiRide Private/ flexiRideShared ',
  parking: 'flexiPark',
  allowance: 'Ödenek',
  subCompanies: 'Alt Şirketler',
  pricing: 'Fiyatlandırma',
  settings: 'Ayarlar',
  noSubCompany: 'Alt şirket bulunamadı.',
  newCampus: 'Yeni Kampüs',
  newRegion: 'Yeni Bölge',
  newLocation: 'Yeni Konum',
  newAdmin: 'Yeni Admin',
  campusName: 'Kampüs Adı',
  regionName: 'Bölge Adı',
  location: 'Lokasyon',
  editCampus: 'Kampüsü Düzenle',
  editRegion: 'Bölgeyi Düzenle',
  deleteCampus: 'Kampüsü Sil',
  noLocation: 'Konum bilgisini bulunamadı.',
  deleteRegion: 'Bölgeyi Sil',
  system: 'Sistem',
  systemAdmin: 'Sistem Admin',
  serviceProviderAdmin: 'Servis Şirketi Admini',
  newDepartment: 'Yeni Departman',
  addNewDepartment: 'Yeni Departman Ekle',
  departmentName: 'Departman Adı',
  departmentNumber: 'Departman Numarası',
  noDepartmentsAdded: 'Henüz bir departman eklenmedi',
  editDepartment: 'Departman Düzenle',
  number: 'Numara',
  phoneNumber: 'Telefon Numarası',
  drawCampusLocation: 'Kampüs konumunu tanımlamak için haritaya bir çokgen çizin.',
  drawRegionLocation: 'Bölge konumunu tanımlamak için haritaya bir çokgen çizin.',
  editCampusArea: 'Yerleşke alanını düzenlemek için poligonun köşe noktalarını uygun yerlere çekin.',
  editRegionArea: 'Bölge alanını düzenlemek için poligonun köşe noktalarını uygun yerlere çekin.',
  campusesDeleted: 'Üzerinde kayıtlı yolcuların ve/veya aktif rota gruplarının bulunduğu kampüsler silinemez.',
  industries: {
    IT: 'Bilişim Teknolojileri',
    Education: 'Eğitim',
    'Sales&CRM': 'Satış ve Müşteri İlişkileri',
    Finance: 'Finans',
    'Design and Creative': 'Tasarım',
    Marketing: 'Pazarlama',
    Operations: 'Operasyon',
    Construction: 'İnşaat ve Yapı',
    'HR & Recruiting': ' İnsan Kaynakları',
    'Software Development': 'Yazılım Geliştirme',
    Legal: 'Hukuk',
    Other: 'Diğer',
  },
  basicInfoSubProps: {
    type: 'Tip',
    companyName: 'Şirket Adı',
    taxNumber: 'Vergi Numarası',
    industry: 'Endüstri',
    language: 'Dil',
    currency: 'Para Birimi',
    unit: 'Birim',
    functionality: 'Modüller',
  },
  functionalities: {
    usingPoolcar: 'flexiCar',
    usingRide: 'flexiRide',
    usingShuttle: 'flexiShuttle',
    usingTaxi: 'flexiMileage',
    usingVanPool: 'flexiVanpool',
    usingCarPool: 'flexiCarpool',
    seeCommuteOptions: 'Commute Options',
    usingSharedRide: 'flexiRide Shared',
    usingFlexiPark: 'flexiPark',
    feedback: 'Geri Bildirim',
  },
  feedback: {
    userFeedbackCategoriesAndTopics: 'Kullanıcı Geri Bildirim Kategorileri ve Konuları',
    noTopicsAdded: 'Konu eklenmedi',
    text1:
      'Her kategori için sağlanan temel konu listesinden seçim yapabilir veya seçimi kaldırabilirsiniz ve isteğe bağlı olarak özel konular ekleyebilirsiniz. Mobil uygulamada kullanıcıların kullanım kolaylığı ve anlaşılırlığı açısından 10`dan fazla konu sunmamanızı tavsiye ederiz.',
    newTopic: 'Yeni Konu',
    walkingDistance: 'Yürüme Mesafesi',
    arrivedLate: 'Geç Geldi',
    leftEarly: 'Erken Ayrıldı',
    noShow: 'Gelmedi',
    driverBehavior: 'Sürücü Davranışı',
    vehicleCleanliness: 'Araç Temizliği',
    missingEquipment: 'Eksik Ekipman',
    checkinScan: 'Giriş Taraması',
    other: 'Diğer',
  },
  generalText: {
    newUser: 'Yeni bir kullanıcı eklendiğinde hoşgeldin maili gönder.',
    emails: 'Kullanıcılara bilgilendirme maili gönder.',
    sendUsersEmail: 'Kayıt olduktan sonra kullanıcılara bilgilendirme maili gönder.',
    displaySurvey: 'Kullanıcı oryantasyonunda anket göster',
    checkPersonel: 'Uygulama modülleri kullanıcıya göre ayarlansın.',
    dashboardShuttle: 'Servis Aracı',
    dashboardPoolcar: 'PoolCar',
    dashboardVanpool: 'Vanpool',
    dashboardTaxi: 'Taksi',
    dashboardRide: 'flexiRide',
    dashboardCalender: 'Takvim',
    dashboardPastUses: 'Geçmişteki Kullanımlar',
    dashboardCarpool: 'Carpool',
    dashboardComplaints: 'Mobil Uygulama Modülleri',
    mobileDashboardItems: 'Mobil Pano Öğeleri',
    request: 'Talep ve Öneri',
    dashboardLoop: 'Ring',
    firstLoginPickupReminder: 'Ev adresi alanı zorunlu olsun',
    changeHomeAddress: 'Personel ev adresi değişikliğinde adminlere email gönder',
  },
  adminsText: {
    setTwoFactor: 'Yöneticiler için iki yönlü doğrulamayı aktif et',
    adminsRequests: 'İstekler ile alakalı bilgilendirilecek yöneticiler',
    placeHolder: 'Birden fazla email virgül ile ayırarak yazabilirsiniz',
  },
  poolCarRideText: {
    approvalFlexiCar: 'flexiCar için gerekli onay',
    shouldComplateDailyChecklist: 'Sürücüler günlük bir kontrol listesi doldurmalıdır',
    reservationFlexiCar: 'flexiCar için gerekli rezervasyon',
    approvalFlexiRide: 'flexiRide için gerekli onay',
    autoPlanning: 'Otomatik planlama',
    sendConfirmationEmail: 'flexiRide talepleri e-postalardan oluşturulduğunda asistanlara onaya gönder',
    notifyRidersflexiRideRequest: 'flexiRide istekleri için sürücü ve araç atandığında sürücüleri bilgilendirin.',
    notifyRidersPickupPoint: 'flexiRide aracı teslim alma noktasına vardığında personelleri bilgilendirin',
    sendUsersSMSLink: 'Kullanıcılara, aracı yolculuk saatlerinde takip etmeleri için bir SMS bağlantısı gönderin.',
    notifyDriverVehicleRequest: 'flexiRide istekleri için sürücü ve araç atandığında yardımcıları bilgilendirin.',
    NotifyflexiRideVehicleArrives: 'flexiRide aracı teslim alma noktasına vardığında asistanları bilgilendirin',
    notifyflexiRideRequest: 'Talep onaylandığında/reddedildiğinde asistanları bilgilendirin.',
    SendSMSLinkTrackVehicleTripTimes:
      'Asistanlara, aracı yolculuk saatlerinde takip etmeleri için bir SMS bağlantısı gönderin.',
    flexiRidePurposes: 'flexiRide Amaçları',
    notifyApproachesPickupPoint:
      'flexiRide aracı toplama noktasına yaklaştığında, varmadan {time} dakika önce asistanları bilgilendirin.',
    notifyRidersApproachesPickupPoint:
      'flexiRide aracı teslim alma noktasına yaklaştığında binicilere varıştan {time} dakika önce haber verin.',
    purposeMustSelectedAdded: 'En az bir amaç seçilmeli veya eklenmelidir.',
    notifyRidersRequest: 'Talep onaylandığında/reddedildiğinde personelleri bilgilendirin.',
    notifyAdministratorsFlexiRideRequest: 'Yeni bir flexiRide isteği alındığında yöneticileri bilgilendirin.',
    riderForRequestAcknowledged: 'flexiRide talebi yönetici tarafından kabul edildiğinde personelleri bilgilendirin.',
    assistantForRequestAcknowledged:
      'flexiRide talebi yönetici tarafından kabul edildiğinde asistanları bilgilendirin.',
    mobilityAssistance: 'Kullanıcılar engelli yardımı talep edebilirler.',
    serviceTimeWithHoursMultiple: 'Bu hizmet {1} {0} arası verilmektedir.',
    serviceTimeWithHours: 'Bu hizmet {1}, {0} arası verilmektedir.',
    serviceTimeWithoutHours: 'Bu hizmet {0}, tüm gün boyunca verilmektedir.',
    singleRequest: 'Bir talep içinde en fazla {mins} kişi seçilebilir.',
    theActiveTimesForFlexiRideSharedService: 'Paylaşımlı sürücülü araç hizmetinin aktif olduğu gün ve saatler:',
    allDay: 'Tüm gün',
    duringSpecifiedHours: 'Belirtilen saatlerde',
    addPeriod: 'Periyot Ekle',
    phoneNumberRequiredFlexiRideSharedRequests: 'Paylaşımlı sürücülü araç taleplerinde telefon numarası olması zorunlu',
    from: '',
    to: '-',
    weekdays: 'hafta içi tüm günler',
  },
  requestStartTime: {
    earliestTime: 'Paylaşımlı sürücülü araç talepleri en erken ne zaman yapılabilir?',
    theSameDay: 'Aynı gün',
    daysInAdvance: 'gün önce',
    sameDay: 'aynı gün',
    onTheDay: 'Aynı gün için yolculuk talebi yapılabilir.',
    daysEarlier: {
      single: 'gün önceden',
      multiple: 'gün önceden',
    },
    requestsCanBeMade: 'Yolculuk zamanından {0} itibaren talep yapılabilir.',
    theStartingTimeMustEarlierThanTheEndingTime: 'Başlangıç ​​saati bitiş saatinden daha erken olmalıdır',
  },
  flexiMileageText: {
    flexiMileageNotifications: 'flexiMileage Bildirimleri',
    flexiMileagePurposes: 'flexiMileage Amaçları',
    perMileCost: 'Mil Başına Maliyet',
    notifyRidersReportedMileageConfirmed: 'Bildirilen kilometreleri onaylandığında sürücüleri bilgilendirin.',
    notifyRidersReportedMileageRejected: 'Bildirilen kilometreleri reddedildiğinde sürücüleri bilgilendirin.',
    notifyRidersReportecMileagePaidBack: 'Bildirilen kilometre geri ödendiğinde sürücüleri bilgilendirin.',
    riderForComfirmed: 'Bildirilen kilometreleri onaylandığında sürücüleri bilgilendirin.',
    riderForRejected: 'Bildirilen kilometreleri reddedildiğinde sürücüleri bilgilendirin.',
    riderForPaidBack: 'Bildirilen kilometre geri ödendiğinde sürücüleri bilgilendirin.',
  },
  shuttleText: {
    userControl: 'Kullanıcı kontrolü',
    text1: 'Kullanıcıların diğer kampüslere giden ring hatlarını görmesine izin ver.',
    text2: 'Yönetici bildirimleri',
    text3: 'Yöneticileri sinyal göndermeyen araçlar ile alakalı bilgilendir',
    text4: 'Yöneticileri ulaşmayan yada geç ulaşan ring hatları ile alakalı bilgilendir',
    text5: 'Yöneticileri kart tarama yüzdeleri hakkında bilgilendir',
    cardScans: 'Kart/QR taramaları',
    text6:
      'Kart/QR {scanDays} gün boyunca taranmazsa, kullanıcı taramayı yeniden başlatmadığı sürece {emailDays} gün boyunca kullanıcılara günlük uyarı e-postaları gönderin.',
    text7: 'Kart yada QR taranmadıysa kullanıcıyı flexiShuttle için pasif olarak ayarla',
    rules: 'Kurallar',
    text8: 'Sürücü uygulamasında, hat rotaları günlük oluşturulmalıdır',
    serviceProviders: 'Servis Şirketi',
    text9: 'Servis şirketi için çalışan listesini görünür yap',
    text10: 'Servis şirketi için kampüs bazlı bildirim yollama kontrolünü aktif yap',
    text11: 'Araç Ruhsatı',
    text12: 'Hat Adı',
    text13: 'Araç ruhsatına göre QR kodlarını ayarlayın',
    text14: 'Servis otobüslerinin maksimum yaşı {maxVehicleAgeAllowed} yıl olmalıdır.',
    text15: 'Sürücüler aranabilsin',
    text16: 'Servis şirketi tarafından yapılan araç ve sürücü atamalarında admin onayı gereklidir.',
    text17: 'Kullanıcılar düzeli rotalarını değiştirmek istediğinde onay gereklidir.',
    text18: 'Sürücü uygulamasına günlük rotaları gönderirken yolcu atanmamış olan durakları da dahil et.',
    checkVehiclesCampusZone: 'Araçların yerleşkeye girişlerinin kontrol edilmesi',
    ridersCanReserveEvenThoughOverCapacity: 'Kapasite Dolu Olmasına Rağmen Rezervasyon Yapılabilsin',
    text19: 'Kullanıcılar dinamik rota talebi yaparken yolcu sayısı seçebilir.',
    text20:
      'Gönderim ekranında, döngü rotaları için tüm durakların OTP`sini toplam kontrol paneli hesaplamalarına dahil edin.',
    shuttleBufferForArrivalTime:
      'Zamanında performans takibi için dikkate alınacak varış saatleri için tampon {shuttleBufferForArrivalTime}`dır',
    shuttleBufferForDepartureTime:
      'Zamanında performans takibi için dikkate alınacak kalkış saatleri için tampon {shuttleBufferForDepartureTime}`dır',
    text21: 'Sürücüler planlanan süre içinde görevleri için check-in yapmadığında yöneticiler bilgilendirilsin.',
    showVehicleInfoBy: {
      message: 'Araç bilgilerini mobil uygulama üzerinden şuna göre göster',
      license: 'Araç plakası',
      vehicleId: 'Araç kısa adı',
    },
  },

  vanpoolText: {
    driverAndRiderAgeRequirements: {
      text1: 'Vanpool <strong>yolcularının</strong> minimum olması gereken yaş.',
      text2: 'Yolcular {age} yaşından büyük olmalı.',
      text3: 'Vanpool <strong>sürücülerinin</strong> minimum olması gereken yaş.',
      text4: 'Sürücüler {age} yaşından büyük olmalı.',
    },
    driverLicenceReqirements: {
      text1: 'Vanpool <strong>sürücüleri</strong> için ehliyet yaş sınırının olması.',
      text2: 'Vanpool sürücüleri min {age} yıl ehliyete sahip olmalı.',
    },
    adminNotifications: {
      text2: 'Yolculuklar {riderCount} nın altında olursa adminleri bilgilendir',
      text3: 'Araçlardaki yolculuk harici km {distanceRatio} oranının üstünde olursa adminleri bilgilendir',
      text6: 'Telematik cihazlar araçlardan söküldüğünde adminleri bilgilendir',
    },
  },
  driverAndRiderAgeRequirements: 'Sürücü ve Yolcu Yaş Gerekliliği',
  driverLicenceReqirements: 'Sürücü Ehliyet Gerekliliği',
  adminNotifications: 'Admin Bilgilendirmeleri',
  carpoolText: {
    unitPricePerMile: 'Mil başına birim fiyat.',
  },
  adminsToBeNotified: 'Bilgilendirilecek yöneticiler',
  clearSelection: 'Seçimi Temizle',
  addNewCompany: 'Yeni Şirket Ekle',
  addNewSubCompany: 'Yeni Alt Şirket Ekle',
  addNewRegion: 'Yeni Bölge Ekle',
  mainCompany: 'Ana Şirket',
  thirdParty: 'Üçüncü Parti',
  companyName: 'Şirket Adı',
  taxNumber: 'Vergi Numarası',
  selectIndustry: 'Endüstri Seç',
  selectCompany: 'Şirket Seç',
  confirmMessage: 'Yaptığın değişiklikleri kaydetmek istiyor musun?',
  deleteMessageText: 'Devam etmek istediğine emin misin?',
  continueMessage: 'Devam etmek istediğine emin misin?',
  noServiceProviderMessage: 'Servis sağlayıcı henuz eklenmedi.',
  noAdminAddedYet: 'Henüz adminler eklenmedi.',
  noCampusesAddedYet: 'Henüz Kampüs Eklenmedi',
  noRegionsAddedYet: 'Henüz Bölge Eklenmedi',
  noServiceProvidersAddedYet: 'Henüz Servis Sağlayıcı Eklenmedi',
  noSubCompaniesDefinedYet: 'Alt Şirketler Henüz Tanımlanmadı',
  confirmContinueMessage: 'Devam etmek istiyor musunuz?',
  flexiRideNotifications: 'flexiRide Bildirimler',
  corporateRiders: 'Kurumsal Personeller',
  flexiRideAssitants: 'flexiRide Asistan',
  pushNotification: 'Bildirim',
  Sms: 'SMS',
  business: 'İş',
  personal: 'Kişisel',
  purposeDescription: 'Amaç Açıklaması',
  estimatedServiceTime: 'Tahmini Hizmet Süresi',
  newPurpose: 'Yeni Amaç',
  flexiRideAssistants: 'flexiRide Yardımcıları',
  serviceProviders: 'Servis Şirketleri',
  companyDomainAddress: 'Şirket domaini',
  timeZone: 'Saat dilimi',
  flexiShuttleDocuments: 'flexiShuttle Dokümanları',
  typeTopicTitle: 'Konu başlığı',
  dismissMessage: 'Paneli kapatmak istediğinizden emin misiniz? Kaydedilmemiş tüm değişiklikler kaybolacaktır.',
  openingPage: 'Açılış Sayfası',
  selectOpeningPage: 'Bir açılış sayfası seçiniz',
  cancelRequestMessage:
    '{0} tarihinde saat {1} için bulunan paylaşımlı sürücülü araç talebinizi iptal etmek istiyor musunuz?',
  TBD: 'Atanacak',
};
