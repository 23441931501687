import monitor from './monitor.tr';
import common from './common.tr';
import dashboard from './dashboard.tr';
import rentals from './rentals.tr';
import vehicle from './vehicle.tr';
import success from './success.tr';
import error from './error.tr';
import users from './users.tr';
import jobs from './jobs.tr';
import customer from './customer.tr';
import settings from './settings.tr';
import campaigns from './campaigns.tr';
import announcements from './announcements.tr';
import tasks from './tasks.tr';
import pricing from './pricing.tr';
import alarms from './alarms.tr';
import geohash from './geohash.tr';
import incidents from './incidents.tr';
import zones from './zones.tr';
import firms from './firms.tr';
import asset from './asset.tr';
import rewards from './rewards.tr';
import subscription from './subscription.tr';
import reservation from './reservation.tr';

export default {
  monitor,
  common,
  dashboard,
  rentals,
  vehicle,
  success,
  error,
  users,
  jobs,
  customer,
  settings,
  campaigns,
  announcements,
  tasks,
  pricing,
  alarms,
  geohash,
  incidents,
  zones,
  firms,
  asset,
  rewards,
  subscription,
  reservation,
};
