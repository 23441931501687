<template>
  <div :class="mainClass" class="shell-default">
    <!-- TODO: Move this to its own component -->
    <nav v-if="!isInFrame" class="navbar has-shadow sticky" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div
          v-if="!isObserver"
          :class="{'is-active': $store.getters.isSidebarOpen}"
          class="hamburger hamburger--vortex"
          type="button"
          @click="toggleSidebar">
          <span class="hamburger-box">
            <span class="hamburger-inner"/>
            <!-- <span class="hamburger-label">Menu</span> -->
          </span>
        </div>
        <router-link :to="{name: $store.getters.entryPage}" class="navbar-item">
          <img
            v-if="$store.getters.application === 'TIKTAK_ADMIN'"
            src="/admin/img/TIKTAK_ADMIN/tiktak-logo.png"
            alt="tiktak"
          >
          <span v-else>{{$store.getters.appName}} / {{pageTitle}}</span>
        </router-link>
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
          &nbsp;
        </div>

        <span href="#" class="navbar-item organization-name">{{$store.state.firm ? $store.state.firm.organisationName : '' }}</span>

        <div class="navbar-end">
          <div v-if="$store.getters.hasPermission('language:change')" class="navbar-item has-dropdown" :class="{'is-active': isSwitchLanguage}" @click="languageActiveTrue">
            <a href="javascript:;" class="navbar-link" >{{availableLang[$i18n.locale]}} </a>
            <div class="navbar-dropdown">
              <a v-for="(lang, key) in availableLang"
                 :key="`${key}`"
                 class="navbar-item"
                 :value="key"
                 @click.prevent ="changeLanguage($event)"
              >{{ lang }}
              </a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              <span class="icon">
                <fa-icon icon="user" />
              </span>
              <span>&nbsp; {{user.fullName}}</span>
            </a>
            <div class="navbar-dropdown">
              <a :href="urlChangePassword" class="navbar-item">
                <span class="icon">
                  <fa-icon icon="cog"/>
                </span>
                <span>&nbsp; {{$t('carshare.common.changePassword')}}</span>
              </a>
              <hr class="navbar-divider">
              <a :href="urlLogin" class="navbar-item logout-link" @click.prevent="logout">
                <span class="icon">
                  <fa-icon icon="sign-out-alt"/>
                </span>
                <span>&nbsp; {{$t('carshare.common.logout')}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <sidebar :class="{'menu-open': $store.getters.isSidebarOpen}" class="sidebar" @selectedMenu="selectedMenu" />

    <div class="main-content">
      <router-view />
    </div>

    <!-- TODO: Move this to its own component -->
    <!-- <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>VPlanner</strong> Admin - V.1.0.0
        </p>
      </div>
    </footer> -->

    <!-- Modals with component content use this component -->
    <modals-container/>
  </div>
</template>

<script>
import Sidebar from '@/common/components/Sidebar.vue';
import userMixin from '@/helpers/mixins/user';
import authService from '@/common/services/auth';

export default {
  name: 'shell-standart',
  components: {
    Sidebar,
  },
  mixins: [userMixin],
  data() {
    return {
      selectedMenuName: null,
      availableLang: {
        tr: 'Türkçe',
        en: 'English',
      },
      isSwitchLanguage: false,
    };
  },
  computed: {
    isInFrame() {
      return window.top !== window.self;
    },
    urlLogin() {
      return `${window.location.origin}${process.env.BASE_URL}`;
    },
    urlChangePassword() {
      return `${window.location.origin}${process.env.BASE_URL}#/password/change`;
    },
    mainClass() {
      const { scopes } = this.$store.getters.user;
      let corp = false;
      if (scopes) {
        corp = scopes.includes('CORPORATE_ADMIN');
      }
      const app = this.$store.getters.application ? this.$store.getters.application.toLowerCase() : '';
      return corp ? `${app}-corp` : app;
    },
    isObserver() {
      const { scopes } = this.$store.getters.user;
      if (scopes) return this.$store.state.user.scopes.includes('OBSERVER');
      return false;
    },
    pageTitle() {
      return this.$route.meta.name;
    },
  },
  methods: {
    async logout() {
      await authService.logout();
      window.location = this.urlLogin;
    },
    changeLanguage(event) {
      this.$i18n.locale = event.target.attributes.value.value;
    },
    languageActiveTrue() {
      this.isSwitchLanguage = !this.isSwitchLanguage;
    },
    toggleSidebar() {
      const status = this.$store.getters.isSidebarOpen;
      this.$store.commit('SET_SIDEBAR_STATUS', !status);
    },
    selectedMenu(payload) {
      this.selectedMenuName = '';
      this.selectedMenuName = `/ ${payload}`;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~@vektor/date-picker/dist/date-picker.css';
</style>
<style lang="scss" scoped>
.sticky{
  position: sticky;
  top: 0;
}
#router-iframe{
  nav{
    display: none;
  }
}
</style>
