import Axios from 'axios';
import ENDPOINTS from '@/endpoints';

class Repository {
  constructor(options) {
    this.token = localStorage.getItem('_token') || sessionStorage.getItem('_token');
    this.createInstance(options);
  }

  createInstance(options = {}, token = undefined) {
    const headers = { 'vektor-token': token || this.token };
    let defaultOptions = {
      responseType: 'json',
      timeout: 60000,
    };

    if (token || this.token) {
      defaultOptions.headers = headers;
    }

    defaultOptions = Object.assign(defaultOptions, options);
    const instance = Axios.create(defaultOptions);

    // TODO: this is messy and prone to errors
    // error redirection should be moved to http wrapper class in Repository.js
    instance.interceptors.response.use((response) => response, (error) => {
      // Do something with response error
      if (error.response && error.response.status === 403) {
        if (window.location.hostname.includes('flexigo')) {
          const [base] = window.location.hostname.split('.');
          const urls = {
            localhost: 'http://localhost:5431',
            secure: 'https://secure.flexigo.com?lang=tr',
            portal: 'https://portal.flexigo.com?lang=en',
            timezone: 'https://timezone.flexigo.com?lang=en',
            secure3: 'https://secure3.vektorteknoloi.com/flexigo/',
          };
          window.location.href = urls[base];
        } else {
          window.location.assign(`${window.location.origin}${process.env.BASE_URL}`);
        }
      } else {
        console.error(error);
      }
      return Promise.reject(error);
    });

    this.instance = instance;
    return instance;
  }

  async get(url, params = null, rawData = false) {
    const response = await this.instance.get(url, { params });
    return rawData ? response : response.data;
  }

  async post(url, payload) {
    const response = await this.instance.post(url, payload);
    return response.data;
  }

  async put(url, payload) {
    const response = await this.instance.put(url, payload);
    return response.data;
  }

  async delete(url, payload = undefined) {
    // delete method in axios 0.20 is broken, this is a workaround
    const response = await this.instance.request({
      url,
      data: payload,
      method: 'delete',
    });
    return response.data;
  }

  static getEndpoint(resource) {
    return resource.split('.').reduce((o, i) => o[i], ENDPOINTS);
  }
}

export default Repository;
