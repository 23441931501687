export default {
  taskList: 'Görev Listesi',
  pendingTask: 'Bekleyen bir görev yok',
  takeTasks: 'Sırada ki görevi üstüne al',
  noTask: 'Görev Yok',
  notSelectedTask: 'Görev Seçilmedi',
  task: 'Görev',
  firstClaimTime: 'İlk Kabul',
  lastClaimTime: 'Son Kabul',
  documents: 'Dökümanlar',
  andFinish: 've Bitir',
  moveNextTask: 've Sonraki göreve geç',
  pleaseSelectDocument: 'Lütfen Döküman Seçiniz.',
  taskNotFound: 'Görev bulunamadı',
  documentUpdated: 'Döküman Güncellendi',
  takeJob: 'Görevi Üstüne Al',
  messageNotSend: 'Mesaj Gönderilemedi',
  messageSent: '{0} {1} kullanıcısına SMS ile şifresi gönderildi.',
  jobCancelReason: 'Görev İptal Nedeni',
  occuredError: 'İptal işlemi sırasında hata.',
  cancelAssignedJob: '{0} adlı valeye atanan iş iptal edildi.',
  cancelSuccessful: 'İptal başarılı',
  assignDriverToJob: 'Göreve sürücü ata',
  workflow: {
    documentTypes: {
      DRIVING_LICENSE: 'Ehliyet',
      IDENTITY: 'Kimlik',
      PASSPORT: 'Pasaport',
      DIGITAL_AGREEMENT: 'E-Sözleşme',
      SIGNED_AGREEMENT: 'Sözleşme',
      SELFIE: 'Selfi',
    },
    documentStatus: {
      ACTIVE: 'Aktif',
      REJECTED: 'Reddedildi',
      PENDING_APPROVAL: 'Onay Bekliyor',
      REPLACED: 'Yenisiyle değiştirildi',
    },
    poolTypes: {
      myTasks: 'Görevlerim',
      customerDocumentApprove: 'Müşteri Doküman Onayı',
      vehicleCleaningControl: 'Araç Temizlik Kontrolü',
    },
    ocrFields: {
      IDENTITY_NUMBER: 'TCKN',
      NAME: 'Adı',
      SURNAME: 'Soyadı',
      DRIVING_LICENSE_NO: 'Ehliyet No',
      DRIVING_LICENSE_ISSUE_DATE: 'Ehliyet Veriliş',
      DRIVING_LICENSE_VALID_UNTIL: 'Ehliyet Geçerlilik',
      DRIVING_LICENSE_CLASS: 'Ehliyet Sınıfı',
    },
  },
  isSmsSend: '{0} kullanıcısına SMS ile şifre gönderilsin mi ?',
  drivingLicenceScore: 'Ehliyet Skoru',
  selfieScore: 'Selfie Skoru',
  drivingLicenceSelfieSelfieMatching: 'Ehliyet Selfie Eşleşme',
};
