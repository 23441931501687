export default {
  unknown: 'An unexpected error has occurred',
  errorOccured: 'An error occurred',
  recordNotChanged: 'Record could not be changed',
  error: 'Error',
  errorUploadingFiles: 'Error uploading files',
  notUploadedFile: 'File could not uploaded',
  transferError: 'Error. Record has not been transfered',
  recordCountError: 'You cant\'t download record as excel more than {0}. Please contact with Vektor',
  unknownError: 'Unknown Error',
  errorOccuredDuringUpdate: 'it has been occured an error during update.',
};
