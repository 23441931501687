export const tr = {
  newCampaign: 'Yeni Kampanya',
  showPassive: 'Pasifleri Göster',
  hidePassive: 'Pasifleri Gösterme',
  fixedCode: 'Sabit Kod',
  percent: 'Yüzde (%)',
  amount: 'Miktar',
  campaignBenefitType: 'Fayda Türü',
  pointsAwardedAt: 'Puanların Verilme Zamanı',
  pointsAwardedAt_AT_START: 'Kiralama Başlangıcında',
  pointsAwardedAt_AFTER_END: 'Kiralama Bittikten Sonra',
  benefitTypes: {
    DISCOUNT_BY_CODE: 'Kod ile İndirim',
    DISCOUNT_BY_AUTO_APPLY: 'Otomatik İndirim',
    POINTS_BY_CODE: 'Kod ile Puan',
    POINTS_BY_AUTO_APPLY: 'Otomatik Puan',
  },
  grid: {
    name: 'İsim',
    active: 'Aktif',
    type: 'Tür',
    discount: 'İndirim Tutarı',
    totalCode: 'Toplam Kod',
    usedCode: 'Kullanılan Kod',
    available: 'Uygun Kod',
    limitPerUser: 'Kişi Başı Limit',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    createdBy: 'Yaratan',
    firstUse: 'İlk Kullanım',
    lastUse: 'Son Kullanım',
  },
  modal: {
    name: 'Kampanya İsmi',
    desc: 'Kampanya Tanımı',
    type: 'İndirim Tipi',
    firstOnly: 'Bu kampanya sadece ilk kiralamalarda geçerli olsun',
    percentAmount: 'Yüzde İndirim (%)',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    startTime: 'Başlangıç Saati',
    endTime: 'Bitiş Saati',
    codeType: 'Kod Tipi',
    auto: 'Otomatik Kod',
    prefix: 'Prefix',
    limit: 'Toplam Limit',
    length: 'Kod Uzunluğu',
    amount: 'Miktar',
    percent: 'Yüzde',
    action: 'Kampanyayı Yarat',
  },
  announcement: {
    type: {
      CAMPAIGN: 'Kampanya',
      ANNOUNCEMENT: 'Duyuru',
    },
  },
};

export const en = {
  newCampaign: 'New Camapign',
  showPassive: 'Show Passive',
  hidePassive: 'Hide Passive',
  fixedCode: 'Fixed Code',
  percent: 'Percent (%)',
  amount: 'Amount',
  campaignBenefitType: 'Benefit Type',
  pointsAwardedAt: 'When To Award',
  pointsAwardedAtStart: 'At Rental Start',
  pointsAwardedAfterEnd: 'After Rental Finish',
  benefitTypes: {
    DISCOUNT_BY_CODE: 'Discount by code',
    DISCOUNT_BY_AUTO_APPLY: 'Auto apply',
    POINTS_BY_CODE: 'Discount by points',
  },
  grid: {
    name: 'Name',
    active: 'Active',
    type: 'Type',
    discount: 'Discount Amount',
    totalCode: 'Total Code',
    usedCode: 'Used Code',
    available: 'Available Codes',
    limitPerUser: 'Limit Per User',
    startDate: 'Start Date',
    endDate: 'End Date',
    createdBy: 'Created By',
    firstUse: 'First Use',
    lastUse: 'Last Use',
  },
  modal: {
    name: 'Campaign Name',
    desc: 'Campagin Description',
    type: 'Discount Type',
    firstOnly: 'This campaign will be available only to new rentals.',
    amount: 'Discount Amount (TL)',
    percentAmount: 'Percent Discount (%)',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    codeType: 'Code Type',
    auto: 'Auto Code',
    prefix: 'Prefix',
    limit: 'Total Limit',
    length: 'Code Length',
    action: 'Create Campaign',
  },
};

export default tr;
