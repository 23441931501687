<template>
  <div class="flipcard">
    <div class="flipcard-front">
      <span class="is-block has-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
        </svg>
      </span>
      <slot name="front"/>
    </div>
    <div class="flipcard-back">
      <slot name="back"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlipCard',
};
</script>

<style lang="scss">
.flipcard{
  height: 114px;
  position: relative;
  width: 100%;
  cursor: pointer;
  z-index: 9;
  perspective: 800px;

  .flipcard-front{
    height: 100%;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 11;
    transform: rotateX(0deg) rotateY(0deg);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: all 0.4s ease-in-out;

    .has-icon{
      position: absolute;
      top: 4px;
      right: 6px;
      width: 1rem;
      height: 1rem;
      color: white;
      z-index: 1;
    }
  }

  .flipcard-back{
    position: absolute;
    top: 0;
    border: 0;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 10;
    transform: rotateY(-179deg);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: all 0.4s ease-in-out;
    color: green;
  }

  &:hover{
    .flipcard-front{
      z-index: 11;
      transform: rotateY(180deg);
    }

    .flipcard-back{
      z-index: 12;
      transform: rotateX(0deg) rotateY(0deg);
    }
  }
}
</style>
