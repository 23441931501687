export default {
  successfullSynchronized: 'Senkronizasyon başarı ile tamamlandı',
  userUpdated: 'Kullanıcı Güncellendi',
  userCreated: 'Kullanıcı Yaratıldı',
  successful: 'Başarılı',
  successfulRefund: 'İade işlemi başarılı',
  updated: 'Güncellendi',
  dataUpdated: 'Veriler Güncellendi',
  fileUploaded: 'Dosya Yüklendi',
  fileSendApprove: 'Dosya Onaya Gönderildi',
  sendNotifications: 'Dosya Yüklendi.Bildirimler gönderilecek',
  valuesUpdated: 'Değerler Güncellendi.',
  changingActivePassive: 'Aktif / Pasif değişimi yapılmıştır',
};
