
export default {
  SHUTTLE: 'flexiShuttle',
  VANPOOL: 'flexiVanpool',
  FLEXI_RIDE: 'flexiRide',
  SHARED_RIDE: 'flexiSharedRide',
  FLEXI_CAR: 'flexiCar',
  FLEXI_CARPOOL: 'flexiCarpool',
  OTHER: 'Other',
}
