export default {
  modesOfCommute: 'İşe Gidip Gelme Modları',
  commuteHeaders: {
    driving: 'Sürücülü Araç',
    vanPool: 'Havuz Araç (Van)',
    carPool: 'Havuz Araç',
    shuttle: 'Servis',
    transit: 'Toplu Taşıma',
    walking: 'Yürüyüş & Bisiklet',
  },
  totalEmployees: 'Tüm Çalışanlar',
  totalEmission: 'Toplam Emisyon',
  totalDuration: 'Toplam Süre*',
  current: 'Şu an',
  best: 'En iyi',
  totalCost: 'Maliyet - Toplam, İşveren tarafından ödenen, çalışan tarafından ödenen',
  hoursMonth: 'Saat / Ay',
  statusCompared: 'En İyi Senaryoya Göre Mevcut Durum',
  emission: 'Emisyon',
  emissionInfo: 'Servisle yolculuk süresinin aynı güzergah için arabayla yolculuk süresine oranı.',
  cost: 'Maliyet',
  timeWastedDriving: 'Araç Üzerinde Harcanan Zaman',
  acceptanceRate: 'Kabul Oranı',
  acceptanceRateInfo: 'Personelin paylaşımlı ulaşım seçeneklerini kabul etme oranı.',
  hoursYears: 'Saat/Yıl',
  avg: 'Ort.',
  total: 'Toplam',
  employee: 'Çalışan',
  employeeLabel: 'çalışan',
  mile: 'Kilometre',
  paidByEmployees: 'Çalışanlar tarafından ödenen',
  paidByEmployer: 'İşveren tarafından ödenen',
  perEmployee: 'Çalışan Başına',
  perMile: 'Mil Başına',
  forNonCommute: 'Ulaşım Dışı Seçenekler',
  kgMonth: 'KG/Ay',
  currencyMonth: 'USD/Ay',
  settings: {
    settingsDescription: 'Ücretli bir müşteri olarak, demo modunda salt okunur olan parametreleri düzenleme seçeneğine sahip olacaksınız.',
    maxDistances: 'Max. Uzaklıklar',
    maxDistancesInfo: 'Personelin belirtilen ulaşım seçeneklerinde en fazla ne kadar mesafe gidebileceklerini belirleyebilirsiniz.',
    maxWalkingDistance: 'Max. Yürüme Mesafesi',
    maxBikingDistance: 'Max. Bisiklet Mesafesi',
    durationCoefficients: 'Süre Katsayıları',
    durationCoefficientsInfo: 'Belirtilen ulaşım seçeneklerinin yolculuk sürelerinin aynı güzergah için arabayla yolculuk süresine oranı.',
    forShuttle: 'Servis için',
    forTransit: 'Toplu Ulaşım için',
    subsidies: 'Sübvansiyonlar',
    subsidiesInfo: 'Ulaşım maliyetlerinin ne kadarını personel adına şirketin karşılayacağını belirleyebilirsiniz.',
    percentage: 'Yüzde',
    value: 'Değer',
    shuttle: 'Servis',
    vanpool: 'Havuz Araç',
    transit: 'Toplu Taşıma',
    vehicleTypes: 'Araç Tipleri',
    vehicleTypesInfo: 'Şirkete ait farklı araç tiplerinin oranlarını girebilirsiniz.',
    electric: 'Elektrik',
    ice: 'İYM',
    facilities: 'İmkanlar',
    facilitiesInfo: 'Şirketin sunduğu olanaklar için kapasite bilgisi girebilirsiniz.',
    carParkingCapacity: 'Otopark Kapasitesi',
    bikeParkingCapacity: 'Bisiklet Parkı Kapasitei',
    carChargingPortCapacity: 'Araç Şarj Portu Kapasitesi',
    daily: 'Günlük',
    monthly: 'Aylık',
    free: 'Ücretsiz',
    resetToDefaultValues: 'Varsayılan Değerlere Dön',
    recalculateCommuteOptions: 'İşe Gidiş Seçeneklerini Yeniden Hesapla',
    internalCombustion: 'İçten Yanmalı Motor',
    parkingCost: 'Otopark Ücreti',
  },
  location: 'Konum',
  shifts: 'Vardiyalar',
  employees: 'Kişiler',
  riders: 'Kişiler',
  setup: {
    approveYourCompanyAddress: 'Şirket adresini onayla.',
    companyLocation: 'İşe gidiş geliş rotanızı oluşturmak için şirketinizin konumuna ihtiyacımız var.',
    downloadExcelTemplate: 'Excel şablonumuzu indirebilir, çalışan bilgileriniz doldurabilir ve yükleyebilirsiniz. Alternatif olarak bilgileri manuel olarak da girebilirsiniz.',
    uploadWithExcel: 'Excel ile Yükle',
    enterManually: 'Manuel Ekle',
    homeAddress: 'Çalışan ev adreslerine ihtiyacımız var',
    calculateRoute: 'böylece rotaları hesaplayabilir ve mevcut durumunuzla karşılaştırmak için optimize edilmiş işe gidip gelme senaryoları oluşturabiliriz.',
    sampleSize: 'Demo için örneklem büyüklüğü 100 çalışanla sınırlıdır.',
    demoDescription: 'Ücretli hesaplar, tüm çalışan verilerini İK sistem entegrasyonları ile kolayca yükleyebilir. Ücretli hesap avantajları hakkında daha fazla bilgi edinmek ve bir tane başlatmak isterseniz, sizinle iletişime geçmekten memnuniyet duyarız!',
    eligibleFor: 'Kullanım Hakları',
    shuttle: 'Servis',
    ride: 'Sürücülü Araç',
    carpool: 'Havuz Araç',
    workSchedule: 'İşe gidip gelme saatleri ile alakalı rota hesaplamak için şirketinizin çalışma programına ihtiyacımız var.',
    addShiftToCampuses: 'Lütfen her kampüsünüz için vardiya bilgilerinizi ekleyin.*',
    demoPurposes: '*Demo için sadece bir vardiya girebilirsiniz.',
    addShift: 'Vardiya Ekle',
    shiftName: 'Vardiya İsmi',
    questionMark: 'Hangi alanların isteğe bağlı olduğunu ve bu bilgilerin neden gerekli olduğunu görmek için soru işaretlerinin üzerine gelin.',
  },
  allCampuses: 'Tüm Kampüsler',
  allDepartments: 'Tüm Departmanlar',
  employeeSaved: 'Çalışan kaydedildi.',
  continueAddingOthers: 'Diğerlerini eklemeye devam edebilirsiniz.',
  searchPlaceholder: 'Çalışan adı veya e-posta ile arama yapın',
  viewFictiveData: 'Hayali Veri ile Göster',
  viewWithYourData: 'Kendi Verin ile Göster',
  tryWithYourOwnData: 'Kendi Verilerinizle Deneyin',
  tryWithYourOwnDataTitle: '{0} için Demo Ayarlama',
  contactUs: 'Bizimle İletişime Geçin',
  totalKm: 'Toplam Uzaklık*',
  passengers: '# Yolcular',
  appArrivalTimes: 'Kalkış ve yerleşkeye ulaşma saati',
  avgWalkingDistanceInMeter: 'Ortalama Yürüme Mesafesi',
  avgTimeCoefficient: 'Ortalama Süre Katsayısı',
  oneWay: '*Tek Yön',
  vehicleCapacity: 'Araç Kapasitesi',
  distanceToCampus: 'Yerleşkeye uzaklık',
  onboarding: 'Personel Sayısı',
  avgDistanceToStop: 'Durağa personnelerin ortalama yürüme mesafesi',
  address: 'Adres',
  employeeName: 'Personel Adı',
  commuteMode: 'İşe Gidip Gelme Modu',
  currentCommuteMode: 'Mevcut İşe Gidip Gelme Modu',
  commuteModeOptions: {
    future: 'Mevcut İşe Gidip Gelme Modu',
    current: 'Mevcut İşe Gidip Gelme Modu',
    best: 'En İyi İşe Gidip Gelme Modu',
  },
  emissionImpact: 'Emisyon Etkisi',
  viewProfile: 'Profile git',
  viewDetails: 'Detaylara git',
  directions: 'Rotalar',
  otherModes: 'Diğer Mod Seçenekleri',
  showOnMap: 'Harita üzerinde göster',
  routes: 'Rotalar',
  modes: 'Modlar',
  searchRoute: 'Rota Ara',
  notFoundRoute: 'Rota bulunamadı',
  occupancy: 'Doluluk Oranı',
  stops: '# Duraklar',
  walkingZone: 'Yürüme Alanı',
  reverseIsolineZone: 'Sürüş Mesafesi',
  deleteSelected: 'Seçili Olanları Sil',
  check: 'Ver',
  uncheck: 'Kaldır',
  selectedEligibleFor: 'seçili olanlar için',
  employeesSelected: 'personel seçildi',
  termsDescription: 'Veri gizliliği konusunda endişeli misiniz? <br><br> Flexigo, GDPR uyumludur ve Amazon sunucularında çalışır.',
  addAndSendEmail: 'Ekle & E-mail Yolla',
  emailSentInfo: 'Seçilen çalışanlara hoşgeldiniz e-maili gönderildi. Kayıt durumunu çalışanlar sayfasından takip edebilirsiniz.',
  resendInvitationEmail: 'Davet E-postasını Yeniden Gönder',
  moreResponds: 'Devam etmek için daha fazla yanıt gerekiyor...',
  info: 'Bilgi',
  commuteModes: 'İşe Gidiş Geliş Modları',
  monthlyCostUsd: 'Aylık Maliyet (USD)',
  monthlyCost: 'Aylık Maliyet',
  letsGo: 'Hadi İlerleyelim',
  letsGoTooltip: 'Şirketiniz için bir simülasyon oluşturmak için yeterli bilgiyi topladık.',
  emissionRating: 'Emisyon Skoru',
  expandMap: 'Haritayı Genişlet',
  shrinkMap: 'Haritayı Küçült',
  addEmployeeData: 'Yolcu ekle',
  pedestrian: 'Yaya',
  transit: 'Toplu Taşıma',
  shuttle: 'Servis',
  tutorial: {
    title: 'Bu gösterge panosu, SOV dışı işe gidip gelme seçeneklerinin değişen kabul oranları için size 2 senaryo sunar.',
    firstDescription: 'Bir şirketteki çalışanlar, çeşitli işe gidip gelme modları için uygundur. SOV dışı seçenekleri ne kadar çok benimserlerse, o kadar fazla emisyon ve maliyet tasarrufu sağlanır.',
    secondDescription: 'Mevcut senaryo, ortalama ulusal istatistik verilerine dayanan varsayımlar üzerine inşa edilmiştir. En iyisi, sürdürülebilirlik açısından ulaşılabilecek nihai seviyedir.',
  },
  goToCampus: 'Kampüse Git',
  avgTimeCoefficientText: 'Servisle yolculuk süresinin aynı güzergah için arabayla yolculuk süresine oranı',
  deniedGeolocation: 'Kullanıcı konum paylaşma izni vermedi.',
  duration: 'Süre',
  hours: 'saat',
  minutes: 'dakika',
  currentlyWaiting: 'Şu anda çalışanların kaydının tamamlanması bekleniyor.',
  viewData: 'Kullanıcı bilgileri alındıkça bu sayfadaki verileri görüntüleyebilirsiniz.',
  viewRegistrationStatus: 'Kayıt Durumunu Görüntüle',
  dataForEmployees: 'Şimdiye kadar kayıt yaptıran {0} çalışanın verileri görüntüleniyor.',
  inProgressMessage: 'Aşağıda kayıtlı olanlar için verileri görüntüleyebilirsiniz. %80 tamamlandığında, optimizasyonu çalıştırabilecek ve çalışanlarınızın kullanabileceği servis hatlarını görüntüleyebileceksiniz.',
  tooltip: {
    name: 'Demo için personel isimlerini girmeniz opsiyoneldir. Boş bırakıldığı takdirde temsili isimler gösterilecektir.',
    email: 'Personelinize hoşgeldin e-postası gönderilecek ve ev adresleri ile mevcut ulaşım yöntemlerini kaydetmeleri için Flexigo uygulamasına yönlendirileceklerdir.',
    department: 'Demo için departman bilgisi girmeniz opsiyoneldir. Boş bırakıldığı takdirde temsili departmanlar gösterilecektir.',
    address: 'Personelin adres bilgilerini girebilirsiniz. Adresler uygulama üzerinden kullanıcılara teyid ettirilecek veya eksikse girilmesi istenecektir.',
    eligibleFor: 'Personelin hangi servislere erişimi olacağını seçebilirsiniz. Demo için tüm seçenekler seçili gelecektir.',
  },
  optimizationSplashInfo: 'Optimizasyon çalışıyor ve 2-3 dakika içerisinde birkaç çözüm gelecektir. İşe gidip gelme analiz panosu üzerinden tüm çalışanlarınızın işe gidip gelme modlarını mevcut ve en iyi seçeneklere göre görebilir ve karşılaştırabilirsiniz.',
  setupTrialWarning: 'Verilerinizle deneme için kurulumu tamamlamadınız. Kuruluma daha sonra devam etmek için İşe Dönüş Analizi Ekranında Kendi Verilerinizle Deneyin düğmesini tıklayın.',
  searchingForCurrentLocation: 'Mevcut konum aranıyor',
  setServiceEligibility: 'Kullanım Haklarını Düzenle',
  markAgreementSignatureStatus: 'Sözleşme İmza Durumunu İşaretle',
  selectServices: 'Modülleri seçin',
  selectAgreement: 'Sözleşmeleri seçin',
  setSelectedRidersAs: 'Seçilen yolcular',
};
