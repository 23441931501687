export default {
  userStatus: {
    heading: 'flexiCarpool/User Status',
    user: 'User',
    carpoolerSince: 'Carpooler Since',
    currentStatus: 'Current Status',
    address: 'Address',
    ridersMatch: 'Riders Matched With',
    ridersThumbsUp: 'Riders Given Thumbs Up',
    ridersThumbsDown: 'Riders Given Thumbs Down',
    driversMatch: 'Drivers Matched With',
    driversThumbsUp: 'Drivers Given Thumbs Up',
    driversThumbsDown: 'Drivers Given Thumbs Down',
  },
  approvedMatches: {
    heading: 'flexiCarpool/Approved Matchings',
    driver: 'Driver',
    driverAddress: 'Driver Address',
    rider: 'Rider',
    riderAddress: 'Rider Address',
    startDate: 'Starting Date',
    endDate: 'Ending Date',
    totalDistance: 'Total Distance',
    costPerTrip: 'Cost Per Trip',
    qrReads: 'Qr Reads',
    totalCost: 'Total Cost',
    incentive: 'Incentive',
    campus: 'Campus',
  },
  flexiCarpoolTrips: 'flexiCarpool Trips',
  QRAddress: 'QR Address',
  QRTime: 'QR Time',
  riderHomeAdress: 'Rider Home Address',
  driverHomeAdress: 'Driver Home Address',
  distanceWithRider: 'Distance with Rider ({0})',
  newTrip: 'New Trip',
  inbound: 'Inbound',
  outbound: 'Outbound',
};
