export default {
  stop: 'Stop',
  distanceToStop: 'Distance To Stop',
  timeOnShuttle: 'Time on Shuttle',
  timeOnVanpool: 'Time on Vanpool',
  min: 'min',
  routeName: 'Route Name',
  capacity: 'Capacity',
  totalCost: 'Total Cost',
  totalDistance: 'Total Distance',
  totalDuration: 'Total Duration',
  status: 'Status',
  plate: 'Plate',
  driver: 'Driver',
  reservation: 'Reservation',
  transferToDifferentRoute: 'Transfer to Different Route',
  searchRoutePlateDriver: 'Search Route, Plate, Driver...',
  personnelAddresses: 'Personnel Addresses',
  addNewRoute: 'New Route',
  editSelectedRoutes: 'Edit Selected Routes',
  stations: 'Stations',
  campus: 'Campus',
  responsableCampus: 'Responsible Campus',
  workgroup: 'Route Group',
  routeType: 'Route Type',
  vehicle: 'Vehicle',
  serviceCompany: 'Service Company',
  editRoute: 'Edit Route',
  activate: 'Activate',
  activeRoutes: 'Active Routes',
  passiveRoutes: 'Passive Routes',
  draftRoutes: 'Draft Routes',
  deleteGroup: 'Delete Group',
  shortRouteName: 'Short Route Name',
  accountingCenter: 'Accounting Center',
  editInfo: 'Edit Info',
  personnelList: 'Personnel List',
  journeys: 'Journeys',
  cardIssuances: 'Card Issuances',
  journeyList: 'Journey List',
  requests: 'Requests',
  confirmations: 'Confirmations',
  type: 'Type',
  walkingDistance: 'Walking Distance',
  journeyName: 'Journey Name',
  instantOccupancy: 'Instant Occupancy',
  transportDuration: 'Transport Duration',
  showGroupInfo: 'Show Group Info',
  hideGroupInfo: 'Hide Group Info',
  optimizeWaypoints: 'Optimize Waypoints',
  recalculateAll: 'Recalculate Modified',
  saveAndActive: 'Save and Activate Route',
  saveAndActiveMultiple: 'Save and Activate Routes',
  showHistory: 'Show History',
  addNewStation: 'Add New Stop',
  selectRoute: 'The route to which the stop will be connected',
  stopByRoute: 'Choose the order in which to stop by this stop on the way',
  transferRoute: 'Transfer Route from History',
  reset: 'Reset',
  calculate: 'Calculate',
  recalculate: 'Recalculate',
  newSettings: 'Preserve order of stops.',
  makeRouting: 'Run Optimization',
  routeInfo: 'Route Info',
  accountingInfo: 'Accounting Info',
  bridgeCrossing: 'Bridge Crossing',
  departure: 'Departure',
  arrival: 'Arrival',
  OUTGOING: 'Outgoing',
  INCOMING: 'Incoming',
  routeInstanceState: {
    PERIOD_STARTED: 'Period Started',
    ARRIVED_ON_TIME: 'Arrived On Time',
    ARRIVED_LATE: 'Arrived Late',
    NOT_ARRIVED: 'Not Arrived',
    LEAVE_EARLY: 'Left Early',
    LEAVE_ON_TIME: 'Left On Time',
    READY_TO_LEAVE: 'Ready to Leave',
    DEVICE_NOT_OK: 'No Telematics Data',
    CREATED: 'Not Started',
    LATE_ARRIVAL: 'Late Arrival',
    ON_THE_WAY: 'On the Way',
    ON_THE_WAY_WITH_DELAY: 'On the Way with Delay',
    STARTING_TIME_DELAYED: 'Departure Delayed',
    PENDING_ACTIONS: 'Edit',
    CANCELLED: 'Canceled by Admin',
    CANCELLED_NO_RESERVATION: 'No Reservations',
  },
  ONE_WAY: 'One Way',
  ROUND_TRIP: 'Round Trip',
  RING: 'Ring',
  workingTypes: {
    FIXED: 'Fixed',
    REGULAR: 'Regular',
    DYNAMIC: 'Dynamic',
  },
  fixedPriceItem: 'Fixed Price Item',
  kmUnitPriceItem: 'Km Unit Price Item',
  miUnitPriceItem: 'Mi Unit Price Item',
  accountCenter: 'Account Center',
  passingBridge: 'Passing Bridge',
  bridgeTollItem: 'Bridge Toll Item',
  revisionItem: 'Revision Item',
  priceDaily: '1 Day Price',
  noPersonnel: 'No assigned riders',
  assignDriverAndVehicle: 'Assign Driver and Vehicle',
  vehicleDriverChangeReason: {
    TEMPORARY_VEHICLE: 'Vehicle is temporarily changed',
    TEMPORARY_DRIVER: 'Driver is temporarily changed',
    TEMPORARY_VEHICLE_AND_DRIVER: 'Vehicle and driver temporarily changed',
    OTHER: 'Other',
  },
  routeTransferred: 'Route transferred',
  addNewStationRightClick: 'Right click where you want to add a new stop.',
  assignedToStop: 'Assigned to stop',
  outsideOfWalkingDistance: 'Outside of walking distance',
  notAssignedStop: 'Not assigned a stop',
  notAssignedRoute: 'Not assigned a route',
  serviceLine: 'Service Line',
  shuttleLine: 'Shuttle Line',
  station: 'Stop',
  stops: 'Stops',
  destinationTravelTime: 'Destination Travel Time',
  travelTime: 'Travel Time',
  route: 'Route',
  timeCoefficient: 'Time Coefficient',
  assignStation: 'Assign Stop',
  unassignFromCurrentStop: 'Unassign from Current Stop',
  personnel: 'Rider',
  assignToRoute: 'Assign to Route',
  editStation: 'Edit Stop',
  selectStation: 'Select the route to assign a stop named {0}',
  deleteStation: 'Are you sure to delete this stop?',
  changingOnRoute: 'Made adjustment on route.',
  updatedSuccessfully: 'Route has been updated',
  createdRoute: 'Route has been created',
  addedStation: 'Stop has been created',
  updatedStation: 'Stop has been updated',
  deletedStation: 'Stop has been deleted',
  activatedRoute: 'Route has been activated',
  sureToRouteDelete: 'Edited routes will be deleted. Are you sure ?',
  cancel: 'Cancel',
  continueEdit: 'Continue Edit',
  cancelEdit: 'Cancel Edit',
  activateEditedRoutes: 'Edited routes will be activated. Are you sure ?',
  editedRoutes: 'You have already {0} edited routes',
  createdEditRoute: 'Route(s) has been created',
  savedEditRoutes: 'Route(s) has been saved',
  deletedEditedRoutes: 'Route(s) has been deleted',
  saveAndActivateRoute: 'Route(s) has been saved and activated',
  selectStationPoint: 'Click on the stop you want to select.',
  personnelToStop: '{0} will be assigned to stop {1}.',
  personnelToRemoveStop: '{0} will be unassigned to stop {1}.',
  removePersonnelFromStationInfo:
    'As there will be no riders left, the stop can be deleted or preserved. If you choose to delete the stop, you will need to reclaculate the route.',
  removePersonnelFromStationConfirm: 'Are you sure you want to detach the rider from the stop?',
  detachRiderAndKeepTheStop: 'Detach Rider and Keep the Stop',
  detachRiderAndDeleteTheStop: 'Detach Rider and Delete the Stop',
  personnelAssignedToStop: 'Personnel assigned to stop successfully.',
  personnelUnassignedToStop: 'The rider has been successfully unassigned from the stop.',
  statusType: {
    0: 'OK',
    1: 'No Device',
    2: 'No Connection',
  },
  optimizations: 'Optimizations',
  areYouSureToCancel: 'Your changes were not saved, are you sure you want to cancel?',
  routeHistory: 'Route History',
  toStart: 'To Start',
  toEnd: 'To End',
  assignSuccessMessageWithApprovalRequired:
    'Your driver and/or vehicle request has been received. An update will be provided after approval.',
  assignSuccessMessage: 'Driver and/or vehicle has been assigneed to the selected route successfully.',
  legend: {
    showStations: 'Stops',
    assignedRoutes: 'Assigned to Stop',
    outOfWalkingDistance: 'Outside of walking distance',
    withoutRoute: 'Not assigned to a route',
    withoutStation: 'Not assigned to a stop',
    drivers: 'Drivers',
    activeDriver: 'Active Driver',
    driverCandidates: 'Driver Candidates',
    bestCommuteShuttle: 'Best Commute Shuttle',
  },
  editMultipleRoutesHeader: 'Route Edit Mode',
  multipleDraftsExistNotification:
    'You already have routes in draft mode. Please activate or cancel them before creating new draft.',
  sendToOptimization: 'Run Optimization with Selected',
  selectForOptimization: 'Select the routes and/or the riders you would like to run optimization with.',
  runOptimization: 'Run Optimization',
  exitOptimizationSelection: 'Exit Optimization Selection',
  needToAddMultipleStops: 'Need to add multiple stops.',
  routeSelected: '{0} route(s) selected.',
  unassignedPersonnel: 'Unassigned Riders',
  noRoutesYet: 'There are no routes in this group yet.',
  optimizeGroup: 'Optimization will be run automatically at {0} on {1}.',
  createRoutesManually: 'If you would like to run optimization now,',
  assignToNearestStop: 'Assign to Nearest Stop',
  riders: 'Riders',
  vanpoolPreferenceInSurvey: 'Vanpool Preference in Survey',
  drivingPreferenceInSurvey: 'Driving Preference in Survey',
  lastSentConfirmation: 'Last Sent Confirmation',
  dateSent: 'Date Sent',
  riderConfirmation: 'Rider Confirmation',
  driverConfirmation: 'Driver Confirmation',
  sendConfirmation: 'Send Confirmations',
  confirmationManagement: 'Confirmation Management',
  confirmationsToBeSent: 'Confirmations to be Sent',
  approvalTypes: {
    NONE: 'None',
    VANPOOL_DRIVER: 'Driving Approval',
    VANPOOL_USER: 'Attendance Approval',
    DRIVER_AND_USER: 'Driving & Attendance Approval',
  },
  approvalTypeChangedSuccessfully: 'Approval type changed successfully.',
  responseStatuses: {
    NONE: '-',
    POSITIVE: 'Confirmed',
    NEGATIVE: 'Declined',
    PENDING: 'Awaiting',
  },
  confirmAssignPersonnelDriver: '{0} will be assigned as the driver. Do you confirm?',
  confirmAssignVanpoolAndPersonnelDriver:
    'The user has stated their preference to join vanpool as a rider. Do you still want to assign them as a driver?',
  driverAssignedSuccessfully: 'The personnel has been successfully assigned as the driver.',
  confirmpersonnelRemovedFromWorkgroup: '{0} will be removed from the workgroup. Do you confirm?',
  personnelRemovedFromWorkgroup: 'Personnel removed from workgroup.',
  routeStatus: 'Routes Status',
  routeStatusTypes: {
    CREATED: 'Created',
    CONFIRMATION_SENT: 'Confirmation Sent',
    MIN_OCCUPENCY_REACHED: 'Min Occupancy Reached',
    MIN_OCCUPENCY_EXPECTED: 'Min Occupancy Expected',
    READY: 'Ready',
    ACTIVE: 'Active',
  },
  addPolygonSelection: 'Add Polygon Selection',
  addPolygon: 'Add Polygon',
  exitPolygonSelection: 'Exit Polygon Selection',
  searchRoutes: 'Search Routes...',
  searchRiders: 'Search Riders...',
  departureLocation: 'Departure Location',
  departureTime: 'Departure Time',
  arrivalLocation: 'Arrival Location',
  arrivalTime: 'Arrival Time',
  assignment: 'Assignment',
  boarding: 'Boarding',
  anomaly: 'Anomaly',
  stopProgress: 'Stop Progress',
  nextStop: 'Next Stop',
  attendance: 'Attendance',
  occupancy: 'Occupancy',
  reservations: 'Ridership',
  regularRiders: 'Regular Riders',
  optimizationSuccess:
    'Optimization is running. According to count of rider in this group it may take a few minutes. Once it comes up a solution, you can see the results in this page.',
  saveAndActivateAllDrafts: 'This will save and activate all drafts routes. Are you sure?',
  totalKm: 'Total Km',
  totalMi: 'Total Mi',
  saveToRoute: 'Are you sure you want to apply the changes to the selected routes?',
  setRouteAsActive: 'Are you sure you want to activate the selected routes?',
  setRouteAsPassive: 'Are you sure you want to disable the selected routes?',
  deleteSelectedroute: 'Are you sure you want to delete the selected routes?',
  saveChangesConfirm: "Are you sure you'd like to save the changes?",
  stopsLocations: 'Stops/Locations',
  plannedStart: 'Planned Start',
  actualStart: 'Actual Start',
  plannedArrival: 'Planned Arrival',
  actualArrival: 'Actual Arrival',
  plannedDeparture: 'Planned Departure',
  actualDeparture: 'Actual Departure',
  routeGroupName: 'Route Group Name',
  routeGroup: 'Route Group',
  plannedOccupancy: 'Planned Occupancy',
  attendanceSoFar: 'Attendance So Far',
  declaredByDriver: 'Declared by Driver',
  plannedDepartureTime: 'Planned Dep. Time',
  plannedArrivalTime: 'Planned Arr. Time',
  tripTracking: 'Trips',
  delayedJourneys: {
    ON_THE_WAY_WITH_DELAY: 'On the Way with {min} min. Delay',
    ARRIVED_LATE: 'Completed with {min} min. Delay',
    STARTING_TIME_DELAYED: 'Starting Time Delayed for {min}',
    NOT_ARRIVED: 'Not Arrived',
    PERIOD_STARTED: 'Period Started',
    ARRIVED_ON_TIME: 'Arrived On Time',
    LEAVE_EARLY: 'Left Early',
    LEAVE_ON_TIME: 'Left On Time',
    READY_TO_LEAVE: 'Ready to leave',
    DEVICE_NOT_OK: 'No Telematics Data',
    CREATED: 'Created',
    LATE_ARRIVAL: 'Late Arrival',
    ON_THE_WAY: 'On the way',
  },
  headingToStop: 'Heading to Stop {0} of {1}',
  planned: 'Planned',
  actual: 'Actual',
  arrivedLastStop: 'Arrived at Last Stop',
  progress: 'Progress',
  plannedRiders: 'Planned Riders',
  actualRiders: 'Actual Riders',
  startLocation: 'Start Location',
  headingToDepot: 'Heading to Depot',
  headingToCampus: 'Heading to Campus',
  arrivedAtDepot: 'Arrived at Depot',
  arrivedAtCampus: 'Arrived at Campus',
  noRoutesGroupYet:
    'There are no routes for this group yet. <br><br> You can automatically create routes by clicking the Run Optimization button.<br><br> Alternatively, you can manually create routes by clicking the Add New Route button.<br><br>',
  noRoutesGroup:
    'There are no routes for this group yet. {br}{br} Automatic optimization has been set for this group.{br}{br} Optimization will be run at {time} and routes will be created.',
  dontShowAgain: 'Don`t show again',
  cardQrRead: 'Card Qr Read',
  awaiting: 'Not Started',
  ready: 'Ready',
  planningStatus: 'Planning Status',
  assignAsDriver: 'Assign as Driver',
  showVehicleTracking: 'Show Vehicle Tracking',
  unregisteredRiders: 'Unregistered Riders',
  unregistered: 'Unregistered',
  selectRouteTransfer: 'Select Route to Transfer to',
  transferReservations: 'Transfer Reservations',
  notAssignedToStop: 'Not assigned to a stop',
  selectRidersToTransferred: 'Select Riders to be Transferred',
  noRidersOnRoute: 'There are currently no riders on this route for the selected date.',
  transferredFrom: 'transferred FROM {0}',
  transferredTo: 'transferred TO {0}',
  transferringFrom: 'Transferring From {0} - {1}',
  accounting: {
    calculatedKM: 'Calculated KM',
    actualKM: 'Actual KM',
    contractKM: 'Contract KM',
    revision: 'Revision',
    bridge: 'Bridge',
    fixedPrice: 'Fixed Price',
    kmPrice: 'KM Price',
  },
  planningStatusOptions: {
    REGULAR: 'Regular',
    REQUESTED: 'Requested',
    RESERVED: 'Reserved',
    FAILED: 'Failed',
    CANCELLED: 'Cancelled',
    NOT_RESERVED: 'Not Reserved',
  },
  boardingOptions: {
    ARRIVED: 'Arrived',
    AWAITING: 'Awaiting',
    SKIPPED: 'Skipped',
    BOARDED: 'Boarded',
    NO_SHOW: 'No Show',
    REPEATING_NO_SHOW: 'Repeating No Show',
  },
  ridesUniqueRiders: 'Rides/Unique Riders',
  over500: { ft: 'Over 500 ft to Stop', m: 'Over 500 m to Stop' },
  pleaseSelectRevision: 'Please Select Revision',
  pleaseSelectBridge: 'Please Select Bridge',
  pleaseSelectFixedPrice: 'Please Select Fixed Price',
  pleaseSelectKMPrice: 'Please Select KM Price',
  totalStopsEmptyStops: 'Total Stops/Empty Stops',
  emptyStops: 'Empty Stops',
  completeRouteDefinition: 'Complete Route Definition',
  editRouteMessage:
    'The ring route for this group was created automatically. <br/> To activate the route, please complete the route definition, and assign vehicles and drivers to each trip.',
  walkingDurationInMin: 'Walking Duration In Min',
  actualDepartureTime: 'Actual Departure Time',
  actualArrivalTime: 'Actual Arrival Time',
  boardingStatus: 'Boarding Status',
  latestStatus: 'Latest Status',
  driverVehicleSameTrips: 'Driver and vehicle are the same for all trips.',
  driverVehicleDifferentTrips: 'Assign different vehicle/driver to each trip.',
  selectEachTime: 'Select each time slot and assign the corresponding resources.',
  assignToMoreTrips: 'Assign to More Trips',
  assignActiveDriver: 'Assign as Active Driver',
  assignSecondaryDriver: 'Assign as Secondary Driver',
  secondaryDriver: 'Secondary Driver',
  activeDriver: 'Active Driver',
  deleteRiderFromThisRoute: 'Are you sure you want to delete the rider from this route?',
  ridersList: 'Riders List',
  qrScans: 'QR Scans',
  secondaryDrivers: 'Secondary Driver(s)',
  direction: 'Direction',
  drivers: 'Driver(s)',
  vehicles: 'Vehicle(s)',
  vehicleName: 'Vehicle Name',
  speedUnit: 'mph',
  ridersOnBoard: 'Riders on Board',
  assignedStartingFrom: 'Assigned Starting from',
  assignedUntil: 'Assigned Until',
  assignmentReason: 'Assignment Reason',
  assignedBy: 'Assigned by',
  assignmentDate: 'Assignment Date',
  referenceNumber: 'Reference Number',
  days: 'Days',
  campusArrivalTimesForInbound: 'Campus Arrival Times for Inbound',
  campusDepartureTimesForOutbound: 'Campus Departure Times for Outbound',
  firstStopOTP: 'First Stop OTP',
  delayedCheckin: 'Delayed Checkin',
  lateArrivalToStop: 'Late Arrival To Stop',
  earlyDepartureFromStop: 'Early Departure From Stop',
  skipped: 'Skipped',
  pendingActions: 'Pending Actions',
  driverCheckin: 'Driver Checkin (Planned-Actual)',
  depotStart: 'Depot Departure (Planned-Actual)',
  firstStopArrival: 'First Stop Arrival (Planned-Actual)',
  earlyDeparture: 'Early Departure',
  lateDeparture: 'Late Departure',
  lateArrival: 'Late Arrival',
  reviewRouteStopTimesBeforeActivating:
    'Please ensure that you have reviewed the route and stop times before activating',
  ridersOn: 'RIDERS ON',
  ridersOff: 'RIDERS OFF',
  showPOIs: 'Show POIs',
  viewStops: 'View Stops',
  driverVehiclePermanentlyChange:
    'Are you sure you want to permanently change the driver and/or vehicle for this route? This permanent change will override any temporary assignments for the route.',
  changePermanently: 'Change Permanently',
  cancelTrip: 'Cancel Trip',
  revertCancellation: 'Revert Cancellation',
  confirm: 'Confirm',
  previousRiders: 'Previous Riders',
  reservedRiders: 'Reserved Riders',
  typeNotificationMessage: 'Type notification message',
  selectRidersSendNotification: 'Select riders to send the notification',
  dispatchTripCancellation: {
    REVERT: {
      INCOMING: 'The trip arriving at {1} on {0} will be operating as previously planned.',
      OUTGOING: 'The trip departing at {1} on {0} will be operating as previously planned.',
    },
    CANCELLED: {
      INCOMING: 'The trip arriving at {0} on {1} will be canceled.',
      OUTGOING: 'The trip departing at {0} on {1} will be canceled.',
    },
  },
  customNotify: {
    CANCELLED: {
      INCOMING: 'The {0} route will not be operating on the inbound trip on {1}.',
      OUTGOING: 'The {0} route will not be operating on {1}.',
    },
    REVERT: {
      INCOMING:
        'The {0} route that was previously cancelled for the inbound trip will now run on its regular schedule on {1}.',
      OUTGOING:
        'The {0} route that was previously cancelled for the outbound trip will now run on its regular schedule on {1}.',
    },
  },
  multiCampus: {
    description: 'The trips departing at {0} on {1} will be canceled.',
    defaultMessage: 'The {0} route will not be operating on the trips departing at {1} on {2}. ',
  },
  cancelMessage: {
    CANCELLED: {
      INCOMING: 'Please confirm if you want to cancel the inbound trip of the route {0} on {1}.',
      OUTGOING: 'Please confirm if you want to cancel the outbound trip of the route {0} on {1}.',
    },
    REVERT: {
      INCOMING:
        'Please confirm if the {0} route will be operating on the inbound trip on {1} that was previously canceled.',
      OUTGOING:
        'Please confirm if the {0} route will be operating on the outbound trip on {1} that was previously canceled.',
    },
  },
  tripCancellationNotification: 'Trip Cancellation Notification',
  pleaseSelectRecipients: 'Please select recipients.',
  tripAlertMessage: {
    CANCELLED:
      'The trip has been cancelled but no riders have been notified. Would you like to send notification to the riders or close the panel anyway?',
    REVERT:
      'The cancelled trip has been reverted but no riders have been notified. Would you like to send notification to the riders or close the panel anyway?',
  },
  closePanel: 'Close Panel',
  sendNotification: 'Send Notification',
  tripCancellationRevertedNotification: 'Trip Cancellation Reverted Notification',
  notifyRiders: 'Notify riders',
  showLess: 'Show less',
  show: 'Show',
  addForReverseDirection: {
    text: 'Do you want to add this stop to the reverse direction as well?',
    confirmButtonText: 'Add to Both',
    cancelButtonText: 'Add Only to the Current Direction',
  },
  deleteForReverseDirection: {
    text: 'Do you want to delete the stop from the reverse direction as well? If so, you must re-calculate both routes and save them.',
    textRiderOnBoth:
      'Do you want to delete the stop from the reverse direction as well? If you delete the stop in both directions, you must re-calculate both routes and save them. Please note that there are riders assigned to it in both directions.',
    textRiderOnReverse:
      'Do you want to delete the stop from the reverse direction as well? If you delete the stop in both directions, you must re-calculate both routes and save them. Please note that there are riders assigned to it in the reverse direction.',
    confirmButtonText: 'Delete from Both',
    cancelButtonText: 'Delete only from This Direction',
  },
  assignReversedDirection: {
    text: 'This stop has a counterpart in the reverse direction. Would you like to assign this rider to that stop as well?',
    confirmButtonText: 'Assign to Both Stops',
    denyButtonText: 'Assign Only to the Current Stop',
  },
  adjustAndCalculateMessage: 'Adjust and calculate the opposite direction’s route as well before activating the route',
  deleteRiderAndStopBothDirections: {
    text: 'Do you want to delete the rider and the stop from both directions?',
    confirmButtonText: 'Delete from both directions',
    denyButtonText: 'Delete only from this direction',
  },
  unassignRiderBothDirections: {
    text: 'Do you want to unassign the rider from the corresponding stop in the reverse direction as well?',
    confirmButtonText: 'Unassign from Both',
    denyButtonText: 'Unassign Only from the Current Stop',
  },
  selectAllActiveTrips: {
    select: 'Select all active trips',
    deSelect: 'Remove all selections',
  },
  cancelMultipleTrips: 'Please confirm if you want to cancel the trips for the {0} route departing at {1} on {2}.',
  cancelled: 'Cancelled',
  cancelTrips: 'Cancel Trips',
  selectDepartureTimes: 'Select the first stop departure times of the trips that you want to cancel.',
  routesCopiedAlert: {
    message:
      'Stops have been copied from the inbound route. Please adjust stop locations considering the traffic direction before you calculate the route.',
    dontShowAgain: "Don't show again",
  },
  routeSchedules: {
    routeSchedules: 'Route Schedules',
    Inbound: 'Inbound',
    Outbound: 'Outbound',
    originalSchedules: 'Original Schedules',
    temporarySchedules: 'Temporary Schedules',
    changeArrivalTimes: 'Change Arrival Times',
    changeDepartureTimes: 'Change Departure Times',
    departureLocation: 'Departure Location',
    arrivalTimeLastCampus: 'Arrival Time to the Last Campus',
    departureTimesFirstCampus: 'Departure Times from the First Campus',
    arrivalTimeChangePrompt: 'Select how you want to change the <b> arrival times to the last campus:',
    departureTimeChangePrompt: 'Select how you want to change the <b> departure times from the first campus:',
    setToASpecificTime: 'Set to a Specific Time',
    laterArrivalBy: 'Later Arrival by',
    earlierArrivalBy: 'Earlier Arrival by',
    laterDepartureBy: 'Late Departure by',
    earlierDepartureBy: 'Earlier Departure by',
    datesForWhichThisChangeWillApply: 'Dates for which this change will apply',
    editStopTimesManually: 'Edit stop times manually',
    fromPlannedTime: 'from the planned time',
    saveInstructions:
      'When you click Save, stop times will be calculated accordingly. If you want to edit the stop times for routes   manually, check the box below.',
    originalArrivalTime: 'Original Arrival Time',
    temporaryArrivalTime: 'Temporary Arrival Time',
    originalDepartureTime: 'Original Departure Time',
    temporaryDepartureTime: 'Temporary Departure Time',
    validFrom: 'Valid from',
    validUntil: 'Valid until',
    destination: 'Destination',
    arrive: 'Arrive',
    depart: 'Depart',
    departureTime: 'Departure',
    arrivalTime: 'Arrival',
    specificGroupName: '{0} Set to {1}',
    earlyGroupName: '{0} {1} Early',
    arriveGroupName: '{0} {1} Late',
    hour: 'Hour',
    hours: 'Hours',
    minute: 'Minute',
  },
  editRouteHeader: {
    route: 'Edit Route Info',
    accounting: 'Edit Accounting Info',
  },
  temporaryScheduleChange: {
    title: 'Temporary Schedule Change',
    message: {
      SPECIFIC: {
        single: 'Are you sure you want to change the {2} times for the selected routes to {3} on {0}?',
        multi: 'Are you sure you want to change the {2} times for the selected routes to {3} from {0} to {1}?',
      },
      LATER: {
        single: 'Are you sure you want to change the times of the selected routes to {2} {3} on {0}?',
        multi: 'Are you sure you want to change the times of the selected routes to {2} {3} from {0} to {1}?',
      },
      EARLY: {
        single: 'Are you sure you want to change the times of the selected routes to {2} {3} on {0}?',
        multi: 'Are you sure you want to change the times of the selected routes to {2} {3} from {0} to {1}?',
      },
    },
  },
  arriveOrDepartEarlyLate: {
    SPECIFIC: ['arrival', 'departure'],
    EARLY: ['arrive', 'depart'],
    LATER: ['arrive', 'depart'],
  },
  delayTime: {
    EARLY: '{0} {1} early',
    LATE: '{0} {1} late',
  },
  specificTime: 'at {0}',
  deleteTemporarySchedule: {
    title: 'Delete Temporary Schedule',
    message:
      'Are you sure you want to cancel the temporary schedule for the selected routes? The trip times will revert to the original schedule.',
  },
  conflictWithExistingSchedules: {
    title: 'Conflict with Existing Schedules',
    message:
      'There are already other temporary schedules on the following routes within the dates you indicated.<br><br>{0}',
  },
  tripsHasStarted: 'Trips for some of the selected routes have already started. ',
  noReservationsOnThisStop: 'No reservations on this stop',
  notifyRidersPanel: {
    panelTitle: 'Temporary Trip Time Update Notification',
    message: {
      SPECIFIC: 'The arrival times for the selected routes will be changed to {0} from {1} to {2}.',
      LATER: 'The times for the selected routes will be delayed by {0} from {1} to {2}.',
      EARLY: 'The times of the selected routes will {3} {0} from {1} to {2}.',
    },
    title: '{0} routes selected',
  },
  arriveOrDepart: ['arrive', 'depart'],
  arrivalOrDeparture: ['arrival', 'departure'],
  allRoutes: 'All Routes',
  allRouteGroups: 'All Route Groups',
  vehiclePlate: 'License Plate',
  vehicleCapacity: 'Vehicle Capacity',
  vehicleId: 'Vehicle ID',
  departureTimePlannedActual: 'Departure Time Planned - Actual',
  arrivalTimePlannedActual: 'Arrival Time Planned - Actual',
  notReservedRiders: 'Not-Reserved Riders',
  actualOccupancy: 'Actual Occupancy',
  qrCardReads: 'QR/Card Reads',
  totalMilesPlannedActual: 'Total Miles Planned - Actual',
  inbound: 'Inbound',
  outbound: 'Outbound',
  tripStatusFilter: {
    NO_SIGNAL: 'No Signal',
    NOT_ARRIVED: 'Not Arrived',
    DEPARTURE_DELAYED: 'Departure Delayed',
    LATE_ARRIVAL: 'Late Arrival',
    CANCELLED_BY_ADMIN: 'Cancelled by Admin',
    NO_RESERVATIONS: 'No Reservations',
  },
  plannedActualRiders: 'Planned / Actual Riders',
  plannedActualStart: 'Planned / Actual Start',
  plannedActualDeparture: 'Planned / Actual Departure',
  plannedActualArrival: 'Planned / Actual Arrival',
  plannedActualDistance: 'Planned / Actual {0}',
  totalPlannedActualDistance: 'Total {0} Planned - Actual',
  addedByDriver: '+{0} added by driver',
  addedByDriverNoNumber: 'Added by driver',
  createTrips: 'Create Trips',
  createTripsHelper:
    'By clicking this button, you can automatically generate past trips that were not recorded for some reason.',
  noShows: 'No Shows',
  missingTripsAreCreated: 'Missing trips have been created.',
  miles: 'Miles',
  km: 'km',
  routeSearch: 'Route Search',
  selectRouteGroup: 'Select Route Group',
  selectRider: 'Select Rider',
  filterBy: 'Filter by',
  schedule: 'Schedule',
  showNearbyStops: 'Show Nearby Stops',
  selectDays: 'Select Days',
  selecTimes: 'Select Times',
  riderAddedtoRoute: 'Rider {0} has been added to the route {1}',
  riderAddedConfirm: 'Do you want to add {0} to the route {1}',
  allRouteGroup: 'All Route Group',
  periodStartTime: 'Route period start time',
  timeErrorMessage: 'The selected time cannot be earlier than the current time. Please choose a valid future time.',
  closeNotifyPanel: 'Are you sure you want to quit? You haven’t sent any notification to the riders yet.',
  timesUpdatedSuccessfully: 'The times for the selected routes have been updated for the selected dates.',
  ok: 'OK',
  paymentNote: 'Payments Notes',
  ridersCheckedIn: 'Riders Checked-in',
  filter: {
    checkedIn: 'Checked In',
    notCheckedIn: 'Not Checked In',
    reserved: 'Reserved',
    notReserved: 'Not Reserved',
    awaiting: 'Awaiting',
    noShowSkipped: 'No Show / Skipped',
  },
  selectRouteGroupToListRoutes: 'Select route group to list routes.',
  noRoutesFound: 'No routes found',
  noRouteGroupsFound: 'No route groups found',
  transferRidersOnRoutename: 'Transfer the riders on {0} to the following route on <b>{1}:',
  reservationInfoText:
    'For each stop, select the corresponding stop on the other route where you want to transfer the reservations.',
  selectStop: 'Select stop',
  selectCampus: 'Select campus',
  reserve: 'reservations',
  past: 'Past',
  hide: 'Hide',
  riderSelectedStopTransferredSelectedRoute:
    'The riders on the selected stops have been transferred to the selected route.',
  PICKUP: 'PICK UP',
  DROPOFF: 'DROP OFF',
  transferInfoMessage:
    'Please confirm if you want to transfer the riders at the selected stops of the route <b>{0}</b> on <b>{1}</b> to the selected stops on route <b>{2}</b> ',
  reservationTransferNotification: 'Reservation Transfer Notification',
  transferSubtitle:
    'The riders at the selected stops on route <b>{0}</b> have been transferred to the selected stops on route <b>{1}</b> for the trip on <b>{2}</b>.',
  transferMessageBox:
    'Your reservation on the route {0} on {1} has been transferred to the route {2}. Please check the flexiShuttle My Trips screen for details.',
  NON_PERSONALIZED_CARD: 'Non personalized card',
  youMustSelectArrivalDepartureTimes: 'You must select arrival and departure times.',
  transferRiders: 'Transfer Riders',
  onlyVisibleAdministrator: 'Only visible to riders added by the administrator.',
};
