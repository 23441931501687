export const tr = {
  requestStatus: {
    ALL: 'Hepsi',
    PENDING: 'Bekleniyor',
    APPROVED: 'Onaylandı',
    PLANNED: 'Planlandı',
    REJECTED: 'Reddedildi',
    CANCELLED: 'İptal',
    FINISHED: 'Tamamlandı',
    IN_PROGRESS: 'Kullanımda',
    PENDING_CHANGE: 'Değişiklik Bekleniyor',
    DRAFT: 'Taslak',
  },
  requestType: {
    ALL: 'Hepsi',
    FLEXIRIDE: 'Sürücülü',
    POOL_CAR: 'Sürücüsüz',
    GUESTRIDE: 'Heyet/Misafir',
    PARTNER_CAR_REQUEST: 'Ek Araç',
    FLEXIRIDE_POOL: 'Havuz',
    GUESTRIDE_POOL: 'Misafir Havuz',
  },
};

export const en = {
  requestStatus: {
    ALL: 'All',
    PENDING: 'Pending',
    APPROVED: 'Approved',
    PLANNED: 'Planned',
    REJECTED: 'Rejected',
    CANCELLED: 'Cancelled',
    FINISHED: 'Finished',
    IN_PROGRESS: 'In progress',
    PENDING_CHANGE: 'Pending Change',
    DRAFT: 'Draft',
  },
  requestType: {
    ALL: 'All',
    FLEXIRIDE: 'With Driver',
    POOL_CAR: 'Poolcar',
    GUESTRIDE: 'Visitor',
    PARTNER_CAR_REQUEST: 'Additional Vehicle',
    FLEXIRIDE_POOL: 'Pool',
    GUESTRIDE_POOL: 'Visitor Pool',
  },
};
