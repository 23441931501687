export default {
  evaluateTravel: 'Seyahat seçenekleri ve günlük iş rotalarınız optimize etmek için değerlendiriliyor.',
  forDemo: 'Demo amacıyla, hayali bir şirket konumu ve hayali çalışan adresleri için senaryolar üreteceğiz. <br/> Ayrıca kendi verilerinizle özelleştirilmiş senaryolar oluşturabilirsiniz.',
  fewSeconds: 'Birkaç saniye sürecek.',
  didYouKnow: 'Biliyor muydunuz?',
  didYouKnowDescription: 'flexiCommute, her çalışanın ev ve kampüs konumlarını kullanarak aşağıdakiler için mesafe, süre, maliyet ve emisyonu hesaplar ve sunar:',
  currentModeOfCommute: 'Mevcut işe gidip gelme modu',
  alternateModesOfCommute: 'Alternatif işe gidip gelme modu',
  bestAlternative: 'En iyi alternatif',
  creatingWorkgroups: 'Rota grupları oluşturuluyor',
  optimizingShuttleRoutes: 'Shuttle rotaları optimize ediliyor',
  assigningVehiclesDrivers: 'Araçlar & Sürücüler atanıyor',
};
