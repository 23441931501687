export default {
  age: 'Yaş',
  yes: 'Evet',
  no: 'Hayır',
  day: 'Gün',
  week: 'Hafta',
  date: 'Tarih',
  duration: 'Süre',
  durationMin: 'Süre(dk)',
  open: 'Açık',
  create: 'Oluştur',
  close: 'Kapat',
  closed: 'Kapalı',
  quit: 'Çık',
  viewAll: 'Hepsini Görüntüle',
  location: 'Konum',
  user: 'Kullanıcı',
  users: 'Kullanıcılar',
  personnel: 'Personel',
  riders: 'Personel',
  route: 'Rota',
  routes: 'Rotalar',
  vehicles: 'Araçlar',
  requests: 'İstekler',
  newRequestsforToday: 'Bugünkü <br> Yeni Talepler',
  NewRequestsforFuture: 'Geleceğe Yönelik <br> Yeni Talepler',
  changeRequestsforToday: 'Bugünkü Değişiklik <br> İstekleri',
  changeRequestsforFuture: 'Geleceğe Yönelik <br> Değişiklik İstekleri',
  rejectedRequests: 'Reddedilen <br> İstekler',
  requestsPendingAssignment: 'Bekleyen <br> Atama Talepleri',
  requestsPendingApproval: 'Requests <br> Pending Approval',
  totalAmount: 'Toplam Tutar',
  daily: 'Günlük',
  weekly: 'Haftalık',
  monthly: 'Aylık',
  monday: 'Pazartesi',
  tuesday: 'Salı',
  wednesday: 'Çarşamba',
  thursday: 'Perşembe',
  friday: 'Cuma',
  saturday: 'Cumartesi',
  sunday: 'Pazar',
  weekdays: 'Hafta içi tüm günler',
  everyDayOfTheWeek: 'haftanın her günü',
  alldaysOfTheWeek: 'haftanın her günü',
  and: 've',
  mon: 'Pzt',
  tue: 'Sal',
  wed: 'Çar',
  thu: 'Per',
  fri: 'Cum',
  sat: 'Cmt',
  sun: 'Paz',
  allDays: {
    mon: 'Pazartesi',
    tue: 'Salı',
    wed: 'Çarşamba',
    thu: 'Perşembe',
    fri: 'Cuma',
    sat: 'Cumartesi',
    sun: 'Pazar',
  },
  from: 'Nereden',
  to: 'Nereye',
  fromTime: 'Başlangıç',
  toTime: 'Bitiş',
  start: 'Başlangıç',
  end: 'Bitiş',
  startDate: 'Başlangıç Tarihi',
  endDate: 'Bitiş Tarihi',
  startEndDate: 'Başlangıç - Bitiş Tarihi',
  startEndTime: 'Başlangıç - Bitiş Zamanı',
  weekend: 'Hafta Sonu',
  weekday: 'Hafta İçi',
  weekendSatSun: 'Hafta Sonu (Cmt-Paz)',
  weekdayMonFri: 'Hafta İçi (Pzt-Cum)',
  departure: 'Çıkış',
  arrival: 'Varış',
  dep: 'Çıkış',
  depFrom: '{0} den Çıkış',
  moreWithCount: '+{0} daha',
  arr: 'Varış',
  total: 'Toplam',
  workdays: 'Hafta içi 5 gün',
  days: 'Günler',
  specificDays: 'Belirli Günler',
  description: 'Açıklama',
  person: 'kişi',
  continue: 'Devam et',
  goBack: 'Geri Dön',
  add: 'Ekle',
  update: 'Güncelle',
  cancel: 'Vazgeç',
  edit: 'Düzenle',
  view: 'Görüntüle',
  delete: 'Sil',
  upload: 'Yükle',
  groupName: 'Grup İsmi',
  finish: 'Bitir',
  settings: 'Ayarlar',
  current: 'Şu anda',
  best: 'En iyi',
  considered: 'Değerlendirilebilir',
  stop: 'Durak',
  locationName: 'Konum İsmi',
  address: 'Adres',
  next: 'İleri',
  back: 'Geri',
  contactUs: 'Bize Ulaşın',
  continueWithDemo: 'Demo ile Devam Edin',
  distance: 'Mesafe',
  employeeName: 'Çalışan İsmi',
  name: 'İsim',
  surname: 'Soy İsim',
  firstName: 'İsim',
  lastName: 'Soy İsim',
  email: 'E-mail',
  campus: 'Kampüs',
  department: 'Departman',
  homeAddress: 'Ev Adresi',
  saveAndAddNew: 'Kaydet ve Yeni Ekle',
  saveAndClose: 'Kaydet ve Kapat',
  headquarters: 'Genel Merkez',
  personnelCount: 'Çalışan Sayısı',
  shuttle: 'Servis',
  shuttleAgreement: 'flexiShuttle Sözleşmesi',
  ride: 'Sürücülü Araç',
  carpool: 'Havuz Araç',
  carpoolAgreement: 'flexiCarpool Sözleşmesi',
  vanpool: 'Vanpool',
  vanpoolAgreement: 'flexiVanpool Sözleşmesi',
  flexiSharedRide: 'flexiSharedRide',
  flexiRideAgreement: 'flexiRide Sözleşmesi',
  car: 'Araç',
  status: 'Durum',
  registryDate: 'Kayıt Tarihi',
  emailLastSentOn: 'E-posta Son Gönderilme Tarihi',
  emissionRating: 'Emisyon Derecesi',
  success: 'Başarılı',
  accept: {
    first: 'Şartları ve koşulları',
    second: 'kabul ediyorum.',
  },
  emailSent: 'E-posta gönderildi',
  error: 'Hata',
  gotIt: 'Anladım',
  DRIVING: 'Sürücülü Araç',
  WALKING: 'Yürüyüş',
  BIKING: 'Bisiklet',
  TRANSIT: 'Toplu Taşıma',
  VANPOOL: 'Havuz Araç',
  SHUTTLE: 'Servis',
  CARPOOL: 'Carpool',
  min: 'dk',
  km: 'km',
  mi: 'mi',
  hello: 'Merhaba',
  pleaseSelect: 'Lütfen seçiniz',
  totalDuration: 'Toplam Süre',
  showAll: 'Tümünü Göster',
  hideAll: 'Tümünü Gizle',
  save: 'Kaydet',
  vehicle: 'Araç',
  driver: 'Sürücü',
  drivers: 'Sürücüler',
  note: 'Not',
  direction: 'Yön',
  distanceType: 'km', // TODO: Birimlere backend ile beraber karar verilecek.
  stationName: 'Durak Adı',
  selectAll: 'Tümünü Seç',
  startTime: 'Başlangıç Saati',
  endTime: 'Bitiş Tarihi',
  transfer: 'Aktar',
  active: 'Aktif',
  passive: 'Pasif',
  activate: 'Aktive Et',
  makePassive: 'Pasife Al',
  setActive: 'Aktife Al',
  setPassive: 'Pasife Al',
  undo: 'Geri Al',
  selectRoute: 'Rota Seçiniz',
  select: 'Seç',
  plate: 'Plaka',
  canbusOdometer: 'Canbus Odometre',
  eventTime: 'Olay Zamanı',
  speed: 'Hız',
  updatedSuccessfully: 'Başarıyla güncellendi.',
  month: 'ay',
  monthUppercase: 'Ay',
  ok: 'Tamam',
  pressEsc: 'İşlemden çıkmak için esc tuşuna basın.',
  approve: 'Onayla',
  register: 'Kayıt Ol',
  login: 'Giriş Yap',
  general: 'Genel',
  nameSurname: 'İsim Soy İsim',
  phone: 'Telefon',
  phoneNumber: 'Telefon Numarası',
  driverPhoneNumber: 'Sürücü Telefon Numarası',
  idNumber: 'Sicil Numarası',
  uniqueID: ' Sicil No',
  employeeNumber: 'Müşteri Numarası',
  cardNumber: 'Kart Numarası',
  badgeNumber: 'Kart Numarası',
  admin: 'Admin',
  region: 'Bölge',
  locations: 'Konumlar',
  station: 'Durak',
  activePassive: 'Aktif / Pasif',
  drivingLicense: 'Ehliyet',
  commuteOptions: 'İşe Gidip Gelme Seçenekleri',
  clear: 'Temizle',
  assign: 'Ata',
  send: 'Gönder',
  sendEmail: 'Email Gönder',
  search: 'Ara',
  searchAddress: 'Adres Ara',
  homeLocation: 'Ev Adresi',
  meter: 'm',
  dashboard: 'Dashboard',
  clickEnterToAdd: 'Eklemek için tıklayınız',
  clickEnterToRemove: 'Kaldırmak için tıklayınız',
  pressEnterToAdd: "Eklemek için Enter'a basınız",
  registered: 'Kaydı tamamlanan',
  oneWay: 'Tek yön',
  passwordReset: 'Şifre Sıfırlama',
  undefined: 'Tanımsız',
  group: 'Grup',
  filter: 'Filtrele',
  reset: 'Sıfırla',
  shrinkOrExpand: 'Küçült / Büyült',
  filters: 'Filtreler',
  routeGroup: 'Rota Grubu',
  searchLocation: 'Konum ara...',
  dontShowAgain: 'Bir daha gösterme',
  type: 'Tip',
  candidate: 'Aday',
  selectStatus: 'Durum Seç',
  saveAll: 'Hepsini Kaydet',
  saveAndActivateAll: 'Hepsini Kaydet ve Aktive Et',
  activateAll: 'Hepsini Aktive Et',
  clickHere: 'buraya tıklayabilirsiniz.',
  selected: 'Seçildi',
  allWeekdays: 'Haftaiçi Tüm Günler',
  companies: 'Şirketler',
  searchCompanies: 'Şirket ara',
  newCompany: 'Yeni Şirket',
  allCompanies: 'Tüm Şirketler',
  language: 'Dil',
  tr: 'TR',
  en: 'EN',
  currency: 'Para Birimi',
  tl: 'TL',
  usd: 'USD',
  unit: 'Birim',
  weightUnit: 'Ağırlık Birimi',
  lengthUnit: 'Uzunluk Birimi',
  metric: 'Metric',
  imperial: 'Imperial',
  functionality: 'İşlevsellik',
  mileage: 'Mileage',
  passwordUpdated: 'Şifre Güncellendi.',
  manageConfirmations: 'Onayları Yönet',
  selectDate: 'Tarih Seçiniz',
  reasons3d: {
    START_OF_RENTAL: 'Kiralama',
    DEBT_PAYMENT: 'Borç Ödeme',
    SUBSCRIPTION_PURCHASE: 'Abonelik Ödeme',
    CARD_VALIDATION: 'Kart Doğrulama',
    DEVICE_VALIDATION: 'Cihaz Doğrulama',
    KM_PACKAGE: 'KM Paketi',
  },
  feedback: 'Geri Bildirim',
  finance: 'Finans',
  accessRights: 'Erişim Hakları',
  confirm: 'Emin misiniz ? ',
  saved: 'Kaydedildi !',
  searchbynameorIDno: 'İsim yada Id ile ara',
  newDriver: 'Yeni Sürücü',
  birthdate: 'Doğum Tarihi',
  socialSecurityNo: 'Sosyal Güvenlik Numarası',
  class: 'Sınıf',
  licenceNumber: 'Lisans Numarası',
  licence: 'Lisans',
  blackList: 'Kara Liste',
  newVehicle: 'Yeni Araç',
  make: 'Model',
  year: 'Yıl',
  walkingPathElevationChart: 'Yürüyüş Yolu Eğim Tablosu',
  totalWalkingDistance: 'Toplam Yürüyüş Mesafesi',
  tickets: 'Talepler',
  document: 'Doküman',
  expirationDate: 'Geçerlilik Tarihi',
  time: 'Zaman',
  thereIsNoData: 'Veri yok.',
  needed: 'Gerekli',
  notNeeded: 'Gerekli Değil',
  notes: 'Notlar',
  loginInfo: {
    hello: 'Merhaba !',
    emailAdress: 'E-posta adresi',
    enterEmail: 'Lütfen e-posta adresinizi girin.',
    next: 'Devam',
    welcomeFlexigo: 'Flexigo`ya Hoşgeldiniz!',
    corporateCampusMobilityPlatform: 'Kurumsal Ulaşım Platformu',
    Password: 'Şifre',
    login: 'Giriş Yap',
    riders: 'Yolcular',
    Administrators: 'Yöneticiler',
    imageText:
      '<b> Flexigo,</b> hem son kullanıcılar hem de operasyon yöneticilerinin kurumsal ulaşım operasyonlarını yönetmeleri için geliştirilmiş bir mobilite yönetim platformudur. Platform, gelişmiş mobilite optimizasyon araçlarından da yararlanarak, personel servisleri, havuz araçları ve kapalı devre sürücülü araç yönetimi tek noktadan yönetme imkanı sağlar.',
  },
  profileNotes: 'Profil Notu',
  addLocation: 'Konum ekle',
  title: 'Başlık',
  notFound: 'Bulunamadı.',
  selectNone: 'Hiçbirini Seçme',
  showRegions: 'Regionları Göster',
  requestApprovals: {
    routeChange: 'Rota Değişikliği',
    routeReservation: 'Rota Rezervasyon',
    nearbyStop: 'Yakın Durak Talepleri',
    vehicleDriverChange: 'Araç/Sürücü Değişikleri',
    documents: 'Evrak',
    payments: 'Hakediş',
    requestTime: 'Talep Zamanı',
    riders: 'Yolcular',
    currentRoute: 'Mevcut Rota',
    newRoute: 'Yeni Rota',
    provider: 'Servis Şirketi',
    previourDriver: 'Öndeki Sürücü',
    previousVehicle: 'Önceki Araç',
    routeCapacity: 'Rota Kapasitesi',
    vehicleCapacity: 'Araç Kapasitesi',
    timeUpload: 'Eklenme Zamanı',
    documentType: 'Belge Tipi',
    paymentName: 'Onay İsmi',
    lastApprovedBy: 'Son Onaylayan Yetkili',
    lastApprovedOn: 'Son Onaylama Tarihi',
    comments: 'Yorumlar',
    approvalStep: 'Onay Adımı',
    approvalExpectedBy: 'Onayı Beklenen Yetkili',
    requestsAndApprovals: 'Onay ve Talepler',
    reasonRejection: 'Red Nedeni',
    pleaseEnterValue: 'Lütfen bir değer girin',
    pleaseStateRejectRequest: 'Lütfen talebi neden onaylamadığınızı belirtin.',
    pleaseStateRejectChange: 'Lütfen değşikliği neden onaylamadığınızı belirtin.',
    pleaseStateRejectDocument: 'Lütfen belgeyi neden reddettiğinizi belirtin.',
    pleaseStateRejectPayment: 'Lütfen ödemeyi neden onaylamadığınızı belirtin',
    preferredShift: 'Tercih Edilen Vardiya',
    stats: {
      ON_HOLD: 'Beklemede',
      PENDING: 'Beklemede',
      RECEIVED: 'Teslim Edilen',
    },
  },
  approver: 'Onaylayan',
  actualOccupancyRate: 'Gerçekleşen Doluluk Oranı',
  aboveWithParam: '{0} üzeri',
  passThrough: 'Uğra?',
  selectDeviceStatus: 'Cihaz Durumunu Seçin',
  trips: 'Seyahatler',
  QRCardReads: 'QR/Kart Okumaları',
  seats: 'koltuk',
  hours: 'Saatler',
  newPassword: 'Yeni Şifreniz',
  newPasswordAgain: 'Yeni Şifreniz Tekrar',
  changePassword: 'Şifre Değiştir',
  oldPassword: 'Eski Şifre',
  approvals: 'Onaylar',
  notOnFile: 'Dosya Bulunamadı',
  dateTime: 'Tarih/Saat',
  totalRiders: 'Toplam Personeller',
  startEndTimes: 'Başlangıç - Bitiş Saatleri',
  thisRouteAcceptReservationsHaveRegularRiders:
    'Bu rotada rezervasyon kabul edilmemektedir veya düzenli yolcular kabul edilmemektedir.',
  eligible: 'Kullanabilir',
  notEligible: 'Kullanamaz',
  markAs: 'Olarak işaretle',
  signed: 'İmzalandı',
  notSigned: 'İmzalanmadı',
  liveTracking: 'Canlı Takip',
  change: 'Değişim',
  untilSpecifedDate: 'Belirtilen Tarihe Kadar',
  permanently: 'Kalıcı olarak',
  until: 'Son tarih',
  selectDriver: 'Sürücü Seçin',
  selectVehicle: 'Araç Seçin',
  hour: 'Saat',
  dispatch: 'Servis Takip',
  editDriverVehicle: 'Sürücü ve Araç Ata',
  driverName: 'Sürücü Adı',
  notCalculated: 'Hesaplanmadı',
  now: 'Şimdi',
  liveTraffic: 'Canlı Trafik',
  makeNewAssigment: 'Yeni bir atama yapmak için aşağıda seçimlerinizi yapıp Kaydet butonuna basın.',
  documentName: 'Belge İsmi',
  hasExpirationDate: 'Geçerlilik Tarihi Var',
  noExpirationDate: 'Süresiz',
  newDocumentType: 'Belge Tipi Ekle',
  typeDocumentName: 'Belge ismini yazın',
  updateProgressStatus: 'Hakediş Durumunu Değiştir',
  category: 'Kategori',
  topic: 'Konu',
  photo: 'Fotoğraf',
  chart: 'Grafik',
  firstStop: 'İlk Durak',
  archive: 'Arşiv',
  showAvailableOptions: 'mevcut seçenekleri göster',
  reservation: 'rezervasyon',
  rider: 'Yolcu',
  all: 'Tümü',
  permanent: 'Kalıcı',
  newAddress: 'Adres Ekle',
  editAddress: 'Adres Düzenle',
  myAddress: 'Adreslerim',
  pickupAddressSet: 'Tercih ettiğiniz teslim alma adresini henüz belirlemediniz',
  setNow: 'Şimdi Ekle',
  columns: 'Kolonlar',
  optional: 'Opsiyonel',
};
