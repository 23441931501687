/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

export default {
  state: {
    orders: [],
    selectedResources: [],
    resourceMap: new Map(),
  },

  mutations: {
    SELECT_RESOURCE(state, resource) {
      state.selectedResources.push(resource);
    },
    UNSELECT_RESOURCE(state, resource) {
      const resourceIndex = state.selectedResources.findIndex((el) => el.routeId === resource.routeId);
      state.selectedResources.splice(resourceIndex, 1);
    },
    SET_ORDERS(state, orders) {
      state.orders = orders;
    },
    SET_RESOURCES_MAP(state, resources) {
      state.resourceMap = new Map(resources.map((r) => [r.routeId, r]));
    },
    RESET_RESOURCES(state) {
      state.selectedResources = [];
    },
  },

  actions: {

  },

  getters: {
    selectedResourceIds(state) {
      return state.selectedResources.map((r) => r.routeId);
    },
  },
};

/* eslint-enable no-param-reassign */
/* eslint-enable no-unused-vars */
