export default {
  ALL: 'Hepsi',
  PENDING_DEMAND: 'Talep Toplanıyor',
  PENDING_PLANNING: 'Planlama Bekleniyor',
  PENDING_OPTIMIZATION_RESULT: 'Planlama Bekleniyor',
  PENDING_RECURRING_OPTIMIZATION: 'Planlama Bekleniyor',
  PLANNING_END: 'Planlama Bitiş',
  DEMAND_END: 'Talep Bitiş',
  ACTIVE: 'Aktif',
  INCOMPLETE: 'Tanımlama Eksik',
  PENDING_ASSIGNMENT: 'Atama Bekleniyor',
  archive: 'Arşiv',
  locations: {
    CAMPUS: 'Ofis',
    POI: 'Poi',
    PERSONNEL_SHUTTLE_STOP: 'Duraklar',
    PERSONNEL_HOME_ADDRESS: 'Sürücü Ev Adresleri',
    PERSONNEL_WORK_LOCATION: 'Sürücü İş Konumları',
  },
  header: 'Çalışma Grupları',
  createWorkgroup: 'Yeni Rota Grubu',
  timeOfArrival: 'Varış Saati',
  departureTime: 'Çıkış Saati',
  arrivalTime: 'Varış Saati',
  readyToDeparture: 'Çıkışa Hazır',
  period: 'Periyot',
  periodicallyUpdatedGroup: 'Periyodik Güncellenen Grup',
  periodicallyUpdatedGroupInfo:
    'Periyodik güncellenen gruplarda her periyot için talep toplanır ve katılımcılara göre rotalar oluşturulur.',
  selectPeriod: 'Periyot seçiniz',
  roundTrip: 'Gidiş - Geliş',
  oneWay: 'Tek Yön',
  doubleDirection: 'Çift Yön',
  fromWhere: 'Nereden',
  toWhere: 'Nereye',
  openSelectMenu: 'Bu menüyü aç',
  groupName: 'Grup adı',
  enterDescription: 'Bir açıklama girebilirsiniz',
  giveNameToGroup: 'Gruba bir isim verin',
  selectLocation: 'Bir konum seçin',
  selectDeparturePoint: 'İlk yolculuk için çıkış noktası seçin',
  responsibleCampus: 'Sorumlu kampüs',
  openToDemand: 'Talep almaya açık.',
  limitQuota: 'Kontenjanı sınırla.',
  departmentRequest: 'Talep yapabilen bölümler',
  reportPlanningEnd: 'Rapor Planlama Bitiş Tarihi',
  startOfBookbuilding: 'Talep Toplama Başlangıç',
  endOfBookbuilding: 'Talep Toplama Bitişi',
  definition: 'Tanım',
  location: 'Konumlar',
  timing: 'Zamanlama',
  operationTimes: 'İşlem Zamanları',
  changeRouteOrStop: 'Rota veya Durak Değiştir',
  removeFromGroup: 'Gruptan Çıkar',
  routePlanning: 'Rota planlamanin bir bitiş tarihi var',
  picnic: 'Piknik',
  overwork: 'Fazla Mesai',
  saturdayShift: 'Cumartesi Vardiya',
  customAddress: 'Özel Adres',
  personnelLocations: 'Personel Konumları',
  PERSONNEL_SHUTTLE_STOP: 'Duraklar',
  PERSONNEL_HOME_ADDRESS: 'Personel Ev Adresleri',
  PERSONNEL_WORK_LOCATION: 'Personel İş Konumları',
  createRecurringGroups: 'Periyodik grupları başlangıç zamanından',
  beforeStart: 'önce yarat.',
  makeDefault: 'Varsayılan Yap',
  default: 'Varsayılan',
  searchGroup: 'Grup Ara',
  selectedPerson: 'kişi seçildi',
  archiveSearchGroup: 'Arşiv grupları ara...',
  deleteSelected: 'Seçilenleri Sil',
  sortBy: 'Sırala',
  sortByEndDate: 'Bitiş tarihine göre sırala',
  sortByStartDate: 'Başlangıç tarihine göre sırala',
  sortByEmployeesCount: 'Personel sayısına göre sırala',
  indefinite: 'Süresiz',
  showMore: 'Daha Fazla Göster',
  campus: 'Yerleşke',
  selectEndDate: 'Bitiş Tarihi Seç',
  hoursBeforeTrip: 'ilk yolculuktan {hours} saat önce',
  hoursBeforeFirstTrip: 'Planlama ilk yolculuktan {hours} saat önce biter',
  changeStatus: 'Statü Değiştir',
  statusChanged: 'Statü {0} olarak değiştirildi.',
  invalidDepartureTime: 'Çıkış Saati Hatalı',
  invalidArrivalTime: 'Varış Saati Hatalı',
  addNewCustomAddress: 'Yeni Özel Adres Ekle',
  descriptor: 'Tanımlayan',
  createdBy: 'Tanımlayan',
  makeDefaultRouteGroup: 'Yeni eklenen personel için varsayılan rota grubu yap.',
  personnelLabeledVanpoolOnly: 'Sadece vanpool etiketli personel',
  optimization: 'Optimizasyon',
  automation: 'Otomasyon',
  vehicleAssignment: 'Araç Atama',
  activation: 'Aktivasyon',
  initiateAutomation: 'Otomasyonu Başlat',
  deleteGroupInstanceConfirm: 'Bu grubu silmek istediğinize emin misiniz?',
  startsFromtheDepot: 'Depodan Başla',
  startingDepot: 'Başlangıç Deposu',
  selectDepot: 'Depo Seçin',
  endingDepot: 'Bitiş Depo',
  endsintheDepot: 'Depoda bitir',
  definedRegion: 'Tanımlanmış bölge(ler)',
  coverageArea: 'Kapsama Alanı',
  noRestrictions: 'Kısıtlama yok',
  addCampus: 'Kampüs Ekle',
  multiCampus: 'Birden çok yerleşke',
  selectRegion: 'Region Seçin',
  selectADeparturePoint: 'Bir kalkış noktası seçin',
  enterThereadyToDepartureDepartureTimes: 'İstenilen kalkışa hazır ve ilk kampüsten ayrılış saatlerini girin',
  enterArrivalTimeToLastDestination: 'Son varış noktasına istenilen varış zamanını girin',
  serviceTimeForEachStop: 'Her durak için hizmet süresi',
  calculate: 'Hesapla',
  vehicleTrackingStart: 'Araç başlangıç zamanı',
  dynamic: 'Dinamik',
  regular: 'Düzenli',
  fixed: 'Sabit',
  DYNAMİC: 'Dinamik',
  REGULAR: 'Düzenli',
  FIXED: 'Sabit',
  readyForDepartureMustEarlierDepartureTime: 'Kalkışa hazır saati kalkış saatinden erken olmalıdır.',
  acceptsReservations: 'Rezervasyona Açık',
  noReservations: 'Rezervasyona Kapalı',
  ring: 'Ring',
  schedule: 'Planlanmış',
  startingEndingPoint: 'Başlangıç/Bitiş Noktası',
  stops: 'Duraklar',
  workdays: 'Hafta içi 5 gün',
  singleTrip: 'Tek seferlik seyahat',
  multipleTrips: 'Çoklu Seyahatler',
  periodical: 'Periyodik',
  atSpecificHours: 'Belirli Saatlerde',
  departureTimeFromFirstPoint: 'İlk noktadan itibaren istediğiniz kalkış saatini girin',
  tripsOnEver: 'Trips on ever',
  numberOfTrips: 'Seyahat Sayısı',
  min: 'dakika(lar)',
  hour: 'saat(ler)',
  totalDurationhours: 'Seferlerin toplam süresi 24 saati aşıyor.',
  addStop: 'Durak Ekle',
  deleteThisRouteGroup: 'Bu rota grubunu silmek istediğinizden emin misiniz ?',
  acceptReservations: 'Rezervasyonları Kabul Et',
  theSameDay: 'Aynı Gün',
  daysBeforeTripDate: 'Seyahat tarihinden önceki gün(ler)',
  singleSelectedMulticampusMessage:
    '‘Birden çok yerleşke’ seçili olduğu halde tek yerleşke seçtiniz. Başka yerleşke eklemek istiyor musunuz?',
  calculateAccordingly: 'Hepsi için buna göre hesapla',
  departureTimeCampusName: 'Kalkış Saati <b>{0}',
  reservationsCanBeMadeUntilCutOffTime: 'Rezervasyonlar belirlenen son tarihe kadar yapılabilmektedir.',
  closeFilters: 'Filtreleri Kapat',
  type: 'Rota türü',
  creator: 'Oluşturan',
  reservations: 'Rezervasyonlar',
  required: 'Zorunlu',
  notRequired: 'Zorunlu Değil',
};
