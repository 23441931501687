export default {
  hello: 'Merhaba',
  createAccountExplore: '30 gün boyunca Flexigo demosunu keşfedebilmeniz için sizin için bir hesap oluşturalım',
  flexigoDemoThirtyDays: 'Flexigo demo for 30 days',
  connectLinkedIn: 'Connect With LinkedIn',
  connectGoogle: 'Connect With Google',
  password: 'Şifre',
  passwordRetype: 'Şifreyi tekrarlayın',
  email: 'E-posta Adresi',
  passwordMust: 'Şifre',
  passSixChars: 'En az 8 karakter uzunluğunda olmalı',
  pass8Chars: 'En az 8 karakter uzunluğunda olmalı',
  passOneDigit: 'Be atleast one digit',
  passOneUppercase: 'Be atleast one capital letter',
  aboutCompany: 'Please tell us about your company',
  locationInfo: 'We need your company location to generate routes for commute options.',
  containFollowing: 'Şunlardan en az üçünü içermeli: bir büyük harf, bir küçük harf, bir sayı, bir özel karakter',
  twoPasswordsMustMatch: 'Girilen iki şifre aynı olmalı',
  locationName: 'Location Name',
  address: 'Address',
  company: 'Company',
  industry: 'Industry',
  companySize: 'Company Size',
  next: 'Next',
  signup: 'Sign Up',
  or: 'Veya',
  companyInformation: 'Şirket Bilgisi',
  companyLocation: 'Şirket Konumu',
  registrationText:
    'flexigo, personel servisleri, havuz araçlar, sürücülü araçlar, araç paylaşımı ve ulaşım masraf yönetimi dahil tüm kurumsal ulaşım ihtiyaçlarınının tek noktadan yönetilmesi için tasarlanmıştır. Hem yöneticiler hem de son kullanıcılara özel platformlarıyla uçtan uca rahat bir deneyim sunar.',
  pleaseTellUsAboutYourCompany: {
    first: 'Lütfen bize',
    second: 'şirketinizden bahsedin.',
  },
  needYourCompanyLocation: {
    first: 'İşe gidip gelme seçenekleri için',
    second: 'rota oluşturmak için şirketinizin konumuna ihtiyacımız var',
  },
  signUp: 'Kayıt Olun',
  nameDomain: 'isim@domain.com',
  continue: 'Devam',
  welcomeToFlexigo: '<span style="letter-spacing:1px">f</span>lexigo\'ya Hoş Geldiniz!',
  slogan: 'Kurumsal Ulaşım Platformu',
  rider: 'Kullanıcı',
  admin: 'Admin',
  forgotMyPassword: 'Şifremi Unuttum',
  forgotPassword: 'Şifrenizi mi unuttunuz?',
  login: 'Giriş Yap',
  requestResetLink: 'Sıfırlama Linki Gönder',
  redirecting: 'Yönlendiriliyor...',
  loginSuccessful: 'Giriş Başarılı',
  newPasswordSent: 'Yeni şifrenizi belirlemek için bir bağlantı e-posta adresinize gönderildi.',
  validEmail: 'Geçerli bir e-posta adresi girmelisiniz.',
  dontHaveAnAccount: 'Hesabınız yok mu?',
  accountDetails: {
    title: 'Hesap Detayları',
    enterNamePassword: 'Lütfen hesabınızı oluşturmak için adınızı girin ve şifrenizi belirleyin.',
    firstName: 'İsim',
    lastName: 'Soy isim',
  },
  register: {
    hello: 'Merhaba! 👋',
    enterMail: 'Lütfen otorizasyon için kurum e-posta adresinizi girin.',
    alreadyHaveAnAccount: 'Zaten bir hesabınız var mı?',
    organizationCode: 'Kurum Kodu',
    pleaseEnterCode: 'Lütfen kurumunuz tarafından verilen kodu girin.',
    companyAuthCode: 'Kurum onay kodu',
    emailCode: 'E-Posta Kodu',
    pleaseEnterEmail: 'Lütfen aşağıdaki e-posta adresine gönderile kodu girin.',
    wrongEmail: 'E-posta hatalı mı?',
    didntReceive: 'Kodu almadınız mı?',
    sendAgain: 'Tekrar gönder',
    selectYourMainCampus: {
      title: 'Ana Kampüsünüzü Seçin',
      description:
        'Taşıma seçeneklerinizi bu seçime göre optimize edeceğiz. Bu seçimi istediğiniz zaman profil ayarlarından değiştirebilirsiniz.',
    },
  },
  main: {
    label: 'Lütfen giriş yapmak istediğiniz hesap türünü seçin.',
    placeholder: 'Hesap Seçin',
  },
  wrongUser: {
    title: 'Bir hata oluştu!',
    message: 'Kullanıcı adı veya şifre hatalı',
  },
  invalidCode: {
    title: 'Geçersiz Kod',
    message:
      'Lütfen doğrulama kodunu kontrol edip tekrar deneyin. Kodunuzun süresi dolduysa, yeni bir kod talep etmek için Tekrar Gönder bağlantısına tıklayabilirsiniz.',
  },
  emailNotFound: 'E-posta bulunamadı!',
  submit: 'Gönder',
  change: 'Değiştir',
  setYourPassword: 'Şifrenizi Belirleyin',
  enterYourPassword: 'Şifrenizi girin',
  passwordUpdated: 'Şifre güncellendi',
  resetYourPassword: 'Şifrenizi sıfırlayın',
  enterYourPasswordTwice: 'Yeni şifrenizi iki kez girin.',
  setPassword: 'Şifre oluşturun',
};
