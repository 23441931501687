export default {
  workgroup: 'Workgroup',
  addPersonnel: 'Add Riders',
  makeRouting: 'Run Optimization',
  downloadExcelTemplate: 'Download Excel Template',
  dragFile: 'Drag the file here or',
  clickBrowse: 'click to browse',
  tableHeader: {
    nameSurname: 'Name Surname',
    email: 'E-mail',
    allSections: 'All Departments',
    route: 'Route',
    station: 'Stop',
    currentGroup: 'Group',
    fromWhere: 'From Where',
    toWhere: 'To Where',
    searchPersonnel: 'Search Personnel',
    searchRiders: 'Search Riders',
    campus: 'Campus',
    allCampus: 'All Campus',
    allGroups: 'All Groups',
    departments: 'Departments',
    vanpoolChoice: 'Vanpool Choice',
    vanpoolDriverChoice: 'Vanpool Driver Choice',
    travelTime: 'Travel Time (min)',
    timeCoefficient: 'Time Coefficient',
  },
  serviceStops: 'Service Stops',
  creationDate: 'Creation Date',
  creator: 'Creator',
  accountingCenter: 'Accounting Center',
  startEnd: 'Start-End',
  openToDemand: 'Open for Opt-In',
  description: 'Description',
  downloadExcelDescription:
    'You can download the Excel template, fill it with the information of the employees you want added to the group and upload it back.',
  riderSelected: 'rider selected',
  deleteSelected: 'Delete Selected Items',
  noPersonnel: 'No riders has been added to the group yet.',
  selectCampus: 'Select Campus',
  addSelectedPersonnel: 'Add Selected Riders',
  selectedPersonnel: 'Selected Rider',
  createPersonnelList: 'Create a list choosing from the rider list below.',
  personnel: 'rider selected.',
  personnelNotFound: 'Personnel not found',
  excludedDrivers: 'Excluded Drivers',
  showNearbyShuttleStops: 'Show Nearby Shuttle Stops',
  assignRiderThisStop: 'Assign Rider to This Stop',
  availability: 'Availability',
  thereAreNoNearbyStations: 'There are no nearby stations',
  showingStopsFor: 'Showing stops for:',
  stopAssignmentSuccessful: 'Stop assignment is successful',
  none: 'None',
  riderTypicallyUsesThisStop: 'The rider typically uses this stop.',
};
