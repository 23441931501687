export default {
  welcomeToFlexigo: 'Welcome to <span style="letter-spacing:1px">f</span>lexigo!',
  slogan: 'Corporate and Campus Mobility Platform',
  rider: 'Rider',
  admin: 'Admin',
  forgotMyPassword: 'Forgot my password',
  forgotPassword: 'Forgot password?',
  login: 'Login',
  hello: 'Hello',
  requestResetLink: 'Request a Reset Link',
  redirecting: 'Redirecting...',
  loginSuccessful: 'Login successful',
  newPasswordSent: 'A link to set your new password has been sent to your email.',
  validEmail: 'You must enter a valid email address.',

  createAccountExplore: 'Let’s create an account for you so that you can explore Flexigo demo for 30 days',
  flexigoDemoThirtyDays: 'Flexigo demo for 30 days',
  connectLinkedIn: 'Connect With LinkedIn',
  connectGoogle: 'Connect With Google',
  email: 'Email address',
  password: 'Password',
  passwordRetype: 'Password again',
  passwordMust: 'Password must',
  passSixChars: 'Be at least 8 characters long',
  pass8Chars: 'Be at least 8 characters long',
  passOneDigit: 'Be at least one digit',
  passOneUppercase: 'Be atleast one capital letter',
  aboutCompany: 'Please tell us about your company',
  locationInfo: 'We need your company location to generate routes for commute options.',
  containFollowing:
    'Contain at least 3 of the following: one upper case letter, one lower case letter, one number, one special character',
  twoPasswordsMustMatch: 'Two passwords must match',
  locationName: 'Location Name',
  address: 'Address',
  company: 'Company',
  industry: 'Industry',
  companySize: 'Company Size',
  next: 'Next',
  signup: 'Sign Up',
  or: 'Or',
  companyInformation: 'Company Information',
  companyLocation: 'Company Location',
  registrationText:
    '<strong>flexigo</strong> provides dynamic responsive commuter shuttles, closed-circuit carsharing and ridepooling platforms, and advanced mobility optimization tools all integrated within a single app to ensure a seamless end-to-end experience for users and management alike.',
  pleaseTellUsAboutYourCompany: {
    first: 'Please tell us about',
    second: 'your company',
  },
  needYourCompanyLocation: {
    first: 'We need your company location',
    second: 'to generate routes for commute options',
  },
  dontHaveAnAccount: 'Don’t have an account yet?',
  signUp: 'Sign up',
  nameDomain: 'name@domain.com',
  continue: 'Continue',

  accountDetails: {
    title: 'Account Details',
    enterNamePassword: 'Please enter your name and a unique password to create your account.',
    firstName: 'First Name',
    lastName: 'Last Name',
  },
  register: {
    hello: 'Hello! 👋',
    enterMail:
      'Please enter your official organization email. This email is required for authentication by your organization.',
    alreadyHaveAnAccount: 'Already have an account?',
    organizationCode: 'Organization Code',
    pleaseEnterCode: 'Please enter the code provided by your organisation.',
    companyAuthCode: 'Company Authorization Key',
    emailCode: 'Email Code',
    pleaseEnterEmail: 'Please enter the verification code sent to the email address below:',
    wrongEmail: 'Wrong email?',
    didntReceive: 'Didn’t receive the code?',
    sendAgain: 'Send again',
    selectYourMainCampus: {
      title: 'Select Your Main Campus',
      description:
        "We'll optimize the transportation options available to you according to this selection. You can always change this selection from your profile settings.",
    },
  },
  main: {
    label: 'To login please select account',
    placeholder: 'Select Account',
  },
  wrongUser: {
    title: 'Login unsuccesful!',
    message: 'Wrong user name or password',
  },
  invalidCode: {
    title: 'Invalid Code',
    message:
      'Please check the verification code and try again. If your code has expired, you can tap the Send Again link to request a new one',
  },
  emailNotFound: 'Email not found!',
  submit: 'Submit',
  change: 'Change',
  setYourPassword: 'Set Your Password',
  enterYourPassword: 'Enter your password',
  passwordUpdated: 'Password updated',
  resetYourPassword: 'Reset Your Password',
  enterYourPasswordTwice: 'Enter your new password twice',
  setPassword: 'Set Password',
};
