export default {
  status: 'Status',
  pickupPoint: 'Pickup Point',
  dropoffPoint: 'Drop-off Point',
  plannedPickupTime: 'Planned Pick-up Time',
  plannedDropoffTime: 'Planned Drop-off Time',
  actualPickupTime: 'Actual Pick-up Time',
  actualArrivalTime: 'Actual Arrival Time',
  actualDropoffTime: 'Actual Drop-off Time',
  driver: 'Driver',
  noAssignedDriver: 'No assigned driver',
  vehicle: 'Vehicle',
  user: 'User',
  rider: 'Rider',
  editRoute: 'Edit',
  approveRoute: 'Activate',
  date: 'Date',
  mode: 'Mode',
  startingMileage: 'Starting Mileage ({0})',
  endingMileage: 'Ending Mileage ({0})',
  startingPoint: 'Starting Point',
  endingPoint: 'Ending Point',
  endingTime: 'Ending Time',
  startingTime: 'Starting Time',
  tripType: 'Trip Type',
  tripPurpose: 'Trip Purpose',
  description: 'Description',
  invoice: 'Invoice',
  totalMileage: 'Total Mileage ({0})',
  declaredCost: 'Declared Cost ({0})',
  toBePaid: 'To Be Paid',
  confirmSelected: 'Confirm Selected',
  rejectSelected: 'Reject Selected',
  markSelectedPaid: 'Mark Selected as Paid',
  belowEmployeesStatusCouldntUpdated:
    'Below employees status couldn’t be updated. To update their status as paid, you must change their status as confirmed',
  addNote: 'Add Note',
  deleteLocation: 'Are you sure you want to delete this location ?',
  locationHasBeenDeleted: 'Location has been deleted',
  activatedRequests: 'Assigned Requests',
  question: 'Question',
  file: 'File',
  response: 'Response',
  delays: 'Delays',
  orderStatus: {
    NOT_STARTED: 'Not Started',
    CANCELLED: 'Cancelled',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
    DELETED: 'Deleted',
  },

  driverFilter: {
    AVAILABLE: 'Available',
    WORKING: 'Working',
    PENDING_TASK_ACCEPTANCE: 'Pending Task Acceptance',
    ON_BREAK: 'On Break',
    NOT_WORKING: 'Not Working',
  },

  eventStatus: {
    CANCELLED: 'Cancelled',
    REQUESTED: 'Requested',
    AWAITING_PLANNING: 'Awaiting Planning',
    PLANNED: 'Planned',
    ASSIGNED: 'Assigned',
    NOT_STARTED: 'Not Started',
    ENROUTE_TO_PICKUP: 'Enroute to Pickup',
    AWAITING_PICKUP: 'Awaiting Pickup',
    ENROUTE_TO_DROPOFF: 'Enroute to Dropoff',
    AWAITING_DROPOFF: 'Awaiting Dropoff',
    COMPLETED: 'Completed',
    CHANGE_REQUEST: 'Change Request',
    ON_TIME: 'On Time',
    DELAYED: 'Delayed',
    ACTIVE: 'Active',
    CHANGE_REQUEST_CANCELLED: 'Cancelled',
    DELAYED_PICKUP: 'Delayed Pickup',
    PICKEDUP_WITH_DELAY: 'Picked Up with Delay',
    PICKEDUP_ON_TIME: 'Picked Up on Time',
    DROPOFF_DELAYED: 'Dropped Off with Delay',
    COMPLETED_WITH_DELAY: 'Completed with Delay',
    DELIVERED_ON_TIME: 'Dropped off On Time',
    DELIVERED_WITH_DELAY: 'Dropped off With Delay',
    DRAFT: 'Draft',
    BY_ADMIN: 'by Admin',
    BY_EA: 'by EA',
    BY_RIDER: 'by Rider',
    BY_SELF: 'by Rider',
    AUTOMATICALLY: 'Automatically ',
    BY_DRIVER: 'Skipped by Driver',
    BY_ASSISTANT: 'by Assistant',
    REJECTED: 'Not Accepted',
    AT_PLANNING: 'At Planning',
    ADA_BREAK: 'ADA Break',
    NO_OFFER: 'No Offer Available',
  },
  requestStatus: {
    PENDING: 'Pending',
    APPROVED: 'Assigned',
    NO_OFFER: 'No offer available',
    PLANNED: 'Planned',
    REJECTED: 'Rejected',
    CANCELLED: 'Cancelled',
    FINISHED: 'Completed',
    IN_PROGRESS: 'In Progress',
    PENDING_CHANGE: 'Pending Change',
    DRAFT: 'Draft',
    CONFIRMED: 'Confirmed',
    CANCELED: 'Rejected',
    CREATED: 'Created',
    PAID: 'Paid',
  },
  driverStatus: {
    assigned: 'Assigned',
    completed: 'Completed',
    driving_with_rider: 'Driving with Rider',
    idle: 'Idle',
    in_transit: 'In transit',
    no_assignment: 'No assignment',
  },
  vehicleStatus: {
    available: 'Available',
    in_maintenance: 'In Maintenance',
    on_duty: 'On duty',
  },
  pointStatus: {
    NOT_STARTED: 'Not Started',
    ON_TIME: 'On Time',
    DELAYED: 'Delayed',
    ARRIVED_ON_TIME: 'Arrived On Time',
    ARRIVED_WITH_DELAY: 'Arrived With Delay',
    PICKED_UP_ON_TIME: 'Picked Up On Time',
    PICKED_UP_WITH_PASSENGER_DELAY: 'Picked Up With Passenger Delay',
    PICKED_UP_WITH_DRIVER_DELAY: 'Picked Up With Driver Delay',
    DELIVERED_ON_TIME: 'Dropped off On Time',
    DELIVERED_WITH_DELAY: 'Dropped off With Delay',
  },

  type: {
    PICKUP: 'Pickup',
    DELIVERY: 'Dropoff',
  },
  modeStatus: {
    COMPANY_CAR: 'Company Car',
    PERSONAL_CAR: 'Personal Car',
    TAXI: 'Taxi',
    RIDE_HAILING: 'Ride Hailing',
  },
  request: {
    emailHeader: 'The request has been drafted from the following e-mail sent by {0}  on {1}',
    submitRequest: 'Submit Request',
    saveDraft: 'Save Draft',
    sourceEmail: 'Source E-mail',
    pickupTime: 'Pick-up Time',
    pickupDropoffTimes: 'Pickup - <br> Dropoff Times',
    dropOffTime: 'Drop-off Time',
    corporateUser: 'Corporate User',
    guestUser: 'Guest User',
    user: 'Rider',
    guestRider: 'Guest Rider Name',
    guestPhoneNumber: 'Guest Phone Number',
    riderCount: '# Riders',
    ridePurpose: 'Ride Purpose',
    isMobilityAssistanceNeeded: 'Check if any riders request mobility assistance.',
    mobilityAssistance: 'Mobility Assistance',
    required: 'Required',
    notRequired: 'Not Required',
    description: 'Ride Purpose',
    requestPickupDate: 'Pickup Date',
    requestPickupTime: 'Pickup Time',
    requestDropoffTime: 'Requested Dropoff Time',
    pickupPoint: 'Pickup Point',
    dropoffPoint: 'Dropoff Point',
    flightNumber: 'Flight Number',
    rideType: 'Ride Type',
    noteToDriver: 'Note to Driver',
    note: 'Notes',
    corporateUserLabel: 'Corporate User Name',
    riderName: 'Rider Name',
    corporateUserName: 'Corporate User Name',
    serviceTimePickup: 'Service Time For Pickup',
    serviceTimeDropOff: 'Service Time For Drop Off',
    sendTrackingSmsToRider: 'Check if you want the rider to receive SMS link for vehicle tracking',
    trackingSms: 'Tracking Sms',
    alReadyRequestMessage:
      'You already have a ride request for the same hours. Would you like to delete the existing request and send a new ride request?',
    keeptheExistingRequest: 'Keep the existing Request',
    makeaNewRequest: 'Make a New Request',
    noNotesAdded: 'No Notes Added',
    youCanDefaultNoteLocation: 'You can add a default note for the location',
    estimatedDropOffTime: 'Estimated Drop Off Time',
    canBeExtendedUpTo: 'Can be Extended Up To',
    extendFor: 'Extend for',
    notesForExtension: 'Notes for Extension',
    extend: 'Extend',
    thisTripCannotBeExtended: 'This trip cannot be extended',
    youMayAddNotes: 'You may add notes…',
    tripExtendedforMinutes: 'The trip has been extended for {0} minutes.',
    rideData: 'Ride Date',
    rider: 'Rider',
    pickupAddress: 'Pickup Address',
    requestedDropoffTime: 'Planned Pick-up Time',
    plannedDropoffTime: 'Planned Dropoff Time',
    acknowledgement: 'Acknowledgement',
    actualDropoffTime: 'Actual Dropoff Time',
    vehicleRating: 'Vehicle Rating',
    cleanlinessEating: 'Cleanliness Rating',
    overallRating: 'Overall Rating',
    requestStatus: 'Request Status',
    actualPickupTime: 'Actual Pickup Time',
    times: 'times',
    plannedActualPickup: 'Planned / Actual Pickup Time',
    plannedActualDropoff: 'Planned / Actual Dropoff Time',
    vehicle: 'Vehicle',
    driver: 'Driver',
    assistance: 'Assistance',
    requestType: 'Request Type',
    preBooked: 'Pre-Booked',
    onDemand: 'On Demand',
  },
  newRequest: {
    pickupStop: 'A - Pick-up Stop',
    dropoffStop: 'B - Drop-off Stop',
    selectStop: 'Select Stop',
    request: 'Request',
    receiveTextMessage: 'Check if you want the rider to receive text message link for ride tracking',
    noteToDriver: 'Note to Driver',
    saveNote: 'Save Note',
    editNotes: 'Edit Notes',
    max100Char: 'Max. 100 characters',
    pickupDate: 'Pickup Date',
    pickupTime: 'Pickup Time',
    phoneNumber: 'Phone Number',
    enterPhone: 'Enter phone number',
  },
  riderNewRequest: {
    receiveTextMessage: 'Check if you want to receive text message link for ride tracking',
  },
  alert: {
    weAreChecking: 'We’re checking for the available vehicles to assign the request…',
    description: 'The closest available time to your request is:',
    pickup: 'PICK-UP',
    dropoff: 'DROP-OFF',
    seconds: 'seconds',
    weCannotAccommodate: 'We cannot accommodate your request.',
    optimizationFailed:
      'Optimization failed to run because there are no vehicles assigned to this service on this campus.',
    youHaveAnotherReservation:
      'You have another reservation for this time. Please cancel the existing reservation before requesting a new one.',
    ok: 'OK',
    reservationSuccessful: 'flexiRide Shared reservation has been successfully made',
    didntRespond: 'You did not respond within the specified time.',
    reSubmit: 'Re-Submit Request',
    createNewRequest: 'Create New Request',
    pleaseConfirm: 'Please confirm if this works for you.',
  },

  requestSaveResults: {
    ALL_OK: 'The trip  has been updated with the requested additional stop(s).',
    REPLANNING_NEEDED:
      'The current driver is unable to fulfill the requested stops. Please review driver/vehicle assignments on the Planning page and update as necessary.',
    FIRST_OK_OTHERS_NEED_PLANNING:
      "The first leg of the trip has been updated. The second leg of the trip conflicts with the driver's other assignments at the moment. Please review driver/vehicle assignments on the Planning page and update as necessary.",
    CANNOT_UPDATE: 'Your update request can not be executed. The driver tasks need to be updated.',
  },

  purposeType: {
    AIRPORT_PICKUP: 'Airport Pickup',
    AIRPORT_DROPOFF: 'Airport Dropoff',
    MEETING: 'Meeting',
    CUSTOMER_VISIT: 'Customer Visit',
    OTHER: 'Other',
    AIRPORT_TRANSFER: 'Airport Transfer',
    RIDE_HOME: 'Ride Home',
    COMMUTE: 'Commute',
  },

  ridePurpose: {
    PERSONAL: 'Personal',
    BUSINESS: 'Business',
  },
  tracking: {
    estimated: 'Estimated',
    planned: 'Planned',
    actual: 'Actual',
    start: 'Start',
    arrival: 'Arrival',
    departure: 'Departure',
    lastStopOnRoute: 'Last stop on this route',
    allCampuses: 'All Campuses',
    select: 'Select',
    completedSteps: 'Completed Steps',
    min: 'min',
    all: 'All',
    planning: 'Planning',
    estimatedDuration: 'Estimated duration',
    distanceToNextStop: 'Distance to the next stop',
    minutes: 'minutes',
    early: 'early',
    late: 'late',
    routeStatus: {
      NOT_STARTED: 'Not Started',
      ACTIVE: 'Active',
      COMPLETED: 'Completed',
      DRAFT: 'Draft',
      CANCELLED: 'Cancelled',
      NOT_CREATED: 'Not Created',
    },
    plannedPickup: 'Planned Pickup',
    actualPickup: 'Actual Pickup',
    plannedDropoff: 'Planned Dropoff',
    actualDropoff: 'Actual Dropoff',
    progress: 'Progress',
    lastStatus: 'Last Status',
    totalTasks: 'Total Tasks',
    cancellations: 'Cancellations',
    delayedPickups: 'Delayed Pickup',
    delayedDropoffs: 'Delayed Dropoffs',
    pickupStop: 'Pickup Stop',
    dropoffStop: 'Dropoff Stop',
    totalRequest: 'Total Requests',
    skippedStops: 'Skipped Stops',
    latePickups: 'Late Pickups',
    lateDropoffs: 'Late Dropoffs',
    requests: 'Requests',
    clickToView: 'Click to view reservation details.',
    totalPassenger: 'Riders',
  },
  confirmMessage: 'Are you sure you want to confirm this payment?',
  selectedConfirmMessage: 'Are you sure you want to confirm the selected row/s?',
  selectedRejectMessage: 'Are you sure you want to reject the selected row/s?',
  selectedMarkPaid: 'Are you sure you want to mark as paid the selected row/s?',
  confirmedSelectedRow: 'You have confirmed the selected row/s',
  rejectedSelectedRow: 'You have rejected the selected row/s.',
  informedRegardingPayment: 'The employee will be informed regarding the payment.',
  rejectMessage: 'Are you sure you want to reject this payment',
  youHaveRejected: 'You have rejected this payment.',
  markPaidMessage: 'Are you sure you want to mark selected as paid?',
  belowEmployees:
    'Below employees status couldn’t be updated. To update their status as paid, you must change their status as confirmed',
  Success: 'Success',

  requestQuestions: {
    DRIVER_NO_EVENTS:
      'The selected driver is currently assigned to the {makeModel}, vehicle {plateNumber}.Would you like to change the driver with their currently assigned vehicle, or just change the driver for this route?',
    DRIVER_HAS_EVENTS:
      'The selected driver has been assigned %{numberOfRequests} requests for the riders {riderNames}, and is currently assigned to the {makeModel}, vehicle {plateNumber}. Would you like to change the driver with their currently assigned vehicle, or just change the driver for this route?',
    VEHICLE_NO_EVENTS:
      'The selected vehicle is currently assigned to {driverName}.Would you like to change the vehicle with the driver currently assigned to it, or just change the vehicle for this route?',
    VEHICLE_HAS_EVENTS:
      'The selected vehicle has been assigned {numberOfRequests} requests for the riders {riderNames} and currently paired with driver {driverName}. Would you like to change the vehicle with the driver currently assigned to it, or just change the vehicle for this route?',
    VEHICLE_HAS_EVENTS_NO_DRIVER:
      'The selected vehicle has been assigned {numberOfRequests} requests for the riders {riderNames}.',
  },

  dashboardRequestTypes: {
    notStarted: 'Not Started',
    active: 'Active',
    completed: 'Completed',
    cancelled: 'Canceled',
    total: 'Total',
    delayedPickup: 'Delayed Pickup',
    pickedUpWithDelay: 'Picked Up with Delay',
    delayedDropoff: 'Delayed Dropoff',
    droppedOffWithDelay: 'Dropped Off with Delay',
  },

  dashboardDriverStatus: {
    in_transit: 'In transit',
    idle: 'Idle',
    no_assignment: 'No assignment',
    driving_with_rider: 'Driving with Rider',
    assigned: 'Assigned',
    completed: 'Completed',
  },
  dashboardVehicleStatus: {
    available: 'Available',
    on_duty: 'On duty',
    in_maintenance: 'In Maintenance',
    assigned: 'Assigned',
  },
  pool: {
    status: {
      ASSIGNED: 'Assigned',
      ENROUTE_TO_PICKUP: 'Enroute to Pickup',
      AWAITING_PICKUP: 'Awaiting Pickup',
      ENROUTE_TO_DROPOFF: 'Enroute to Dropoff',
      AWAITING_DROPOFF: 'Awaiting Dropoff',
      COMPLETED: 'Completed',
      NOT_STARTED: 'Not Started',
      ACTIVE: 'Enroute to Dropoff',
    },
  },
  riderCountLimitByCapacity: 'Rider counts are limited by the vehicle capacity',
};
