export const tr = {
  common: {
    id: 'Id',
    name: 'İsim',
    status: 'Durum',
    vehicle: 'Araç',
    valet: 'Vale',
    yes: 'Evet',
    no: 'Hayır',
    confirm: 'Emin misin?',
    success: 'İşlem başarılı',
    device: 'Cihaz',
    owner: 'Sahibi',
    changeOwner: 'Sahibini değiştir',
    active: 'Aktif',
    passive: 'Pasif',
    sendPasswordWithSms: 'SMS ile şifre gönder',
  },
  dashboard: {
    pleaseSelect: 'Seçiniz',
    general: 'Genel',
    yes: 'Tamam',
    totalUsers: 'Toplam Kullanıcı',
    registeredUser: 'Kayıtlı Kullanıcı',
    totalRentals: 'Toplam Kiralama',
    todaysRental: 'Bugünkü Kiralama',
    numberOfRental: 'KİRALAMA SAYILARI',
    history: 'Date',
    rentals: 'Kiralamalar',
    canceled: 'İptal Edilen',
    normal: 'normal',
    totalAsThisHour: 'Bu Saat İtibarıyle toplam',
    amountOfUsage: 'KULLANIM MİKTARI (KM)',
    totalKm: 'Toplam Km',
    avarageKm: 'Ortalama km',
    rentalTimes: 'KİRALAMA SÜRELERİ',
    valueHour: '{value} saat',
    totalHour: 'Toplam saat',
    avarageHour: 'Ortalama saat',
    hour: ' saat',
    rentalAmounts: 'KİRALAMA TUTARLARI',
    valueCurrency: '{value} TL',
    totalAmount: 'Toplam Tutar',
    avarageAmount: 'Ortalama Tutar',
    turkishCurrency: ' TL',
    unitMeasurement: ' km',
    accountDebitCorparation:
      'Kurumunuzun bakiyesi eksidedir. Kullanıcıların kiralama yapabilmesi için bakiye yükleyiniz',
    accountDebit: 'Eksi Bakiye',
    warningBalance: 'Bakiye Uyarısı!',
    inOrderToContinue:
      'Değerli müşterimiz, çalışanlarınızın kurumsal kiralamalara devam edebilmesi için hesabınızda minimum',
    pleaseContactCallCenter:
      'TL’lik bir bakiye bulunması gerekmektedir. Hesabınıza Ön Ödemeli Bakiye yüklenmesi için lütfen TikTak Çağrı Merkezi ile iletişime geçiniz.',
    symbol: '#',
    user: 'Kullanıcı',
    number: 'tane',
    total: 'Toplam',
    specifiedDateRange: 'Belirtilen Tarih Aralğında',
    noData: 'verisi yok.',
  },
  rentals: {
    start: 'Başlangıç:',
    end: 'Bitiş:',
    minuteUnit: 'dk.',
    measurementUnit: 'km.',
    state: 'Durum',
    requestDate: 'Talep Tarihi',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    startHour: 'Başlangıç Saati',
    endHour: 'Bitiş Saati',
    distance: 'Mesafe(km.)',
    time: 'Süre: (Dk.)',
    total: 'Toplam (₺)',
    discount: 'İndirim (%)',
    type: 'Tip',
    rentalReason: 'Kiralama Nedeni',
    ending: 'Bitiş',
    estimatedArrival: 'Tahmini varış',
    startAddress: 'Başlangıç Adresi',
    endAddress: 'Bitiş Adresi',
    startOdo: 'Başlangıç Km.',
    endOdo: 'Bitiş Km.',
    startKm: 'Başlangıç Km.',
    endKm: 'Bitiş Km.',
  },
  customers: {
    deleteCustomer: 'kullanıcıyı sil',
    addCustomer: 'Kullanıcı Ekle',
    downloadCustomerTemplate: 'Toplu Kullanıcı şablonunu indir',
    refresh: 'Yenile',
    chooseFile: 'Dosya Seç',
    batchUpload: 'Toplu yükle',
    reset: 'Sıfırla',
    name: 'İsim',
    lastName: 'Soyad',
    citizenId: 'TCKN',
    driverLicenseNo: 'Ehliyet No',
    addContactCorparateUser: 'kişiyi kurumsal kullanıcı olarak ekle.',
    gsm: 'Cep Tel.',
    corparateMembership: 'Kurumsal Üyelik',
    applicationMembership: 'Uygulama Üyelik',
    reviewAndAdd: 'Dosyada kayıtlı satırları inceleyip ekleyebilirsiniz.',
    fileUploaded: 'Dosya yüklendi',
    errorUploadingFiles: 'Dosya yüklemede hata!',
    completedSuccessfully: 'İşlem başarı ile tamamlandı',
    successfull: 'Başarılı',
    occuredErrorWhenRegister: 'Kayıt sırasında bir hata oluştu',
    error: 'Hata',
    selected: 'Seçilen',
    sureToRemoveUser: 'kullanıcıyı kurumsaldan çıkarmak istediğinize emin misiniz?',
    userPassived: 'kullanıcı pasife alındı',
    usersCouldNotBeDeleted: 'Seçili kullanıcılar silinemedi.',
    loading: 'yükleniyor...',
    uploadFile: 'Dosyayı Yükle',
    email: 'E-mail',
    passive: 'Pasife Al',
    drivingLicenseFront: 'Ehliyet ön',
    drivingLicenseBack: 'Ehliyet arka',
    trafficFine: 'Trafik Para Cezası',
    totalRentalPrice: 'Toplam Kiralama Bedeli',
    noRentals: 'Bu kullanıcının şu ana kadar bir kiralaması bulunmuyor.',
    removeFromCorporate: '{0} isimli kullanıcıyı kurumsaldan çıkarmak istediğinize emin misiniz?',
    userDeleted: '{0} isimli kullanıcı silindi',
    makePassive: 'Kullanıcıyı Pasife Al',
    makeActive: 'Kullanıcıyı Aktife Al',
  },
  vehicles: {
    newVehicle: 'Yeni Araç',
    catalog: 'Katalog',
    park: 'Park',
    chooseOtoPark: 'Otopark Seç',
    changeParkingLot: 'Otopark Değiştir',
    plate: 'Plaka',
    chassisNo: 'Şasi No',
    color: 'Renk',
    save: 'Kaydet',
    state: 'Durum',
    operation: 'Operasyon',
    fuel: 'Yakıt (l.)',
    battery: 'Akü',
    km: 'KM.',
    averageWaiting: 'Ortalama Bekleme',
    brand: 'Marka',
    model: 'Model',
    gear: 'Vites',
    type: 'Tip',
    address: 'Adres',
    maintenanceStatus: 'Bakım Durum',
    continent: 'Kıta',
    city: 'Şehir',
    region: 'Bölge',
    lastLocation: 'Son Konum',
    lastRentingStart: 'Son Kiralama Başlangıç',
    lastRentingEnd: 'Son Kiralama Bitiş',
    latestStatusUpdate: 'Son Durum Güncellemesi',
    otopark: 'Otopark',
    finalMaintenance: 'Son Bakım',
    nextMaintenance: 'Sonraki Bakım Km',
    statusChangedBy: 'Değişikliği Yapan',
    vehicle: 'Araç',
    savedByPlate: 'plaka ile kaydedildi.',
    savedVehicle: 'Araç Kaydedildi',
    choose: 'Seç',
    chooseCatalog: 'Katalog Seç',
    addVehicle: 'Araç Ekle',
    brandModel: 'Marka/Model',
    makeModel: 'Marka/Model',
    deviceType: 'Cihaz Tipi',
    noDevice: 'Cihaz yok',
    basicDevice: 'Temel cihaz',
    remoteDoorDevice: 'Uzaktan Kapı Kumandalı Cihaz',
    lastVehiclePhotoFront: 'Aracın Son Fotoğrafı (Ön)',
    lastVehiclePhotoBack: 'Aracın Son Fotoğrafı (Arka)',
    lastVehiclePhotoLeft: 'Aracın Son Fotoğrafı (Sol)',
    lastVehiclePhotoRight: 'Aracın Son Fotoğrafı (Sağ)',
    lastRentalsOfVehicle: 'Aracın son {0} kiralaması',
    closeDoors: 'Kapıları Kapat',
    openDoors: 'Kapıları Aç',
    lastUpdate: 'Son güncelleme',
    ownerChange: 'Araç Sahibi değişikliği',
    ownerName: 'Araç Sahibi',
    ownVehicle: 'Öz Mal',
    contactPhone: 'İletişim',
    isWheelchairEnabled: 'Engelli rampası var mı',
    wheelchair: 'Engelli',
  },
  drivers: {
    id: 'Id',
    name: 'İsim',
    idNo: 'Kimlik No',
    mobil: 'Mobil',
    mSoftware: 'M. Yazılım',
    task: 'Görev',
    firstLogin: 'İlk Giriş',
    lastLogout: 'Son Çıkış',
    addDriver: 'Sürücü Ekle',
    newDriver: 'Yeni Sürücü',
    driverDetails: 'Sürücü Ayrıntıları',
    driverOperationDone: 'Sürücü İşlemi yapıldı',
    unableToAddDriver: 'Sürücü Eklenemiyor',
    userIdAlready: 'Aynı kullanıcı kimliğine sahip bu sürücü zaten eklenmiş',
    emailAlready: 'Aynı e-posta adresine sahip bir sürücü zaten eklenmiş',
    type: 'Tipi',
    defaultVehicle: 'Varsayılan Araç',
    defaultDriver: 'Varsayılan Sürücü',
  },
  usages: {
    amount: 'Tutar (₺)',
    totalKm: 'Toplam km.',
    minute: 'Dakika',
    differentUser: 'Farklı kullanıcı',
    rentalTrend: 'Kiralama trendi',
    rentalTrendAsDateRange: 'Verilen tarih aralığındaki farklı günlere göre kiralam trendi',
  },
  requests: {
    new: 'Yeni',
    newRequest: 'Yeni Talep',
    date: 'Tarih',
    hour: 'Saat',
    totalPassenger: 'Yolcu sayısı',
    departure: 'Kalkış',
    arrival: 'Varış',
    add: 'Ekle',
    note: 'Not',
    vehicleAndDriverAssignment: 'Araç ve Sürücü Ataması',
    planNo: 'Plan No',
    createdNewDemand: 'için yeni talep yaratıldı.',
    createdRequest: 'Talep yaratıldı',
    remove: 'Kaldır',
    selected: 'Seçili',
    select: 'Seç',
    selectUser: 'Kullanıcı Seç',
    selectVehicle: 'Araç Seç',
    childSeat: 'Çocuk koltuğu',
    driver: 'Sürücü',
    planning: 'Plan',
    automaticPlanning: 'Otomatik Planlama',
    automaticAssignmentMade: 'Otomatik atama yapıldı. Araç:',
    problemOccured: 'Otomatik atama sırasında bir sorun çıktı',
    assignmentFailed: 'Atama Yapılamadı',
    travelTypeHeader: 'Türü',
    travelType: {
      LOCAL: 'Şehir içi',
      INTERCITY: 'Şehirler arası',
    },
    requestType: 'Talep Türü',
    cancelReason: 'İptal Nedeni',
    pricing: 'Fiyatlandırma',
    location: 'Görev Yeri',
    resStart: 'Rez. Başlangıç',
    resEnd: 'Rez. Bitiş',
    startKm: 'Başlangıç Km.',
    endKm: 'Bitiş Km.',
    approvalNote: 'Onay notu',
    visitor: 'Misafir',
    park: 'Otopark',
    desc: 'Açıklama',
    drivercar: 'Sürücülü Talep',
    poolcar: 'Havuz Araç Talep',
    totalCars: 'Toplam Araç',
    uniqueCars: 'Tekil Seçili',
    selectParkingLot: 'Otopark Seçiniz',
    selectCatalog: 'Katalog Seçiniz',
    fillFormForCatalogList: 'Katalog Listesi için formu doldur',
    guestUsers: 'Ek Kullanıcılar',
    cancel: 'İptal Et',
    changeCar: 'Aracı Değiştir',
    approve: 'Onayla',
    addNote: 'Not Ekle',
    requestNotCancelled: 'Talep İptal Edilemedi',
    requestHasBeenCreated: 'Talep Yaratıldı',
    requestHasNotBeenCreated: 'Talep Yaratılmadı',
    requestWillBeCancelled: 'Bu Talep İptal Edilecek.',
    cancelRequest: 'Talebi İptal Et',
    confirmRequest: 'Bu isteği iptal etmek istediğinizden emin misiniz?',
    replaceVehicles: '{0} plakalı aracı aşağıdaki araç ile değiştir.',
    vehicleChange: 'Araç Değiştir',
  },
  map: {
    searchAddress: 'Adres Arama',
  },
  parkings: {
    pyp: 'Pyp',
  },
};

export const en = {
  common: {
    id: 'Id',
    name: 'Name',
    status: 'Status',
    vehicle: 'Vehicle',
    valet: 'Valet',
    yes: 'Yes',
    no: 'No',
    confirm: 'Are you sure?',
    success: 'Operation successful',
    device: 'Device',
    owner: 'Owner',
    changeOwner: 'Change owner',
    active: 'Active',
    passive: 'Passive',
    sendPasswordWithSms: 'Send password with SMS',
  },
  dashboard: {
    pleaseSelect: 'Please Select',
    general: 'General',
    yes: 'Yes',
    totalUsers: 'Total Users',
    registeredUser: 'Registered User',
    totalRentals: 'Total Rides',
    todaysRental: "Today's Rides",
    numberOfRental: 'Number Of Rides',
    history: 'Date',
    rentals: 'Rentals',
    canceled: 'Canceled',
    normal: 'normal',
    totalAsThisHour: 'Total as this hour',
    amountOfUsage: 'Usage Distance(KM)',
    totalKm: 'Total Km',
    avarageKm: 'Avarage km',
    rentalTimes: 'Usage Duration',
    valueHour: '{value} hour',
    totalHour: 'Total hour',
    avarageHour: 'Avarage Hour',
    hour: 'hour',
    rentalAmounts: 'Amounts',
    valueCurrency: '{value} TL',
    totalAmount: 'Amount',
    avarageAmount: 'Avarage Amount',
    turkishCurrency: ' TL',
    unitMeasurement: ' km',
    accountDebitCorparation: "Your institution's balance is negative. Upload balance so users can rent",
    accountDebit: 'Account Debit',
    warningBalance: 'Warning Balance!',
    inOrderToContinue: 'Dear customer, in order for your employees to continue corporate renting, you',
    pleaseContactCallCenter:
      'TL is required. Please contact TikTak Call Center to load Prepaid Balance to your account.',
    symbol: '#',
    user: 'Users',
    number: 'Number',
    total: 'Total',
    specifiedDateRange: 'In the specified date range',
    noData: 'No data.',
  },
  rentals: {
    start: 'Start:',
    end: 'End:',
    minuteUnit: 'minute',
    measurementUnit: 'km',
    state: 'State',
    requestDate: 'Request Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    startHour: 'Start Hour',
    endHour: 'End Hour',
    distance: 'Distance(km.)',
    time: 'Time: (Minute)',
    total: 'Total (₺)',
    discount: 'Discount (%)',
    type: 'Type',
    rentalReason: 'Rental Reason',
    ending: 'Ending',
    estimatedArrival: 'Estimated Arrival',
    startAddress: 'Start Address',
    endAddress: 'End Address',
    startOdo: 'Start Km.',
    endOdo: 'End Km.',
    startKm: 'Start Km.',
    endKm: 'End Km.',
  },
  customers: {
    deleteCustomer: 'Delete customer',
    addCustomer: 'Add Customer',
    downloadCustomerTemplate: 'Download All Customer Template',
    refresh: 'Refresh',
    chooseFile: 'Choose File',
    batchUpload: 'Batch Upload',
    reset: 'Reset',
    name: 'First Name',
    lastName: 'Last Name',
    citizenId: 'User Id',
    driverLicenseNo: 'Driver License No',
    addContactCorparateUser: 'Add the contact as corparate user.',
    gsm: 'GSM',
    corparateMembership: 'Corparate Membership',
    applicationMembership: 'Application Membership',
    reviewAndAdd: 'You can review or add the saved lines in the file',
    fileUploaded: 'File uploaded',
    errorUploadingFiles: 'Error uploading files',
    completedSuccessfully: 'Completed Successfully',
    successfull: 'Successfull',
    occuredErrorWhenRegister: 'Occured Error when registering',
    error: 'error',
    selected: 'Selected',
    sureToRemoveUser: 'Are you sure want the user to log out ?',
    userPassived: 'user moved to inactive',
    usersCouldNotBeDeleted: 'Users could not be deleted.',
    loading: 'loading...',
    uploadFile: 'Upload file',
    email: 'Email',
    passive: 'Passive',
    drivingLicenseFront: 'Driving license front',
    drivingLicenseBack: 'Driving license back',
    trafficFine: 'Traffic Fine',
    totalRentalPrice: 'Total Rental Price',
    noRentals: 'This user does not have a rental so far.',
    removeFromCorporate: 'Are you sure you want to remove {0} from the corporate?',
    userDeleted: '{0} has been deleted',
    makePassive: 'Passive User',
    makeActive: 'Activate User',
  },
  vehicles: {
    newVehicle: 'New Vehicle',
    catalog: 'Catalog',
    park: 'Park',
    chooseOtoPark: 'Choose Parking Lot',
    changeParkingLot: 'Change Parking Lot',
    plate: 'Plate',
    chassisNo: 'VIN',
    color: 'Color',
    save: 'Save',
    state: 'State',
    operation: 'Operation',
    fuel: 'Fuel (l.)',
    battery: 'Battery',
    km: 'KM.',
    averageWaiting: 'Average Wait Time',
    brand: 'Brand',
    model: 'Model',
    gear: 'Gear',
    type: 'Type',
    address: 'Address',
    maintenanceStatus: 'Maintenance Status',
    continent: 'Continent',
    city: 'City',
    region: 'Region',
    lastLocation: 'Last Location',
    lastRentingStart: 'Last Usages Start',
    lastRentingEnd: 'Last Usages End',
    latestStatusUpdate: 'Latest Status Update',
    otopark: 'Parking Lot',
    finalMaintenance: 'Last Maintenance',
    nextMaintenance: 'Next Maintenance',
    statusChangedBy: 'Status Changed By',
    vehicle: 'Vehicle',
    savedByPlate: 'saved by plate.',
    savedVehicle: 'saved vehicle',
    choose: 'Choose',
    chooseCatalog: 'Choose Catalog',
    addVehicle: 'Add Vehicle',
    brandModel: 'Brand/Model',
    makeModel: 'Make/Model',
    deviceType: 'Device Type',
    noDevice: 'No device',
    basicDevice: 'Main device',
    remoteDoorDevice: 'Remote door device',
    lastVehiclePhotoFront: 'Last Photo of Vehicle (Front)',
    lastVehiclePhotoBack: 'Last Photo of Vehicle (Back)',
    lastVehiclePhotoLeft: 'Last Photo of Vehicle (Left)',
    lastVehiclePhotoRight: 'Last Photo of Vehicle (Right)',
    lastRentalsOfVehicle: 'Last {0} rentals of the vehicle',
    closeDoors: 'Close Doors',
    openDoors: 'Open Doors',
    lastUpdate: 'Last update',
    ownerChange: 'Change the vehicle provider',
    ownerName: 'Provider name',
    ownVehicle: 'Own Vehicle',
    contactPhone: 'Phone Number ',
    isWheelchairEnabled: 'Is wheelchair enabled',
    wheelchair: 'Wheelchair',
  },
  drivers: {
    id: 'System Id',
    name: 'Name',
    idNo: 'User Id',
    mobil: 'Mobile',
    mSoftware: 'M. Software',
    task: 'Task',
    firstLogin: 'First Login',
    lastLogout: 'Last Logout',
    addDriver: 'Add Driver',
    newDriver: 'New Driver',
    driverDetails: 'Driver Details',
    driverOperationDone: 'Driver Operation Done',
    unableToAddDriver: 'Unable to Add Driver',
    userIdAlready: 'This driver with the same user id has already been added',
    emailAlready: 'A driver with the same email address has already been added',
    type: 'Type',
    defaultVehicle: 'Default Vehicle',
    defaultDriver: 'Default Driver',
  },
  usages: {
    amount: 'Amount (₺)',
    totalKm: 'Total km.',
    minute: 'Minute',
    differentUser: 'Other User',
    rentalTrend: 'Rental trend',
    rentalTrendAsDateRange: 'Rental trend according to different days in the given date range',
  },
  requests: {
    new: 'New',
    newRequest: 'New request',
    date: 'Date',
    hour: 'Hour',
    totalPassenger: 'Total passenger',
    departure: 'Departure',
    arrival: 'Arrival',
    add: 'Add',
    vehicleAndDriverAssignment: 'Vehicle and Drşver Assignment',
    planNo: 'Plan No',
    createdNewDemand: 'Created new demand',
    createdRequest: 'Created request',
    remove: 'Remove',
    selected: 'Selected',
    select: 'Select',
    selectUser: 'Select User',
    selectVehicle: 'Select Vehicle',
    childSeat: 'Child Seat',
    driver: 'Driver',
    planning: 'Planning',
    automaticPlanning: 'Automatic Planning',
    automaticAssignmentMade: 'Automatic assignment has been made. Vehicle:',
    problemOccured: 'A problem occurred during automatic assignment',
    assignmentFailed: 'There was a problem during automatic assignment',
    travelTypeHeader: 'Type',
    note: 'Note',
    travelType: {
      LOCAL: 'Local',
      INTERCITY: 'Intercity',
    },
    requestType: 'Request Type',
    cancelReason: 'Cancel Reason',
    pricing: 'Pricing',
    location: 'Location',
    resStart: 'Res. Start',
    resEnd: 'Res. End',
    startKm: 'Start Km.',
    endKm: 'End Km.',
    approvalNote: 'Approval note',
    visitor: 'Visitor',
    park: 'Parking',
    desc: 'Description',
    drivercar: 'Driver Request',
    poolcar: 'Poolcar Request',
    totalCars: 'Total Cars',
    uniqueCars: 'Unique Selected',
    selectParkingLot: 'Select Parking Lot',
    selectCatalog: 'Select Catalog',
    fillFormForCatalogList: 'Fill Form for Catalog List',
    guestUsers: 'Guest Users',
    cancel: 'Cancel',
    changeCar: 'Change Car',
    approve: 'Approve',
    addNote: 'Add Note',
    requestNotCancelled: 'The request was not cancelled.',
    requestHasBeenCreated: 'The request has been created.',
    requestHasNotBeenCreated: 'The request has not been created.',
    requestWillBeCancelled: 'This request will be cancelled.',
    cancelRequest: 'Cancel Request',
    confirmRequest: 'Are you sure you want to cancel this request?',
    replaceVehicles: 'Replace the vehicle with the license plate {0} with the vehicle below',
    vehicleChange: 'Vehicle Change',
  },
  map: {
    searchAddress: 'Search Address',
  },
  parkings: {
    pyp: 'Pyp',
  },
};

export default tr;
