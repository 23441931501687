export const tr = {
  valet: 'vale',
  registeredUsers: 'Kayıtlı Müşteri',
  userWithContract: 'Sözleşmeli Müşteri',
  totalRentals: 'Toplam Kiralama',
  activeVehicles: 'Aktif Araç',
  todaysRentals: 'Bugünkü Kiralama',
  valetStatus: 'Valelerin Anlık Durumu',
  missingContract: 'Sözleşme Eksik',
  rentalUserCount: 'Kiralama Yapan Müşteri',
  todaysRadar: 'Bugünün Radar Sonuçları',
  currentRentals: 'Anlık Kiralama Durumu',
  customers: 'Müşteriler',
  rentalCount: 'Kiralama Sayıları',
  newRegistration: 'Yeni kayıt',
  newContract: 'Yeni Sözleşme',
  firstRental: 'İlk Kiralama',
  rentals: 'Kiralamalar',
  cancelled: 'İptal Edilen',
  rentalCounts: 'Kiralama Sayıları',
  instantTotal: 'Bu Saat İtibarıyle toplam',
  rentalTimes: 'Kiralama Süreleri',
  avgHours: 'Ortalama saat',
  totalHours: 'Toplam saat',
  valetTasks: 'Vale Görevleri',
  taskCount: 'Görev Sayısı',
  rentalIncomes: 'Kiralama Cirosu',
  totalAmount: 'Toplam Tutar',
  avgAmount: 'Ortalama Tutar',
  selfServiceSearch: 'Self Servis Aramaları (mesafe)',
  areaSearch: 'Servis Alanlarına Göre Kiralamalar',
  searchCount: 'Arama Sayısı',
  avgDistance: 'Ortalama Mesafe',
  parkingStats: 'Otopark İstatistik',
  usage: 'Kullanımlar',
  firstUse: 'İlk Kullanım',
  usageAmount: 'Kullanım Miktarı (KM)',
  totalKm: 'Toplam KM',
  avgKm: 'Ortalama KM',
  numbers: 'Sayılar',
  invites: {
    header: '"Arkadaşını Davet Et" Sayıları',
    users: 'Kullanıcılar',
    both_used: 'Karşılıklı kullanan',
    invitee_not_registered: 'Davetli Kayıtlı Değil',
    invitee_not_used: 'Davetli Kullanmadı',
    inviter_not_used: 'Kullanan',
    totalInvitees: 'Davet Edilen',
  },
  rentalsByZone: 'Aktif Kiralama Dağılımı',
};

export const en = {
  valet: 'valet',
  registeredUsers: 'Registered Users',
  userWithContract: 'Customer with Contract',
  totalRentals: 'Total Rentals',
  activeVehicles: 'Active Vehicles',
  todaysRentals: 'Rentals Today',
  valetStatus: 'Valet Status',
  missingContract: 'Missing Contract',
  rentalUserCount: 'Rental Users',
  todaysRadar: 'Daily Radar Stats',
  currentRentals: 'Current Rentals Status',
  customers: 'Customers',
  rentalCount: 'Rental Counts',
  newRegistration: 'New Registration',
  newContract: 'New Contract',
  firstRental: 'First Rental',
  rentals: 'Rentals',
  cancelled: 'Cancelled',
  rentalCounts: 'Rental Counts',
  instantTotal: 'Instant Rental Count',
  rentalTimes: 'Rental Times',
  avgHours: 'Avg. Hours.',
  totalHours: 'Total Hours',
  valetTasks: 'Valet Tasks',
  taskCount: 'Task Count',
  rentalIncomes: 'Rental Income',
  totalAmount: 'Total Amount',
  avgAmount: 'Avg. Amount',
  selfServiceSearch: 'Self Service Searches (distance)',
  areaSearch: 'Search by Service Area',
  searchCount: 'Search Count',
  avgDistance: 'Avg. Distance',
  parkingStats: 'Parking Stats.',
  usage: 'Usage',
  firstUse: 'First Use',
  usageAmount: 'Usage (KM)',
  totalKm: 'Total KM',
  avgKm: 'Avg. KM',
  numbers: 'Numbers',
  invites: {
    header: '"Invite Your Friend" Numbers',
    users: 'Users',
    both_used: 'Both used',
    invitee_not_registered: 'Invitee not registered',
    invitee_not_used: 'Invitee not used',
    inviter_not_used: 'Inviter not used',
    totalInvitees: 'Invitees',
  },
  rentalsByZone: 'Rentals by Zone',
};

export default tr;
