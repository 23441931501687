export default {
  resources: 'Kaynaklar',
  aviNumber: 'X bir numara',
  color: 'Renk',
  catalogId: 'Katolog Numarası',
  id: 'Araç Numarası',
  activeRentalId: 'Aktif Kiralama Numarasi',
  rentalStatus: 'Kiralama Durumu',
  status: 'Araç Durumu',
  address: 'Adres',
  bodyType: 'Kasa Tipi',
  odometer: 'Odometre',
  canbusOdometer: 'Canbus Km.',
  chassisNumber: 'Şasi Numarası',
  doorLockType: 'Kapı Kilit Tipi',
  equipmentNumber: 'Ekipman Numarası',
  fuelLevelLt: 'Yakıt Seviyesi(Lt.)',
  fuelLevelBattery: 'Batarya Seviyesi',
  fuelType: 'Yakıt Tipi',
  hgsNumber: 'HGS Numarası',
  imeiNumber: 'IMEI Numarası',
  lastDirection: 'Yön',
  latestLocation: 'Konum',
  licenseNumber: 'Lisans Numarası',
  make: 'Marka',
  materialNumber: 'Materyal Numarası',
  model: 'Model',
  modelYear: 'Model Yılı',
  plate: 'Plaka',
  transmissionType: 'Şanzıman Tipi',
  typeDetail: 'Model Detayı',
  firstAvailableDate: 'İlk Aktif Tarihi',
  firstDeliveryDate: 'İlk Teslim Tarihi',
  // DO NOT USE THIS, It will be deprecated, use rentals.key instead
  rentalStatuses: {
    PENDING_PICKUP: 'Vale Bekliyor',
    NOT_ACTIVATED: 'Aktif Değil',
    ACTIVATED: 'Aktif',
    WORKING: 'Kirada',
    AVAILABLE: 'Uygun',
    ALL: 'Hepsi',
    IN_TRANSIT: 'Hareket Halinde',
  },
  damage: {
    description: 'Hasar Tanımı',
    fileIds: 'Hasar Dosyaları',
    region: 'Hasar Bölgesi',
    id: 'Hasar Numarası',
  },
  operationalStatus: {
    IN_TRANSIT: 'Hareket Halinde',
    PENDING_PICKUP: 'Vale Bekliyor',
    WORKING: 'Çalışıyor',
    WILL_TRANSFER_TO_CAR_LOT: 'Otoparka Çekilecek',
    TRANSFERING_TO_CAR_LOT: 'Otoparka Çekiliyor',
    NONE: '-',
    IN_REPLACEMENT: 'İkame',
  },
  vehicleStatus: {
    ACTIVATED: 'Pasif',
    NOT_ACTIVATED: 'Aktif Değil',
    OUT_OF_SERVICE: 'Hizmet Dışı',
    DELETED: 'Silinmiş',
    AVAILABLE: 'Hizmet İçi',
    STOLEN: 'Çalıntı',
    STOLEN_OUT_OF_FLEET: 'Çalıntı (out of fleet)',
    RETURN_PREPARATION: 'İade Hazırlık',
    HEAVILY_DAMAGED: 'Ağır Hasarlı',
    RETURNED: 'İade Edildi',
    DEVICE_IS_BEING_INSTALLED: 'Cihaz Takılıyor',
    PLATE_IS_BEING_UPDATED: 'Plaka Güncelleniyor',
    NEW: 'Yeni',
  },
  compoundOperationalStatus: {
    AVAILABLE: 'Kiralamaya Uygun',
    NOT_AVAILABLE: 'Meşgul/Görevde',
    WORKING: 'Kirada',
  },
  assignJob: 'Görev Atama',
  streetView: 'Sokak Görünümü',
  breakdown: 'Arıza Bildirimi',
  vehicleDetails: 'Araç Detayı',
  inRental: 'Kiralama',
  lastAddress: 'Son Bulunduğu Adres',
  lastVisible: 'Son Görülme',
  job: {
    title: 'Görev Atama',
  },
  assetHistory: {
    DoorCommandCodeExecuted: 'Kapı açma/kapama komutu',
    DoorCloseCommandSent: 'Kapı kapama komutu gönderildi.',
    DoorOpenCommandSent: 'Kapı açma komutu gönderildi.',
    IgnitionOn: 'Kontak açık',
    IgnitionOff: 'Kontak kapalı',
    DoorOpenEvent: 'Kapı açıldı',
    DoorCloseEvent: 'Kapı kapandı',
    RTCSetTimeCommandSent: 'RTC Zaman Ayarlama Komutu Gönderildi',
    RTCDoorOnCommandSent: 'RTC Kapı Aç Komutu Gönderildi',
    RTCDoorOffCommandSent: 'RTC Kapı Kapa Komutu Gönderildi',
    ResetCommandSent: 'Reset Komutu Gönderildi',
    SoftwareStart: 'Cihaz Çalışmaya Başladı',
    EchoResponse: 'Echo Cevabı',
    EchoCommandSent: 'Echo Komutu Gönderildi',
    valets: 'Görevliler',
    parks: 'Otoparklar',
    stations: 'İstasyonlar',
    maintenance: 'Bakım',
    maintenanceDamage: 'Bakım / Hasar',
    DoorCloseCommandSendingFailed: 'Kapı kapama komutu gönderilemedi.',
    DeviceConnected: 'Cihaz Bağlandı',
    ABSFailure: 'ABS Hatası',
    ABSFailureReversed: 'ABS Arızası Düzeltildi',
    AirbagFailure: 'Hava Yastığı Arızası',
    AirbagFailureReversed: 'Hava Yastığı Arızası Düzeltildi',
    ASRFailure: 'ASR Failure',
    OutputStateChanged: 'Output Durumu Değişti',
    VDCFailureReversed: 'VDC Arızası Düzeltildi',
    TrailerDisconnected: 'Konteyner Ayrıldı',
    LeftHandFrontParkTailLightFailureReversed: 'Sol Ön Park Lamba Arızası Düzeltildi',
    ImmobilizerFailure: 'Immobilizer Hatası',
    DeviceDisconnected: 'Cihaz Bağlantısı Kesildi',
  },
  taskColumns: {
    name: 'Görev',
    note: 'Not',
    notes: 'Notlar',
    passiveReason: 'Pasif nedeni',
    status: 'Durum',
    driverName: 'Sürücü',
    startDate: 'Başlangıç',
    endDate: 'Bitiş',
    startDistrict: 'Başlangıç İlçe',
    endDistrict: 'Bitiş İlçe',
    createdBy: 'Oluşturan',
    cancelReason: 'İptal Nedeni',
    creationDate: 'Oluşturulma',
    creationTime: 'Oluşturulma',
    acceptanceTime: 'Kabul',
    description: 'Tanım',
    comment: 'Yorum',
    destination: 'Hedef',
  },
  available24: 'Hizmet İçi (0-24 Saat İşlemsiz)',
  available2448: 'Hizmet İçi (24-48 Saat İşlemsiz)',
  available48: 'Hizmet İçi (48+ Saat İşlemsiz)',
};
