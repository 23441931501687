import common from './common.tr';
import dashboard from './dashboard.tr';
import date from './date.tr';
import workgroup from './workgroup.tr';
import groupList from './groupList.tr';
import personnelList from './personnelList.tr';
import onboarding from './onboarding.tr';
import login from './login.tr';
import splash from './splash.tr';
import employee from './employee.tr';
import routeList from './routeList.tr';
import employeeList from './employeeList.tr';
import company from './company.tr';
import carpool from './carpool.tr';
import tickets from './tickets.tr';
import survey from './survey.tr';
import flexiride from './flexiride.tr';
import driver from './driver.tr';
import vehicle from './vehicle.tr';
import notifications from './notifications.tr';
import reports from './reports.tr';
import rideShared from './rideShared.tr';
import rider from './rider.tr';
import modules from './modules.tr';

export default {
  common,
  dashboard,
  date,
  workgroup,
  groupList,
  personnelList,
  onboarding,
  login,
  splash,
  employee,
  routeList,
  employeeList,
  company,
  carpool,
  tickets,
  survey,
  flexiride,
  driver,
  vehicle,
  notifications,
  reports,
  rideShared,
  rider,
  modules,
};
