export default {
  confirmTransfer:
    '<strong>{0}</strong> üzerindeki istekleri diğer uygun araçlara aktarmak istediğinizi onaylayın. Ayrıca rotayı iptal etmeyi seçerseniz, çözülmemiş tüm istekler iptal edilecektir.',
  keepTheRoute: 'Rotayı Koruyun ve İstekleri Transfer Edin',
  cancelTheRoute: 'Rotayı İptal Edin ve İstekleri Transfer Edin',
  requestsTransferred: 'İstekler Transfer Edildi',
  requestTransferred: 'İstek Transfer Edildi',
  requestTransferredText: 'İstek başarıyla uygun araçlara transfer edildi.',
  requestsTransferredText: 'İstekler başarıyla uygun araçlara transfer edildi.',
  transferSummary: 'Transfer Özeti',
  unresolvedText:
    'Optimizasyon, <strong>{1}</strong> isteğin <strong>{0}</strong> tanesini diğer araçlara transfer edebildi. Aşağıdaki istekler trsanfer edilemedi. {2}',
  cancelUnresolved: 'Çözülmemiş İstekleri İptal Et',
  cancelRouteAlert: '<strong>{1}</strong> için <br/><strong>{0}</strong> iptal etmek istediğinizi onaylayın.',
  cancelRouteAlertWithOptions:
    'Rotadaki istekler için bir seçenek seçtikten sonra <br/><strong>{1}</strong> için <strong>{0}</strong> iptal etmek istediğinizi onaylayın.',
  tryTransfering: 'İstekleri diğer uygun araçlara transfer etmeyi deneyin ve çözülmemiş olanları iptal edin',
  cancelAllRequests: 'Tüm istekleri iptal et',
  routeCancelled: 'Rota İptal Edildi',
  routeCancelledText: 'Rota ve üzerindeki istekler iptal edildi.',
  confirmAssignVehicle: '<strong>{1}</strong> yerine <strong>{0}</strong> aracını atamak istediğinizi onaylayın.',
  changeDriver: '<strong>{0}</strong> sürücüsünü <strong>{1}</strong> ile değiştirmek istediğinizden emin misiniz?',
  noOrderOrRequests: {
    title: 'İstekler transfer edilemez.',
    content: 'Hiçbir talep bulunamadı.',
  },
  requestNotBeAccommodated: 'Talep karşılanamadı.',
  reactivateRouteAlert: '<strong> {0} </strong> rotasını aktive etmek istiyor musunuz?',
};
