export default {
  registeredUser: 'Registered Users',
  totalRentals: 'Total Rentals',
  rentalUsers: 'Rental Users',
  corparateRental: 'Corparate Rental',
  registeredCompany: 'Registered Company',
  todayRentals: 'Today Rentals',
  dailyRent: 'Daily Rental',
  customerStates: 'Customer States',
  vehicles: 'Vehicles',
  rentalStatus: 'Rental Status',
  rentalTypes: 'Rental Types',
  instantRentalTypes: 'Instant Rental Types',
  vehicleState: 'Vehicle State',
  instantRentalState: 'Instant Rental State',
  radar: {
    status: {
      RADAR_RESPONSE: 'Successful',
      RADAR_REQUEST: 'Aktive',
      RADAR_EXPIRED: 'Failed',
      RADAR_CANCELED: 'Cancelled',
    },
  },
  addCodeToMessage: 'Add Code To Message',
  sendMessage: 'Send Message',
  activeNeoUser: 'Active Neo User',
  subcriptionFinalAmount: 'Neo Final Amount ',
  rentalType: {
    RESERVED_DAILY: 'Daily Reservation',
    'FREE_FLOAT-DAILY': 'Free Float - Daily',
    'FREE_FLOAT-SELF_SERVICE': 'Free Float - Self Service',
    'FREE_FLOAT-SUBSCRIPTION': 'Free Float - Subscription',
    'FREE_FLOAT-SELF_SERVICE_ROUTE': 'Route',
    'FREE_FLOAT-SLOT_BASED': 'Slot Based',
  },
};
