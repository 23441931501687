export default {
  newRegistration: 'New Registration',
  rentalCounts: 'Rental Counts',
  newContract: 'New Contract',
  firstRental: 'First Rental',
  cancelled: 'Cancelled',
  totalRentals: 'Total Rentals',
  instantTotal: 'Instant Total',
  usageAmount: 'Usage Amount',
  totalKm: 'Total Km',
  avgKm: 'Average Km',
  rentalTimes: 'Rental Times',
  totalHours: 'Total Hours',
  avgHours: 'Average Hours',
  valetTasks: 'Valet Tasks',
  taskCount: 'Task Count',
  rentalIncomes: 'Rental Incomes',
  totalAmount: 'Total Amount',
  avgAmount: 'Average Amount',
  areaSearch: 'Search by Service Area',
  reasonsApproval: 'Approval and Reject reasons',
  rejectStatus: 'Reject Status',
  coupon: 'Coupon',
  definition: 'Definition',
  rate: 'Rate',
  used: 'Used',
  totalDiscounted: 'Total Discounted',
  withoutTotalDiscounted: 'Without Total Discount',
  totalDiscount: 'Total Discount',
  notInServiceArea: 'Not in service area',
  usage: 'Usage',
  current: 'Current',
  totalRevenue: 'Total Renevue',
  valet: 'valet',
  registeredUsers: 'Registered Users',
  userWithContract: 'Customer with Contract',
  activeVehicles: 'Active Vehicles',
  todaysRentals: 'Rentals Today',
  valetStatus: 'Valet Status',
  missingContract: 'Missing Contract',
  rentalUserCount: 'Rental Users',
  todaysRadar: 'Daily Radar Stats',
  currentRentals: 'Current Rentals Status',
  customers: 'Customers',
  rentalCount: 'Rental Counts',
  rentals: 'Rentals',
  selfServiceSearch: 'Self Service Searches (distance)',
  searchCount: 'Search Count',
  avgDistance: 'Avg. Distance',
  parkingStats: 'Parking Stats.',
  firstUse: 'First Use',
  numbers: 'Numbers',
  invites: {
    header: '"Invite Your Friend" Numbers',
    users: 'Users',
    both_used: 'Both used',
    invitee_not_registered: 'Invitee not registered',
    invitee_not_used: 'Invitee not used',
    inviter_not_used: 'Inviter not used',
    totalInvitees: 'Invitees',
  },
  rentalsByZone: 'Rentals by Zone',
  valets: 'Valets',
  passiveCategoriesRatio: 'Passive Categories Ratio (%)',
  activenessRatio: 'Activeness Ratio (%)',
  utilizationChart: 'Utilization (%)',
  currentRatio: 'Current Ratio',
  fromThisHour: 'From this hour',
  activeVehicleRatio: 'Active Vehicle Ratio (%)',
  newCustomerUntilThisHour: 'New Customer Until This Hour',
  signedAgreementUntilThisTime: 'Signed Agreement Until This Hour',
  driverStatus: 'Driver Status',
  rentalStatus: 'Rental Status',
  radarStatus: 'Radar Status',
  userCounts: 'User Counts',
  firstRentalFromThisTime: 'First Rental Until This Hour',
  routeRentalInfo: {
    activeRouteCount: 'Active Route Count',
    searchCount: 'Search Count',
    completedRouteCount: 'Completed Route Count',
    incompleteRouteCount: 'Incomplete Route Count',
  },
  routeRental: 'Route Rental',
  routeRentals: 'Route Rentals',
};
