export default {
  settings: 'Settings',
  generalSettings: 'General Settings',
  smsProvider: 'SMS Provider',
  otpProvider: 'OTP Provider',
  shutdownMessage: 'Shutdown Message',
  showShutdownMessage: 'Show Shutdown Message',
  dontShowShutdownMessage: 'Don\'t show shutdown message',
  showMessage: 'will be displayed',
  dontShowMessage: 'will not be displayed',
  selectEmailList: 'Select Email List',
  changedSmsProvider: 'SMS Provider has been changed',
  notChangedSmsProvider: 'SMS Provider could not change',
  changedOtpProvider: 'OTP SMS Provider has been changed',
  notChangedOtpProvider: 'OTP SMS Provider could not change',
  areYouSureToChange: 'Are you sure you want to make changes ?',
  unsavedChanges: 'There are unsaved changes!',
  rental: {
    isRentalSearchRequestEnabled: 'Rental Search Request Enabled',
    valetSearchForSecondRentalAllowed: 'Valet Search For Second Rental Allowed',
    cancelSelfServiceRentalOnDeviceProblem: 'Cancel Self Service Rental on Device Problem',
    enableAutomaticDebtCollection: 'Enable Automatic Debt Collection',
    workingHours: 'Working Hours',
    workingHoursWeekend: 'Working Hours in Weekend',
  },
  payment: {
    enableProvisionVoid: 'Enable Provision Void',
  },
  proximity: {
    useVehicleInTransferToPark: 'Use Vehicle in Transfer to Park',
  },
  notification: {
    enableSendFirstRentalMail: 'Enable Send First Rental Mail',
    inBlackList: 'Blacklist',
    dailyReport: 'Daily Report',
    outOfServiceEmails: 'Out of Service Emails',
    changedRentalDeliveryJob: 'Changed Rental Delivery Job',
    inconsistentVehicleStatus: 'Inconsistent Vehicle Status',
    dailyDriverPerformanceReport: 'Daily Driver Performance Report',
    collectionNotCreated: 'Collection Not Created',
    contractNotCreated: 'Contract Not Created',
    resetSmsWarningEmails: 'Reset Sms Warning Emails',
    odometerJump: 'Odometer Jump',
    rating: 'Rating',
  },
  sap: {
    notificationEmails: 'SAP Notification Emails',
  },
  androidVersion: 'Android - Force Update Version No',
  iosVersion: 'IOS - Force Update Version No',
  latestAndroidVersion: 'Android - Latest Version No',
  latestIosVersion: 'IOS - Latest Version No',
  notificationMessage: 'Notification Message',
  forceUpdateText: 'Force Update Text',
  filteredScores: 'You can see only maximum 1 months data from the selected start date.',
  newProcessOpen: 'New Process Open',
  newProcessClosed: 'New Process Closed',
  registrationFlowParameters: 'Registration Flow Parameter Management'
};
