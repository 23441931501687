export default {
  surveyQn: {
    selectAll: 'lütfen uygun olanların tümünü seçin',
    selectPrimary: 'Lütfen birincil modu seçin',
    selectSecondary: 'Lütfen ikincil modu seçin',
    skip: 'Atlamak',
    continue: 'Devam et',
    addSecondary: 'İkincil mod ekle',
    removeSecondary: 'İkincil modu kaldır',
  },
  signUp: {
    joinFlexigo: "İşe gidip gelme seçeneklerinizi görmek için flexigo'ya katılın!",
    company: 'Şirket',
    reset: 'Sıfırla',
    notMyCompany: 'Bu şirkette çalışmıyor musunuz?',
    email: 'E-posta',
    atCompany: '@şirket.com',
    emailVerification: 'Bu adrese doğrulama e-postası gönderilecektir.',
    password: 'Parola',
    signUp: 'Üye olmak',
    isRegistered: 'Zaten kayıtlı?',
    login: 'Giriş yapmak',
    continueOnWeb: "Web'de Devam Et",
    learnMore: 'daha fazla bilgi edin',
    aboutFlexigo: 'flexigo, işle ilgili tüm mobilite ihtiyaçlarınız için tek nokta platformudur',
    downloadApp: 'Flexigo Uygulamasını İndirin',
    seeCommuteOptions: 'kaydolmak ve işe gidip gelme seçeneklerinizi görmek için',
    userOpinion: 'Kullanıcılar ne diyor',
    appUser: 'flexigo uygulaması kullanıcısı',
    or: 'veya',
  },
  dashboard: {
    profile: 'Profil',
    homeAddress: 'Ev Adresi',
    changePassword: 'Şifre değiştir',
    signOut: 'Oturumu Kapat',
    aboutFlexigo: 'Flexigo hakkında',
    myCommute: 'benim işe gidişim',
    currentMode: 'MEVCUT MOD',
    driving: 'Sürme',
    carbonEmission: 'Emisyon (CO2)',
    cost: 'Maliyet',
    time: 'Zaman',
    nonSovMode: 'SOV olmayan bir moda geçerek ayda 2 ağaç kurtarabilirsiniz!',
    otherOptions: 'DİĞER SEÇENEKLER',
    monthly: 'AYLIK',
    shuttle: 'Servis',
    realCost: 'Gerçek Maliyet',
    subsidy: 'Sübvansiyon',
    applied: 'Uygulamalı',
    apply: 'Uygula',
    flexiShuttle: "Neden flexiShuttle'ı kullanmalısınız?",
    publicTransport: 'Toplu taşıma',
    markCurrentMode: 'Geçerli mod olarak işaretle',
    publicTransportOptions: 'TOPLU TAŞIMA SEÇENEKLERİ',
    travelDuration: 'Tek Yön Seyahat Süresi',
    firstOption: 'Seçenek 1',
    details: 'Detaylar',
    secondOption: 'Seçenek 2',
    applyForShuttle: 'Shuttle Hizmetine Başvurun',
    verified: 'Doğrulandı',
    changes: 'değişiklikler',
    fullName: 'Ad Soyad',
    workSchedule: 'Çalışma programı',
    weekdays: 'Hafta içi 9am-6pm',
    validateAddress: 'Lütfen adresinizin geçerli olduğundan emin olun.',
    requestReceived: ' Flexishuttle kullanma isteğinizi aldık. Rotanız onaylanır onaylanmaz sizi bilgilendireceğiz.',
    youNeedFlexigoApp:
      'Servisinizi takip etmek ve rezervasyon yapmak için flexigo uygulamasını indirmeniz gerekmektedir.',
    fullFeatureAccess: 'applieduygulanan tam özellik erişimi için',
  },
  homeAddress: {
    setHomeSuccess:
      'Adresiniz başarılı bir şekilde eklenmiştir. Servis kullanmak istiyorsanız ev adresinizin etrafındaki duraklardan birisini seçiniz',
  },
  workSchedule: 'Çalışma Programı',
  preferredArrivalTimes: 'Tercih Edilen Varış Saatleri',
  preferredDepartureTimes: 'Tercih Edilen Kalkış Saatleri',
  onSiteDays: 'Saha Günleri',
  HYBRID: 'Hibrit',
  ON_SITE: 'Sahada',
  REMOTE: 'Uzaktan',
  type: 'Türü',
  riderList: 'Personel Listesi',
  deleteRider: 'Personeli Sil',
  sendNotification: 'Bildirim Gönder',
  sendEmail: 'E-posta Gönder',
};
