export default {
  evaluateTravel: 'Evaluating travel options and optimizing routes for daily commute...',
  forDemo: 'For demo purposes, we wil be generating scenarios for a fictive company location and fictive employee addresses. You may also generate customized scenarios with your own data.',
  fewSeconds: 'It will take a few seconds.',
  didYouKnow: 'Did you know?',
  didYouKnowDescription: 'Using home and campus locations of each employee, flexigo calculates and presents distance, time, cost and emission for:',
  currentModeOfCommute: 'Current mode of commute',
  alternateModesOfCommute: 'Alternate modes of commute',
  bestAlternative: 'Best alternative',
  creatingWorkgroups: 'Creating Route Groups',
  optimizingShuttleRoutes: 'Optimizing shuttle routes',
  assigningVehiclesDrivers: 'Assigning Vehicles & Drivers',
};
