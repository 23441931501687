export default {
  clickForPassive: 'Kampanya Aktif. Pasife almak için tıkla.',
  clickForActive: 'Kampanya Pasif. Aktife almak için tıkla.',
  selectCampaign: 'Kampanya Seç',
  selectFile: 'Dosya Seçin',
  imgFile: 'Resim Dosyası',
  title: 'Başlık',
  order: 'Sıra',
  content: 'İçerik',
  announcementsMobile: 'Mobil Uygulamadaki Duyurular / bildirimler / pushlar\'ın gösterimi ile ilgili olarak kurallar :',
  campaignImage: 'Kampanya Resmi',
  deleteNotice: 'Bu ilan ile ilgili tüm veriler silinecek',
  activeCampaign: 'Kampanya Aktif.',
  passiveCampaign: 'Kampanya Pasif',
  mobileAppRules: 'Mobil uygulama metin gösterim kuralları',
  selectDocumentExamine: 'İncelemek için bir döküman seçin',
  documentApproved: 'Döküman Onaylandı',
  announcement: {
    type: {
      CAMPAIGN: 'Kampanya',
      ANNOUNCEMENT: 'Duyuru',
    },
  },
  accountIdBound: 'Hesaba Bağlı',
  customerCount: 'Müşteri Sayısı',
  campaignBound: 'Kampanyaya Bağlı',
  idDependencyChanged: 'Id bağımlılık durumu değiştirildi.',
  campaignDependencyChanged: 'Kampanya bağımlılık durumu değiştirildi',
};
