export default {
  allVehicles: 'Tüm Araçlar',
  notUsedOneDay: 'Son 24 saatte dokunulmayan araçlar',
  notUsedTwoDay: 'Son 48 saatte dokunulmayan araçlar',
  downloadSearchData: 'Arama Bilgilerini İndir', //
  operation: 'Operasyon',
  battery: 'Akü',
  avgWaiting: 'Ortalama Bekleme',
  chassisNo: 'Şase No',
  modal: 'Model',
  transmissionType: 'Vites',
  maintenanceStatus: 'Bakım Durumu',
  serviceArea: 'Hizmet Bölgesi',
  lastLocation: 'Son Konum',
  lastRentingStart: 'Son Kiralama Başlangıç',
  lastRentingEnd: 'Son Kiralama Bitiş',
  latestStatusUpdate: 'Son Durum Güncellemesi',
  lastAvailableDate: 'Son Aktife Çekilme',
  lastOutOfServiceTime: 'Son Servis Dışı Süre',
  lastMaintenanceDate: 'Son Bakım',
  nextMaintenanceDate: 'Sonraki Bakım Km',
  statusChangedBy: 'Değişikliği Yapan',
  lastValetName: 'Görevli',
  lastValetRouteActualEnd: 'Son Görev Bitişi',
  lastValetRouteNote: 'Görev Notu',
  activePassive: 'Aktif / Pasif',
  outOfServiceCategory: 'Pasif Kategori',
  outOfServiceReason: 'Pasif Nedeni',
  radius: 'Yarıçap (m)',
  maxDate: '(Maksimum 1000 m seçilebilir)',
  maxHours: '(Maksimum 24 saat aralığı seçilebilir)',
  searchingAddress: 'Adres Arama',
  mSoftware: 'M. Yazılım',
  firstLogin: 'İlk Giriş',
  lastLogout: 'Son Çıkış',
  task: 'Görev',
  current: 'Anlık Durum',
  changeStatus: 'Topluca Statü Değiştir',
  changeVehicleStatusAll: 'Topluca Araç Statü Değiştir',
  requestedStatusChange: 'Statü Değişikliği İstenen Araç Adeti',
  changedVehicleStatus: 'Statüsü başarıyla değişen araçlar',
  notChangedVehicleStatus: 'Statüsü değişmeyen araçlar',
  assignedZone: 'Atandığı Bölge',
  vehicleFrontPhoto: 'Aracın Son Fotoğrafı (Ön)',
  vehicleBackPhoto: 'Aracın Son Fotoğrafı (Arka)',
  vehicleLeftPhoto: 'Aracın Son Fotoğrafı (Sol)',
  vehicleRightPhoto: 'Aracın Son Fotoğrafı (Sağ)',
  vehicleInnerFront: 'Aracın Son Fotoğrafı (İç-Ön)',
  vehicleInnerBack: 'Aracın Son Fotoğrafı (İç-Arka)',
  working: 'Çalışıyor',
  notWorking: 'Çalışmıyor',
  expectedToAcceptJob: 'Görevi Kabul etmesi bekleniyor',
  lastRentalsOfVehicle: 'Aracın son {0} kiralaması',
  photos: 'Fotoğraflar',
  minute: 'Dakika',
  customerDriver: 'Müşteri/Sürücü',
  incidentsOfVehicle: 'Araca ait vakalar',
  closeDoors: 'Kapıları Kapat',
  openDoors: 'Kapıları Aç',
  resetSms: 'Reset Sms',
  echoSms: 'Echo Sms',
  assignJob: 'Görev Ata',
  lastUpdate: 'Son Güncelleme',
  lastJobsOfVehicle: 'Aracın Son Görevleri',
  cancelReason: 'İptal Nedeni',
  eventDate: 'Olay Tarihçesi',
  resetCommandSent: 'SMS ile resetleme komutu gönderildi.',
  commandNotSend: 'Komut iletilemedi.',
  echoCommandSent: 'SMS ile echo komutu gönderildi.',
  approval: 'Onay?',
  repairedDamageApproval: 'Bütün hasarlar onarıldı olarak işaretlenecek. Onaylıyor musunuz?',
  markedAsRepaired: 'Araca ait bütün hasarlar onarıldı olarak işaretlendi',
  vehicleDamageRepaired: 'Araca ait hasar onarıldı olarak işaretlendi',
  noteAdded: 'Not Eklendi',
  noteDeleted: 'Not Silindi',
  definitionUpdated: 'Tanım Güncellendi',
  definitionNotUpdated: 'Tanım Güncellenemedi',
  bodyType: 'Gövde',
  fuelType: 'Yakıt Türü',
  availableVehicle: 'Uygun Araç',
  outOfService: 'Hizmet Dışı',
  totalVehicle: 'Toplam Araç',
  catalogPicture: 'Katalog Resmi',
  selectCatalogWantToChange: 'Araç Kataloğunda fiyat değişikliği yapmak istediğiniz araç kataloğunu seçiniz.',
  hourlyCost: 'Saatlik Ücret',
  kmPrice: 'KM Ücreti',
  dailyRentTreshold: 'Günlük Kiralama Eşiği',
  dailyCost: 'Günlük Ücret',
  clickChangeStatus: 'Durum değiştirmek için tıklayınız',
  saveFiles: 'Dosyaları Kaydet',
  filesCouldntUpdate: 'Dosyalar Yüklenemedi',
  packageThatAdded: 'Eklemek istenilen paket',
  packageConfirm: '{0} şirketine {1} paketi eklenecek. Onaylıyor musunuz ?',
  packageName: 'Paket Adı',
  imageFile: 'Resim Dosyası',
  totalUploaded: 'Toplam Yüklenecek',
  rejectionReason: 'Red Nedeni',
  selectRejectionReason: 'Bir red nedeni seçin',
  selectReasonForRejection: 'Reddetmek için bir neden seçin',
  uploadingExcel: 'Excel Yükleme',
  vehicleInformationUpdated: 'Araç Bilgileri Güncellendi.',
  vehicleStatusCouldntChange: 'Aracın Durumu Değiştirilemedi.',
  changeVehicleStatus: 'Araç Durumunu Değiştir',
  selectWantToChange: 'Değiştirmek İstediğiniz statüyü seçiniz!',
  subReason: 'Alt Sebep',
  vehicleStatusChange: 'Aracın Durumu Değiştirildi.',
  vehicleStatus: 'Araç Durumu',
  drivers: {
    availability: {
      null: 'Bilinmiyor',
      AVAILABLE: 'Müsait',
      WORKING: 'Çalışıyor',
      NOT_WORKING: 'Çalışmıyor',
      ON_BREAK: 'Molada',
      PENDING_TASK_ACCEPTANCE: 'Kabul Bekleniyor',
    },
  },
  commandHistory: 'Komut Tarihçesi',
  command: 'Komut',
  noDataDateRange: 'Bu tarih aralığında bir veri yok.',
  firstAvailableDate: 'İlk Aktif',
  firstDeliveryDate: 'İlk Teslim',
  outOfServiceSubReason: 'Pasif Alt Nedeni',
  outOfServiceDescription: 'Pasif Açıklama',
  updateVehiclesCollectively: 'Toplu Araç Durumu Güncelleme',
  idleTimeInHour: 'İşlemsiz Geçen Süre',
  jobStep: 'Görev Adımı',
  idleHours: 'Dokunulmayan Araç',
  downtime: 'İşlemsiz Geçen Süre',
  downtime24_48: '24-48 Saattir İşlemsiz',
  downtime48: '48+ Saattir İşlemsiz',
  charge: 'Şarj Durumu',
  charging: 'Şarj Oluyor',
  numberOfAssignedTasks: 'Atanan Görev Sayısı',
  damage: 'Hasarlar',
  repaired: 'Onarılmış',
  markRepairedAllDamage: 'Tüm hasarları onarıldı olarak işaretle',
  markRepaired: 'Onarıldı olarak işaretle',
  photo: 'Foto',
  noDamage: 'Araçta herhangi bir hasar bulunmuyor',
  catalogPictureUpdated: 'katalog resmi güncellendi.',
  getDrivers: 'Getir {0} (vale)',
  appAbr: 'Uyg.',
  workingAbr: 'Çl.',
  notWorkingAbr: 'Çlş.',
  expectedToAcceptJobAbr: 'Bek.',
  valuesUpdated: 'Değerler Güncellendi',
  activePassiveChanged: 'Aktif / Pasif değişikliği yapıldı',
  selectApplication: 'Uygulama Seç',
  selectCategory: 'Kategori Seç',
  synchVehiclestoMiles: 'Miles ile senkronize et',
  routeStatus: {
    NOT_STARTED: 'Başlamadı',
    ACTIVE: 'Aktif',
    COMPLETED: 'Tamamlandı',
    DRAFT: 'Taslak',
    CANCELLED: 'İptal',
    NOT_CREATED: 'Oluşturulmadı',
  },
  deliveryLocation: 'Hedef Lokasyon',
  finishTask: 'Görevi Tamamla',
  bookingWillDeleted: 'Bu rezervasyon silinecek.',
  jobWillComplete: 'Bu görev tamamlanacak.',
  bookingDeleted: 'Rezervasyon silindi',
  taskCompleted: 'Görev Tamamlandı',
  withdrawMoneyFromCard: '{0} adlı müşterinin {1} kartından para çekimi',
  vehiclesInService: 'Servisteki Araçlar',
  vehiclesInStation: 'Şarj İstasyonundaki Araçlar',
  noSuitableConbination: 'Uygun vale araç kombinasyonu bulunamadı',
  customer: 'MÜŞTERİ',
  valet: 'VALE',
  taskTypes: {
    valet: 'Vale',
    pool: 'Havuz',
    inService: 'Servis',
  },
  logoCondition: 'Logo Durumu',
  vehicleColor: 'Araç Rengi',
  showDamages: 'Hasarları Göster',
  hideDamages: 'Hasarları Gizle',
  outOfServiceDate: 'Pasife Alınma Zamanı',
  lastConnectionDate: 'Son Bağlantı Zamanı',
  lastDoorStatus: 'Kapı Durumu',
  totalCommandCount: 'Komut Gönderme Sayısı',
  lastSpeedAndUpdateDate: 'Aracın Son Hız Değeri ve Son Hız Bilgisinin Alınma Zamanı',
  isControlInProgress: 'Kontrol Devam Ediyor mu ?',
  recordCommandSentDates: 'Record Komutu',
  echoCommandSentDates: 'Echo Komutu ',
  resetCommandSentDates: 'Reset Komutu',
  doorCloseCommandSentDates: 'Kapı Komutu',
  fuelBlockageCommandSentDate: 'Yakıt Kesme Komutu',
  hoodOpenedAlarmDates: 'Kaput Açıldı Alarmı ',
  deviceAlarmDates: 'Cihaz Bağlantısı Kesildi Alarmı',
  securityAlarmDates: 'Güvenlik Alarmı',
  crashAlarmDates: 'Kaza Oldu Alarmı',
  securityAlarmCount: 'Gönderilen Güvenlik Alarm Sayısı',
  commandType: 'Komut Tipi',
  alarmType: 'Alarm Tipi',
  secondDeviceCommandSentDate: 'İkinci Cihaz Kesme Komutu',
  gsmAccountProvider: 'Gsm Operatörü',
  gsmAccountIdentity: 'Gsm Numarası',
  hardwareType: 'Donanım Tipi',
  deviceIdentity: 'Cihaz No',
  telematicServer: 'Telematik Sunucusu',
  showDeletedVehicles: 'Silinmiş Araçları Göster',
  dontShowDeletedVehicles: 'Silinmiş Araçları Gösterme',
};
