import Repository from '@/common/services/repository';
// import { reservation } from '@/endpoints';

const client = new Repository();
const endpoint = Repository.getEndpoint;

export default {
  async getLocationSettings() {
    const url = endpoint('planner.corporate.settings.locations');
    const r = await client.get(url);
    return r;
  },

  async saveLocationSettings(payload) {
    const url = endpoint('planner.corporate.settings.locations');
    const r = await client.post(url, payload);
    return r;
  },

  async getLoadDimensions() {
    const url = endpoint('planner.corporate.settings.loadDimensions');
    const r = await client.get(url);
    return r;
  },

  async saveLoadDimensions(payload) {
    const url = endpoint('planner.corporate.settings.loadDimensions');
    const r = await client.post(url, payload);
    return r;
  },

  async getVehicleFeatures() {
    const url = endpoint('planner.corporate.settings.vehicleFeatures');
    const r = await client.get(url);
    return r;
  },

  async saveVehicleFeatures(payload) {
    const url = endpoint('planner.corporate.settings.vehicleFeatures');
    const r = await client.post(url, payload);
    return r;
  },

  async getSkills() {
    const url = endpoint('planner.corporate.settings.driverSkills');
    const r = await client.get(url);
    return r;
  },

  async saveSkills(payload) {
    const url = endpoint('planner.corporate.settings.driverSkills');
    const r = await client.post(url, payload);
    return r;
  },

  async getApplicationSettings() {
    const url = endpoint('planner.corporate.settings.application');
    const r = await client.get(url);
    return r;
  },
};
