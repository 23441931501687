export default {
  alarmRules: 'Alarm Kuralları',
  generatedAlarms: 'Oluşan Alarmlar',
  creationTime: 'Oluşma Zamanı',
  ruleId: 'Kural ID',
  ruleName: 'Kural Adı',
  rentalName: 'Kiralama Adı',
  categoryName: 'Kategori İsmi',
  channel: 'Kanal',
  departments: 'İlgili Birimler',
  rentalId: 'Kiralama ID',
  accountId: 'Hesap ID',
  nameSurname: 'Ad Soyad',
  completedRentals: 'Tamamlanan Kiralama',
  instantLocation: 'Anlık Lokasyon(il)',
  subject: 'Konu',
  toList: 'Alıcılar',
  active: 'Aktif ?',
  addDepartment: 'Birim Ekle',
  addToList: 'Alıcı Ekle',
  sendNotification: 'Mail Gönderim',
  apiCall: 'Api Call',
  topic: 'Api Konu',
  additionalInfo: 'Ek Bilgi',
};
