export const tr = {
  prices: 'Fiyatlar',
  packages: 'Paketler',
  list: 'Ücret Tarifesi',
  action: 'Tarife değişikliğini kaydet',
  effect: 'Bu ücret tarifesi {group} araç grubu ve bu gruplarda bulunan {count} aracı etkiliyor',
  newVal: 'Yeni Değer',
  oldVal: 'Eski Değer',
  change: 'Değişim',
  modified: '{amount} değer değiştirildi',
  hourlyCostBeforeDiscount: 'İndirimsiz Saatlik Ücret (₺)',
  hourlyCost: 'İndirimli Saatlik Ücret (₺)',
  kmExceededCost: 'KM Ücreti (₺)',
  convertToDailyThreshold: 'Günlük Kiralama Eşiği (saat)',
  provisionCost: 'Provizyon',
  kmPerRentalLimit: 'Kiralama/KM Limit',
  nightlyCost: 'Gece Sabit',
  pointRatio: 'Puan kazanım oranı - Bireysel',
  corporatePointRatio: 'Puan kazanım oranı - Kurumsal',
};

export const en = {
  prices: 'Prices',
  packages: 'Packages',
  list: 'Price List',
  action: 'Save Price Changes',
  effect: 'This pricing affects {group} vehicle groups and {count} vehicle(s) inside those groups',
  newVal: 'New Value',
  oldVal: 'Old Value',
  change: 'Change',
  modified: '{amount} item(s) modified',
  hourlyCostBeforeDiscount: 'Hourly Cost Before Discount',
  hourlyCost: 'Discounted Hourly Price',
  kmExceededCost: 'KM Price',
  convertToDailyThreshold: 'Daily Rent Treshold',
  provisionCost: 'Provision',
  kmPerRentalLimit: 'Rental/KM Limit',
  nightlyCost: 'Fixed Nightly',
  pointRatio: 'Points earning rate - Individual',
  corporatePointRatio: 'Points earning rate - Corparate',
};
