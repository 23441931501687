export default {
  blackList: 'Black List',
  updateDrivingLicence: 'Update Driving Licence',
  updateGender: 'Update Gender',
  updateSelfie: 'Update Selfie',
  customerInBlackList: 'Customer in Black List',
  reason: 'Reason',
  balanceWithDownPayment: 'Balance with Down Payment',
  bonusBalance: 'Bonus Balance',
  tiktakPersonnel: 'Tiktak Personnel',
  totalCrash: 'Total Crash',
  customerNegativeBalance: 'Customer with Negative Balance',
  debitBalance: 'Debit Balance',
  yourApproval: 'Your approval is awaited',
  assignmentWaiting: 'Assignment has been waiting',
  trackingStatus: 'Tracking Status',
  notInTracking: 'Not in Tracking',
  unfollow: 'Unfollow',
  followUp: 'Follow up / Unfollow',
  exitPermit: 'Power User',
  emailVerified: 'Email Verified',
  emailNotVerified: 'Email not Verified',
  corporateMembershipDate: 'Corporate Membership Date',
  verifiedPhone: 'Verified Phone',
  notVerifiedPhone: 'Not Verified Phone',
  mobileVerification: 'Mobile Confirm Code Verification',
  passportNo: 'Passport No',
  editCustomer: 'Edit Customer',
  dateOfEntry: 'Date of entry into the country',
  isBirtday: 'Today is a birtday this customer',
  linkedin: 'linkedin',
  softwareVersion: 'Software Version',
  passiveReason: 'Passive Reason',
  rental: 'rental',
  searchPlaceholder: 'Enter search criteria',
  customerConcluded: 'Customer concluded an aggreement.',
  checkedDriverLicense: 'I checked driver license',
  documentNo: 'Document No',
  placeOfIssue: 'Place of Issue',
  issueDate: 'Issue Date',
  dateOfValidity: 'Date of Validity',
  registeredCards: 'Registered Cards',
  card: 'Card',
  receivePayment: 'Receive Payment',
  makePayment: 'Make Payment',
  maxAmount: 'Maximum amount that can be withdrawn is',
  notWithdrawn: 'Money could not withdrawn',
  moneyWithdrawn: 'Money has been withdrawn',
  addMonetary: 'Add Monetary Transactions',
  lastPhotoVehicleFront: 'Last photo of vehicle (front)',
  lastPhotoVehicleBack: 'Last photo of vehicle (back)',
  lastPhotoVehicleLeft: 'Last photo of vehicle (left)',
  lastPhotoVehicleRight: 'Last photo of vehicle (right)',
  citizenNo: 'Citizen No',
  addReason: 'Add Reason',
  addToBlackList: 'Add to Black List',
  partialRefund: 'Partial Refund',
  fullRefund: 'Full Refund',
  refundAmount: 'Refund Amount',
  maxAmountRefund: 'The maximum amount that can be refunded is',
  selectRefundReason: 'Select Refund Reason',
  reverseRefund: 'Is the system automatically create reverse record for refund ?',
  platform: 'Platform',
  createdBy: 'Created by',
  entryLocation: 'Entry Location',
  entryDate: 'Entry Date',
  exitLocation: 'Exit Location',
  exitDate: 'Exit Date',
  deleteSelectedCustomer: 'Are you sure to delete selected customer from black list ?',
  updatedDrivingLicence: 'Driving Licence Updated',
  deleteCustomer: 'Delete Customer',
  membershipCancelled: 'Membership cancelled.',
  customerDeleted: 'Customer Deleted.',
  photograph: 'Photograph',
  requestDate: 'Request Date',
  rentalReason: 'Rental Reason',
  overallScore: 'Score (Overall)',
  cleanliness: 'Score (Cleanliness)',
  avgScore: 'Score (Average)',
  rentalId: 'Rental Id',
  transaction: 'Transaction',
  cardName: 'Card Name',
  refund: 'Refund',
  amountRefund: 'Amount(Refund)',
  explanationIncıdent: 'Explanation of Incıdent',
  dateHour: 'Date/Hour',
  legalFollowup: 'Legal Follow Up',
  notes: 'Notes',
  noteWillDelete: 'This note will be delete',
  changingGsm: 'Changing Gsm and Verify',
  sendOtp: 'Send OTP',
  verifyPassword: 'Please verify password which taken from user',
  sendPasswordAgain: 'Send Password Again',
  completed: 'completed',
  activeRental: 'active rental',
  totalAfterDiscount: 'Total after discount',
  membershipStatus: 'Membership Status',
  delete: 'Delete',
  deleted: 'Deleted',
  confirm: 'Are you sure?',
  confirmCancel: 'Are you sure you want to cancel the membership?',
  tiktakEmployee: 'This person is Tiktak Employee',
  customerUpdated: 'Customer has been updated',
  pleaseSelectRecord: 'Please Select a Record',
  off: 'Off',
  activateReason: 'Activate Reason',
  membershipDocuments: 'Membership Documents',
  change: 'Change',
  customerStatus: {
    legalFollowup: 'Legal Follow up',
    deleted: 'Deleted',
    active: 'Active',
    rejected: 'Rejected',
    passive: 'Passive',
    pending: 'Pending',
    new: 'New',
    reevaluate: 'Reevaluate',
    registered: 'Registry created',
  },
  rentals: 'Rentals',
  payments: 'Payments',
  documents: 'Documents',
  creditCards: 'Credit Cards',
  transactions: 'Transactions',
  transactionHistory: 'Transaction History',
  hgs: 'HGS',
  codes: 'Codes',
  messageManagementSystem: 'IYS', // :LTODO
  incidents: 'Incidents',
  stationPermissions: 'Station Permissions',
  devices: 'Devices',
  addresses: 'Addresses',
  stationPermission: 'Station Permission',
  brandModel: 'Brand - Model',
  mobileId: 'Mobil Id',
  loginDate: 'Login Date',
  deviceApproval: 'Device Approval',
  addressNotUpdated: 'Address has not been updated',
  cleanlinessScore: 'Score ( Cleanliness )',
  workflow: {
    documentTypes: {
      DRIVING_LICENSE: 'Driving License',
      IDENTITY: 'Identity',
      PASSPORT: 'Passport',
      DIGITAL_AGREEMENT: 'Digital Agreement',
      SIGNED_AGREEMENT: 'Signed Agreement',
      SELFIE: 'Selfi',
      ISPARK_INVOICE: 'Ispark Invoice',
      SUBSCRIPTION_AGREEMENT: 'Subscription Agreement',
    },
    documentStatus: {
      ACTIVE: 'Active',
      REJECTED: 'Rejected',
      PENDING_APPROVAL: 'Pending Approval',
      REPLACED: 'Replaced',
    },
    poolTypes: {
      myTasks: 'My Tasks',
      customerDocumentApprove: 'Customer Document Approve',
      vehicleCleaningControl: 'Vehicle Cleaning Control',
    },
    ocrFields: {
      IDENTITY_NUMBER: 'Identity Number',
      NAME: 'Name',
      SURNAME: 'Surname',
      DRIVING_LICENSE_NO: 'Driving License No',
      DRIVING_LICENSE_ISSUE_DATE: 'Driving Licence Issue Date',
      DRIVING_LICENSE_VALID_UNTIL: 'Driving Licence Valid Until',
      DRIVING_LICENSE_CLASS: 'Driving Licence Class',
    },
  },
  current: 'Current',
  onTask: 'Task on {task}',
  createdDate: 'Creation Date: {date}',
  activatedDate: 'Activated Date: {date}',
  approvedBy: 'This document {documentType} approved by {approvedBy} on date {approvedDate}',
  userInBlacklist: 'User is {passive} or {blacklist}. User must be active to sign contract.',
  inBlacklist: 'in blacklist.',
  missing: 'missing',
  missingDrivingLicence: 'It cannot be approved because the driver\'s license information is {missing}.Make sure that the customer has entered the driver\'s license information completely.',
  showNegativeBalanceCustomer: 'Show customer(s) who has negative balance',
  stationPermissionChanged: 'Station Permission has been changed',
  ocrInformations: 'Ocr Informations',
  undoApproval: 'Undo Approval',
  tabs: {
    rentals: '#rentals',
    payments: '#payments',
    documents: '#documents',
    creditCards: '#credit-cards',
    transactions: '#transactions',
    transactionsHistory: '#transaction-history',
    hgs: '#hgs',
    codes: '#codes',
    iys: '#iys',
    incidents: '#incidents',
    stationPermissions: '#station-permissions',
    devices: '#devices',
    addresses: '#addresses',
    subscription: '#subscription',
    instantOffer: '#instant-offer',
  },
  sendToApprove: 'Send to Approve',
  deleteImmediately: 'Delete Immediately',
  confirmDeleteImmediately: 'Membership will have deleted. Are you sure ? ',
  membershipWillHaveDeleted: 'Membership will have deleted with this action. Are you sure to take this action ? This action can not take back.',
  redirectToManuel: 'Redirect membership application to manuel',
  additionalDocuments: 'Additional Documents',
  sgkDocument: 'Social Security Institution Documents',
  studentDocument: 'Student Documents',
  demographicScore: 'Demographic Score',
  rentalBehaviorScore: 'Rental Behavior Score',
  customerCount: 'Customer Count',
  demographicScoreAvg: 'Demographic Score Average',
  rentalBehaviorCount: 'Rental Behavior Count',
  rentalBehaviorScoreAvg: 'Rental Behavior Score Average',
  damageCostScore: 'Damage Cost Score',
  rfmClass: 'Rfm Class',
  noRental: 'No Rental',
  notLoggedIn: 'Not Logged in',
  securityMeasures: 'Security Measures',
  hasLockDueToLocationChange: 'Has lock due to location change',
  removeLock: 'Remove Lock',
  hasFuelBlockageSupport: 'Has fuel blockage support ?',
  setFuelBlockage: 'Set Fuel Blockage',
  unsetFuelBlockage: 'Unset Fuel Blockage',
  confirmRemoveLock: 'Are you sure you want to remove lock ?',
  deletedCustomerTransactions: 'Deleted Customer Transactions',
  deviceCreationTime: 'Device Creation Time',
  customerLogoutConfirm: 'This session will end. Are you sure ?',
  qualificationMarkType: 'Qualification Mark Type',
  membershipHistory: 'Membership History',
  approved: 'Approved',
  failedToVerify: 'Failed to verify',
  approvalStatus: 'Approval Status',
  citizenIdVerified: 'Citizen Id verified',
  citizenIdNotVerified: 'Citizen Id not verified',
  registerFlow: 'Register Flow',
  dailyRentalExtend: 'Daily Rental Extend',
  dailyKmPackage: 'Daily Km Package',
};
