export default {
  driverDeleted: 'Driver Deleted',
  category: 'Category',
  EHLIYET: 'Driver Licence',
  SABIKA_KAYDI: 'Criminal Record',
  SRC_BELGESI: ' Driver Docs.',
  PSIKOTEKNIK: 'Psychotechnical',
  IKAMETGAH: 'Residence',
  ARAC_FOTOGRAFI: 'Vehicle Photo',
  TRAFIK_SIGORTASI: 'Traffic Insurance',
  YOL_BELGESI: 'Traffic Document',
  RUHSAT: 'License',
  ISG: 'ISG Document',
  dragAndBrowse: 'Drag file to upload or <span class="text-primary">browse</span>',
  driversLicence: "Driver's Licence",
  uploadNewDocument: 'Upload New Document',
  totalDistance: 'Total Distance ({0})',
  driverAdded: 'Driver Added.',
  driverUpdated: 'Driver Updated.',
  documentLoaded: 'Document Loaded.',
  documentNotFound: 'Document not found.',
  pendingApproval: 'Pending Approval',
  addDocument: 'Add Document',
  showDocument: 'Show Document',
  driversNotFound: "Searched driver's not found.",
  shuttleProvider: 'Shuttle  Provider',
  driverChangeReasons: {
    ON_LEAVE: 'On Leave',
    SICK: 'Sick',
    OTHER: 'Other',
  },
};
