export default {
  withoutDepartment: 'Without Department',
  allDepartments: 'All Departments',
  selectDepartment: 'Select Department',
  allGroups: 'All Groups',
  selectGroup: 'Select Group',
  uploadPersonnelWithExcel: 'Upload with Excel',
  personalFormInfo: 'To add employees to the group you can upload the information with excel, filter the employee list or search employees by name and surname.',
  or: 'or',
  filterPersonnelList: 'Please select to filter the riders list.',
  campus: 'Campus',
  departments: 'Departments',
  group: 'Group',
  searchPersonnel: 'Add personnel by search their name and surname.',
  personnel: 'Employees',
  addAll: 'Add All',
  showList: 'Show List',
  addLater: 'Add Later',
};
