export default {
  cancelReason: 'Cancel Reason',
  type: 'Type',
  acceptancePeriod: 'Acceptance Period (min)',
  actualStart: 'Actual Start',
  actualEnd: 'Actual End',
  createdTime: 'Created Time',
  doorOpenTime: 'Door Open Time',
  vehicleStartAddress: 'Vehicle Start Address',
  vehicleStartDistrict: 'Vehicle Start District',
  vehicleStartCity: 'Vehicle Start City',
  vehicleEndAddress: 'Vehicle End Address',
  vehicleEndDistrict: 'Vehicle End District',
  vehicleEndCity: 'Vehicle End City',
  jobStep: 'Job Step',
  destination: 'Destination',
  jobTasks: {
    VEHICLE_DELIVERY: 'Delivery',
    VEHICLE_DELIVERY_FIRST: '1st Delivery',
    VEHICLE_PICKUP: 'To Parking Lot',
    VEHICLE_WASH: 'Wash',
    VEHICLE_TRANSFER: 'Transfer',
    VEHICLE_INTERVENTION: 'Intervention',
    selectOnMap: 'Select on the map',
    mapDesc: 'Select by dragging the map to the desired location',
    selectDriverText: 'Select a driver for vehicle ({plate}) for the task "{taskName}".',
  },
  assignJobDone: 'Assign Job has been done',
  maintenanceStatus: 'Maintenance Status',
  maintenanceStatusAdded: 'Mainteanance Status has been added',
  vehicleLatestAddress: 'Vehicle Latest Address',
  vehicleLatestDistrict: 'Vehicle Latest District',
  vehicleLatestCity: 'Vehicle Latest City',
  orderCreatedBy: 'Order Created By',
  destinationCity: 'Destination City',
  destinationContinent: 'Destination Continent',
  vehicleLatestContinent: 'Vehicle Latest Continent',
  jobs: {
    COMPLETED: 'Completed',
    NOT_STARTED: 'Not Started',
    ACTIVE: 'Active',
    CANCELLED: 'Cancelled',
    DIRECT: 'Direct',
    BY_DISTANCE: 'By Distance',
  },
};
