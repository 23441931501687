import ENDPOINTS from '@/endpoints';
import HttpClient from './client';

export default class {
  static async login(data, application = undefined) {
    const client = HttpClient();
    const payload = data;
    payload.application = application;

    const response = await client.post(ENDPOINTS.umng.flow, payload);
    return response.data;
  }

  static async logout() {
    const client = new HttpClient();
    return client.get(ENDPOINTS.umng.logout);
  }

  static async getMenu(lang = undefined) {
    const client = HttpClient();
    const response = await client.get(ENDPOINTS.umng.mainMenu, { params: { lang } });
    return response.data;
  }

  // checks the validity of a given token, use type = reset to check reset tokens
  static async tokenInfo(token, type = null) {
    const client = HttpClient();

    if (type === 'reset') {
      const url = `${ENDPOINTS.umng.resetTokenInfo}/${token}`;
      const response = await client.get(url);
      return response.data;
    }

    const response = await client.post(ENDPOINTS.umng.tokenInfo, { token });
    return response.data;
  }

  static async changePassword(payload) {
    const client = HttpClient();

    const response = await client.post(ENDPOINTS.umng.changePassword, payload);
    return response;
  }

  static canViewScreen(permission, permissionList) {
    if (typeof permission === 'boolean' && permission) return true;
    const found = permissionList.find((el) => el === permission);
    if (found) return found;
    return false;
  }

  static getLoginPayload(loginData, application) {
    let actionType;

    const payload = {
      providerType: 'USER_PASSWORD',
      action: loginData.action ? loginData.action : 'AUTH',
    };

    if (application) {
      payload.application = application;
    } else {
      payload.scope = 'VEKTOR_PORTAL';
    }

    if (payload.action.indexOf('OTP') > -1) {
      actionType = 'OTP';
    } else if (payload.action.indexOf('EULA') > -1) {
      actionType = 'EULA';
    } else {
      actionType = payload.action;
    }

    switch (actionType) {
      case 'AUTH':
        payload.username = loginData.username;
        payload.password = loginData.password;
        payload.action = 'AUTH';
        break;
      case 'OTP':
        payload.action = loginData.action.replace('OK', 'AUTH');
        payload.otp = loginData.otp;
        payload.otpToken = loginData.otpToken;
        break;
      case 'EULA':
        payload.action = loginData.action.replace('OK', 'AUTH');
        payload.eula = loginData.eula;
        payload.eulaToken = loginData.eulaToken;
        payload.dataUsagePermission = loginData.dataUsagePermission;
        break;
      case 'RESEND_OTP':
        payload.action = 'RESEND_OTP';
        payload.otpToken = loginData.otpToken;
        break;
      default:
        return undefined;
    }

    return payload;
  }
}
