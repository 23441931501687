export default {
  available: 'Hizmet İçi Araç',
  rentable: 'Kiralanabilir Araç',
  daily: 'Günde',
  user: 'Kullanıcı',
  minute: 'Dakika',
  price: 'Fiyat',
  general: {
    title: 'Genel',
    minDays: 'Minumum Gün',
    maxDays: 'Maksimum Gün',
    dayCount: 'Gün Sayısı',
  },
  avgs: {
    title: 'Ortalamalar',
    vehicleCount: 'Araç Sayısı',
    waitTime: 'Ortalama Aracın Kiralama Bekleme Süresi',
  },
  totals: {
    title: 'Toplamlar',
    start: 'Kiralama Başlangıç',
    end: 'Kiralama Bitiş',
    users: 'Kiralama Yapan Kullanıcılar',
    income: 'Gelir',
  },
  selfService: {
    title: 'Kiralamalar (Self Servis)',
    searching: 'Arama Yapan Kullanıcılar',
    success: 'Başarılı Aramalar',
    inRegion: 'Bölgedeki Kullanıcı Sayısı',
    all: 'Bütün Aramalar',
  },
  assignJob: 'Görev Atama',
  streetView: 'Sokak Görünümü',
  vehicleFeatures: 'Araç Özellikleri',
  breakdown: 'Arıza Bildirimleri',
  searchCategories: {
    AVAILABLE_VEHICLE_NOT_FOUND: 'Uygun Araç Bulunamadı',
    NOT_IN_WORKING_HOURS: 'Çalışma Saatleri Dışında',
    SUCCESS: 'Başarılı',
    RENTAL: 'Kiralama',
    RENTAL_END: 'Kiralama Bitiş',
    RENTAL_SELFSERVICE: 'Self Servis Kiralama',
    NO_VALET_SERVICE: 'Vale Servisi Yok',
    NOT_IN_SERVICE_AREA: 'Servis Alanı Dışında',
    SEARCH_MAX_REQUEST: 'Maksimum istek',
    SUCCESS_SELFSERVICE: 'Başarılı (Self Servis)',
    FAIL_SELFSERVICE: 'Self Servis Hata',
    RADAR_RESPONSE: 'Radar Tamamlanan',
    RADAR_REQUEST: 'Radar Aktif',
    RADAR_EXPIRED: 'Radar Başarısız',
    RADAR_CANCELED: 'Radar İptal',
  },
  selectTimePeriod: 'Zaman Dilimi Seçiniz',
  selectCriterion: 'Kriter Seçiniz',
  rentalCount: 'Kiralama Sayısı',
  income: 'Gelir',
  incomeCount: 'Gelir Sayısı',
  showJobs: 'Görevleri Göster',
  morning: 'Sabah(7-10)',
  noon: 'Öğlen(10-13)',
  afternoon: 'Öğleden Sonra(13-17)',
  evening: 'Akşam(17-23)',
  night: 'Gece(23-02)',
  midnight: 'Gece Yarısı(02-07)',
  rentalPercentage: 'Kiralama Yüzdesi',
  incomePercentage: 'Gelir Yüzdesi',
};
