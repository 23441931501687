export default {
  cancelReason: 'İptal Nedeni',
  type: 'Tipi',
  acceptancePeriod: 'Kabul Süresi (dk).',
  actualStart: 'Başlama',
  actualEnd: 'Bitiş',
  createdTime: 'Yaratılma Tarihi',
  vehicleStartAddress: 'Baş. Adres',
  vehicleStartDistrict: 'Baş. İlçe',
  vehicleStartCity: 'Baş. Şehir',
  vehicleEndAddress: 'Bit. Adres',
  vehicleEndDistrict: 'Bit. İlçe',
  vehicleEndCity: 'Bit. Şehir',
  jobStep: 'İş Adımı',
  destination: 'Hedef Adres',
  doorOpenTime: 'Kapı Açılma Tarihi',
  jobTasks: {
    VEHICLE_DELIVERY: 'Teslimat',
    VEHICLE_DELIVERY_FIRST: 'İlk Teslimat',
    VEHICLE_PICKUP: 'Otoparka',
    VEHICLE_WASH: 'Yıkama',
    VEHICLE_TRANSFER: 'Transfer',
    VEHICLE_INTERVENTION: 'Müdahale',
    selectOnMap: 'Haritadan seç',
    mapDesc: 'Haritayı kaydırarak yeni noktayı seçebilirsiniz.',
    selectDriverText: '{plate} plakalı araç için "{taskName}" görevine sürücü seçin.',
  },
  assignJobDone: 'Görev ataması yapıldı',
  maintenanceStatus: 'Bakım Durumu',
  maintenanceStatusAdded: 'Bakım Durumu Eklendi.',
  vehicleLatestAddress: 'Araç Adres',
  vehicleLatestDistrict: 'Araç Bölge',
  vehicleLatestCity: 'Araç Şehir',
  orderCreatedBy: 'Görevi Oluşturan Kişi',
  destinationCity: 'Hedef Şehir',
  destinationContinent: 'Hedef Kıta',
  vehicleLatestContinent: 'Araç Kıta',
  jobs: {
    COMPLETED: 'Tamamlandı',
    NOT_STARTED: 'Başlamadı',
    ACTIVE: 'Aktif',
    CANCELLED: 'İptal',
    DIRECT: 'Direkt',
    BY_DISTANCE: 'Havuz',
  },
};
