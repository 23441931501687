export const tr = {
  general: {
    tracking: 'Takip',
    total: 'Toplam',
    chosen: 'Seçili Rota',
    order: 'Sipariş',
    route: 'Rota',
    driver: 'Sürücü',
    vehicle: 'Araç',
    vehicles: 'Araçlar',
    details: 'Detaylar',
    planning: 'Planlama',
    add: 'Ekle',
    general: 'Genel',
    pageTitle: 'Planlama',
    select: 'Seç',
    emptyRoutesActivated: 'Boş rotalar etkinleştirilemez',
    planningRequests: 'Planlama İstekleri',
    planRequests: 'Plan İstekleri',
    estimated: 'Tahmini',
  },
  requests: {
    driverRequest: 'Sürücülü Talep',
    poolcarRequest: 'Havuz Araç Talep',
    totalVehicles: 'Toplam Araç',
    uniqueVehicle: 'tekil araç',
  },
  columns: {
    reference: 'Referans No',
    orderId: 'Sipariş No',
    plate: 'Plaka',
    pickupAddress: 'Biniş Adresi',
    requestAddress: 'Talep Adresi',
    deliveryAddress: 'İniş Adresi',
    routeStatus: 'Rota Durumu',
    orderStatus: 'Sipariş Durumu',
    departure: 'Hareket',
    start: 'Başlangıç',
    end: 'Bitiş',
    duration: 'Süre',
    address: 'Adres',
    city: 'İl/ İlçe',
    desc: 'Açıklama',
    podNote: 'Teslimat Notu',
    podPhoto: 'Teslimat Belgesi',
    popNote: 'Yükleme Notu',
    popPhoto: 'Yükleme Belgesi',
    order: 'Sıra',
    make: 'Marka',
    usage: 'Kullanım',
    driver: 'Sürücü',
    rider: 'Kullanıcı',
    notes: 'Notlar',
    purposeType: 'Amaç Tipi',
    ridePurpose: 'Yolculuk Amacı',
    flightInfo: 'Uçuş Numarası',
    profileNote: 'Profil Notu',
    requestedPickupTime: 'Planlanan Alış Saati',
    requestedDropoffTime: 'Planlanan Bırakma Saati',
    flightPlannedTime: 'Planlanan Uçuş Saati',
    flightEstimatedTime: 'Tahmini Uçuş Saati',
    driverTime: 'Sürüş/Toplam',
    dropoffTime: 'Gerçekleşen Bırakma Saati',
    pickupTime: 'Gerçekleşen Alma Saati',
    dropoffStatus: 'Bırakma Durumu',
    pickupStatus: 'Alma Durumu',
    status: 'Durum',
  },
  routeStatus: {
    NOT_STARTED: 'Başlamadı',
    ACTIVE: 'Aktif',
    COMPLETED: 'Tamamlandı',
    DRAFT: 'Taslak',
    CANCELLED: 'İptal',
    NOT_CREATED: 'Oluşturulmadı',
  },
  event: {
    status: {
      noRoute: 'Boşta',
      TODO: 'Başlamadı',
      IN_PROGRESS: 'Devam etmekte',
      DONE: 'Tamamlandı',
      CANCELED: 'İptal',
      POSTPONED: 'Ertelendi',
    },
  },
  orderStatus: {
    unplanned: 'Planlanmadı',
    planned: 'Planlandı',
    assigned: 'Atanmış',
  },
  timeStatus: {
    onTime: 'Zamanında',
    early: 'Erken',
    late: 'Geç',
    projectedLate: 'Tahmini geç',
  },
  days: {
    mon: 'Pazartesi',
    tue: 'Salı',
    wed: 'Çarşamba',
    thu: 'Perşembe',
    fri: 'Cuma',
    sat: 'Cumartesi',
    sun: 'Pazar',
  },
  priority: {
    LOW: 'Düşük',
    MEDIUM: 'Normal',
    HIGH: 'Yüksek',
    CRITICAL: 'Kritik',
  },
  routingType: {
    CAR: 'Araba',
    TRUCK: 'Kamyon',
  },
  filters: {
    all: 'Tümü',
    inUse: 'Aktif Kullanımda',
    lateToStart: 'Rezervazyon Başlanıgı Gecikenler',
    lateToFinish: 'Rezervasyon Bitişi Gecikenler',
  },
  legend: {
    normal: 'Rezervasyon',
    lateStart: 'Başlangıç Gecikmiş',
    lateFinish: 'Bitiş Gecikmiş',
    active: 'Aktif Kullanım',
    maintenance: 'Bakım/Onarım',
  },
};

export const en = {
  general: {
    tracking: 'Tracking',
    total: 'Total',
    chosen: 'Chosen Routes',
    order: 'Order',
    route: 'Route',
    driver: 'Driver',
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    details: 'Details',
    planning: 'Planning',
    add: 'Add',
    general: 'General',
    pageTitle: 'Planning',
    select: 'Select..',
    emptyRoutesActivated: 'The empty routes cannot be activated',
    planningRequests: 'Planning Requests',
    planRequests: 'Plan Requests',
    estimated: 'Estimated',
  },
  columns: {
    reference: 'Reference',
    orderId: 'Order Id',
    plate: 'Vehicle',
    pickupAddress: 'Pickup Address',
    deliveryAddress: 'Dropoff Address',
    routeStatus: 'Route Status',
    orderStatus: 'Order Status',
    departure: 'Departure',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    address: 'Address',
    city: 'City',
    desc: 'Description',
    podNote: 'POD Note',
    podPhoto: 'POD Photo',
    order: 'Order',
    select: 'Select',
    make: 'Make',
    usage: 'Usage',
    driver: 'Driver',
    rider: 'Rider',
    notes: 'Notes',
    purposeType: 'Purpose Type',
    ridePurpose: 'Ride Purpose',
    flightInfo: 'Flight No',
    profileNote: 'Profile Note',
    requestedPickupTime: 'Planned Pick-up Time',
    requestedDropoffTime: 'Planned Dropoff Time',
    flightPlannedTime: 'Flight Planned Time',
    flightEstimatedTime: 'Flight Estimated Time',
    driverTime: 'Driving/Total',
    dropoffTime: 'Dropoff Time',
    pickupTime: 'Pickup Time',
    dropoffStatus: 'Dropoff Status',
    pickupStatus: 'Pickup Status',
    status: 'Status',
  },
  routeStatus: {
    NOT_STARTED: 'Not Started',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
    DRAFT: 'Draft',
    CANCELLED: 'Cancelled',
    NOT_CREATED: 'Not Created',
  },
  event: {
    status: {
      noRoute: 'No Route',
      TODO: 'Not Started',
      IN_PROGRESS: 'In progress',
      DONE: 'Done',
      CANCELED: 'Cancelled',
      POSTPONED: 'Postponed',
    },
  },
  orderStatus: {
    unplanned: 'Unplanned',
    planned: 'Planned',
    assigned: 'Assigned',
  },
  timeStatus: {
    onTime: 'On Time',
    early: 'Early',
    late: 'Late',
    projectedLate: 'Projected Late',
  },
  days: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  routingType: {
    CAR: 'Car',
    TRUCK: 'Truck',
  },
  filters: {
    all: 'All',
    inUse: 'In Active Use',
    lateToStart: 'Late To Start',
    lateToFinish: 'Late To Finisih',
  },
  legend: {
    normal: 'Reservation',
    lateStart: 'Late start',
    lateFinish: 'Late Finish',
    active: 'Active',
    maintenance: 'Maintenance',
  },
};
