export default {
  addUser: 'Add User',
  newUser: 'New User',
  mobileVerification: 'Mobile Verification',
  lastLoginTime: 'Last Login Time',
  newPassword: 'New Password',
  updateUser: 'Update User',
  userType: 'User Type',
  putInactive: 'Put Inactive',
  putActive: 'Put Active',
  sendSms: 'Send password with SMS',
  userManagement: 'User Management',
  application: 'Application',
  roles: 'Roles',
  selectApplication: 'Select Application',
  addRole: 'Add Role',
  noDescription: 'There is no description',
  noRole: 'There is no role',
  permissions: 'Permissions',
  filterRoles: 'Filter Roles...',
  filterPermissions: 'Filter Permissions...',
  pleaseSelectRole: 'Please Select Role',
  noPermissions: 'There is no permissions',
  roleDeleted: 'Role has been deleted',
  users: 'Users',
  menu: 'Menu',
  newPermission: 'New Permission',
  rolePermissionsUpdated: 'Role Permissions has been updated',
  permissionWillBeUpdated: 'Permission of {0} will be updated',
  roleWillBeDeleted: '{0} role will be deleted. Are you sure ?',
  roleAdded: 'Role Added',
  permissionCreated: 'Permission of {0} has been created',
  permissionNotCreated: 'Permission could not created',
  noUsers: 'There is no users',
  selectUser: 'Please Select User',
  addNode: 'Add Node',
  filter: 'Filter...',
  route: 'Route',
  noNodes: 'No nodes found to show',
  pleaseSelectNode: 'Please select Node',
  parentNode: 'Parent Node',
  selectParentNode: 'Select Parent Node',
  selectPermission: 'Select Permission',
  selectStatus: 'Select Status',
  nodeSaved: 'Node has been saved',
  filterUser: 'Filter User...',
  person: {
    VALE: 'Valet',
    VALE_SEFI: 'Vale Head',
    GARENTA_MOTOVALE_ADMIN: 'Motovale Admin',
    GARENTA_DAY_BRANCH: 'Garenta Day',
    YIKAMACI: 'Yıkamacı', // :LTODO
    CORPORATE_ADMIN: 'Corporate Admin',
    TIKTAK_SUPER_ADMIN: 'Tiktak Super Admin',
    SUPER_VALE: 'Super Vale',
  },
  user: {
    status: {
      WAITING_FOR_ACTIVATION: 'Waiting for Activation',
      ACTIVE: 'Active',
      PASSIVE: 'Passive',
    },
    newUser: 'New User',
    updateUser: 'Update User',
  },
  registrationStatus: 'Status of the registration has been changed to {0}.',
};
