export const tr = {
  available: 'Hizmet İçi Araç',
  rentable: 'Kiralanabilir Araç',
  daily: 'Günde',
  user: 'Kullanıcı',
  minute: 'Dakika',
  price: 'Fiyat',
  general: {
    title: 'Genel',
    minDays: 'Minumum Gün',
    maxDays: 'Maksimum Gün',
    dayCount: 'Gün Sayısı',
  },
  avgs: {
    title: 'Ortalamalar',
    vehicleCount: 'Araç Sayısı',
    waitTime: 'Ortalama Aracın Kiralama Bekleme Süresi',
  },
  totals: {
    title: 'Toplamlar',
    start: 'Kiralama Başlangıç',
    end: 'Kiralama Bitiş',
    users: 'Kiralama Yapan Kullanıcılar',
    income: 'Gelir',
  },
  selfService: {
    title: 'Kiralamalar (Self Servis)',
    searching: 'Arama Yapan Kullanıcılar',
    success: 'Başarılı Aramalar',
    inRegion: 'Bölgedeki Kullanıcı Sayısı',
    all: 'Bütün Aramalar',
  },
};

export const en = {
  available: 'Available Vehicles',
  rentable: 'Rentable Vehicles',
  daily: 'Daily',
  user: 'Users',
  minute: 'Minute',
  general: {
    title: 'General',
    minDays: 'Minumum Days',
    maxDays: 'Maximum Days',
    dayCount: 'Day Count',
  },
  avgs: {
    title: 'Averages',
    price: 'Price',
    vehicleCount: 'Vehicle Count',
    waitTime: 'Avg. Waiting Time for a rental',
  },
  totals: {
    title: 'Totals',
    start: 'Rental Start',
    end: 'Rental End',
    users: 'Customers',
    income: 'Income',
  },
  selfService: {
    title: 'Rentals (Self Service)',
    searching: 'User Searches',
    success: 'Successful Searches',
    inRegion: 'User count in region',
    all: 'All Searches',
  },
};

export default tr;
