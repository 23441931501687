export default {
  home: {
    indicatePreferred: 'Tercih ettiğiniz biniş noktasını haritada işaretleyin.',
    searchAddress:
      'Tercih ettiğiniz biniş noktasını arama çubuğundan aratabilir ya da ev ikonunu istediğiniz noktaya sürükleyerek seçebilirsiniz.',
    setLater: 'Daha sonra kaydet',
  },
  kvkk: {
    approveDocument: 'Kullanıcı sözleşmesini kabul ediyorum',
  },
};
