import { tr as trOpt, en as enOpt } from './optimization';
import { tr as trDashboard, en as enDashboard } from './dashboard';
import { tr as trCarshare, en as enCarshare } from './carshare';
import { tr as trCampaigns, en as enCampaigns } from './campaigns';
import { tr as trAsset, en as enAsset } from './asset';
import { tr as trGeohash, en as enGeohash } from './geohash';
import { tr as trPricing, en as enPricing } from './pricing';
import { tr as trPlanner, en as enPlanner } from './planner';
import { tr as trPool, en as enPool } from './reservation-pool';
import { tr as trPoolVehicle, en as enPoolVehicle } from './pool-vehicle';
import { tr as trFlexigo, en as enFlexigo } from './flexigo';

export default {
  tr: {
    common: {
      all: 'Hepsi',
      true: 'Evet',
      false: 'Hayır',
      yes: 'Evet',
      no: 'Hayır',
      name: 'İsim',
      INDIVIDUAL: 'Bireysel',
      CORPORATE: 'Kurumsal',
      date: 'Tarih',
      total: 'Toplam',
      avg: 'Ortalama',
      amount: 'Miktar',
      percent: 'Yüzde',
      search: 'Arama',
      details: 'Detay',
      vehicle: 'Araç',
      job: 'Görev',
      valet: 'Vale',
      note: 'Not',
      cancel: 'İptal',
      create: 'Yarat',
      destination: 'Hedef',
      status: 'Durum',
      cell: 'Cep',
      select: 'Seç',
      day: 'Gün',
      user: 'Kullanıcı',
      piece: 'Adet',
      hour: 'Saat',
      save: 'Kaydet',
      gender: 'Cinsiyet',
      male: 'Erkek',
      female: 'Kadın',
      ACTIVE: 'Aktif',
      PASSIVE: 'Pasif',
      refresh: 'Yenile',
      MONTHLY: 'Aylık',
      INSTANTLY: 'Hemen',
    },
    asset: trAsset,
    jobTasks: {
      VEHICLE_DELIVERY: 'Teslimat',
      VEHICLE_DELIVERY_FIRST: 'İlk Teslimat',
      VEHICLE_PICKUP: 'Otoparka',
      VEHICLE_WASH: 'Yıkama',
      VEHICLE_TRANSFER: 'Transfer',
      VEHICLE_INTERVENTION: 'Müdahale',
      selectOnMap: 'Haritadan seç',
      mapDesc: 'Haritayı kaydırarak yeni noktayı seçebilirsiniz.',
      selectDriverText: '{plate} plakalı araç için "{taskName}" görevine sürücü seçin.',
    },
    person: {
      VALE: 'Vale',
      VALE_SEFI: 'Vale Şefi',
      GARENTA_MOTOVALE_ADMIN: 'MotoVale Yöneticisi',
      GARENTA_DAY_BRANCH: 'Garenta Day',
      YIKAMACI: 'Yıkamacı',
      CORPORATE_ADMIN: 'Kurumsal Yönetici',
      TIKTAK_SUPER_ADMIN: 'Tiktak Yöneticisi',
      SUPER_VALE: 'Süper Saha Görevlisi',
    },
    user: {
      status: {
        WAITING_FOR_ACTIVATION: 'Aktivasyon Bekliyor',
        ACTIVE: 'Aktif',
        PASSIVE: 'Pasif',
      },
      newUser: 'Yeni Kullanıcı',
      updateUser: 'Kullanıcıyı Güncelle',
    },
    map: {
      selectStatus: 'Durum Seçiniz',
      showValets: 'Valeleri Göster',
      hideValets: 'Valeleri Gizle',
      showParks: 'Otopark Göster',
      hideParks: 'Otopark Gizle',
      showAssets: 'Araçları Göster',
      hideAssets: 'Araçları Gizle',
    },
    umng: {
      token: {
        INVALID: 'Kod geçersiz.',
        EXPIRED: 'Kodun süresi dolmuş.',
        USED: 'Bu kod daha önce kullanılmış.',
      },
    },
    rentals: {
      NOT_STARTED: 'Başlamadı',
      PREPARING_VEHICLE: 'Araç hazırlanıyor',
      FINISHED: 'Tamamlandı',
      CANCELED: 'İptal',
      WAITING_RENTAL_START: 'Kiralamanın Başlaması Bekleniyor',
      VEHICLE_ON_DELIVERY: 'Araç Müşteriye Götürülüyor',
      RENTAL_IN_PROGRESS: 'Aktif Kiralama',
      WAITING_CUSTOMER_TO_VEHICLE: 'Müşteri Araca Bekleniyor',
      WAITING_PAYMENT: 'Ödeme Bekleniyor',
      score: {
        accScore: 'Hızlanma',
        decScore: 'Yavaşlama',
        idlingScore: 'Rölanti',
        speedScore: 'Sürat',
        corneringScore: 'Dönüş',
        overallScore: 'Ortalama',
      },
    },
    customer: {
      membershipStatus: 'Üyelik Durumu',
      membershipCancelled: 'Üyelik iptal edilmiştir.',
      customerDeleted: 'Bu kullanıcı silinmiştir.',
      delete: 'Sil',
      deleted: 'İptal Edildi',
      searchPlaceholder: 'Arama kriterlerini giriniz',
      repeatSearchPlaceholder: 'Tekrar arama metni yazıp enter\'a basın',
      searchMaxResults: 'En fazla 200 arama sonucu gösterilmektedir',
      searchAlternative: 'Aramanızı sınırlandırarak arama yapın',
      enableCustomer: 'Müşteriyi aktive etmek için tıkla',
      disableCustomer: 'Müşteriyi pasife çekmek için tıkla',
      enabled: 'Aktif',
      disabled: 'Pasif',
      confirm: 'Emin misin?',
      confirmEnable: 'Müşteriyi aktive etmek istediğine emin misin?',
      confirmDisable: 'Müşteriyi pasife çekmek istediğine emin misin?',
      confirmCancel: 'Üyeliği iptal etmek istediğine emin misin? Bu hareket geri alınamaz!',
      editCustomer: 'Müşteri Güncelle',
      customerStatus: {
        legalFollowup: 'Yasal Takip',
        deleted: 'Silinmiş',
        active: 'Aktif',
        rejected: 'Reddedildi',
        passive: 'Pasif',
        pending: 'Onay Bekliyor',
        new: 'Başvuru Aşamasında',
        reevaluate: 'Yeniden Değerlendirilecek',
      },
    },
    rentalType: {
      VALET_SERVICE: 'Vale Servis',
      SELF_SERVICE: 'Self Servis',
      STATION_BASED: 'İstasyon',
      POOL: 'Havuz',
      DAILY: 'Günlük',
    },
    zoneType: {
      'FREE_FLOAT-DAILY': 'Free Float - Günlük',
      'FREE_FLOAT-SELF_SERVICE': 'Free Float - Günlük',
      HOME: 'Ev',
      OFFICE: 'Ofis',
      MALL: 'AVM',
      CITY: 'Şehir',
      CAMPUS: 'Kampüs',
    },
    jobs: {
      COMPLETED: 'Tamamlandı',
      NOT_STARTED: 'Başlamadı',
      ACTIVE: 'Aktif',
      CANCELLED: 'İptal',
      DIRECT: 'Direkt',
      BY_DISTANCE: 'Havuz',
    },
    eventCategory: {
      DELIVERY: 'Bildirim',
      PAYMENT: 'Ödeme',
      RENTAL: 'Kiralama',
      REQUEST: 'Talep',
      SYSTEM: 'Sistem',
      REFUND: 'Geri ödeme',
      DRIVER: 'Sürücü',
    },
    events: {
      assignVehicle: 'Araç Rezerve',
      provision_paid: 'Provizyon Ödeme',
      newRental: 'Kiralama Talebi',
      preparingVehicle: 'Araç Hazırlanıyor',
      deliveryRouteStarted: 'Teslimat Başladı',
      deliveredToCustomer: 'Teslimat',
      lateRentalStart: 'Kiralama Başladı',
      arrivedToCustomerLocation: 'Teslimat Noktasına Ulaşıldı',
      assignVehicleLog: 'Araç Sistem Kaydı',
      assignVehicleLogV2: 'Araç Sistem Kaydı',
      valetWasNotAvailable: 'Vale Görevdeyken Kiralama',
      endRentalDoorStatusLog: 'Araç Sistem Kaydı. Kapı Durumu',
      changeDeliveryJob: 'Sistem Kaydı: Vale ve/veya Araç değişti',
      cancelRentalByUser: 'Kullanıcı tarafından iptal edildi.',
      directPaymentSuccess: 'Para çekimi başarılı',
      directPaymentFailure: 'Para çekimi denemesi başarısız',
      provisionPaymentSuccess: 'Provizyondan ödeme alındı',
      provisionPaymentFailure: 'Provizyondan ödeme alma başarısız',
      refundSuccess: 'İade İşlemi Başarılı',
      refundFailure: 'İade İşlemi Yapılamadı',
      startRentalAddress: 'Kiralama Başlangıç Adresi',
      endRentalAddress: 'Kiralama Bitiş Adresi',
      valetCallNotification: 'Vale Arandı',
      valetAcceptedJob: 'Vale görevi kabul etti.',
      cancelContract: 'Sözleşme İptal edildi.',
      cancelContractFail: 'Sözleşme İptali başarısız',
      createContract: 'Sözleşme Oluşturuldu.',
      updateTotalCost: 'Toplam Ücret Güncellendi',
      updateOdometer: 'Odometre Güncellendi',
      updateStartDate: 'Başlangıç Tarihi Güncellendi',
      updateEndDate: 'Bitiş Tarihi Güncellendi',
      updateVehicleStatus: 'Araç Durumu Güncellendi',
      updateNoCharge: 'Ücretsiz duruma getirildi',
      rentFromStreet: 'Talep otopark dışından karşılandı',
      vehicleLeftServiceArea: 'Araç Servis Alanı Dışına çıktı.',
      vehicleEnteredServiceArea: 'Araç Servis Alanına girdi.',
      adressWhenVehicleLeftServiceArea: 'Servis alanından çıkılan adres',
      adressWhenVehicleEnteredServiceArea: 'Servis alanına girilen adres',
      hgsInvoice: 'HGS Fatura',
      newRentalSelfService: 'Kiralama Talebi (Self Servis)',
      newRentalStation: 'Kiralama Talebi (İstasyon\'dan Kiralama)',
      cancelSelfService: 'Sistem Self Servis İptal',
      devicePassiveStateDetected: 'Cihaz Pasif Durumda',
      sendResetForNotActive: 'Otomatik Cihaz Kontrol',
      sendKabisDataToCmc: 'Kabis verileri gönderildi',
      sendKabisDataToRobot: 'Kabis verileri gönderildi (Robot)',
      vehicleHasExistingTask: 'Aracın üstünde önceden görev var.',
      airportRentalStart: 'Havaalanında Kiralama Başlangıcı',
      airportRentalEnd: 'Havaalanında Kiralama Bitişi',
      finishedByAdmin: 'Admin tarafından sonlandırıldı',
      finishedByRentalChange: 'Kiralama değişikliği nedeni ile sonlandırıldı',
      startedByRentalChange: 'Kiralama değişikliği nedeni ile başlatıldı',
      provisionVoided: 'Bloke Kaldırıldı',
      rentalChangedToCorporate: 'Kurumsal Kiralamaya Geçiş',
      rentalChangedToPersonal: 'Bireysel Kiralamaya Geçiş',
      infoTiktakWeekendCostNotification: 'Hafta Sonu Fiyat Bilgilendirme',
      infoNigtlyCostNotification: 'Gece Fiyat Bilgilendirme',
      assignDiscount: 'İndirim Uygulandı',
      removeDiscount: 'İndirim İptal Edildi',
      unsuccessfulPayment: 'Ara ödeme başarısız',
      successfulPayment: 'Ara ödeme alındı',
      successfullSynchronized: 'Senkronizasyon başarı ile tamamlandı',
    },
    drivers: {
      availability: {
        null: 'Bilinmiyor',
        AVAILABLE: 'Müsait',
        WORKING: 'Çalışıyor',
        NOT_WORKING: 'Çalışmıyor',
        ON_BREAK: 'Molada',
        PENDING_TASK_ACCEPTANCE: 'Kabul Bekleniyor',
      },
    },
    radar: {
      status: {
        RADAR_RESPONSE: 'Başarılı',
        RADAR_REQUEST: 'Aktif',
        RADAR_EXPIRED: 'Başarısız',
        RADAR_CANCELED: 'İptal',
      },
    },
    errors: {
      WRONG_USER_PASS: 'Yanlış Kullanıcı Adı veya Şifre',
      ACCOUNT_NOT_ACTIVE: 'Hesap aktif değil',
      ACCOUNT_WAITING_FOR_ACTIVATION: 'Aktivasyon Bekleniyor',
      WRONG_OTP: 'Onay kodu yanlış',
      RATE_LIMIT: 'Çok fazla deneme',
      TOKEN_INVALID: 'Geçersiz kod',
      unknown: 'Beklenmeyen bir hata oluştu',
    },
    assetHistory: {
      DoorCommandCodeExecuted: 'Kapı açma/kapama komutu',
      DoorCloseCommandSent: 'Kapı kapama komutu gönderildi.',
      DoorOpenCommandSent: 'Kapı açma komutu gönderildi.',
      IgnitionOn: 'Kontak açık',
      IgnitionOff: 'Kontak kapalı',
      DoorOpenEvent: 'Kapı açıldı',
      DoorCloseEvent: 'Kapı kapandı',
      RTCSetTimeCommandSent: 'RTC Zaman Ayarlama Komutu Gönderildi',
      RTCDoorOnCommandSent: 'RTC Kapı Aç Komutu Gönderildi',
      RTCDoorOffCommandSent: 'RTC Kapı Kapa Komutu Gönderildi',
      ResetCommandSent: 'Reset Komutu Gönderildi',
      SoftwareStart: 'Cihaz Çalışmaya Başladı',
      EchoResponse: 'Echo Cevabı',
      EchoCommandSent: 'Echo Komutu Gönderildi',
    },
    searchCategories: {
      AVAILABLE_VEHICLE_NOT_FOUND: 'Uygun Araç Bulunamadı',
      NOT_IN_WORKING_HOURS: 'Çalışma Saatleri Dışında',
      SUCCESS: 'Başarılı',
      RENTAL: 'Kiralama',
      RENTAL_END: 'Kiralama Bitiş',
      RENTAL_SELFSERVICE: 'Self Servis Kiralama',
      NO_VALET_SERVICE: 'Vale Servisi Yok',
      NOT_IN_SERVICE_AREA: 'Servis Alanı Dışında',
      SEARCH_MAX_REQUEST: 'Maksimum istek',
      SUCCESS_SELFSERVICE: 'Başarılı (Self Servis)',
      FAIL_SELFSERVICE: 'Self Servis Hata',
      RADAR_RESPONSE: 'Radar Tamamlanan',
      RADAR_REQUEST: 'Radar Aktif',
      RADAR_EXPIRED: 'Radar Başarısız',
      RADAR_CANCELED: 'Radar İptal',
    },
    ratings: {
      OVERALL: 'Genel',
      CLEANLINESS: 'Temizlik',
    },
    damages: {
      regions: {
        RIGHT: 'Sağ',
        LEFT: 'Sol',
        FRONT: 'Ön',
        REAR: 'Arka',
        TOP: 'Üst',
        INTERIOR: 'İç',
      },
    },
    paymentTypes: {
      DIRECT_PAYMENT: 'Ödeme',
      PROVISION: 'Ön Provizyon',
      PROVISION_PAYMENT: 'Provizyondan Ödeme',
      REFUND: 'İade',
      THREE_D_PAYMENT: '3D Ödeme',
      VOID: 'Provizyon İade',
      PAYMENT_VOID: 'İptal',
    },
    settings: {
      rental: {
        isRentalSearchRequestEnabled: 'Arama İstekleri Açık',
        valetSearchForSecondRentalAllowed: 'Valeli Aramaları Sözleşmeli Müşteriler Kullanabilir',
        cancelSelfServiceRentalOnDeviceProblem: 'Cihaz probleminde self servisi iptal et',
        enableAutomaticDebtCollection: 'Otomatik bakiye çekimi',
        workingHours: 'Çalışma Saatleri',
        workingHoursWeekend: 'Hafta Sonu Çalışma Saatleri',
      },
      payment: {
        enableProvisionVoid: 'Otomatik olarak provizyonlari iptal et',
      },
      proximity: {
        useVehicleInTransferToPark: 'Otopark görevi atanan araçları kullan',
      },
      notification: {
        enableSendFirstRentalMail: 'İlk kiralama için email gönder.',
        inBlackList: 'Blacklist',
        dailyReport: 'Günlük Rapor',
        outOfServiceEmails: 'Araç Pasif Uyarısı',
        changedRentalDeliveryJob: 'changedRentalDeliveryJob',
        inconsistentVehicleStatus: 'changedRentalDeliveryJob',
        dailyDriverPerformanceReport: 'Günlük Sürücü Performans',
        collectionNotCreated: 'collectionNotCreated',
        contractNotCreated: 'Kontrat yaratılamadı',
        resetSmsWarningEmails: 'resetSmsWarningEmails',
        odometerJump: 'odometerJump',
        rating: 'Rating',
      },
      sap: {
        notificationEmails: 'SAP Notification Emails',
      },
    },
    metrics: {
      revenue_total: 'Toplam Gelir',
      average_wait_total: 'Ortalama Bekleme',
      total_search: 'Toplam Arama',
      average_price: 'Ortalama Fiyat',
      success_search: 'Başarılı Arama',
      average_vehicle_count: 'Ortalama Araç Sayısı',
    },
    campaign: {
      AMOUNT: 'Sabit Miktar',
      PERCENT: 'Yüzde',
    },
    workflow: {
      documentTypes: {
        DRIVING_LICENSE: 'Ehliyet',
        IDENTITY: 'Kimlik',
        PASSPORT: 'Pasaport',
        DIGITAL_AGREEMENT: 'E-Sözleşme',
        SIGNED_AGREEMENT: 'Sözleşme',
        SELFIE: 'Selfi',
      },
      documentStatus: {
        ACTIVE: 'Aktif',
        REJECTED: 'Reddedildi',
        PENDING_APPROVAL: 'Onay Bekliyor',
        REPLACED: 'Yenisiyle değiştirildi',
      },
      poolTypes: {
        myTasks: 'Görevlerim',
        customerDocumentApprove: 'Müşteri Doküman Onayı',
        vehicleCleaningControl: 'Araç Temizlik Kontrolü',
      },
      ocrFields: {
        IDENTITY_NUMBER: 'TCKN',
        NAME: 'Adı',
        SURNAME: 'Soyadı',
        DRIVING_LICENSE_NO: 'Ehliyet No',
        DRIVING_LICENSE_ISSUE_DATE: 'Ehliyet Veriliş',
        DRIVING_LICENSE_VALID_UNTIL: 'Ehliyet Geçerlilik',
        DRIVING_LICENSE_CLASS: 'Ehliyet Sınıfı',
      },
    },
    cargo: {
      orderStatus: {
        PENDING_APPROVAL: 'Onay Bekliyor',
        PENDING_DEPOT_ASSIGNMENT: 'Depo Ataması Bekliyor',
        PENDING_SHIPMENT_ASSIGNMENT: 'Sevkiyat Planlamada',
        SHIPMENT_IN_PROGRESS: 'Gönderimde',
        DELIVERED: 'Teslim edildi',
        REJECTED: 'Reddedildi',
        CANCELED: 'İptal edildi',
        SHIPMENT_ARRIVED: 'Depoya Ulaştı',
      },
      shipmentStatus: {
        NEW: 'Yeni',
        PLANNED: 'Planlandı',
        READY: 'Hazır',
        ARRIVED_TO_SUPPLIER_DEPOT: 'Depoda',
        LOADED: 'Yüklendi',
        STARTED: 'Başladı',
        FINISHED: 'Bitti',
      },
    },
    tooltips: {
      synchVehiclestoMiles: 'Miles ile senkronize et',
    },
    firms: {
      invoiceDeliveryRule: 'Fatura Gönderim Kuralı',
      instantlyInvoice: 'Anlık Fatura Kesimi',
      monthlyInvoice: 'Aylık Fatura Kesimi',
      invoiceDeliveringType: 'Fatura gönderim türü',
      savedAs: 'olarak kaydedildi',
      succussfull: 'Başarılı',
      error: 'Hata',
      savedEmailBefore: 'Bu email ile önceden bir kullanıcı kaydedilmiş',
      creditCardForCompany: 'şirketi için kredi kartı',
      mandatory: 'zorunlu',
      notMandatory: 'zorunlu değil',
      updated: ' olarak güncellendi',
      companysMinLimit: 'şirketi minimum limiti',
      credit: 'alacak',
      debt: 'borç',
      package: 'paketi',
      beReflectedAs: 'olarak yansıtıldı',
      companysDiscountRate: 'şirketi indirim oranı',
      updatedInfos: 'bilgileri güncellendi',
      addedEnterpriceManaged: 'Kurumsal Yönetici Eklendi',
      updateCompany: 'Şirketi güncelle',
      minLimit: 'Minimum Limit',
      creditCardRequirement: 'Kredi Kart Mecburiyeti',
      financialTransaction: 'Finansal İşlem',
      updateDiscountRate: 'İndirim Oranı Güncelle',
      addPackage: 'Paket Ekle',
      addManager: 'Yönetici ekle',
      totalUser: 'Toplam Kullanıcı',
      systemUser: 'Sistem Kullanıcı',
      mobileUser: 'Mobil Kullanıcı',
      createdTime: 'Oluşturulma Zamanı',
      addedToCompany: 'şirketine eklendi',
      companyUpdated: 'Şirket Güncellendi',
      discountRate: 'İndirim Oranı (%)',
      newCompany: 'Yeni Şirket',
      MONTHLY: 'aylık',
      INSTANTLY: 'anlık',
      balance: 'Bakiye',
    },
    types: {
      AT_START: 'Günlük',
      RENTAL_IN_PROGRESS: 'Sabit',
    },
    excelTypes: {
      drivingLicense: 'Ehliyet',
      gender: 'Cinsiyet',
      selfie: 'Selfie',
    },
    optimization: trOpt,
    dashboard: trDashboard,
    campaigns: trCampaigns,
    geohash: trGeohash,
    pricing: trPricing,
    planner: trPlanner,
    pool: trPool,
    poolVehicle: trPoolVehicle,
    flexigo: trFlexigo,
    carshare: trCarshare,
  },
  en: {
    common: {
      all: 'All',
      date: 'date',
      true: 'Yes',
      false: 'No',
      name: 'Name',
      amount: 'Amount',
      percent: 'Percent',
      search: 'Search',
      details: 'Details',
      vehicle: 'Vehicle',
      job: 'Job',
      valet: 'Valet',
      note: 'Notes',
      cancel: 'Cancel',
      create: 'Create',
      destination: 'Destination',
      status: 'Status',
      cell: 'Mobile',
      select: 'Select',
      day: 'day',
      user: 'User',
      piece: 'piece',
      hour: 'Hours',
      save: 'Save',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      MONTHLY: 'Monthly',
      INSTANTLY: 'Instantly',
    },
    asset: enAsset,
    jobTasks: {
      VEHICLE_DELIVERY: 'Delivery',
      VEHICLE_DELIVERY_FIRST: '1st Delivery',
      VEHICLE_PICKUP: 'To Parking Lot',
      VEHICLE_WASH: 'Wash',
      VEHICLE_TRANSFER: 'Transfer',
      VEHICLE_INTERVENTION: 'Intervention',
      selectOnMap: 'Select on the map',
      mapDesc: 'Select by dragging the map to the desired location',
      selectDriverText: 'Select a driver for vehicle ({plate}) for the task "{taskName}".',
    },
    drivers: {
      availability: {
        null: 'Unknown',
        AVAILABLE: 'Available',
        WORKING: 'Working',
        NOT_WORKING: 'Not Working',
        ON_BREAK: 'On Braek',
        PENDING_TASK_ACCEPTANCE: 'Pending Task Acceptance',
      },
    },
    rentals: {
      NOT_STARTED: 'Not Started',
      PREPARING_VEHICLE: 'Preparing Vehicle',
      FINISHED: 'Finished',
      CANCELED: 'Cancelled',
      WAITING_RENTAL_START: 'Waiting Rental Start',
      VEHICLE_ON_DELIVERY: 'Vehicle on Delivery',
      RENTAL_IN_PROGRESS: 'Active Rental',
      WAITING_CUSTOMER_TO_VEHICLE: 'Waiting Customer',
      WAITING_PAYMENT: 'Waiting Payment',
      score: {
        accScore: 'Accelaration',
        decScore: 'Decelaration',
        idlingScore: 'Idling',
        speedScore: 'Speed',
        corneringScore: 'Cornering',
        overallScore: 'Overall',
      },
    },
    customer: {
      membershipStatus: 'Membership Status',
      membershipCancelled: 'Membership cancelled.',
      customerDeleted: 'Customer Deleted.',
      delete: 'Delete',
      deleted: 'Deleted',
      confirm: 'Are you sure?',
      confirmCancel: 'Are you sure you want to cancel the membership?',
    },
    rentalType: {
      VALET_SERVICE: 'Valet Service',
      SELF_SERVICE: 'Self Service',
      STATION_BASED: 'Station Based',
      POOL: 'Pool',
      DAILY: 'Daily',
    },
    zoneType: {
      'FREE_FLOAT-DAILY': 'Free Float - Daily',
      'FREE_FLOAT-SELF_SERVICE': 'Free Float - Daily',
      HOME: 'Home',
      OFFICE: 'Office',
      MALL: 'Mall',
      CITY: 'City',
      CAMPUS: 'Campus',
    },
    jobs: {
      COMPLETED: 'Completed',
      NOT_STARTED: 'Not Started',
      ACTIVE: 'Active',
      CANCELLED: 'Cancelled',
      DIRECT: 'Direct',
      BY_DISTANCE: 'By Distance',
    },
    eventCategory: {
      DELIVERY: 'Delivery',
      PAYMENT: 'Payment',
      RENTAL: 'Rental',
      REQUEST: 'Request',
      SYSTEM: 'System',
      REFUND: 'Refund',
      DRIVER: 'Driver',
    },
    events: {
      assignVehicle: 'Assign vehicle',
      provision_paid: 'Provision paid',
      newRental: 'Rental request',
      preparingVehicle: 'Preparing Vehicle',
      deliveryRouteStarted: 'Delivery route started',
      deliveredToCustomer: 'Delivery',
      lateRentalStart: 'Rental started',
      arrivedToCustomerLocation: 'Arrived to customer Location',
      assignVehicleLog: 'Assign vehicle log',
      assignVehicleLogV2: 'Vehicle system registration',
      valetWasNotAvailable: 'Valet was not available',
      endRentalDoorStatusLog: 'Vehicle system log, Door status',
      changeDeliveryJob: 'Log status: Valet or Vehicle changed',
      cancelRentalByUser: 'Cancel rental by user.',
      directPaymentSuccess: 'Payment Success',
      directPaymentFailure: 'Payment failure',
      provisionPaymentSuccess: 'Provision payment success',
      provisionPaymentFailure: 'Povision payment failure',
      refundSuccess: 'Refund success',
      refundFailure: 'Refund failure',
      startRentalAddress: 'Start rental address',
      endRentalAddress: 'End rental address',
      valetCallNotification: 'Valet call notification',
      valetAcceptedJob: 'Valet accepted job',
      cancelContract: 'Contract canceled',
      cancelContractFail: 'Contract cancellation failed',
      createContract: 'Contract created',
      updateTotalCost: 'Total fee updated',
      updateOdometer: 'Odometer updated',
      updateStartDate: 'Start date updated',
      updateEndDate: 'End date updated',
      updateVehicleStatus: 'Vehicle status updated',
      updateNoCharge: 'Update no charge',
      rentFromStreet: 'Rent charged from outside of car park',
      vehicleLeftServiceArea: 'Vehicle left service area',
      vehicleEnteredServiceArea: 'Vehicle entered the service area',
      adressWhenVehicleLeftServiceArea: 'The address removed from service area',
      adressWhenVehicleEnteredServiceArea: 'The address entered in the service area',
      hgsInvoice: 'HGS Invoice',
      newRentalSelfService: 'Rental request (Self Service)',
      newRentalStation: 'Rental request (Rental from station)',
      cancelSelfService: 'System self service cancellation',
      devicePassiveStateDetected: 'Device in passive state',
      sendResetForNotActive: 'Automatic device control',
      sendKabisDataToCmc: 'Kabis data sent',
      sendKabisDataToRobot: 'Kabis saat sent (Robot)',
      vehicleHasExistingTask: 'There is a pretask on the vehicle',
      airportRentalStart: 'Airport rental start',
      airportRentalEnd: 'Airport rental end',
      finishedByAdmin: 'Finished by admin',
      finishedByRentalChange: 'Finished by rental change',
      startedByRentalChange: 'Started by rental change',
      provisionVoided: 'Unblocked provision',
      rentalChangedToCorporate: 'Rental changed to corporate',
      rentalChangedToPersonal: 'Rental changed to personal',
      infoTiktakWeekendCostNotification: 'Weekend cost information',
      infoNigtlyCostNotification: 'Night cost information',
      assignDiscount: 'Discount applied',
      removeDiscount: 'Cancelled discount',
      unsuccessfulPayment: 'Unsuccessful payment',
      successfulPayment: 'Successful payment',
      successfullSynchronized: 'Successfull synchoronized',
    },
    radar: {
      status: {
        RADAR_RESPONSE: 'Successful',
        RADAR_REQUEST: 'Aktive',
        RADAR_EXPIRED: 'Failed',
        RADAR_CANCELED: 'Cancelled',
      },
    },
    metrics: {
      revenue_total: 'Total Revenue',
      average_wait_total: 'Avg. Wait Times',
      total_search: 'Total Searches',
      average_price: 'Avg. Prices',
      success_search: 'Successful Search',
      average_vehicle_count: 'Avg. Vehicle Count',
    },
    firms: {
      invoiceDeliveryRule: 'Invoice Delivery Rule',
      instantlyInvoice: 'Instantly Invoice',
      monthlyInvoice: 'Monthly Invoice',
      invoiceDeliveringType: 'Invoice Delivering Type',
      savedAs: 'saved as',
      succussfull: 'succussfull',
      error: 'Error',
      savedEmailBefore: 'A user has already been registered with this email',
      creditCardForCompany: 'credit card for company',
      mandatory: 'mandatory',
      notMandatory: 'not mandatory',
      updated: 'updated',
      companysMinLimit: 'company Minumum Limit',
      credit: 'money owed to one',
      debt: 'borç',
      package: 'package',
      beReflectedAs: 'be reflected',
      companysDiscountRate: 'company discount rate',
      updatedInfos: 'updated',
      addedEnterpriceManaged: 'Added Enterprice Managed',
      updateCompany: 'Update Company',
      minLimit: 'Minimum Limit',
      creditCardRequirement: 'Credit Card Requirement',
      financialTransaction: 'Financial Transaction',
      updateDiscountRate: 'Update Discount Rate',
      addPackage: 'Add Package',
      addManager: 'Add Manager',
      totalUser: 'Total User',
      systemUser: 'System User',
      mobileUser: 'Mobile User',
      createdTime: 'Created Time',
      addedToCompany: 'added to company',
      companyUpdated: 'Company Updated',
      discountRate: 'Discount Rate (%)',
      newCompany: 'New Company',
      refresh: 'Refresh',
      MONTHLY: 'monthly',
      INSTANTLY: 'instantly',
      balance: 'Balance',
    },
    types: {
      AT_START: 'Daily',
      RENTAL_IN_PROGRESS: 'Fixed',
    },
    excelTypes: {
      drivingLicense: 'Driving License',
      gender: 'Gender',
      selfie: 'Selfie',
    },
    optimization: enOpt,
    dashboard: enDashboard,
    campaigns: enCampaigns,
    geohash: enGeohash,
    pricing: enPricing,
    planner: enPlanner,
    pool: enPool,
    poolVehicle: enPoolVehicle,
    flexigo: enFlexigo,
    carshare: enCarshare,
  },
  tooltips: {
    synchVehiclestoMiles: 'Sync with Miles',
  },
  errors: {
    WRONG_USER_PASS: 'Wrong User or Password',
    ACCOUNT_NOT_ACTIVE: 'Account not active',
    ACCOUNT_WAITING_FOR_ACTIVATION: 'Account waiting for activation',
    WRONG_OTP: 'Confirm code is wrong',
    unknown: 'An unexpected error has occurred',
  },
};
