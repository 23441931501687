export default {
  modesOfCommute: 'Modes of Commute',
  commuteHeaders: {
    driving: 'Driving',
    vanPool: 'Vanpool',
    carPool: 'Carpool',
    shuttle: 'Shuttle',
    transit: 'Transit',
    walking: 'Walking & Biking',
  },
  totalEmployees: 'Total Employees',
  totalEmission: 'Total Emission',
  totalDuration: 'Total Duration*',
  current: 'Current',
  best: 'Best',
  totalCost: 'Cost - Total',
  hoursMonth: 'Hours / Month',
  statusCompared: 'Current Status Compared to Best Scenario',
  emission: 'Emission',
  emissionInfo: 'The ratio of the travel time by shuttle compared to the travel time by car for the same route.',
  cost: 'Cost',
  timeWastedDriving: 'Time Wasted Driving',
  acceptanceRate: 'Acceptance Rate',
  acceptanceRateInfo: 'The rate at which employees accept shared commute options.',
  hoursYears: 'Hours/Year',
  avg: 'Avg',
  total: 'Total',
  employee: 'Employee',
  employeeLabel: 'employee(s)',
  mile: 'Mile',
  paidByEmployees: 'Paid by Employees',
  paidByEmployer: 'Paid by Employer',
  perEmployee: 'per Employee',
  perMile: 'per Mile',
  forNonCommute: 'for non-SOV Commute Options',
  kgMonth: 'KG/Month',
  currencyMonth: 'USD/Month',
  settings: {
    settingsDescription: 'As a paid customer you will have the option to edit parameters that are read-only in demo mode.',
    maxDistances: 'Max. Distances',
    maxDistancesInfo: 'You can set the maximum distances you would accept your employees to take by stated means of travel.',
    maxWalkingDistance: 'Max. Walking Distance',
    maxBikingDistance: 'Max. Biking Distance',
    durationCoefficients: 'Duration Coefficients',
    durationCoefficientsInfo: 'The ratio of the travel time by stated commute options compared to the travel time by car for the same route.',
    forShuttle: 'for Shuttle',
    forTransit: 'for Transit',
    subsidies: 'Subsidies',
    subsidiesInfo: 'You can set how much of the cost of commute the company would grant for the employees.',
    percentage: 'Percentage',
    value: 'Value',
    shuttle: 'Shuttle',
    vanpool: 'Vanpool',
    transit: 'Transit',
    vehicleTypes: 'Vehicle Types',
    vehicleTypesInfo: 'You can set the ratio of different vehicle types the company owns.',
    electric: 'Electric',
    ice: 'ICE',
    facilities: 'Facilities',
    facilitiesInfo: 'You can set the capacity information for the facilities provided by the company.',
    carParkingCapacity: 'Car Parking Capacity',
    bikeParkingCapacity: 'Bike Parking Capacity',
    carChargingPortCapacity: 'Car Charging Port Capacity',
    daily: 'Daily',
    monthly: 'Monthly',
    free: 'Free',
    resetToDefaultValues: 'Reset to Default Values',
    recalculateCommuteOptions: 'Recalculate Commute Options',
    internalCombustion: 'Internal Combustion Engine',
    parkingCost: 'Parking Cost',
  },
  location: 'Location',
  shifts: 'Shifts',
  employees: 'Riders',
  riders: 'Riders',
  setup: {
    approveYourCompanyAddress: 'Approve your company address.',
    companyLocation: 'We need your company location to generate routes for commute.',
    downloadExcelTemplate: 'You can download our excel template, fill your employee information and upload it. Alternatively you can enter information manually.',
    uploadWithExcel: 'Upload with Excel',
    enterManually: 'Add Manually',
    homeAddress: 'We need employee home addresses',
    calculateRoute: 'so that we can calculate routes and create optimized commute scenarios to compare with your current status.',
    sampleSize: 'For demo purposes the sample size is limited to 100 employees.',
    demoDescription: 'Paid accounts can upload their whole employee data with HR system integrations easily. If you’d like to learn more about paid account advantages and start one, we’ll be pleased to contact you!',
    eligibleFor: 'Eligible for',
    shuttle: 'Shuttle',
    ride: 'Ride',
    carpool: 'Carpool',
    workSchedule: 'We need your company’s work schedule to calculate routes for commute hours.',
    addShiftToCampuses: 'Please add your shift information for each of your campuses.*',
    demoPurposes: '*For demo purposes you can only add one shift.',
    addShift: 'Add Shift',
    shiftName: 'Shift Name',
    questionMark: 'Hover the question marks to see which fields are optional and why this information is needed.',
  },
  allCampuses: 'All Campuses',
  allDepartments: 'All Departments',
  employeeSaved: 'Rider saved.',
  continueAddingOthers: 'Continue adding others.',
  searchPlaceholder: 'Search by rider name or e-mail',
  viewFictiveData: 'View with Sample Data',
  viewWithYourData: 'View with Your Own Data',
  tryWithYourOwnData: 'Try With Your Own Data',
  tryWithYourOwnDataTitle: 'Setting Up Demo For {0}',
  contactUs: 'Contact Us',
  employeeName: 'Rider Name',
  commuteMode: 'Commute Mode',
  currentCommuteMode: 'Current Commute Mode',
  commuteModeOptions: {
    future: 'Current Commute Mode',
    current: 'Current Commute Mode',
    best: 'Best Commute Mode',
  },
  emissionImpact: 'Emission Impact',
  viewProfile: 'View Profile',
  viewDetails: 'View Details',
  totalKm: 'Total Distance*',
  vehicleCapacity: 'Vehicle Capacity',
  passengers: '# Passengers',
  avgWalkingDistanceInMeter: 'Avg.Walk Distance',
  avgTimeCoefficient: 'Avg. Time Coefficient',
  oneWay: '*One Way',
  appArrivalTimes: 'App. Arrival Times',
  distanceToCampus: 'Distance to Campus',
  onboarding: 'Onboarding',
  avgDistanceToStop: 'Avg. Distance to Stop',
  address: 'Address',
  directions: 'Directions',
  otherModes: 'Other commute options',
  showOnMap: 'Show On Map',
  routes: 'Routes',
  modes: 'Modes',
  searchRoute: 'Search Route',
  notFoundRoute: 'Route not found',
  occupancy: 'Occupancy',
  stops: '# Stops',
  walkingZone: 'Walking Zone',
  reverseIsolineZone: 'Driving Dist. Zone',
  deleteSelected: 'Delete Selected',
  check: 'Check',
  uncheck: 'Uncheck',
  selectedEligibleFor: 'selected eligible for',
  employeesSelected: 'employees selected',
  termsDescription: 'Concerned about data privacy ? <br><br> Flexigo is GDPR compliant and works on Amazon servers.',
  addAndSendEmail: 'Add & Send E-mail',
  emailSentInfo: 'Welcome e-mail was sent to selected employees. You can track the registration status on the employees page.',
  resendInvitationEmail: 'Resend Invitation E-mail',
  moreResponds: 'More registration required to continue...',
  info: 'Info',
  commuteModes: 'Commute Modes',
  monthlyCostUsd: 'Monthly Cost (USD)',
  monthlyCost: 'Monthly Cost',
  letsGo: 'Let\'s Go',
  letsGoTooltip: 'We have collected adequate information to create a simulation for your company.',
  emissionRating: 'Emission Rating',
  expandMap: 'Expand Map',
  shrinkMap: 'Shrink Map',
  addEmployeeData: 'Add Rider Data',
  pedestrian: 'Walking',
  transit: 'Transit',
  shuttle: 'Shuttle',
  tutorial: {
    title: 'This dashboard presents you 2 scenarios for varying acceptance rates of non-SOV commute options.',
    firstDescription: 'Employees in a corporation are eligible for various modes of commute. The more they embrace the non-SOV options, the more emission and cost saved.',
    secondDescription: 'Current scenario is built on assumptions based on average national statistics data. The best is the ultimate level that can be reached as of sustainability.',
  },
  goToCampus: 'Go to campus',
  avgTimeCoefficientText: 'The ratio of travel time by shuttle compared to the travel time by car for the same route',
  deniedGeolocation: 'User did not grant location sharing permission.',
  duration: 'Duration',
  hours: 'hours',
  minutes: 'minutes',
  currentlyWaiting: 'Currently waiting for employees to complete registration.',
  viewData: 'You can view data on this page as the user information is received.',
  viewRegistrationStatus: 'View Registration Status',
  dataForEmployees: 'Displaying data for {0} employees who have registered so far.',
  inProgressMessage: 'You can view data for the registered below. Once 80% is complete, you will be able to run optimisation and view shuttle lines that your employees can use.',
  tooltip: {
    name: 'Employee name field is optional for demo. Random names will be generated for demo if you prefer to leave it blank.',
    email: 'Your employees will receive a welcome e-mail and will be redirected to Flexigo app to enter their home addresses and state their current mode of commute.',
    department: 'Department field is optional for demo. Random names will be generated for demo if you prefer to leave it blank.',
    address: 'You may fill the address information for your employees. The addresses will be verified via e-mail.',
    eligibleFor: 'You can select which services your employees will have access for. For demo purposes all options will come selected by default.',
  },
  optimizationSplashInfo: 'Optimization is running and it comes up a few solutions within 2-3 minutes. You can see and compare all of your employee’s commute modes according to current&best options via the commute analysis dashboard.',
  setupTrialWarning: 'You haven\'t completed the setup for trial with your data. To continue setup later, click Try with Your Own Data button on the Commute Analysis Screen.',
  searchingForCurrentLocation: 'Searching for current location',
  setServiceEligibility: 'Set Service Eligibility',
  markAgreementSignatureStatus: 'Mark Agreement Signature Status',
  selectServices: 'Select services',
  selectAgreement: 'Select Agreement',
  setSelectedRidersAs: 'Set selected riders as',
};
