export default {
  surveyQn: {
    selectAll: 'Please select all that apply',
    selectPrimary: 'Select your primary mode',
    selectSecondary: 'Select your secondary mode',
    skip: 'Skip',
    continue: 'Continue',
    addSecondary: 'Add secondary mode',
    removeSecondary: 'Remove secondary mode',
  },
  signUp: {
    joinFlexigo: 'Join flexigo to see your commute options!',
    company: 'Company',
    reset: 'Reset',
    notMyCompany: 'Not working for this company?',
    email: 'E-mail',
    atCompany: '@company.com',
    emailVerification: 'Verification e-mail will be sent to this address.',
    password: 'Password',
    signUp: 'Sign Up',
    isRegistered: 'Already registered?',
    login: 'Login',
    continueOnWeb: 'Continue on Web',
    learnMore: 'learn more',
    aboutFlexigo: 'flexigo is a single point platform for all your work related mobility needs',
    downloadApp: 'Download Flexigo App',
    seeCommuteOptions: 'to signup and see your commute options',
    userOpinion: 'What users say',
    appUser: 'flexigo app user',
    or: 'or',
  },
  dashboard: {
    profile: 'Profile',
    homeAddress: 'Home Address',
    changePassword: 'Change Password',
    signOut: 'Sign Out',
    aboutFlexigo: 'About flexigo',
    myCommute: 'My Commute',
    currentMode: 'CURRENT MODE',
    driving: 'Driving',
    carbonEmission: 'Emission (CO2)',
    cost: 'Cost',
    time: 'Time',
    nonSovMode: 'Switching to a non-SOV mode you could save 2 trees a month!',
    otherOptions: 'OTHER OPTIONS',
    monthly: 'MONTHLY',
    shuttle: 'Shuttle',
    realCost: 'Real Cost',
    subsidy: 'Subsidy',
    applied: 'Applied',
    apply: 'Apply',
    flexiShuttle: 'Why use flexiShuttle?',
    publicTransport: 'Public Transport',
    markCurrentMode: 'Mark as current mode',
    publicTransportOptions: 'PUBLIC TRANSPORT OPTIONS',
    travelDuration: 'One-Way Travel Duration',
    firstOption: 'Option 1',
    details: 'Details',
    secondOption: 'Option 2',
    applyForShuttle: 'Apply for Shuttle Service',
    verified: 'Verified',
    changes: 'changes',
    fullName: 'Full Name',
    workSchedule: 'Work Schedule',
    weekdays: 'Weekdays 9am-6pm',
    validateAddress: 'Please make sure your address is valid.',
    requestReceived:
      ' We’ve received your request to use flexishuttle. We will inform you as soon as your route is confirmed.',
    youNeedFlexigoApp: ' You need to download the flexigo app to track your shuttle and make reservations.',
    fullFeatureAccess: 'for full feature access applied',
  },
  homeAddress: {
    setHomeSuccess:
      'You’ve successfully set your home address. If you wish to use a shuttle, please select a nearby stop',
  },
  workSchedule: 'Work Schedule',
  preferredArrivalTimes: 'Preferred Arrival Times',
  preferredDepartureTimes: 'Preferred Departure Times',
  onSiteDays: 'On Site Days',
  HYBRID: 'Hybrid',
  ON_SITE: 'On Site',
  REMOTE: 'Remote',
  type: 'Type',
  riderList: 'Riders',
  deleteRider: 'Delete Rider',
  sendNotification: 'Send Notification',
  sendEmail: 'Send Email',
};
