export default {
  newRegistration: 'Yeni Kayıt',
  rentalCounts: 'Kiralama Sayıları',
  newContract: 'Yeni Sözleşme',
  firstRental: 'İlk Kiralama',
  cancelled: 'İptal Edilen',
  totalRentals: 'Toplam Kiralamalar',
  instantTotal: 'Bu saat itibariyle toplam',
  usageAmount: 'Kullanım Miktarı (KM)',
  totalKm: 'Toplam Km',
  avgKm: 'Ortalama Km',
  rentalTimes: 'Kiralama Süreleri',
  totalHours: 'Toplam Saat',
  avgHours: 'Ortalama Saat',
  valetTasks: 'Vale Görevleri',
  taskCount: 'Görev Sayısı',
  rentalIncomes: 'Kiralama Cirosu',
  totalAmount: 'Toplam Tutar',
  avgAmount: 'Ortalama Tutar',
  areaSearch: 'Servis Alanlarına Göre Kiralamalar',
  reasonsApproval: 'Onay ve Red nedenleri',
  rejectStatus: 'Red Durumu',
  coupon: 'Kupon',
  definition: 'Tanım',
  rate: 'Oran',
  used: 'Kullanılan',
  totalDiscounted: 'Toplam İndirimli',
  withoutTotalDiscounted: 'Toplam İndirimsiz',
  totalDiscount: 'Toplam İndirim',
  notInServiceArea: 'Servis Alanı Dışı',
  usage: 'Kullanım',
  current: 'Kullanılan',
  totalRevenue: 'Toplam Gelir',
  valet: 'vale',
  registeredUsers: 'Kayıtlı Müşteri',
  userWithContract: 'Sözleşmeli Müşteri',
  activeVehicles: 'Aktif Araç',
  todaysRentals: 'Bugünkü Kiralama',
  valetStatus: 'Valelerin Anlık Durumu',
  missingContract: 'Sözleşme Eksik',
  rentalUserCount: 'Kiralama Yapan Müşteri',
  todaysRadar: 'Bugünün Radar Sonuçları',
  currentRentals: 'Anlık Kiralama Durumu',
  customers: 'Müşteriler',
  rentalCount: 'Kiralama Sayıları',
  rentals: 'Kiralamalar',
  selfServiceSearch: 'Self Servis Aramaları (mesafe)',
  searchCount: 'Arama Sayısı',
  avgDistance: 'Ortalama Mesafe',
  parkingStats: 'Otopark İstatistik',
  firstUse: 'İlk Kullanım',
  numbers: 'Sayılar',
  invites: {
    header: '"Arkadaşını Davet Et" Sayıları',
    users: 'Kullanıcılar',
    both_used: 'Karşılıklı kullanan',
    invitee_not_registered: 'Davetli Kayıtlı Değil',
    invitee_not_used: 'Davetli Kullanmadı',
    inviter_not_used: 'Kullanan',
    totalInvitees: 'Davet Edilen',
  },
  rentalsByZone: 'Aktif Kiralama Dağılımı',
  valets: 'Valeler',
  passiveCategoriesRatio: 'Pasif Kategoriler Rasyosu (%)',
  activenessRatio: 'Aktiflik Rasyosu (%)',
  utilizationChart: 'Utilizasyon (%)',
  currentRatio: 'Aktif Değer',
  fromThisHour: 'Bu saat itibariyle',
  activeVehicleRatio: 'Aktif Araç Yüzdesi (%)',
  newCustomerUntilThisHour: 'Yeni Kayıt (Bu saat itibariyle)',
  signedAgreementUntilThisTime: 'Yeni Sözleşme (Bu saat itibariyle)',
  driverStatus: 'Sürücü Durumu',
  rentalStatus: 'Kiralama Durumu',
  radarStatus: 'Radar Durumu',
  userCounts: 'Kullanıcı Sayısı',
  firstRentalFromThisTime: 'İlk Kiralama ( Bu saat itibariyle)',
  routeRentalInfo: {
    activeRouteCount: 'Aktif Rota Sayısı',
    searchCount: 'Arama Sayısı',
    incompleteRouteCount: 'Tamamlanmamış Rota Sayısı',
    completedRouteCount: 'Tamamlanmış Rota Sayısı',
  },
  routeRentals: 'Rota Kiralamaları',
  routeRental: 'Rota Kiralaması',
  subscriptionRental: 'Abonelik Kiralaması',
  dailyRental: 'Günlük Kiralama',
  reservationDailyRental: 'Rezervasyonlu Kiralama',
  selfService: 'Dakikalık Kiralama',
};
