export default {
  below: 'Below {0}',
  belowWithPercent: 'Below %{0}',
  over: 'Over {0}',
  overWithPercent: 'Over %{0}',
  driversVehicles: 'Drivers/Vehicles',
  tasksDocuments: 'Documents',
  addressMissing: 'Address Missing',
  tickets: 'Support Cases',
  systemStatus: 'System Status',
  occupancyRatio: 'Occupancy Ratio',
  viewData: 'View {0}',
  viewUsers: 'View Users',
  viewRoutes: 'View Routes',
  avg: 'Avg',
  timeCoefficient: 'Time Coefficient',
  walkingDistance: 'Walking Distance',
  totalCost: 'Total Cost',
  totalEmission: 'Total Emission (CO2)',
  perEmployee: 'per Employee',
  perEmployeeMile: 'per Employee/Mile',
  toCampus: 'to Campus',
  fromCampus: 'from Campus',
  onTimeArrival: 'On Time Arrival',
  noArrival: 'No Arrival',
  lateArrival: 'Late Arrival',
  deviceProblem: 'Device Problem',
  leaveOnTime: 'Leave On Time',
  readyToLeave: 'Ready To Leave',
  leaveEarly: 'Leave Early',
  devices: 'Devices',
  availableDevice: 'Available',
  notAvailableDevice: 'Not Available',
  noSignal: 'No Signal',
  userRegistration: 'User Registration',
  completed: 'Completed',
  undefinedRoute: 'Route Undefined',
  notUsingShuttle: 'Not Using Shuttle',
  documents: 'Documents',
  progressPayment: 'Progress Payment',
  missing: 'Missing',
  planned: 'Planned',
  tasks: 'Tasks',
  pendingRequests: 'Pending Requests',
  routeChange: 'Route Change',
  reservation: 'Reservation',
  pendingAssignments: 'Pending Assignments',
  stops: 'Stops',
  employeeLocationAndRouteDistribution: 'Employee Location and Route Distribution',
  occupancyRatioAvg: 'Occupancy Ratio Avg. (Min-Max)',
  numberOfUser: '# of Users',
  numberOfRoutes: '# of Routes',
  ratioToTotalUsers: 'Ratio to Total Users',
  usageRate: 'Usage Rate',
  fullfillmentRate: 'Fullfillment Rate',
  usage: 'Usage',
  actual: 'Actual',
  cardScans: 'Employee Check-in',
  kgMonth: 'KG/Month',
  city: 'City',
  allCampuses: 'All campuses & route groups',
  allRoutes: 'All routes',
  routeGroupSelected: '{0} route group selected',
  searchCampus: 'Search campuses & route groups',
  searchRoutes: 'Search routes',
  vehicles: 'Vehicles',
  drivers: 'Drivers',
  overTheAgeLimit: 'Over the age limit',
  welcometoFlexigo: 'Welcome to flexigo!',
  downloadApp: 'Download the flexigo app now and get started.',
  dynamicRoutes: 'Dynamic Routes',
  fixedRoutes: 'Fixed Routes',
  uniqueRidersRides: 'Unique Riders / Rides',
  tripsRoutes: 'Trips / Routes',
  avgOccupancy: 'Avg. Occupancy',
  avgTimeCoefficient: 'Avg. Time Coefficient',
  avgDistanceToStop: 'Avg. Distance to Stop',
  above: 'Above {0} ft',
  perEmployeeTrip: 'per Employee Trip',
  totalEmmisions: 'Total Emmisions (CO₂)',
  telematicsDevices: 'Telematics Devices',
  noDevice: 'No Device',
  noConnection: 'No Connection',
  actionRequired: 'Action Required',
  openTickets: 'Open tickets',
  nearbyStopRequests: 'Nearby Stop Requests',
  changeStopRequests: 'Stop Change Requests',
  driversOverAgeLimit: 'Drivers over Age Limit',
  driverVehicleApprovals: 'Driver/Vehicle Approvals',
  documentApprovals: 'Document Approvals',
  paymentApprovals: 'Payment Approvals',
  rideRequests: 'Ride Requests',
  reserved: 'Reserved',
  failed: 'Failed',
  canceled: 'Canceled',
  numberOfRidersByDayOfTheWeek: 'Number of Riders by Day of the Week',
  ridersEmbarkedOnFixedRoutes: 'Riders Embarked on Fixed Routes',
  ridersEmbarkedOnDynamicRoutes: 'Riders Embarked on Dynamic Routes',
  occupancyRatiosOverTime: 'Occupancy Ratios Over Time',
  reportedByDriver: 'Reported by driver',
  riderCheckins: 'Rider Checkins',
  onTimePerformanceOverTime: 'On Time Performance over Time',
  lateArrivals: 'Late Arrivals',
  noArrivals: 'No Arrivals',
  noArrivalsDepartures: 'No Arrivals/Departures',
  earlyDepartures: 'Early Departures',
  onTimePerformance: 'On Time Performance',
  nonCommuteMiles: 'Non-Commute Miles',
  avgRidersPerTrip: 'Avg. Riders per Trip',
  compliance: 'Compliance (Trips with less than 4 Riders & Vehicles with non-commute miles over 20%)',
  complianceGraphMarker: {
    first: 'Trips with less than 4 riders',
    second: 'Vehicles with non-commute miles over 20%',
  },
  vanpoolRoutesAvgRidership: 'Vanpool Routes by <br/> Avg. Ridership',
  vanpoolRoutesAvgNonCommuteMiles: 'Vanpool Routes by <br/> Avg. Non-Commute Miles',
};
